import { Dropdown, IDropdownOption, IDropdownStyles, Label } from '@fluentui/react';
import { FC, useMemo } from 'react';

import { ColorPalettePicker } from '../Color/ColorPalettePicker';
import './cssStyles.scss';
import { CssUnitBox } from './CssUnitBox';

export interface CssBorderInputProps {
    label: string;
    title?: string;
    value: string | undefined;
    onChange: (value: string | undefined) => void;
}

const borderType: IDropdownOption[] = [
    {
        key: 'none',
        text: 'No',
        title: 'Pas de bord',
    },
    {
        key: 'solid',
        text: 'plein',
        title: 'bord plein',
    },
    {
        key: 'dotted',
        text: 'pointillée',
        title: 'bord en pointillée',
    },
    {
        key: 'dashed',
        text: 'tiré',
        title: 'bord avec des tirés',
    },
    {
        key: 'double',
        text: 'double',
        title: 'bord doublée',
    },
];

function convertToLitteral(size: string | undefined, type: string | undefined, color: string | undefined) {
    return `${size ?? ''} ${type ?? ''} ${color ?? ''}`;
}
function parseCssValue(val: string | undefined) {
    if (val) {
        const array = val.split(' ');
        return { size: array[0], type: array[1], color: array[2] };
    }
    return { size: undefined, type: undefined, color: undefined };
}
const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 100 },
    dropdownOptionText: { overflow: 'visible', whiteSpace: 'normal' },
    dropdownItem: { height: 'auto' },
};
export const CssBorderInput: FC<CssBorderInputProps> = (props: CssBorderInputProps) => {
    const { value, onChange, label, title } = props;

    const data = useMemo(() => {
        return parseCssValue(value);
    }, [value]);
    return (
        <>
            {label && <Label title={title}>{label}</Label>}
            <div className='DivFlexHorizontalCenter'>
                <CssUnitBox
                    value={data.size ?? '0px'}
                    small
                    onChange={(value: string | undefined) => {
                        onChange(convertToLitteral(value ?? '0px', data.type ?? 'solid', data.color ?? '#000000'));
                    }}
                />
                <Dropdown
                    styles={dropdownStyles}
                    options={borderType}
                    selectedKey={data.type ?? 'solid'}
                    onChange={(_, opt) => {
                        onChange(convertToLitteral(data.size ?? '0px', (opt?.key as string) ?? 'solid', data.color ?? '#000000'));
                    }}
                />
                <ColorPalettePicker
                    type='b'
                    idAsCssVariable
                    colorId={data.color}
                    border
                    //title='Couleur de la bordure'
                    onChange={(colorId) => {
                        onChange(convertToLitteral(data.size ?? '0px', data.type ?? 'solid', colorId));
                    }}
                />
            </div>
        </>
    );
};
