import { Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react';
import { LexicalEditor } from 'lexical';
import { FC, useCallback } from 'react';
import { defaultFont, listOfFontFamily } from '../../../../styles/FontStyles';
import { applyStyleOnSelection } from '../../Helpers/selection';
import { predefinedStylePropertyName } from './PredefinedStyleSelector';

export interface FontSelectorProps {
    fontFamily?: string;
    editor: LexicalEditor;
}

export function buildFontFamilyDropdown(names: string[]): IDropdownOption[] {
    return names
        .map((n) => {
            const name = n.split(',')[0].replaceAll("'", '');
            return {
                key: n,
                text: name,
                data: n,
            };
        })
        .sort((a, b) => a.text.localeCompare(b.text));
}
export const listFonts: IDropdownOption[] = buildFontFamilyDropdown(listOfFontFamily);
const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 175 },
};

export const FontSelector: FC<FontSelectorProps> = (props: FontSelectorProps) => {
    const { fontFamily, editor } = props;

    const handleClick = useCallback(
        (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number) => {
            applyStyleOnSelection(
                editor,
                {
                    [predefinedStylePropertyName]: null,
                    'font-family': (option?.key as string) ?? defaultFont,
                },
                false,
            );
        },
        [editor],
    );

    return <Dropdown className='ddlsmallsize' options={listFonts} styles={dropdownStyles} selectedKey={fontFamily ?? defaultFont} onChange={handleClick} />;
};
