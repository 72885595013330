import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IResource, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';
import { Container } from '../../../../common/Hooks/StorageResolver';
import { getExtension } from '../../../../common/helpers/FileHelper';
import { ResourceFileDetails } from '../ResourceFileDetails';
import { ImageCategories } from './ImageCategory';

export interface ResourceImageDetailsProps {
    resource?: IResource;
    show: boolean;
    category?: string;
    onClose: (resource: IResource | undefined) => void;
}

export const ResourceImageDetails: FC<ResourceImageDetailsProps> = (props: ResourceImageDetailsProps) => {
    const { resource, show, onClose, category } = props;

    return (
        <ResourceFileDetails
            allowAlternativesFiles={[{ suffix: 'm', label: 'Fichier alternatif pour Mobile' }]}
            blobnameResolver={(_, filename) => `/images/${uuidv4()}.${getExtension(filename)}`}
            container={Container.Public}
            contentDisposition='inline'
            onClose={onClose}
            category={category}
            resource={resource}
            categoryOptions={ImageCategories}
            show={show}
            type={ResourceTypeEnum.Image}
        />
    );
};
