export function combineUri(uri: string, path: string) {
    if (uri.endsWith('/')) {
        if (path.startsWith('/')) {
            return uri + path.substring(1);
        }
        return uri + path;
    } else {
        if (path.startsWith('/')) {
            return uri + path;
        }
        return uri + '/' + path;
    }
}

export function openNewTab(url: string) {
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('target', '_blank');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}
export function openInCurrentTab(url: string) {
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}
