import { ActionButton, Label, TextField } from '@fluentui/react';
import { FC, useMemo, useState } from 'react';

import { CssUnitBox } from './CssUnitBox';

import clsx from 'clsx';
import { AccordionIcon } from '../AccordionIcon/AccordionIcon';
import './cssStyles.scss';
export interface CssRectInputProps {
    label: string;
    title?: string;
    value: string | undefined;
    onChange: (value: string | undefined) => void;
    cornerMode?: boolean;
}
function convertToLitteral(left: string | undefined, right: string | undefined, top: string | undefined, bottom: string | undefined) {
    if (left === right && right === top && top === bottom) {
        return left;
    }
    return `${top ?? '0'} ${right ?? '0'} ${bottom ?? '0'} ${left ?? '0'}`;
}
function parseCssValue(val: string | undefined) {
    if (val) {
        const array = val.split(' ');
        if (array.length === 1) {
            return { left: array[0], right: array[0], top: array[0], bottom: array[0], singleValue: true };
        }
        return { left: array[3], right: array[1], top: array[0], bottom: array[2], singleValue: false };
    }
    return { left: undefined, right: undefined, top: undefined, bottom: undefined, singleValue: true };
}
export const CssRectInput: FC<CssRectInputProps> = (props: CssRectInputProps) => {
    const { value, onChange, label, title, cornerMode } = props;
    const data = useMemo(() => {
        return parseCssValue(value);
    }, [value]);
    const [isOpen, setIsOpen] = useState(!data.singleValue);

    return (
        <>
            <Label title={title}>{label}</Label>
            <div className='cssRectInput'>
                <div className={clsx('cssRectInputHeader', isOpen ? 'noBottomborderRadius' : '')}>
                    <div
                        className={'swapMode'}
                        onClick={() => {
                            setIsOpen(!isOpen);

                            // if (data.left === data.right && data.right === data.top && data.top === data.bottom) {
                            //     onChange(convertToLitteral(data.left, undefined, undefined, undefined));

                            // } else {
                            //     onChange(convertToLitteral(data.left, data.left, data.left, data.left));
                            // }
                        }}
                    >
                        <AccordionIcon open={isOpen} />
                    </div>
                    {!data.singleValue ? (
                        <TextField className='viewCssRectInput' title='Haut,Droite,Bas,Gauche' readOnly value={value} />
                    ) : (
                        <CssUnitBox
                            title='Tous'
                            hasExpandButton
                            value={data.left}
                            noBottomborderRadius={isOpen}
                            onChange={(value: string | undefined) => {
                                onChange(convertToLitteral(value, value, value, value));
                            }}
                        />
                    )}
                </div>
                {isOpen ? (
                    <div className='cssRectInputDetails'>
                        {cornerMode ? (
                            <>
                                <CssUnitBox
                                    title='En Haut à gauche'
                                    value={data.top}
                                    small
                                    hideUnit
                                    onChange={(value: string | undefined) => {
                                        onChange(convertToLitteral(data.left, data.right, value, data.bottom));
                                    }}
                                />
                                <div />
                                <CssUnitBox
                                    title='En haut à Droite'
                                    value={data.right}
                                    small
                                    hideUnit
                                    onChange={(value: string | undefined) => {
                                        onChange(convertToLitteral(data.left, value, data.top, data.bottom));
                                    }}
                                />
                                <div />
                                <ActionButton
                                    onClick={() => {
                                        onChange(convertToLitteral(data.left, data.left, data.left, data.left));
                                    }}
                                >
                                    Reset
                                </ActionButton>
                                <div />
                                <CssUnitBox
                                    title='En bas à Gauche'
                                    value={data.left}
                                    small
                                    hideUnit
                                    onChange={(value: string | undefined) => {
                                        onChange(convertToLitteral(value, data.right, data.top, data.bottom));
                                    }}
                                />
                                <div />
                                <CssUnitBox
                                    title='En Bas à droite'
                                    value={data.bottom}
                                    small
                                    hideUnit
                                    onChange={(value: string | undefined) => {
                                        onChange(convertToLitteral(data.left, data.right, data.top, value));
                                    }}
                                />
                            </>
                        ) : (
                            <>
                                <div />
                                <CssUnitBox
                                    title='Haut'
                                    value={data.top}
                                    small
                                    hideUnit
                                    onChange={(value: string | undefined) => {
                                        onChange(convertToLitteral(data.left, data.right, value, data.bottom));
                                    }}
                                />
                                <div />
                                <CssUnitBox
                                    title='Gauche'
                                    value={data.left}
                                    small
                                    hideUnit
                                    onChange={(value: string | undefined) => {
                                        onChange(convertToLitteral(value, data.right, data.top, data.bottom));
                                    }}
                                />
                                <ActionButton
                                    onClick={() => {
                                        onChange(convertToLitteral(data.left, data.left, data.left, data.left));
                                    }}
                                >
                                    Reset
                                </ActionButton>
                                <CssUnitBox
                                    title='Droite'
                                    value={data.right}
                                    small
                                    hideUnit
                                    onChange={(value: string | undefined) => {
                                        onChange(convertToLitteral(data.left, value, data.top, data.bottom));
                                    }}
                                />
                                <div />
                                <CssUnitBox
                                    title='Bas'
                                    value={data.bottom}
                                    small
                                    hideUnit
                                    onChange={(value: string | undefined) => {
                                        onChange(convertToLitteral(data.left, data.right, data.top, value));
                                    }}
                                />
                                <div />
                            </>
                        )}
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};
