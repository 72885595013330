import { ActionButton } from '@fluentui/react';
import { FC } from 'react';
import { IPageElement, IPageElementCustomComponent, PageElementContainer, PageElementType } from '../../../../Services/SakuraApiClient';
import { CustomComponentName } from '../../Page/Elements/CustomComponent/type';
import { getVisualOfElementType } from './ElementToolBox';

export interface PageHierarchyMapItemProps {
    data: IPageElement;
    onClick: (data: IPageElement) => void;
}
export const PageHierarchyMapItem: FC<PageHierarchyMapItemProps> = (props: PageHierarchyMapItemProps) => {
    const { data, onClick } = props;
    const visual = getVisualOfElementType(data.type, data.type === PageElementType.CustomComponent ? ((data as unknown as IPageElementCustomComponent).name as CustomComponentName) : undefined);
    return (
        <>
            <ActionButton iconProps={{ iconName: visual[0] }} text={visual[1]} onClick={() => onClick(data)} />
            {data.type === PageElementType.Container ? (
                <div className='mapBoxItems'>
                    {(data as PageElementContainer).items?.map((item, index) => (
                        <PageHierarchyMapItem
                            key={`itemMap${index}`}
                            data={item}
                            onClick={(element) => {
                                onClick(element);
                            }}
                        />
                    ))}
                </div>
            ) : (
                <></>
            )}
        </>
    );
};
