import { FC, useEffect, useState } from 'react';

import { ITextFieldProps, TextField } from '@fluentui/react';
export type InputType = 'Number' | 'DecimalNumber';

export interface NumberFieldProps extends Omit<ITextFieldProps, 'value' | 'onChange'> {
    inputType?: 'Number' | 'DecimalNumber';
    value?: number;

    onChange?: (value: number | undefined) => void;
}

export const NumberField: FC<NumberFieldProps> = (props: NumberFieldProps) => {
    const { inputType, value, onChange } = props;

    const [textValue, setTextValue] = useState<string | undefined>(value?.toString());

    useEffect(() => {
        setTextValue(value?.toString());
    }, [value]);

    const checkFormat = (val: string | undefined) => {
        if (inputType && val) {
            switch (inputType) {
                case 'DecimalNumber':
                    return /^[0-9]+\.?[0-9]*$/.test(val);

                case 'Number':
                    return /^[0-9]+$/.test(val);
                default:
                    return true;
            }
        }
        return true;
    };

    return (
        <TextField
            {...props}
            value={textValue ?? ''}
            onKeyDown={(event) => {
                if (
                    !(
                        event.code === 'Backspace' || // backspace
                        event.code === 'Home' ||
                        event.code === 'Delete' || // backspace
                        (event.key >= '0' && event.key <= '9') ||
                        event.code.startsWith('Arrow') ||
                        event.key === '.' ||
                        event.key === '-'
                    )
                ) {
                    event.preventDefault(); // Prevent character input
                }
                if (inputType === 'Number' && event.key === '.') {
                    event.preventDefault(); // Prevent character input
                }
            }}
            onChange={(_, v) => {
                setTextValue(v);
                if (v === '') {
                    if (onChange) {
                        onChange(undefined);
                    }
                }
                if (checkFormat(v) && v) {
                    const value = inputType === 'DecimalNumber' ? Number.parseFloat(v) : Number.parseInt(v, 10);
                    if (onChange) {
                        onChange(value);
                    }
                }
            }}
        />
    );
};
