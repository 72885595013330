import { Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react';
import React, { FC } from 'react';
import { defaultFont, listOfFontFamily } from '../../styles/FontStyles';

export interface FontFamilySelectorProps {
    label: string;
    family: string | undefined;
    onChange: (family: string) => void;
}
const fontDdlStyle: Partial<IDropdownStyles> = {
    dropdown: { minWidth: 275 },
};
export function buildFontFamilyDropdown(names: string[]): IDropdownOption[] {
    return names
        .map((n) => {
            const name = n.split(',')[0].replaceAll("'", '');
            return {
                key: n,
                text: name,
            };
        })
        .sort((a, b) => a.text.localeCompare(b.text));
}
const policeList = buildFontFamilyDropdown(listOfFontFamily);

export const FontFamilySelector: FC<FontFamilySelectorProps> = (props: FontFamilySelectorProps) => {
    const { family, label, onChange } = props;
    return (
        <Dropdown
            label={label}
            options={policeList}
            styles={fontDdlStyle}
            selectedKey={family}
            onRenderOption={(itemProps, df) => {
                return (
                    <div className='fontItem'>
                        <span>{itemProps?.text}</span>
                        <span className='preview' style={{ fontFamily: (itemProps?.key as string) ?? defaultFont }}>
                            {itemProps?.text}
                        </span>
                    </div>
                );
            }}
            onChange={(_, option) => {
                onChange(option?.key as string);
            }}
        ></Dropdown>
    );
};
