import { FC, useCallback, useState } from 'react';
import { ResourcePageContent, getResourcesInfo } from '../Pages/Common/Page/ResourcePageContent';
import { setStorageEndpoints, useMessageSelector } from '../Redux/Reducers/System/reducer';
import { useAppDispatch } from '../Redux/hook';
import { IProtectedValueInfo, IPublicResource, ResourceTypeEnum } from '../Services/SakuraApiClient';
import { useApiCall } from '../common/Hooks/useApiCall';
import { InvalidLinkPage } from '../common/InvalidLinkPage';
import { LayoutPublic } from '../common/LayoutPublic/LayoutPublic';
import { LoadingScreen } from '../common/LoadingScreen';
import { addPixelFacebookOnPage } from '../common/helpers/PixelFacebook';
import { ResourceDocumentPage } from './ResourceDocumentPage/ResourceDocumentPage';
import { ResourceQuestionnaryPage } from './ResourceQuestionnaryPage/ResourceQuestionnaryPage';
import { MarketingOffer, ViewMarketingOffer } from './ViewMarketingOffer';

interface ViewResourceState {
    resource: IPublicResource | undefined;
    offer: MarketingOffer;
    queryString: Record<string, IProtectedValueInfo>;
}
export const ViewResource: FC = () => {
    const [state, setState] = useState<ViewResourceState | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const error = useMessageSelector();
    const dispatch = useAppDispatch();

    useApiCall(
        useCallback(
            async (client, cancelToken) => {
                let url = window.location.href;
                let snlid = undefined;

                const index = url.indexOf('&snlid=');
                if (index !== -1) {
                    snlid = Number.parseInt(url.slice(index + '&snlid='.length), 10);
                    url = url.slice(0, index);
                }
                const values = await client.getProtectedValueOfQueryString(url, cancelToken);
                const resourceId = values['id'];
                const resource = await client.publicGetResource(resourceId.protected, snlid);

                if (resource.pixelFacebook) {
                    addPixelFacebookOnPage(resource.pixelFacebook);
                }
                dispatch(setStorageEndpoints({ storage: resource.storageInformation }));
                setState({
                    resource,
                    offer: {
                        showCurrentOffers: values['o'],
                        offerId1: values['o1'],
                        offerId2: values['o2'],
                    },
                    queryString: values,
                });
                setIsLoading(false);
            },
            [dispatch, setIsLoading, setState],
        ),
        { anonymous: true },
    );
    const type = state?.resource?.resourceType;
    return (
        <>
            {error && <InvalidLinkPage />}
            {!error && (
                <>
                    {isLoading ? (
                        <LoadingScreen text='Bienvenue &#x1F603;' />
                    ) : (
                        <LayoutPublic>
                            {state?.resource ? (
                                <>
                                    <>
                                        {type === ResourceTypeEnum.Page ? (
                                            <ResourcePageContent
                                                pageResourceId={state.resource.id}
                                                pageData={state.resource?.page.data.content.page}
                                                resourceData={state.resource.page.data}
                                                innerResources={getResourcesInfo(state.resource.page.innerResourcePackages ?? {})}
                                                resourceAccountData={undefined}
                                                parentResourcePathId={undefined}
                                            ></ResourcePageContent>
                                        ) : (
                                            <></>
                                        )}
                                        {type === ResourceTypeEnum.Document ? (
                                            <ViewMarketingOffer enabled={state.resource.enabled} offer={state?.offer}>
                                                <ResourceDocumentPage resource={state?.resource} />
                                            </ViewMarketingOffer>
                                        ) : (
                                            <></>
                                        )}
                                        {type === ResourceTypeEnum.Questionnary ? <ResourceQuestionnaryPage resource={state?.resource} queryString={state?.queryString} offer={state?.offer} /> : <></>}
                                    </>
                                </>
                            ) : (
                                <></>
                            )}
                            {!type && <InvalidLinkPage />}
                        </LayoutPublic>
                    )}
                </>
            )}
        </>
    );
};
