import { FC, useEffect, useState } from 'react';
import { ResourcePageGrid } from '../../../../Redux/Reducers/DataSet/ApiCall/Resource';
import { clearContext } from '../../../../Redux/Reducers/System/reducer';
import { useAppDispatch } from '../../../../Redux/hook';
import { screenPaths } from '../../../../Routing/ScreenPaths';
import { IResource, Resource, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';
import { performApiCall } from '../../../../common/Hooks/useApiCall';
import { INavigationState, useNav } from '../../../../common/Hooks/useNav';
import { ConfirmationDialog } from '../../../../common/components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { IconEnabled } from '../../../../common/components/Icons/IconEnabled';
import { GetResourceTypeIcon } from '../../../../common/components/Icons/ResourceTypeIcon';
import { Panel } from '../../../../common/components/Panel/Panel';
import { ResourceGenerateLink } from '../ResourceGenerateLink';
import { ResourceList } from '../ResourceList';
import { NewPageDialog } from './NewPageDialog';

interface NavState {
    refresh?: boolean;
}

export const ResourcePageList: FC = () => {
    const [selectedResource, setSelectedResource] = useState<IResource>();
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [showNewPageDialog, setShowNewPageDialog] = useState<boolean>(false);
    const [showGenerateLinkDialog, setShowGenerateLinkDialog] = useState<boolean>(false);

    const nav = useNav();
    const appDispatch = useAppDispatch();
    const navState = nav.current.state as INavigationState<NavState>;
    useEffect(() => {
        if (navState?.state?.refresh) {
            ResourcePageGrid.refresh();
        }
    }, [navState?.state?.refresh]);

    const duplicate = (resource: IResource) => {
        performApiCall(async (client) => {
            const newResource = new Resource();
            newResource.init(resource);
            newResource.name = newResource.name + ' - copy';
            await client.createResource(newResource);
            ResourcePageGrid.refresh();
        }, appDispatch);
    };
    return (
        <Panel
            title='Liste des Pages'
            icon={GetResourceTypeIcon(ResourceTypeEnum.Formation)}
            commands={[
                {
                    icon: 'AddTo',
                    text: 'Ajouter',
                    onClick: () => {
                        setShowNewPageDialog(true);
                    },
                },
                {
                    icon: 'Edit',
                    text: 'Modifier',
                    disabled: !selectedResource,
                    onClick: () => {
                        if (selectedResource?.id) {
                            nav.navigate(screenPaths.resourceUpdate(ResourceTypeEnum.Page, selectedResource.id), undefined); // no state here because of the full package required
                        }
                    },
                },
                {
                    icon: 'Link',
                    text: "Générer un lien d'accés",
                    disabled: !selectedResource,
                    onClick: () => {
                        if (selectedResource?.id) {
                            setShowGenerateLinkDialog(true);
                        }
                    },
                },
                {
                    icon: 'Copy',
                    text: 'Dupliquer',
                    disabled: !selectedResource,
                    onClick: () => {
                        if (selectedResource) {
                            duplicate(selectedResource);
                        }
                    },
                },
                {
                    icon: 'Delete',
                    text: 'Supprimer',
                    disabled: !selectedResource,
                    onClick: () => {
                        setShowDeleteDialog(true);
                    },
                },
                {
                    icon: 'Refresh',
                    text: 'Actualiser',
                    onClick: () => {
                        ResourcePageGrid.refresh();
                    },
                },
            ]}
        >
            <ResourceList
                datasetName={'ResourcePageGrid'}
                onSelect={(selection) => setSelectedResource(selection)}
                extraColumns={[
                    {
                        key: 'Reusable',
                        name: 'Partageable',
                        minWidth: 60,
                        maxWidth: 60,
                        allowSorting: true,
                        isResizable: true,
                        onRender: (item: IResource) => {
                            return <IconEnabled enabled={item.reusable} />;
                        },
                    },
                ]}
                onRowDoubleClick={(item) => {
                    if (item) {
                        nav.navigate(screenPaths.resourceUpdate(ResourceTypeEnum.Page, item.id), undefined); // no state here because of the full package required
                    }
                }}
            />
            <ConfirmationDialog
                id='ConfirmationDialog'
                icon={{ iconName: GetResourceTypeIcon(ResourceTypeEnum.Formation) }}
                show={showDeleteDialog}
                title='Suppression de la page'
                subText='Etes-vous sur de vouloir supprimer la page?'
                onReply={(reply) => {
                    if (reply) {
                        performApiCall(
                            async (client) => {
                                if (selectedResource?.id) {
                                    await client.deleteResource(selectedResource.id);
                                    setShowDeleteDialog(false);
                                    ResourcePageGrid.refresh();
                                }
                            },
                            appDispatch,
                            { contextId: 'ConfirmationDialog' },
                        );
                    } else {
                        setShowDeleteDialog(false);
                        appDispatch(
                            clearContext({
                                contextId: 'ConfirmationDialog',
                            }),
                        );
                    }
                }}
            />
            {showGenerateLinkDialog && selectedResource && <ResourceGenerateLink resource={selectedResource} show={showGenerateLinkDialog} onClose={() => setShowGenerateLinkDialog(false)} />}
            {showNewPageDialog ? (
                <NewPageDialog
                    createRecord
                    onClose={(pagePackage) => {
                        if (pagePackage) {
                            ResourcePageGrid.refresh();
                            nav.navigate(screenPaths.resourceUpdate(ResourceTypeEnum.Page, pagePackage.resource.id), pagePackage); //we already know the package resource so let pass it
                        } else {
                            setShowNewPageDialog(false);
                        }
                    }}
                />
            ) : null}
        </Panel>
    );
};
