export function beginOfDay(date: Date | null | undefined): Date | undefined {
    if (date) {
        return new Date(date.setHours(0, 0, 0, 0));
    }
    return date ?? undefined;
}
export function endOfDay(date: Date | null | undefined): Date | undefined {
    if (date) {
        return new Date(date.setHours(23, 59, 59, 99));
    }
    return date ?? undefined;
}

export function displayTime(ticksInSecs: number | undefined): string {
    if (ticksInSecs !== undefined) {
        const ticks = ticksInSecs;
        const hh = Math.floor(ticks / 3600);
        const mm = Math.floor((ticks % 3600) / 60);
        const ss = ticks % 60;

        return pad(hh.toString(), 2) + ':' + pad(mm.toString(), 2) + ':' + pad(ss.toString(), 2);
    }
    return '';
}
export function elapsedSecond(a: Date, b: Date) {
    const difference = (a.getTime() - b.getTime()) / 1000;
    return difference;
}
function pad(n: string, width: number): string {
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
}

export function dateNow(): Date {
    return new Date(Date.now());
}
export function addDays(date: Date, days: number) {
    return new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
}

export function getDatetimeOffsetNow() {
    const now = dateNow();
    const tz = getTimeZone();
    const datePart = `${pad(now.getDate().toString(), 2)}/${pad(now.getMonth().toString(), 2)}/${pad(now.getFullYear().toString(), 4)}`;
    const timePart = `${pad(now.getHours().toString(), 2)}:${pad(now.getMinutes().toString(), 2)}:${pad(now.getSeconds().toString(), 2)}`;
    return `${datePart} ${timePart} ${tz}`;
}
export function getCurrentYear() {
    const now = dateNow();

    return pad(now.getFullYear().toString(), 4);
}
/**
 * Get client side timezone.
 *
 * @returns {(+|-)HH:mm} - Where `HH` is 2 digits hours and `mm` 2 digits minutes.
 * @example
 * // From Indian/Reunion with UTC+4
 * // '+04:00'
 * getTimeZone()
 */
export const getTimeZone = () => {
    const timezoneOffset = new Date().getTimezoneOffset();
    const offset = Math.abs(timezoneOffset);
    const offsetOperator = timezoneOffset < 0 ? '+' : '-';
    const offsetHours = (offset / 60).toString().padStart(2, '0');
    const offsetMinutes = (offset % 60).toString().padStart(2, '0');

    return `${offsetOperator}${offsetHours}:${offsetMinutes}`;
};
export const displayDate = (date: Date | undefined) => {
    if (date) {
        const datePart = `${pad(date.getDate().toString(), 2)}/${pad((date.getMonth() + 1).toString(), 2)}/${pad(date.getFullYear().toString(), 4)}`;
        return datePart;
    }
    return '';
};
export const displayDateAndTime = (date: Date | undefined) => {
    if (date) {
        const datePart = `${pad(date.getDate().toString(), 2)}/${pad((date.getMonth() + 1).toString(), 2)}/${pad(date.getFullYear().toString(), 4)}`;
        const timePart = `${pad(date.getHours().toString(), 2)}:${pad(date.getMinutes().toString(), 2)}`;

        return `${datePart} ${timePart}`;
    }
    return '';
};
export const displayHHMM = (date: Date | undefined) => {
    if (date) {
        const timePart = `${pad(date.getHours().toString(), 2)}:${pad(date.getMinutes().toString(), 2)}`;
        return timePart;
    }
    return '00:00';
};
export const dateAndTime = (date: Date | undefined, time: string) => {
    const part = time.split(':');
    const hh = Number.parseInt(part[0], 10);
    const mm = Number.parseInt(part[1], 10);
    let newDate = new Date(date ? date.getTime() : 0);

    newDate = new Date(newDate.setHours(Number.isNaN(hh) ? 0 : hh, Number.isNaN(mm) ? 0 : mm, 0, 0));
    return newDate;
};

const date_pattern = /^(0?[1-9]|[12][0-9]|3[01])(?:\/|-)(0[1-9]|1[0-2])(?:\/|-)(\d{4})$/;

export const parseDate = (dateStr: string) => {
    const parts = dateStr.match(date_pattern);
    if (parts) {
        const dt = new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10));
        return dt;
    }
    return undefined;
};
