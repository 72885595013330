import { ActionButton, Label } from '@fluentui/react';
import React, { FC } from 'react';
import { NumberField } from '../../../../common/components/NumberField/NumberField';
import { ArrayOperation } from '../../../../common/helpers/ObjectAndArray';
import { DeepPartial, FormError } from '../../../../common/Hooks/Form/useForm';
import { ICategoryPoint } from '../../../../Services/SakuraApiClient';
import { CategorySelector } from './CategorySelector';

export interface CategoriesPointsProps {
    label?: string;
    errors?: FormError<ICategoryPoint[] | undefined>;
    categories: DeepPartial<ICategoryPoint>[] | undefined;
    onChange: (categories: DeepPartial<ICategoryPoint>[] | undefined) => void;
}

export const CategoriesPoints: FC<CategoriesPointsProps> = (props: CategoriesPointsProps) => {
    const { label, errors, categories, onChange } = props;
    return (
        <div>
            <Label>{label}</Label>
            {categories?.map((c, index) => {
                const errorItem = errors?.at(index);
                return (
                    <div key={`category${index}`} className='DivFlexHorizontalCenter'>
                        <CategorySelector
                            categoryId={c.id}
                            errorMessage={errorItem?.id}
                            onChange={(id) => {
                                onChange(ArrayOperation.updateByIndex(categories, index, { id }));
                            }}
                        />
                        <NumberField
                            value={c.points}
                            style={{ minWidth: 40 }}
                            placeholder='points'
                            suffix='Pts'
                            errorMessage={errorItem?.points}
                            onChange={(points) => {
                                onChange(ArrayOperation.updateByIndex(categories, index, { points }));
                            }}
                        />
                        <ActionButton
                            iconProps={{ iconName: 'SkypeCircleMinus', style: { color: 'red' } }}
                            onClick={() => {
                                onChange(ArrayOperation.removeByIndex(categories, index));
                            }}
                        />
                    </div>
                );
            })}
            <ActionButton
                iconProps={{ iconName: 'CircleAdditionSolid' }}
                onClick={() => {
                    onChange(ArrayOperation.add(categories ?? [], { id: undefined, points: undefined }));
                }}
            />
        </div>
    );
};
