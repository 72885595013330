import { FC, useMemo, useState } from 'react';
import { IProduct, IRelatedResource, IResource, IResourceOfProduct, RelatedResource, Resource, ResourceOfProduct, ResourceTypeEnum } from '../../../Services/SakuraApiClient';

import { ActionButton, Icon } from '@fluentui/react';
import { EditDialog } from '../../../common/components/Dialog/EditDialog/EditDialog';
import { performApiCall } from '../../../common/Hooks/useApiCall';
import { useAppDispatch } from '../../../Redux/hook';

import { DatetimePicker } from '../../../common/components/DatetimePicker/DatetimePicker';
import { ConfirmationDialog } from '../../../common/components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { GetResourceTypeIcon, GetResourceTypeText, GetResourceTypeTextOne } from '../../../common/components/Icons/ResourceTypeIcon';
import { Panel } from '../../../common/components/Panel/Panel';
import { ArrayOperation } from '../../../common/helpers/ObjectAndArray';
import { ResourceLookupField } from '../../../common/LookupFields/ResourceLookupField';
import { FilterChange } from '../../../Redux/Reducers/DataSet/ApiCall/apiCall';
import { ResourcesByProduct } from '../../../Redux/Reducers/DataSet/ApiCall/Resource';
import { resourceDatasetNameByType } from '../../../Redux/Reducers/DataSet/DataSetName';
import { clearContext } from '../../../Redux/Reducers/System/reducer';
import { DatasetGrid } from '../../Common/DatasetGrid';
import { LocalDatasetGrid } from '../../Common/LocalDatasetGrid';
import { ResourceTypeDropDown } from '../Resources/ResourceTypeDropDown';

export interface ProductDetailResourceListProps {
    createMode: boolean;
    product?: IProduct | undefined;
    resources: IResourceOfProduct[]; //use in creation mode
    onResourcesChange: (resources: IResourceOfProduct[]) => void; //use in creation mode
}
interface ResourceLinkProduct extends Partial<Omit<IRelatedResource, 'resourceId'>> {
    resource?: IResource;
}
export const ProductDetailResourceList: FC<ProductDetailResourceListProps> = (props: ProductDetailResourceListProps) => {
    const { createMode, product, onResourcesChange, resources } = props;

    const appDispatch = useAppDispatch();

    const [selectedResource, setSelectedResource] = useState<IResourceOfProduct | undefined>();
    const [selectedResourceType, setSelectedResourceType] = useState<ResourceTypeEnum | undefined>();
    const [resourceToAdd, setResourceToAdd] = useState<ResourceLinkProduct | undefined>();
    const [showAddResourceDialog, setShowAddResourceDialog] = useState<'create' | 'edit'>();
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const onSelect = (resource: IResourceOfProduct | undefined) => {
        setSelectedResource(resource);
    };

    const columns = [
        {
            key: 'icon',
            name: '',
            minWidth: 25,
            maxWidth: 25,
            allowSorting: true,
            isResizable: true,
            onRender: (item: IResourceOfProduct) => {
                return <Icon iconName={GetResourceTypeIcon(item.resourceType)} style={{ fontSize: 'large' }} />;
            },
        },
        {
            key: 'name',
            name: 'Nom',
            fieldName: 'name',
            minWidth: 250,
            maxWidth: 400,
            allowSorting: true,
            isResizable: true,
            onRender: (item: IResourceOfProduct) => {
                return (
                    <ActionButton
                        onClick={() => {
                            alert('todo');
                            //nav.navigate(screenPaths.productUpdate(item.id), item);
                        }}
                    >
                        {item.name}
                    </ActionButton>
                );
            },
        },
        {
            key: 'type',
            name: 'Type',
            minWidth: 75,
            maxWidth: 75,
            allowSorting: true,
            isResizable: true,
            onRender: (item: IResourceOfProduct) => {
                return <span>{GetResourceTypeText(item.resourceType)}</span>;
            },
        },
        {
            key: 'description',
            name: 'Description',
            fieldName: 'description',
            minWidth: 200,
            maxWidth: 200,
            allowSorting: true,
            isResizable: true,
        },
        {
            key: 'activationDate',
            name: "Date d'activation",
            fieldName: 'activationDate',
            minWidth: 200,
            maxWidth: 200,
            allowSorting: true,
            isResizable: true,
            onRender: (item: IResourceOfProduct) => {
                return <span>{item.activationDate?.toLocaleString()}</span>;
            },
        },
        {
            key: 'expirationDate',
            name: "Date d'expiration",
            fieldName: 'expirationDate',
            minWidth: 200,
            maxWidth: 200,
            allowSorting: true,
            isResizable: true,
            onRender: (item: IResourceOfProduct) => {
                return <span>{item.expirationDate?.toLocaleString()}</span>;
            },
        },
    ];
    const defaultResourceGridCriteria = useMemo<FilterChange | undefined>(() => (product?.id ? { mode: 'Full', change: { productId: product.id } } : undefined), [product?.id]);
    return (
        <>
            <Panel
                title='Resources du produit'
                icon='ProductVariant'
                commands={[
                    {
                        icon: 'AddTo',
                        text: 'Ajouter',
                        onClick: () => {
                            setShowAddResourceDialog('create');
                        },
                    },
                    {
                        icon: 'Edit',
                        text: 'Modifier',
                        disabled: !selectedResource,
                        onClick: () => {
                            setSelectedResourceType(selectedResource?.resourceType);
                            if (selectedResource) {
                                const res = new Resource();
                                res.init(selectedResource);
                                setResourceToAdd({
                                    resource: res,
                                    activationDate: selectedResource.activationDate,
                                    expirationDate: selectedResource.expirationDate,
                                });
                            }
                            setShowAddResourceDialog('edit');
                        },
                    },
                    {
                        icon: 'Delete',
                        text: 'Supprimer',
                        disabled: !selectedResource,
                        onClick: () => {
                            setShowDeleteDialog(true);
                        },
                    },
                ]}
            >
                {createMode ? (
                    <LocalDatasetGrid data={resources} columns={columns} getKey={(item) => item.id ?? ''} onSelect={onSelect} />
                ) : (
                    <DatasetGrid datasetName={'ResourcesByProduct'} defaultCriteria={defaultResourceGridCriteria} columns={columns} getKey={(item) => item.id ?? ''} onSelect={onSelect} />
                )}
            </Panel>
            <EditDialog
                id='SelectResourceDialog'
                icon={{ iconName: 'ProductVariant' }}
                show={showAddResourceDialog !== undefined}
                title={showAddResourceDialog === 'create' ? 'Ajouter une resource' : 'Modifier les dates'}
                subText={''}
                mode={showAddResourceDialog === 'create' ? 'Add' : 'Edit'}
                onSubmit={() => {
                    performApiCall(
                        async (client) => {
                            if (createMode && resourceToAdd) {
                                if (showAddResourceDialog === 'create') {
                                    const relatedResource = new ResourceOfProduct();
                                    relatedResource.init(resourceToAdd.resource);
                                    relatedResource.activationDate = resourceToAdd.activationDate;
                                    relatedResource.expirationDate = resourceToAdd.expirationDate;
                                    onResourcesChange([...resources, relatedResource]);
                                } else {
                                    onResourcesChange(
                                        ArrayOperation.update(resources, (obj) => obj.id === resourceToAdd.resource?.id, {
                                            activationDate: resourceToAdd.activationDate,
                                            expirationDate: resourceToAdd.expirationDate,
                                        }),
                                    );
                                }
                            } else if (product) {
                                if (resourceToAdd?.resource) {
                                    const relatedResource = new RelatedResource();
                                    relatedResource.init(resourceToAdd);
                                    relatedResource.resourceId = resourceToAdd.resource.id;
                                    if (showAddResourceDialog === 'create') {
                                        await client.addResourceToProduct(product.id, relatedResource);
                                    } else {
                                        await client.updateResourceToProduct(product.id, relatedResource);
                                    }
                                    ResourcesByProduct.refresh();
                                    setResourceToAdd(undefined);
                                }
                            }
                            setShowAddResourceDialog(undefined);
                        },
                        appDispatch,
                        { contextId: 'SelectResourceDialog' },
                    );
                }}
                onClose={() => {
                    setShowAddResourceDialog(undefined);
                    setResourceToAdd(undefined);
                    //  ProductLookup.clear();
                }}
            >
                <>
                    <ResourceTypeDropDown disabled={showAddResourceDialog === 'edit'} selectedResourceType={selectedResourceType} onSelectResourceType={(t) => setSelectedResourceType(t)} />
                    {selectedResourceType ? (
                        <>
                            <ResourceLookupField
                                readOnly={showAddResourceDialog === 'edit'}
                                label={`Sélectionner ${GetResourceTypeTextOne(selectedResourceType)}`}
                                type={resourceDatasetNameByType(selectedResourceType, 'Lookup')}
                                selectedResourceId={resourceToAdd?.resource?.id}
                                onSelectResource={(r) => setResourceToAdd({ ...resourceToAdd, resource: r })}
                            />
                            <DatetimePicker label="Date d'activation" value={resourceToAdd?.activationDate} onSelectDate={(v) => setResourceToAdd({ ...resourceToAdd, activationDate: v })} />
                            <DatetimePicker label="Date d'expiration" value={resourceToAdd?.expirationDate} onSelectDate={(v) => setResourceToAdd({ ...resourceToAdd, expirationDate: v })} />
                        </>
                    ) : (
                        <></>
                    )}
                </>
            </EditDialog>
            <ConfirmationDialog
                id='ConfirmationDialog'
                icon={{ iconName: 'ProductVariant' }}
                show={showDeleteDialog}
                title='Suppression de la ressource'
                subText='Etes-vous sur de vouloir supprimer la ressource de ce produit?'
                onReply={(reply) => {
                    if (reply) {
                        if (createMode && selectedResource) {
                            onResourcesChange(resources.filter((r) => r.id !== selectedResource.id));
                            setShowDeleteDialog(false);
                        } else if (product) {
                            performApiCall(
                                async (client) => {
                                    if (selectedResource?.id) {
                                        await client.deleteResourceFromProduct(product.id, selectedResource.id);
                                        setShowDeleteDialog(false);
                                        ResourcesByProduct.refresh();
                                    }
                                },
                                appDispatch,
                                { contextId: 'ConfirmationDialog' },
                            );
                        }
                    } else {
                        setShowDeleteDialog(false);
                        appDispatch(
                            clearContext({
                                contextId: 'ConfirmationDialog',
                            }),
                        );
                    }
                }}
            />
        </>
    );
};
