import { ActionButton } from '@fluentui/react';
import { FC, useEffect, useState } from 'react';
import { SocialNetworkLinksGrid } from '../../../Redux/Reducers/DataSet/ApiCall/SocialNetworkLinks';
import { clearContext } from '../../../Redux/Reducers/System/reducer';
import { useAppDispatch } from '../../../Redux/hook';
import { ISocialNetworkLink } from '../../../Services/SakuraApiClient';
import { performApiCall } from '../../../common/Hooks/useApiCall';
import { INavigationState, useNav } from '../../../common/Hooks/useNav';
import { FilterType } from '../../../common/components/CustomDetailList/FilteredHeaderColumn';
import { ConfirmationDialog } from '../../../common/components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { SimpleDialog } from '../../../common/components/Dialog/SimpleDialog/SimpleDialog';
import { Panel } from '../../../common/components/Panel/Panel';
import { TextFieldExtended } from '../../../common/components/TextFieldExtended/TextFieldExtended';
import { displayDateAndTime } from '../../../common/helpers/DateTimeHelper';
import { DatasetGrid } from '../../Common/DatasetGrid';
import { SocialNetworkLinkDetails } from './SocialNetworkLinkDetails';
import { SocialNetworkLinkTrackingList } from './SocialNetworkLinkTrackingList';

interface NavState {
    refresh?: boolean;
}

export const SocialNetworkLinksList: FC = () => {
    const [selectedSNL, setSelectedSNL] = useState<ISocialNetworkLink | undefined>();
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [showSeeLinkDialog, setShowSeeLinkDialog] = useState<boolean>(false);
    const [showEditDialog, setShowEditDialog] = useState<'New' | 'Edit' | undefined>();
    const [showTrackingId, setShowTrackingId] = useState<number | undefined>();
    const dispatch = useAppDispatch();
    const nav = useNav();

    const navState = nav.current.state as INavigationState<NavState>;
    useEffect(() => {
        if (navState?.state?.refresh) {
            SocialNetworkLinksGrid.refresh();
        }
    }, [navState?.state?.refresh]);

    const deleteSNL = (id: number) => {
        performApiCall(
            async (client, _cancelToken) => {
                await client.deleteSocialNetworkLink(id, _cancelToken);
                setShowDeleteDialog(false);
                SocialNetworkLinksGrid.refresh(undefined, _cancelToken);
            },
            dispatch,
            { contextId: 'ConfirmationDialog' },
        );
    };

    const columns = [
        {
            key: 'Nom',
            name: 'Nom',
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 150,
            allowSorting: true,
            isResizable: true,
            filterOption: {
                type: FilterType.Text,
                placeholder: 'Nom',
                fieldNames: [
                    {
                        fieldName: 'name',
                        displayName: 'nom',
                    },
                ],
            },
        },
        {
            key: 'Nom',
            name: 'Nom court (SLUG)',
            fieldName: 'shortner',
            minWidth: 100,
            maxWidth: 150,
            allowSorting: true,
            isResizable: true,
            filterOption: {
                type: FilterType.Text,
                placeholder: 'Nom court',
                fieldNames: [
                    {
                        fieldName: 'shortner',
                        displayName: 'nom court',
                    },
                ],
            },
        },
        {
            key: 'Link',
            name: 'Lien',
            fieldName: 'link',
            minWidth: 200,
            maxWidth: 250,
            allowSorting: true,
            isResizable: true,
            filterOption: {
                type: FilterType.Text,
                placeholder: 'Lien',
                fieldNames: [
                    {
                        fieldName: 'link',
                        displayName: 'lien',
                    },
                ],
            },
        },
        {
            key: 'Comment',
            name: 'Commentaire',
            fieldName: 'comment',
            minWidth: 200,
            maxWidth: 250,
            allowSorting: true,
            isResizable: true,
            filterOption: {
                type: FilterType.Text,
                placeholder: 'Commentaire',
                fieldNames: [
                    {
                        fieldName: 'comment',
                        displayName: 'Commentaire',
                    },
                ],
            },
        },
        {
            key: 'hitCount',
            name: 'Nombre de visite du lien',
            fieldName: 'hitCount',
            minWidth: 175,
            maxWidth: 175,
            allowSorting: true,
            isResizable: true,
            onRender: (item: ISocialNetworkLink) => {
                return (
                    <ActionButton
                        onClick={() => {
                            setShowTrackingId(item.id);
                        }}
                    >
                        {item.hitCount}
                    </ActionButton>
                );
            },
        },
        {
            key: 'LastHitCount',
            name: 'Derniere visite du lien le',
            fieldName: 'lastHitOn',
            minWidth: 200,
            maxWidth: 200,
            allowSorting: true,
            isResizable: true,
            onRender: (item: ISocialNetworkLink) => {
                return <span>{item.lastHitOn && item.hitCount ? displayDateAndTime(item.lastHitOn) : null}</span>;
            },
        },
        {
            key: 'CreatedOn',
            name: 'Créer le',
            fieldName: 'createdOn',
            minWidth: 100,
            maxWidth: 100,
            allowSorting: true,
            isResizable: true,
            onRender: (item: ISocialNetworkLink) => {
                return <span>{item.createdOn.toLocaleDateString()}</span>;
            },
        },
        {
            key: 'updatedOn',
            name: 'Modifié le',
            fieldName: 'updatedOn',
            minWidth: 100,
            maxWidth: 100,
            allowSorting: true,
            isResizable: true,
            onRender: (item: ISocialNetworkLink) => {
                return <span>{item.updatedOn?.toLocaleDateString()}</span>;
            },
        },
    ];
    return (
        <Panel
            title='Liens réseau sociaux'
            icon='JoinOnlineMeeting'
            commands={[
                {
                    icon: 'AddTo',
                    text: 'Ajouter',
                    onClick: () => {
                        setShowEditDialog('New');
                    },
                },

                {
                    icon: 'Edit',
                    text: 'Modifier',
                    disabled: !selectedSNL,
                    onClick: () => {
                        setShowEditDialog('Edit');
                    },
                },
                {
                    icon: 'Delete',
                    text: 'Supprimer',
                    disabled: !selectedSNL,
                    onClick: () => {
                        if (selectedSNL) {
                            setShowDeleteDialog(true);
                        }
                    },
                },
                {
                    icon: 'TestBeaker',
                    text: 'Tester le lien',
                    disabled: !selectedSNL,
                    onClick: () => {
                        window.open(`/web/${selectedSNL?.shortner}`);
                    },
                },
                {
                    icon: 'View',
                    text: 'Voir le lien',
                    disabled: !selectedSNL,
                    onClick: () => {
                        if (selectedSNL) {
                            setShowSeeLinkDialog(true);
                        }
                    },
                },
                {
                    icon: 'Refresh',
                    text: 'Actualiser',
                    onClick: () => {
                        SocialNetworkLinksGrid.refresh();
                    },
                },
            ]}
        >
            <DatasetGrid
                datasetName={'SocialNetworkLinksGrid'}
                columns={columns}
                getKey={(item) => item.id ?? ''}
                onRowDoubleClick={(item) => {
                    setSelectedSNL(item);
                    setShowEditDialog('Edit');
                }}
                onSelect={(selected: ISocialNetworkLink | undefined) => setSelectedSNL(selected)}
            />

            <ConfirmationDialog
                id='ConfirmationDialog'
                icon={{ iconName: 'JoinOnlineMeeting' }}
                show={showDeleteDialog}
                title='Suppression du lien'
                subText='Etes-vous sur de vouloir supprimer ce lien?'
                onReply={(reply) => {
                    if (reply) {
                        if (selectedSNL) {
                            deleteSNL(selectedSNL.id);
                        }
                    } else {
                        setShowDeleteDialog(false);
                        dispatch(
                            clearContext({
                                contextId: 'ConfirmationDialog',
                            }),
                        );
                    }
                }}
            />
            {showEditDialog ? (
                <SocialNetworkLinkDetails
                    onClose={(entity) => {
                        setShowEditDialog(undefined);
                        if (entity) {
                            SocialNetworkLinksGrid.refresh();
                        }
                    }}
                    entity={showEditDialog === 'Edit' ? selectedSNL : undefined}
                    show={true}
                />
            ) : null}
            {showSeeLinkDialog ? (
                <SimpleDialog onClose={() => setShowSeeLinkDialog(false)} id={'seelink'} title={'Utiliser ce lien depuis un réseau sociale'} show={true}>
                    <TextFieldExtended value={`${window.location.protocol}//${window.location.host}/web/${selectedSNL?.shortner}`} copyMessage='Le lien a été copier dans le presse-papiers' />
                </SimpleDialog>
            ) : null}
            {showTrackingId ? (
                <SimpleDialog onClose={() => setShowTrackingId(undefined)} id={'showTrackingId'} title={'Dates de consultation du lien'} show={true}>
                    <SocialNetworkLinkTrackingList socialNetworkId={showTrackingId} />
                </SimpleDialog>
            ) : null}
        </Panel>
    );
};
