import { Callout, DatePicker, Dropdown, IColumn, IconButton, IDropdownOption, TextField } from '@fluentui/react';
import clsx from 'clsx';
import React, { FC, useEffect, useReducer, useRef } from 'react';
import './FilteredHeaderColumn.scss';

export interface ColumnInfo extends IColumn {
    nameAsJsx?: JSX.Element;
    valueAccesor?: (root: any) => any;
    filterOption?: FilterOption;
    allowSorting?: boolean;
}
export type ValueComparator = (value: any, filterValue: FilterValue) => boolean;
export type FilterValue = Date | string | number | null | undefined;
export interface FieldDefinition {
    fieldName: string;
    displayName: string;
}
export interface FilterCriteria {
    fieldName: string;
    filterValue: FilterValue;
}
export type OnFilterCallback = (col: ColumnInfo, value?: FilterCriteria[]) => void;

export enum FilterType {
    Text,
    Date,
    Enum,
}
export interface FilterOption {
    type: FilterType;
    placeholder: string;
    options?: IDropdownOption[];
    comparator?: ValueComparator;
    fieldNames?: FieldDefinition[];
    onReset?: () => void;
    onTextChange?: (value: string[]) => void;
    onDateChange?: (value?: Date[]) => void;
    onDropdownChange?: (value?: IDropdownOption[]) => void;
}

export interface FilteredHeaderColumnProps {
    column: ColumnInfo;
    criteria: FilterCriteria[] | undefined;
    filter: FilterOption;
    onColumnClick: (ev?: React.MouseEvent<HTMLElement>, col?: ColumnInfo) => void;
    onfilterApply: OnFilterCallback;
}

export interface FilteredHeaderColumnState {
    fieldLength: number;
    calloutOpen: boolean;
    isFiltered: boolean;
    dateValues: Date[];
    textValues: string[];
    itemValues: IDropdownOption[];
    commitedDateValues: Date[];
    commitedTextValues: string[];
    commitedItemValues: IDropdownOption[];
}

function init(fieldLength: number): FilteredHeaderColumnState {
    return {
        fieldLength,
        calloutOpen: false,
        isFiltered: false,
        textValues: Array<string>(fieldLength),
        dateValues: Array<Date>(fieldLength),
        itemValues: Array<IDropdownOption>(fieldLength),
        commitedTextValues: Array<string>(fieldLength),
        commitedDateValues: Array<Date>(fieldLength),
        commitedItemValues: Array<IDropdownOption>(fieldLength),
    };
}

function updateStateTextValues(state: FilteredHeaderColumnState, fieldValue: string, fieldIndex: number): FilteredHeaderColumnState {
    return {
        ...state,
        textValues: [...state.textValues].map((v, i) => {
            if (fieldIndex === i) {
                return fieldValue;
            }
            return v;
        }),
    };
}

function updateStateDateValues(state: FilteredHeaderColumnState, fieldValue: Date, fieldIndex: number): FilteredHeaderColumnState {
    return {
        ...state,
        dateValues: [...state.dateValues].map((v, i) => {
            if (fieldIndex === i) {
                return fieldValue;
            }
            return v;
        }),
    };
}

function updateStateItemValues(state: FilteredHeaderColumnState, fieldValue: IDropdownOption, fieldIndex: number): FilteredHeaderColumnState {
    const res = {
        ...state,
        itemValues: [...state.itemValues].map((v, i) => {
            if (fieldIndex === i) {
                return fieldValue;
            }
            return v;
        }),
    };

    return res;
}

type TextFieldListAction =
    | { type: 'textValueChanged'; fieldValue: string; fieldIndex: number }
    | { type: 'dateValueChanged'; fieldValue: Date; fieldIndex: number }
    | {
          type: 'itemValueChanged';
          fieldValue: IDropdownOption;
          fieldIndex: number;
      }
    | { type: 'resetFilters' }
    | {
          type: 'setFilters';
          criteria: FilterCriteria[] | undefined;
          filterType: FilterType;
          filterOptions: IDropdownOption<any>[];
      }
    | { type: 'acceptFilters'; isFiltered: boolean }
    | { type: 'openCallout' }
    | { type: 'closeCallout' };

function reducer(state: FilteredHeaderColumnState, action: TextFieldListAction) {
    switch (action.type) {
        case 'textValueChanged':
            return updateStateTextValues(state, action.fieldValue, action.fieldIndex);
        case 'dateValueChanged':
            return updateStateDateValues(state, action.fieldValue, action.fieldIndex);
        case 'itemValueChanged':
            return updateStateItemValues(state, action.fieldValue, action.fieldIndex);
        case 'resetFilters':
            return init(state.fieldLength);
        case 'setFilters': {
            const newState = { ...state };
            if (action.filterType === FilterType.Text) {
                newState.textValues = action.criteria?.map((c) => (c.filterValue as string) ?? '') ?? [];
                newState.commitedTextValues = action.criteria?.map((c) => (c.filterValue as string) ?? '') ?? [];
            }
            if (action.filterType === FilterType.Date) {
                newState.dateValues = action.criteria?.map((c) => (c.filterValue as Date) ?? '') ?? [];
                newState.commitedDateValues = action.criteria?.map((c) => (c.filterValue as Date) ?? '') ?? [];
            }
            if (action.filterType === FilterType.Enum) {
                newState.itemValues =
                    action.criteria?.map((c) => {
                        return action.filterOptions?.find((fo) => fo.key === (c.filterValue ?? 'All')) ?? action.filterOptions[0];
                    }) ?? [];
                newState.commitedItemValues =
                    action.criteria?.map((c) => {
                        return action.filterOptions?.find((fo) => fo.key === (c.filterValue ?? 'All')) ?? action.filterOptions[0];
                    }) ?? [];
            }
            newState.isFiltered = (action.criteria ?? []).filter((c) => c.filterValue !== undefined).length > 0;
            return newState;
        }
        case 'acceptFilters':
            return {
                ...state,
                calloutOpen: false,
                commitedDateValues: state.dateValues,
                commitedTextValues: state.textValues,
                commitedItemValues: state.itemValues,
                isFiltered: action.isFiltered,
            };
        case 'closeCallout':
            return { ...state, calloutOpen: false };
        case 'openCallout':
            return {
                ...state,
                calloutOpen: true,
                dateValues: state.commitedDateValues,
                itemValues: state.commitedItemValues,
                textValues: state.commitedTextValues,
            };
        default:
            return { ...state };
    }
}

export const FilteredHeaderColumn: FC<FilteredHeaderColumnProps> = (props: FilteredHeaderColumnProps) => {
    const { filter, column, criteria } = props;
    const { fieldNames } = filter;
    const fields = fieldNames ?? [
        {
            fieldName: column.fieldName ?? column.key,
            displayName: column.name,
        },
    ];
    const menuCaretRef = useRef<HTMLSpanElement>(null);
    const [state, dispatch] = useReducer(reducer, fields.length, init);

    const resetFilter = (notify: boolean) => {
        dispatch({ type: 'resetFilters' });

        if (filter.onTextChange) {
            filter.onTextChange([]);
        }
        if (filter.onDropdownChange) {
            filter.onDropdownChange([]);
        }
        if (filter.onDateChange) {
            filter.onDateChange([]);
        }
        if (filter.onReset) {
            filter.onReset();
        }
        if (notify) {
            props.onfilterApply(props.column, undefined);
        }
    };
    useEffect(() => {
        dispatch({
            type: 'setFilters',
            criteria,
            filterType: filter.type,
            filterOptions: filter.options ?? [],
        });
    }, [criteria, filter.type, filter.options]);

    const acceptFilter = () => {
        switch (filter.type) {
            case FilterType.Text:
                if (state.textValues.some((v) => !!v)) {
                    if (filter.onTextChange) {
                        filter.onTextChange(state.textValues);
                    }
                    props.onfilterApply(
                        props.column,
                        fields.map((fieldDef, i) => ({
                            fieldName: fieldDef.fieldName,
                            filterValue: state.textValues[i],
                        })),
                    );
                } else {
                    props.onfilterApply(props.column, undefined);
                }
                break;
            case FilterType.Date:
                if (state.dateValues.some((v) => !!v)) {
                    if (filter.onDateChange) {
                        filter.onDateChange(state.dateValues);
                    }
                    props.onfilterApply(
                        props.column,
                        fields.map((fieldDef, i) => ({
                            fieldName: fieldDef.fieldName,
                            filterValue: state.dateValues[i],
                        })),
                    );
                } else {
                    props.onfilterApply(props.column, undefined);
                }
                break;
            case FilterType.Enum:
                if (state.itemValues.some((v) => !!v)) {
                    if (filter.onDropdownChange) {
                        filter.onDropdownChange(state.itemValues);
                    }
                    props.onfilterApply(
                        props.column,
                        fields.map((fieldDef, i) => ({
                            fieldName: fieldDef.fieldName,
                            filterValue: state.itemValues[i] && state.itemValues[i].key !== 'all' ? state.itemValues[i].key : undefined,
                        })),
                    );
                } else {
                    props.onfilterApply(props.column, undefined);
                }
                break;
            default:
                break;
        }
        dispatch({
            type: 'acceptFilters',
            isFiltered: state.textValues.some((v) => !!v) || state.itemValues.some((v) => !!v && v.key !== 'all') || state.dateValues.some((v) => !!v),
        });
    };

    const getFilterInput = () => {
        if (!filter) {
            return null;
        }

        switch (filter.type) {
            case FilterType.Text:
                return (
                    <>
                        {fields.map((fn, i) => (
                            <TextField
                                key={fn.fieldName}
                                label={fn.displayName}
                                placeholder={filter.placeholder}
                                inputMode='search'
                                value={state.textValues[i]}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        acceptFilter();
                                    }
                                }}
                                onChange={(_, value) => {
                                    if (value !== undefined) {
                                        dispatch({
                                            type: 'textValueChanged',
                                            fieldValue: value,
                                            fieldIndex: i,
                                        });
                                    }
                                }}
                            />
                        ))}
                    </>
                );
            case FilterType.Date:
                return (
                    <>
                        {fields.map((fn, i) => (
                            <DatePicker
                                key={fn.fieldName}
                                label={fn.displayName}
                                placeholder={filter.placeholder}
                                isRequired={false}
                                value={state.dateValues[i]}
                                className='search-box-first-name'
                                onSelectDate={(date) => {
                                    if (date !== undefined && date !== null) {
                                        dispatch({
                                            type: 'dateValueChanged',
                                            fieldValue: date,
                                            fieldIndex: i,
                                        });
                                    }
                                }}
                            />
                        ))}
                    </>
                );
            case FilterType.Enum:
                return (
                    <>
                        {fields.map((fn, i) => (
                            <Dropdown
                                key={fn.fieldName}
                                label={fn.displayName}
                                placeholder={filter.placeholder}
                                defaultSelectedKey={state.commitedItemValues[i]?.key || 'all'}
                                onChanged={(item) => {
                                    dispatch({
                                        type: 'itemValueChanged',
                                        fieldValue: item,
                                        fieldIndex: i,
                                    });
                                }}
                                options={filter.options ?? []}
                            />
                        ))}
                    </>
                );
            default:
                return <></>;
        }
    };
    const clickSort = (e: React.MouseEvent<HTMLElement>) => {
        props.onColumnClick(e, props.column);
        if (column.onColumnClick) {
            column.onColumnClick(e, column);
        }
    };
    return (
        <span aria-hidden className={clsx('table-custom-header-cell', state.isFiltered ? 'filter' : '')} onClick={clickSort}>
            <span ref={menuCaretRef}>
                <IconButton
                    className={state.isFiltered ? 'active' : ''}
                    onClick={(e) => {
                        dispatch({ type: 'openCallout' });
                        e.stopPropagation();
                    }}
                    iconProps={{ iconName: state.isFiltered ? 'FilterSolid' : 'Filter' }}
                />
            </span>
            <span className='table-custom-header-cell-title'>{column.nameAsJsx ?? column.name}</span>
            {column.isSorted && (
                <span>
                    <IconButton
                        iconProps={{
                            iconName: column.isSortedDescending ? 'SortDown' : 'SortUp',
                        }}
                    />
                </span>
            )}

            {filter && state.calloutOpen ? (
                <Callout className='table-filter-callout' target={menuCaretRef.current} beakWidth={12} onDismiss={() => dispatch({ type: 'closeCallout' })}>
                    {getFilterInput()}
                    <div className='table-filter-callout-buttons'>
                        <IconButton onClick={() => resetFilter(true)} iconProps={{ iconName: 'ClearFilter' }} />
                        <IconButton onClick={() => acceptFilter()} iconProps={{ iconName: 'CheckMark' }} />
                    </div>
                </Callout>
            ) : null}
        </span>
    );
};
