import { Toggle } from '@fluentui/react';
import { FC } from 'react';
import { ICategoryPoint, IWorkflow, QuestionnaryKind } from '../../../../Services/SakuraApiClient';
import { DeepPartial, FormError } from '../../../../common/Hooks/Form/useForm';
import { NumberField } from '../../../../common/components/NumberField/NumberField';
import { CategoriesPoints } from './CategoriesPoints';
import { WorkflowConfigurator } from './WorkflowConfigurator';

export interface AnswerSettingData {
    isCorrect: boolean;
    points: number | undefined;
    categories: ICategoryPoint[] | undefined;
    workflow: IWorkflow;
}

export interface AnswerSettingContentProps {
    data: DeepPartial<AnswerSettingData> | undefined;
    error: FormError<AnswerSettingData> | undefined;
    onUpdate: (data: DeepPartial<AnswerSettingData>) => void;
    questionnaryKind: QuestionnaryKind;
    hideIsCorrect?: boolean;
}

export const AnswerSettingContent: FC<AnswerSettingContentProps> = (props: AnswerSettingContentProps) => {
    const { data, error, onUpdate, questionnaryKind, hideIsCorrect } = props;

    return (
        <div className='DivFlexHorizontalCenter'>
            {questionnaryKind === QuestionnaryKind.Exam && !hideIsCorrect ? (
                <div className='correctIncorrect'>
                    <Toggle
                        label='Réponse'
                        onText='Correct'
                        offText='Incorrect'
                        checked={data?.isCorrect}
                        onChange={(_, isCorrect) => {
                            onUpdate({ isCorrect });
                        }}
                    />
                </div>
            ) : (
                <></>
            )}
            {questionnaryKind === QuestionnaryKind.Survey ? (
                <CategoriesPoints label='Categories' categories={data?.categories} errors={error?.categories} onChange={(categories) => onUpdate({ categories })} />
            ) : (
                <></>
            )}
            {questionnaryKind === QuestionnaryKind.Exam ? (
                <NumberField label='Points' inputType='DecimalNumber' suffix='Pts' errorMessage={error?.points} value={data?.points} onChange={(points) => onUpdate({ points })} />
            ) : (
                <></>
            )}
            <WorkflowConfigurator workflow={data?.workflow as IWorkflow} onChangeWorkflow={(workflow) => onUpdate({ workflow })} />
        </div>
    );
};
