import { FC, PropsWithChildren, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { Icon } from '@fluentui/react';
import clsx from 'clsx';
import { useState2 } from '../../../../common/Hooks/useState2';
import { generateUniqueId } from '../../../../common/helpers/uniqueId';
import './AnswerDraggable.scss';

export interface PivotItemDraggableProps {
    index: number;
    onDrop: (currentIndex: number, targetIndex: number) => void;
    disabled?: boolean;
}
interface ItemData {
    index: number;
}
export type DropAt = 'Before' | 'After';

export const AnswerDraggable: FC<PropsWithChildren<PivotItemDraggableProps>> = (props: PropsWithChildren<PivotItemDraggableProps>) => {
    const { index, children, onDrop, disabled } = props;
    const pivotItemId = useRef(generateUniqueId('AnswerDraggable_'));
    const htmlDivRef = useRef<HTMLDivElement>();
    // const htmlDivRef = useRef<HTMLDivElement>();
    const [dragOver, setDragOver, dragOverRef] = useState2<DropAt | undefined>();
    const [{ isDragging }, drag, dragPreview] = useDrag(
        () => ({
            type: 'ANSWER_ITEM',
            item: { index },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end(draggedItem, monitor) {
                if (monitor.didDrop()) {
                }
            },
        }),
        [index],
    );
    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: ['ANSWER_ITEM'],
            drop: (item: unknown, monitor) => {
                if (monitor.didDrop()) {
                    return monitor.getDropResult();
                }
                if (!htmlDivRef.current) {
                    htmlDivRef.current = document.getElementById(pivotItemId.current) as HTMLDivElement;
                }
                const itemIndex = (item as ItemData).index;
                let targetIndex = itemIndex;
                if (itemIndex > index) {
                    targetIndex = dragOverRef.current === 'Before' ? index : index + 1;
                } else {
                    targetIndex = dragOverRef.current === 'Before' ? index - 1 : index;
                }
                onDrop(itemIndex, targetIndex);
                return monitor.getDropResult();
            },
            hover: (item, monitor) => {
                if ((item as ItemData).index === index) {
                    return;
                }
                if (!htmlDivRef.current) {
                    htmlDivRef.current = document.getElementById(pivotItemId.current) as HTMLDivElement;
                }
                const offset = monitor.getSourceClientOffset();
                if (offset) {
                    setDragOver(offset.y - htmlDivRef.current.offsetTop < htmlDivRef.current.clientHeight / 2 ? 'Before' : 'After');
                }
            },
            canDrop: (item, monitor) => {
                if (monitor.getItemType() === 'ANSWER_ITEM') {
                    if ((item as ItemData).index !== index) {
                        return true;
                    }
                }
                return false;
            },
            collect: (monitor) => ({
                isOver: monitor.isOver({ shallow: true }),
                canDrop: monitor.canDrop(),
            }),
        }),
        [index],
    );
    return (
        <div ref={disabled ? undefined : dragPreview} style={{ opacity: isDragging ? 0.5 : 1 }} className='answerRoot'>
            <div ref={disabled ? undefined : drag} className='AnswerDraggableZone'>
                <Icon iconName='DragObject' />
            </div>
            <div id={pivotItemId.current} ref={disabled ? undefined : drop} className={clsx('AnswerDraggable', isOver && dragOver ? `dragHover${dragOver}` : '')}>
                {children}
            </div>
        </div>
    );
};
