import { $patchStyleText } from '@lexical/selection';
import { $getSelection, $isRangeSelection, LexicalEditor } from 'lexical';
import { FC, useCallback } from 'react';
import { IFontStyleEntry } from '../../../../../Services/SakuraApiClient';
import { parseFontProperty } from '../../../../styles/FontStyles';
import { FontStylePicker } from '../../../FontStyles/FontStylePicker';
import { forceFormat } from '../../Helpers/selection';

export const predefinedStylePropertyName = '--PStyleName';
export interface PredefinedStyleSelectorProps {
    editor: LexicalEditor;
    predefinedStyle?: string;
}

export const PredefinedStyleSelector: FC<PredefinedStyleSelectorProps> = (props: PredefinedStyleSelectorProps) => {
    const { editor, predefinedStyle } = props;

    const handleSelect = useCallback(
        (fontStyleName: string, data: IFontStyleEntry) => {
            const styles: Record<string, string | null> = {
                [predefinedStylePropertyName]: data.entryName ?? null,
            };
            let isBold = false;
            let isItalic = false;
            if (data.color) {
                styles['color'] = data.color;
            }
            if (data.font) {
                const fontProperties = parseFontProperty(data.font);
                styles['font-size'] = fontProperties.size ?? null;
                styles['font-variant'] = fontProperties.variant ?? null;
                styles['font-family'] = fontProperties.family ?? null;
                styles['line-height'] = fontProperties.height ?? null;
                styles['font-stretch'] = fontProperties.stretch ?? null;

                if (fontProperties.weight) {
                    isBold = true;
                }
                if (fontProperties.style) {
                    isItalic = true;
                }

                /*
                styles['font-weight'] = fontProperties.weight ?? null;
                styles['font-style'] = fontProperties.style ?? null;*/
            }
            const textDecorations = [];

            if (data.underline) {
                textDecorations.push('underline');
            }
            if (data.strike) {
                textDecorations.push('line-through');
            }
            if (textDecorations.length > 0) {
                styles['text-decoration'] = textDecorations.join(',');
            }

            editor.update(() => {
                const selection = $getSelection();
                if (selection !== null) {
                    if ($isRangeSelection(selection)) {
                        forceFormat(selection, 'bold', isBold);
                        forceFormat(selection, 'italic', isItalic);
                    }
                    $patchStyleText(selection, styles);
                }
            }, {});
        },
        [editor],
    );
    return <FontStylePicker smallDropdown selectedFontStyleName={predefinedStyle ?? ''} onSelect={handleSelect} />;
};
