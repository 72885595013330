import { MessageBarType } from '@fluentui/react';
import { FC } from 'react';
import { setMessage } from '../../../../Redux/Reducers/System/reducer';
import { useAppDispatch } from '../../../../Redux/hook';
import { IResource, QuestionnaryKind } from '../../../../Services/SakuraApiClient';
import { parseBoolean } from '../../../../common/helpers/boolean';
import { ResourceGenerateLink, contextId } from '../ResourceGenerateLink';

export interface QuestionnaryGenerateLinkProps {
    resource: IResource;
    show: boolean;
    onClose: () => void;
}
export const QuestionnaryGenerateLink: FC<QuestionnaryGenerateLinkProps> = (props: QuestionnaryGenerateLinkProps) => {
    const { resource, show, onClose } = props;
    const appDispatch = useAppDispatch();
    return (
        <ResourceGenerateLink
            resource={resource}
            show={show}
            onClose={onClose}
            onValidate={(extraParameters: string[]) => {
                if (resource.data.content.questionnary.kind !== QuestionnaryKind.Interview) {
                    if (extraParameters.length === 0 || parseBoolean(extraParameters[0]) !== true) {
                        if (resource.data.content.questionnary.contactListId === undefined) {
                            appDispatch(
                                setMessage({
                                    contextId,
                                    message: {
                                        Severity: MessageBarType.error,
                                        text: 'Si les résultats ne sont pas affichés, alors vous devez choisir une liste de diffusion pour que le contact recoive un e-mail.',
                                    },
                                }),
                            );
                            return false;
                        }
                        if (resource.data.content.questionnary.contactAttributeResultLink === undefined) {
                            appDispatch(
                                setMessage({
                                    contextId,
                                    message: {
                                        Severity: MessageBarType.error,
                                        text: 'Si les résultats ne sont pas affichés, alors vous devez choisir un attribut du contact pour stocker le liens de visualisation des résultats.',
                                    },
                                }),
                            );
                            return false;
                        }
                    }
                }
                // if (resource.data.content.questionnary.kind === QuestionnaryKind.Interview) {
                //     if (resource.data.content.questionnary.contactListId === undefined) {
                //         appDispatch(
                //             setMessage({
                //                 contextId,
                //                 message: {
                //                     Severity: MessageBarType.error,
                //                     text: 'Pour une interview vous devez choisir une liste de diffusion pour que le contact recoive un e-mail.',
                //                 },
                //             }),
                //         );
                //         return false;
                //     }
                //     if (resource.data.content.questionnary.contactAttributeResultLink === undefined) {
                //         appDispatch(
                //             setMessage({
                //                 contextId,
                //                 message: {
                //                     Severity: MessageBarType.error,
                //                     text: 'Pour une interview  vous devez choisir un attribut du contact pour stocker le liens de visualisation des résultats.',
                //                 },
                //             }),
                //         );
                //         return false;
                //     }
                // }
                appDispatch(
                    setMessage({
                        contextId,
                        message: undefined,
                    }),
                );
                return true;
            }}
            extraParameters={
                resource.data.content.questionnary.kind === QuestionnaryKind.Interview
                    ? undefined
                    : [
                          {
                              label: 'Afficher les résultats du questionnaire',
                              name: 'sr',
                              type: 'Boolean',
                          },
                      ]
            }
        />
    );
};
