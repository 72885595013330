import { FC } from 'react';

import './InvalidLinkPage.scss';

export const InvalidLinkPage: FC = () => {
    return (
        <div className='invalidPage'>
            <img src='/Logo_application.svg' alt='Sakura coaching logo' className='applicationLogo entrance' tabIndex={-1} width={370} />
            <span style={{ color: 'gray', margin: 10, fontSize: 30 }}>&#x1F914;</span>
            <span style={{ color: 'gray', margin: 10 }}>Ce lien n&apos;est pas ou n&apos;est plus valide.</span>
        </div>
    );
};
