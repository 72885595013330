import { ResourceTypeEnum } from '../../../Services/SakuraApiClient';
import { AccountGrid } from './ApiCall/Account';
import { BadgeGrid, BadgeLookup } from './ApiCall/Badge';
import { ContactAttributesLookup, ContactListLookup, EmailTemplateLookup } from './ApiCall/Brevo';
import { AccountActivityLogGrid, ActivityLogExternalServiceGrid, CoinTransactionLogGrid, DiamondTransactionLogGrid } from './ApiCall/Log';
import { OfferGrid, OfferLookup } from './ApiCall/Offer';
import { ProductGrid, ProductLookup, ProductTileGrid, ProductTileWhishlistGrid, ProductsByOffer } from './ApiCall/Product';
import { QuestionnaryResponsesGrid } from './ApiCall/QuestionnaryResponses';
import {
    ResourceAudioGrid,
    ResourceAudioLookup,
    ResourceDocumentGrid,
    ResourceDocumentLookup,
    ResourceFormationGrid,
    ResourceFormationLookup,
    ResourceGameContentGrid,
    ResourceGameContentLookup,
    ResourceImageContentLookup,
    ResourceImageGrid,
    ResourceImageLookup,
    ResourceImagePubliciteLookup,
    ResourceImageVignetteLookup,
    ResourceModuleLookup,
    ResourcePageAutreLookup,
    ResourcePageBlockGrid,
    ResourcePageFormationLookup,
    ResourcePageGrid,
    ResourcePageTemplateGrid,
    ResourcePageVenteLookup,
    ResourcePublicLinkAccessGrid,
    ResourceQuestionnaryGrid,
    ResourceQuestionnaryLookup,
    ResourceTileFavoriteGrid,
    ResourceTileGrid,
    ResourceVideoGrid,
    ResourceVideoLookup,
    ResourceWorkflowGrid,
    ResourceWorkflowLookup,
    ResourcesByProduct,
} from './ApiCall/Resource';
import { SocialNetworkLinksGrid, SocialNetworkLinksTrackingGrid } from './ApiCall/SocialNetworkLinks';
import { ApiCall } from './ApiCall/apiCall';
import { DataSetInfo } from './state';

type SNLink = 'SocialNetworkLinksGrid' | 'SocialNetworkLinksTrackingGrid';
type Account = 'AccountGrid';
type Badge = 'BadgeLookup' | 'BadgeGrid';
type Product = 'ProductGrid' | 'ProductsByOffer' | 'ProductLookup' | 'ProductTileGrid' | 'ProductTileWhishlistGrid';
type Offer = 'OfferLookup' | 'OfferGrid';
type Brevo = 'EmailTemplateLookup' | 'ContactListLookup' | 'ContactAttributesLookup';
type Log = 'ActivityLogExternalServiceGrid' | 'CoinTransactionLogGrid' | 'DiamondTransactionLogGrid' | 'AccountActivityLogGrid';
type Questionnary = 'QuestionnaryResponsesGrid';
type Resource =
    | 'ResourceDocumentGrid'
    | 'ResourceImageGrid'
    | 'ResourceVideoGrid'
    | 'ResourceAudioGrid'
    | 'ResourceFormationGrid'
    | 'ResourcePageGrid'
    | 'ResourceQuestionnaryGrid'
    | 'ResourceGameContentGrid'
    | 'ResourcesByProduct'
    | 'ResourceWorkflowGrid'
    | 'ResourcePageBlockGrid'
    | 'ResourcePageTemplateGrid'
    | 'ResourcePublicLinkAccessGrid'
    | 'ResourceTileGrid'
    | 'ResourceTileFavoriteGrid';

export type ResourceLookUp =
    | 'ResourceDocumentLookup'
    | 'ResourceImageLookup'
    | 'ResourceImagePubliciteLookup'
    | 'ResourceImageVignetteLookup'
    | 'ResourceImageContentLookup'
    | 'ResourceVideoLookup'
    | 'ResourceAudioLookup'
    | 'ResourceFormationLookup'
    | 'ResourcePageFormationLookup'
    | 'ResourcePageVenteLookup'
    | 'ResourcePageAutreLookup'
    | 'ResourceModuleLookup'
    | 'ResourceQuestionnaryLookup'
    | 'ResourceGameContentLookup'
    | 'ResourceWorkflowLookup';

export const resourceDatasetNameByType = (type: ResourceTypeEnum, kind: 'Grid' | 'Lookup'): ResourceLookUp => `Resource${type}${kind}` as ResourceLookUp;

export type DataSetName = Account | Resource | ResourceLookUp | Offer | Product | Badge | Brevo | Log | SNLink | Questionnary;

export const datasetCollections: Record<DataSetName, ApiCall> = {
    AccountGrid,
    BadgeLookup,
    BadgeGrid,
    OfferLookup,
    OfferGrid,
    ProductLookup,
    ProductGrid,
    ProductsByOffer,
    ProductTileGrid,
    ProductTileWhishlistGrid,
    ResourceDocumentGrid,
    ResourceImageGrid,
    ResourceVideoGrid,
    ResourceAudioGrid,
    ResourceFormationGrid,
    ResourcePageGrid,
    ResourceQuestionnaryGrid,
    ResourceGameContentGrid,
    ResourcePublicLinkAccessGrid,
    ResourceAudioLookup,
    ResourceDocumentLookup,
    ResourceImageLookup,
    ResourceImagePubliciteLookup,
    ResourceImageVignetteLookup,
    ResourceImageContentLookup,
    ResourceVideoLookup,
    ResourceFormationLookup,
    ResourcePageFormationLookup,
    ResourcePageVenteLookup,
    ResourcePageAutreLookup,
    ResourceModuleLookup,
    ResourceQuestionnaryLookup,
    ResourceGameContentLookup,
    ResourcesByProduct,
    ResourceWorkflowGrid,
    ResourceWorkflowLookup,
    ResourceTileGrid,
    ResourceTileFavoriteGrid,
    EmailTemplateLookup,
    ContactAttributesLookup,
    ContactListLookup,
    ResourcePageBlockGrid,
    ResourcePageTemplateGrid,
    ActivityLogExternalServiceGrid,
    CoinTransactionLogGrid,
    DiamondTransactionLogGrid,
    AccountActivityLogGrid,
    SocialNetworkLinksGrid,
    SocialNetworkLinksTrackingGrid,
    QuestionnaryResponsesGrid,
};

export const customDefaultState: Record<string, DataSetInfo> = {
    EmailTemplateLookup: {
        pageIndex: 0,
        pageSize: 200,
        totalCount: 0,
        totalPages: 0,
        data: [],
        filters: {},
    },
};
