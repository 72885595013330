import { FC } from 'react';
import { IPageElementCustomComponent } from '../../../../../../Services/SakuraApiClient';
import { ExpressionField, ExpressionKind } from '../../../../../../common/components/ExpressionField/ExpressionField';
import { DeepPartial } from '../../../../../../common/helpers/ObjectAndArray';
import { SocialNetworkNameShareable, supportedPropsForShare } from '../../../../SocialNetworkLinks/type';
import { NetworkPicker } from './NetworkPicker';

export interface ShareButtonPanelProps {
    data: IPageElementCustomComponent;
    onChangeData: (data: DeepPartial<IPageElementCustomComponent>) => void;
}

export const ShareButtonPanel: FC<ShareButtonPanelProps> = (props: ShareButtonPanelProps) => {
    const { data, onChangeData } = props;
    const supported = supportedPropsForShare(data.props?.target as SocialNetworkNameShareable | undefined);
    return (
        <>
            <NetworkPicker
                value={data.props?.target}
                onChangeData={(target) => {
                    onChangeData({ props: { target } });
                }}
            />
            <ExpressionField
                kind={ExpressionKind.TextWithExpression}
                showExpandButton
                label='Lien'
                value={data.props?.url}
                onChange={(url) => {
                    onChangeData({ props: { url } });
                }}
            ></ExpressionField>
            {supported.hashTag ? (
                <ExpressionField
                    kind={ExpressionKind.TextWithExpression}
                    showExpandButton
                    label='HashTag'
                    value={data.props?.hashTag}
                    onChange={(hashTag) => {
                        onChangeData({ props: { hashTag } });
                    }}
                ></ExpressionField>
            ) : null}
            {supported.content ? (
                <ExpressionField
                    kind={ExpressionKind.TextWithExpression}
                    showExpandButton
                    label='Objet'
                    value={data.props?.subject}
                    onChange={(subject) => {
                        onChangeData({ props: { subject } });
                    }}
                ></ExpressionField>
            ) : null}
            {supported.content ? (
                <ExpressionField
                    kind={ExpressionKind.TextWithExpression}
                    showExpandButton
                    label="Corps de l'email"
                    value={data.props?.content}
                    onChange={(content) => {
                        onChangeData({ props: { content } });
                    }}
                ></ExpressionField>
            ) : null}
        </>
    );
};
