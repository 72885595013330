import { Reducer } from 'react';
import { PageElement } from '../../../../Services/SakuraApiClient';
import { IEditorDataContextState, ViewType } from './IEditorDataContextState';

export type EditorDataContextAction =
    | { type: 'select'; payload: { uiComponentId: string; element: PageElement; updater: (data: PageElement) => PageElement; deleter: () => void } }
    | { type: 'planSelection'; payload: { element: PageElement } }
    | { type: 'changeView'; payload: { type: ViewType } }
    | { type: 'unSelect' };

export type EditorDataContextReducerType = Reducer<IEditorDataContextState, EditorDataContextAction>;
export const EditorDataContextReducer = (state: IEditorDataContextState, action: EditorDataContextAction): IEditorDataContextState => {
    switch (action.type) {
        case 'select':
            return {
                ...state,
                uiComponentId: action.payload.uiComponentId,
                selectedElement: action.payload.element,
                updateSelectedElement: action.payload.updater,
                deleteSelectedElement: action.payload.deleter,
                planSelection: undefined,
            };
        case 'planSelection':
            return { ...state, planSelection: action.payload.element };
        case 'changeView':
            return { ...state, viewType: action.payload.type };
        case 'unSelect':
            return { ...state, uiComponentId: undefined, selectedElement: undefined, updateSelectedElement: undefined, deleteSelectedElement: undefined, planSelection: undefined };
    }
    return state;
};
