import { FC, PropsWithChildren, useEffect } from 'react';
import { AuthenticationService } from './AuthService';

export interface ILogoutCallbackProps {
    authService: AuthenticationService;
}
export const LogoutCallback: FC<PropsWithChildren<ILogoutCallbackProps>> = (props: PropsWithChildren<ILogoutCallbackProps>) => {
    const { authService, children } = props;
    useEffect(() => {
        authService.logout();
    }, [authService]);

    return children ? <>{children}</> : <></>;
};
