const timeout: Record<string, ReturnType<typeof setTimeout>> = {};

export const debounce = <T extends (...args: any[]) => any>(name: string, callback: T, waitFor: number) => {
    return (...args: Parameters<T>): ReturnType<T> => {
        let result: any;
        timeout[name] && clearTimeout(timeout[name]);
        timeout[name] = setTimeout(() => {
            result = callback(...args);
        }, waitFor);
        return result;
    };
};
