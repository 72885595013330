import { ActionButton, PrimaryButton } from '@fluentui/react';
import { FC, useState } from 'react';
import { EnumVideoProvider } from '../../../Services/SakuraApiClient';
import { SimpleDialog } from '../Dialog/SimpleDialog/SimpleDialog';
import { VideoViewer } from './VideoViewer';

export interface ButtonVideoViewerProps {
    videoName: string;
    videoDescription?: string;
    provider: EnumVideoProvider;
    videoId: string;
    display: 'Action' | 'Button';
    disabled?: boolean;
}
const iconViewProps = { iconName: 'play' };
export const ButtonVideoViewer: FC<ButtonVideoViewerProps> = (props: ButtonVideoViewerProps) => {
    const { display, provider, videoId, videoDescription, videoName, disabled } = props;
    const [showDialog, setShowDialog] = useState<boolean>(false);

    const onShow = () => {
        setShowDialog(true);
    };
    return (
        <>
            {display === 'Button' && <PrimaryButton disabled={disabled} iconProps={iconViewProps} text={'lire la video'} onClick={onShow} />}
            {display === 'Action' && <ActionButton disabled={disabled} iconProps={iconViewProps} text={'lire la video'} onClick={onShow} />}
            {showDialog && (
                <SimpleDialog id='Dialog' show={showDialog} onClose={() => setShowDialog(false)} title={videoName} subText={videoDescription}>
                    <VideoViewer videoId={videoId} provider={provider} width={800} height={600} />
                </SimpleDialog>
            )}
        </>
    );
};
