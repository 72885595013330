import { FC } from 'react';

import { ContentSourceType } from './ContentSourceType';
import { ThumbnailCarousel } from './ThumbnailCarousel';
import { useThumbnailSource } from './useThumbnailSource';

export interface ThumbnailSectionProps {
    contentSource: ContentSourceType;
    sectionName?: string;
    disableActions?: boolean;
}

export const ThumbnailSection: FC<ThumbnailSectionProps> = (props: ThumbnailSectionProps) => {
    const { contentSource, sectionName, disableActions } = props;

    const items = useThumbnailSource(contentSource, sectionName);

    return <ThumbnailCarousel items={items} disableActions={disableActions} />;
};
