import { Label } from '@fluentui/react';
import { FC, useCallback, useState } from 'react';
import { storageEndpointsSelector } from '../../../Redux/Reducers/System/reducer';
import { useAppSelector } from '../../../Redux/hook';
import { IAcquiredBadge } from '../../../Services/SakuraApiClient';
import { ST } from '../../../common/Hooks/StorageResolver';
import { useApiCall } from '../../../common/Hooks/useApiCall';
import { BadgeViewer } from '../../../common/components/Badge/BadgeViewer';
import { displayDate } from '../../../common/helpers/DateTimeHelper';

import './badgeCollection.scss';

export const BadgeCollection: FC = () => {
    const [badges, setBadges] = useState<IAcquiredBadge[]>([]);

    const storage = useAppSelector(storageEndpointsSelector);
    useApiCall(
        useCallback(async (client, cancelToken) => {
            const result = await client.getMyBadges(cancelToken);
            setBadges(result);
        }, []),
    );
    return (
        <div className='badgeCollection'>
            {badges.map((badge, index) => (
                <div className='item' key={`${badge.name}${index}`}>
                    <BadgeViewer width={125} height={125} url={ST(storage, badge.imageBlobPath)} type={badge.type} graphics={badge.graphics} />
                    <Label>{badge.description}</Label>
                    <Label className='date'>{displayDate(badge.acquiredDate)}</Label>
                </div>
            ))}
        </div>
    );
};
