import { Dropdown, IDropdownOption, Label, Pivot, PivotItem } from '@fluentui/react';
import { FC, useCallback, useContext, useState } from 'react';
import { IPageElement, IPageElementContainer, IResourceDataPage, PageElement, PageElementType } from '../../../Services/SakuraApiClient';

import { PageElementEdition } from './PageElementEdition';

import { setContextualMobileDisplay } from '../../../Redux/Reducers/System/reducer';
import { useAppDispatch } from '../../../Redux/hook';
import { DeepPartial } from '../../../common/Hooks/Form/useForm';
import { NumberField } from '../../../common/components/NumberField/NumberField';
import { DeepPartialWithArrayFunc } from '../../../common/helpers/ObjectAndArray';
import { createNewElement } from '../Page/Elements/Container/createNewElement';
import '../Page/Page.scss';
import { EditorDataContext } from './EditorContext/EditorDataContextProvider';
import { ViewType } from './EditorContext/IEditorDataContextState';
import { deviceResolutions } from './mobileResolution';

export interface PageViewProps {
    pageData: IResourceDataPage | undefined;
    onChangePageData?: (data: DeepPartial<IResourceDataPage>) => void;
}

export const PageEdition: FC<PageViewProps> = (props: PageViewProps) => {
    const { pageData, onChangePageData } = props;
    const [mobileResolution, setMobileResolution] = useState<IDropdownOption | undefined>(deviceResolutions[0]);
    const editorContext = useContext(EditorDataContext);
    const dispatch = useAppDispatch();

    const onChangeData = useCallback(
        (page: PageElement) => {
            if (onChangePageData) {
                onChangePageData({ page });
            }
        },
        [onChangePageData],
    );
    const onChangeDataMobile = useCallback(
        (mobilePage: PageElement) => {
            if (onChangePageData) {
                onChangePageData({ mobilePage });
            }
        },
        [onChangePageData],
    );

    const onCopyPasteEvent = useCallback(
        (itemCopied: IPageElement, to: IPageElement, index: number) => {
            if (to.type === PageElementType.Container) {
                const pageElement = createNewElement(itemCopied.type, undefined, itemCopied);
                if (pageElement) {
                    const page: DeepPartialWithArrayFunc<IPageElementContainer> = {
                        items: (array) => {
                            return [...array, pageElement];
                        },
                    };
                    if (onChangePageData) {
                        onChangePageData({
                            page,
                        });
                    }
                }

                // const newData = new PageElementContainer();
                // const existingData = pageData.page;
                // newData.init({ ...existingData });

                // //Copy inside the container
                // if (pageElement && newData.items) {
                //     newData.items = ArrayOperation.add(newData.items ?? [], pageElement);
                // }
                // if (onChangePageData) {
                //     onChangePageData({ ...pageData, page: newData });
                // }
            }
        },
        [onChangePageData],
    );
    const onCopyPasteEventMobile = useCallback(
        (itemCopied: IPageElement, to: IPageElement, index: number) => {
            if (to.type === PageElementType.Container) {
                const pageElement = createNewElement(itemCopied.type, undefined, itemCopied);
                if (pageElement) {
                    const mobilePage: DeepPartialWithArrayFunc<IPageElementContainer> = {
                        items: (array) => {
                            return [...array, pageElement];
                        },
                    };
                    if (onChangePageData) {
                        onChangePageData({
                            mobilePage,
                        });
                    }
                }
            }
        },
        [onChangePageData],
    );
    return (
        <Pivot
            linkSize='large'
            className='content'
            selectedKey={editorContext.viewType}
            onLinkClick={(item) => {
                if (item?.props) {
                    const viewType = item.props.itemKey as ViewType;
                    editorContext.changeViewType(viewType);
                    dispatch(setContextualMobileDisplay({ mobile: viewType === 'Mobile' }));
                }
            }}
        >
            <PivotItem headerText='Navigateur' itemKey='Desktop' itemIcon='ThisPC' className='contentView'>
                <div className='viewport'>
                    {pageData?.page ? (
                        <>
                            <PageElementEdition index={0} data={pageData.page} noDrag onChangeData={onChangeData} onCopyPaste={onCopyPasteEvent} />
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </PivotItem>
            <PivotItem headerText='Mobile' itemIcon='CellPhone' itemKey='Mobile' className='contentViewMobile'>
                <div className='contentViewMobileHeader'>
                    <Dropdown style={{ width: 250 }} options={deviceResolutions} selectedKey={mobileResolution?.key} onChange={(_, opt) => setMobileResolution(opt)}></Dropdown>
                    <NumberField value={mobileResolution?.data.width} readOnly></NumberField>
                    <Label>X</Label>
                    <NumberField value={mobileResolution?.data.height} readOnly></NumberField>
                </div>
                <div className='contentViewMobileDevice'>
                    <div style={{ width: mobileResolution?.data.width, height: mobileResolution?.data.height }}>
                        {pageData?.mobilePage ? (
                            <>
                                <PageElementEdition noDrag index={0} data={pageData.mobilePage as PageElement} onChangeData={onChangeDataMobile} onCopyPaste={onCopyPasteEventMobile} />
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </PivotItem>
        </Pivot>
    );
};
