import { PrimaryButton } from '@fluentui/react';
import { FC } from 'react';
import { IAnswer, QuestionnaryKind } from '../../../../Services/SakuraApiClient';
import { DeepPartial, FormError } from '../../../../common/Hooks/Form/useForm';
import { ResourceLookupField } from '../../../../common/LookupFields/ResourceLookupField';

import './QuestionEdit.scss';

import { ExpressionField, ExpressionKind } from '../../../../common/components/ExpressionField/ExpressionField';
import { VisibilityComponent } from '../../../Common/PageEditor/ElementPanel/VisibilityComponent';
import { AnswerSettingContent } from './AnswerSettingContent';

export interface AnswerEditProps {
    answer: DeepPartial<IAnswer>;
    error: FormError<IAnswer> | undefined;
    onUpdate: (answer: DeepPartial<IAnswer>) => void;
    questionnaryKind: QuestionnaryKind;
    onDelete: () => void;
    disabledDelete: boolean;
}

export const AnswerEdit: FC<AnswerEditProps> = (props: AnswerEditProps) => {
    const { answer, error, onUpdate, questionnaryKind, onDelete, disabledDelete } = props;

    return (
        <div className='answerContainer'>
            <PrimaryButton
                disabled={disabledDelete}
                className='button'
                title='Effacer cette réponse'
                iconProps={{ iconName: 'Delete' }}
                onClick={() => {
                    onDelete();
                }}
            />

            <div className='answer'>
                <div>
                    <div className='answerVisibility'>
                        <VisibilityComponent visibleIf={answer.visibleIf} onChange={(visibleIf) => onUpdate({ visibleIf })} />
                    </div>
                    <ExpressionField
                        kind={ExpressionKind.TextWithExpression}
                        onDragStart={(event) => {
                            event.preventDefault();
                        }}
                        className='answerText'
                        onClick={(e) => {
                            e.preventDefault();
                        }}
                        onDrag={(e) => {
                            e.preventDefault();
                        }}
                        required={answer.imageResourceId === undefined}
                        label={`${answer.id} - Réponse`}
                        value={answer.text}
                        errorMessage={error?.text}
                        onChange={(text) => onUpdate({ text })}
                    />
                </div>
                <ResourceLookupField
                    label='Image'
                    type='ResourceImageContentLookup'
                    selectedResourceId={answer.imageResourceId}
                    onSelectResource={(resource) => {
                        if (resource) {
                            onUpdate({ imageResourceId: resource.id });
                        } else {
                            onUpdate({ imageResourceId: undefined });
                        }
                    }}
                />

                <AnswerSettingContent
                    data={answer}
                    error={error}
                    questionnaryKind={questionnaryKind}
                    onUpdate={(data) => {
                        onUpdate(data);
                    }}
                />
            </div>
        </div>
    );
};
