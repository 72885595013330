import { PrimaryButton } from '@fluentui/react';
import { FC, useMemo } from 'react';
import { SocialNetworkLinksTrackingGrid } from '../../../Redux/Reducers/DataSet/ApiCall/SocialNetworkLinks';
import { FilterValue } from '../../../Redux/Reducers/DataSet/state';
import { ISocialNetworkLinkTracking } from '../../../Services/SakuraApiClient';
import { ColumnInfo } from '../../../common/components/CustomDetailList/FilteredHeaderColumn';
import { displayDateAndTime } from '../../../common/helpers/DateTimeHelper';
import { DatasetGrid } from '../../Common/DatasetGrid';

export interface SocialNetworkLinkTrackingListProps {
    socialNetworkId: number;
}
export const SocialNetworkLinkTrackingList: FC<SocialNetworkLinkTrackingListProps> = (props: SocialNetworkLinkTrackingListProps) => {
    const { socialNetworkId } = props;

    const columns = useMemo<ColumnInfo[]>(() => {
        return [
            {
                key: 'visitedOn',
                name: 'Visité le',
                fieldName: 'visitedOn',
                minWidth: 150,
                maxWidth: 150,
                allowSorting: true,
                isResizable: true,
                onRender: (item: ISocialNetworkLinkTracking) => {
                    return <span>{displayDateAndTime(item.visitedOn)}</span>;
                },
            },
        ];
    }, []);

    const defaultParameter = useMemo<Record<string, FilterValue>>(() => ({ linkId: socialNetworkId }), [socialNetworkId]);

    return (
        <div>
            <PrimaryButton iconProps={{ iconName: 'Refresh' }} text='Actualiser' onClick={() => SocialNetworkLinksTrackingGrid.refresh()} />
            <DatasetGrid datasetName={'SocialNetworkLinksTrackingGrid'} parameters={defaultParameter} columns={columns} getKey={(item: ISocialNetworkLinkTracking) => item.linkId ?? ''} />
        </div>
    );
};
