import { FC } from 'react';
import { PageElementType } from '../../../../Services/SakuraApiClient';
import { Accordion } from '../../../../common/components/Accordion';
import { ElementToolBox, ElementToolBoxProps } from './ElementToolBox';
import { PageBlockLibrary } from './PageBlockLibrary';

interface ListOfElements {
    category: string;
    items: ElementToolBoxProps[];
}

const listOfElements: ListOfElements[] = [
    {
        category: 'Layout',
        items: [{ elementType: PageElementType.Container }, { elementType: PageElementType.Separator }],
    },
    {
        category: 'Texte',
        items: [{ elementType: PageElementType.Label }, { elementType: PageElementType.RichText }],
    },
    {
        category: 'Media',
        items: [
            { elementType: PageElementType.Image },
            { elementType: PageElementType.Audio },
            { elementType: PageElementType.Video },
            { elementType: PageElementType.Questionnary },
            { elementType: PageElementType.PDF },
        ],
    },

    {
        category: "Saisie d'information",
        items: [{ elementType: PageElementType.TextField }, { elementType: PageElementType.NumberField }, { elementType: PageElementType.DatetimeField }, { elementType: PageElementType.CheckField }],
    },
    {
        category: 'Déclencheur',
        items: [{ elementType: PageElementType.Button }, { elementType: PageElementType.Link }],
    },
    {
        category: 'Autres',
        items: [
            { elementType: PageElementType.CustomComponent, elementSubType: 'ThumbnailsSection' },
            { elementType: PageElementType.CustomComponent, elementSubType: 'SocialNetwork' },
            { elementType: PageElementType.CustomComponent, elementSubType: 'ShareButton' },
        ],
    },
];
export const ToolBoxContent: FC = () => {
    return (
        <div>
            {listOfElements.map((item) => (
                <Accordion key={item.category} title={item.category} lightMode defaultState>
                    <div className='toolBoxContent'>
                        {item.items.map((t) => {
                            return <ElementToolBox key={`${t.elementType}${t.elementSubType}`} elementType={t.elementType} elementSubType={t.elementSubType} />;
                        })}
                    </div>
                </Accordion>
            ))}
            <Accordion key={'Bibliotheque'} title={'Bibliotheque'} lightMode defaultState>
                <PageBlockLibrary />
            </Accordion>
        </div>
    );
};
