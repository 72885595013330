import { DefaultButton, DialogFooter, PrimaryButton } from '@fluentui/react';
import { FC, useMemo, useState } from 'react';
import { useLoadingSelector } from '../../../../Redux/Reducers/System/reducer';
import { useAppDispatch } from '../../../../Redux/hook';
import { EntityState, IResource, IResourceHierarchy, Resource, ResourceContent, ResourceData, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';
import { DeepPartialWithArrayFunc } from '../../../../common/Hooks/Form/useForm';
import { performApiCall } from '../../../../common/Hooks/useApiCall';
import { CustomDialog } from '../../../../common/components/Dialog/CustomDialog';
import { generateUniqueId } from '../../../../common/helpers/uniqueId';
import { NewPageDialog } from '../Page/NewPageDialog';
import { buildNewPage } from '../Page/utils';
import { NewOrLinkResource } from './NewOrLinkResource';

export interface DialogNewFormationItemProps {
    id: string;
    type: 'Module' | 'Page' | undefined;
    parentResource: IResourceHierarchy;
    onClose: (item: DeepPartialWithArrayFunc<IResourceHierarchy> | undefined) => void;
}

function getNewModule() {
    const moduleResource = new Resource();
    moduleResource.init({
        resourceType: ResourceTypeEnum.Module,
        name: 'Nouveau module',
        enabled: true,
    });
    moduleResource.data = new ResourceData();
    moduleResource.data.content = new ResourceContent();
    moduleResource.data.content.page = buildNewPage();
    return moduleResource;
}
export const DialogNewFormationItem: FC<DialogNewFormationItemProps> = (props: DialogNewFormationItemProps) => {
    const { type, onClose, id, parentResource } = props;
    const loadingIndicator = useLoadingSelector(id);
    const [selectedResource, setSelectedResource] = useState<IResource | undefined>();
    const [showNewPageDialog, setShowNewPageDialog] = useState<boolean>(false);

    const [action, setAction] = useState<'new' | 'link'>('new');
    const title = useMemo(() => {
        if (type === 'Module') {
            return 'Ajouter un module';
        }
        if (type === 'Page') {
            return 'Ajouter une page';
        }
        return '';
    }, [type]);
    const appDispatch = useAppDispatch();

    return (
        <>
            <CustomDialog
                id={id}
                title={title}
                icon={type === 'Module' ? { iconName: 'Dictionnary' } : { iconName: 'Page' }}
                hidden={!type}
                onDismiss={() => {
                    onClose(undefined);
                }}
            >
                {type === 'Module' ? (
                    <NewOrLinkResource
                        action={action}
                        newPhrase='Nouveau module'
                        linkPhrase='Module existant'
                        type='ResourceModuleLookup'
                        onSelect={(actionVal, resourceVal) => {
                            setAction(actionVal);
                            setSelectedResource(resourceVal);
                        }}
                        selectedResource={selectedResource}
                    />
                ) : null}
                {type === 'Page' ? (
                    <NewOrLinkResource
                        action={action}
                        newPhrase='Nouvelle page'
                        linkPhrase='Page existante'
                        type='ResourcePageFormationLookup'
                        onSelect={(actionVal, resourceVal) => {
                            setAction(actionVal);
                            setSelectedResource(resourceVal);
                        }}
                        selectedResource={selectedResource}
                    />
                ) : null}
                <DialogFooter>
                    <PrimaryButton
                        disabled={loadingIndicator?.isLoading}
                        onClick={() => {
                            if (action === 'new') {
                                if (type === 'Module') {
                                    onClose({
                                        resourceState: EntityState.Create,
                                        linkState: EntityState.Create,
                                        orderIndex: parentResource.children ? parentResource.children.length : 0,
                                        parentId: parentResource.resource.id,
                                        resourcePath: `${parentResource.resourcePath}/${generateUniqueId('R')}`,
                                        resource: getNewModule() as unknown as DeepPartialWithArrayFunc<IResource>,
                                    });
                                } else {
                                    setShowNewPageDialog(true);
                                }
                            } else if (action === 'link' && selectedResource?.id) {
                                performApiCall(async (client, ct) => {
                                    const res = await client.getResourcePackageFull(selectedResource?.id, ct);

                                    onClose({
                                        resourceState: EntityState.Unchange,
                                        linkState: EntityState.Create,
                                        orderIndex: parentResource.children ? parentResource.children.length : 0,
                                        parentId: parentResource.resource.id,
                                        resource: res.resource as unknown as DeepPartialWithArrayFunc<IResource>,
                                        innerResourcePackages: res.resourcePackage.innerResourcePackages,
                                        resourcePath: `${parentResource.resourcePath}/${res.resource.id}`,
                                    });
                                }, appDispatch);
                            }
                        }}
                        text={'Ajouter'}
                    />
                    <DefaultButton disabled={loadingIndicator?.isLoading} onClick={() => onClose(undefined)} text='Annuler' />
                </DialogFooter>
            </CustomDialog>
            {showNewPageDialog ? (
                <NewPageDialog
                    createRecord={false}
                    presetCategory='Formation'
                    onClose={(resPackage) => {
                        setShowNewPageDialog(false);
                        if (resPackage) {
                            onClose({
                                resourceState: EntityState.Create,
                                linkState: EntityState.Create,
                                orderIndex: parentResource.children ? parentResource.children.length : 0,
                                parentId: parentResource.resource.id,
                                resourcePath: `${parentResource.resourcePath}/${generateUniqueId('R')}`,
                                innerResourcePackages: resPackage.resourcePackage.innerResourcePackages,
                                resource: resPackage.resource as unknown as DeepPartialWithArrayFunc<IResource>,
                            });
                        }
                    }}
                />
            ) : null}
        </>
    );
};
