import { IStyle } from '@fluentui/react';
import { FC, useMemo, useState } from 'react';
import { NumberField } from '../../../../../common/components/NumberField/NumberField';
import { IPageElementNumberField, NumberFieldType } from '../../../../../Services/SakuraApiClient';
import { textStyleBuilder, toIStyleContainer, toIStyleLabel } from '../Label/textStyleBuilder';

export interface ElementNumberFieldProps {
    data: IPageElementNumberField;
}

export const ElementNumberField: FC<ElementNumberFieldProps> = (props: ElementNumberFieldProps) => {
    const { data } = props;
    const [number, setNumber] = useState<number>();
    const style = useMemo(() => {
        return textStyleBuilder(data.style);
    }, [data.style]);

    const labelStyleFU = useMemo(() => {
        const root = toIStyleLabel(style);
        return root;
    }, [style]);
    const containerStyleFU = useMemo<IStyle>(() => {
        return toIStyleContainer(style);
    }, [style]);
    return (
        <>
            <NumberField
                styles={{
                    field: labelStyleFU,
                    subComponentStyles: {
                        label: {
                            root: labelStyleFU,
                        },
                    },
                    root: containerStyleFU,
                }}
                label={data.label}
                required={data.mandatory}
                inputType={data.fieldType === NumberFieldType.Integer ? 'Number' : 'DecimalNumber'}
                value={number}
                onChange={(val) => {
                    setNumber(val);
                }}
            />
        </>
    );
};
