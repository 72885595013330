import { Label } from '@fluentui/react';
import React, { FC } from 'react';

import './cssStyles.scss';
import { IDimensions } from '../../../Services/SakuraApiClient';
import { NumberField } from '../NumberField/NumberField';

export interface DimensionsInputProps {
    label: string;
    fixed?: boolean;
    dimension: IDimensions | undefined;
    onChange: (dimension: Partial<IDimensions> | undefined) => void;
}

export const DimensionsInput: FC<DimensionsInputProps> = (props: DimensionsInputProps) => {
    const { label, dimension, onChange, fixed } = props;
    return (
        <>
            <Label>{label}</Label>
            <div className='dimensionBox'>
                {fixed ? (
                    <>
                        <span></span>
                        <span></span>
                        <NumberField
                            title={'Largeur'}
                            value={dimension?.width}
                            inputType='Number'
                            onChange={(v) => {
                                onChange({ ...dimension, width: v });
                            }}
                        />
                        <span>X</span>
                        <NumberField
                            title={'Hauteur'}
                            value={dimension?.height}
                            inputType='Number'
                            onChange={(v) => {
                                onChange({ ...dimension, height: v });
                            }}
                        />
                    </>
                ) : (
                    <>
                        <span></span>
                        <span>Minimal</span>
                        <NumberField
                            title={'Largeur minimal'}
                            value={dimension?.minWidth}
                            inputType='Number'
                            onChange={(v) => {
                                onChange({ ...dimension, minWidth: v });
                            }}
                        />
                        <span>X</span>
                        <NumberField
                            title={'Hauteur minimal'}
                            value={dimension?.minHeight}
                            inputType='Number'
                            onChange={(v) => {
                                onChange({ ...dimension, minHeight: v });
                            }}
                        />
                        <span></span>
                        <span>Maximal</span>
                        <NumberField
                            title={'Largeur maximal'}
                            value={dimension?.maxWidth}
                            inputType='Number'
                            onChange={(v) => {
                                onChange({ ...dimension, maxWidth: v });
                            }}
                        />
                        <span>X</span>
                        <NumberField
                            title={'Hauteur maximal'}
                            value={dimension?.maxHeight}
                            inputType='Number'
                            onChange={(v) => {
                                onChange({ ...dimension, maxHeight: v });
                            }}
                        />
                    </>
                )}
            </div>
        </>
    );
};
