import { FC, ReactNode, useEffect, useState } from 'react';
import { useAppDispatch } from '../Redux/hook';
import { IResourceConsumerPackage } from '../Services/SakuraApiClient';
import { performApiCall } from '../common/Hooks/useApiCall';
import { ResourceView } from './Common/Resource/ResourceView';

export interface ResourcePageProps {
    resourceId: number;
    resourceConsumerPackage?: IResourceConsumerPackage;
    fallback: ReactNode;
}
export const ResourcePage: FC<ResourcePageProps> = (props: ResourcePageProps) => {
    const { resourceId, resourceConsumerPackage, fallback } = props;
    const [isLoaded, setIsLoaded] = useState<boolean>(resourceConsumerPackage !== undefined);
    const [resource, setResource] = useState<IResourceConsumerPackage | undefined>(resourceConsumerPackage);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (resourceConsumerPackage === undefined) {
            performApiCall(async (client, c) => {
                try {
                    const resPackage = await client.getResourceConsumerPackage(resourceId, c);
                    setResource(resPackage);
                } catch (ex: unknown) {
                    if ((ex as any)?.message !== 'canceled') {
                        const error = ex as Error & { status: number; detail: string };
                        if (error.status === 404) {
                            setResource(undefined);
                        } else {
                            throw ex;
                        }
                    } else {
                        throw ex;
                    }
                }
                setIsLoaded(true);
            }, dispatch);
        }
    }, [resourceId, resourceConsumerPackage, dispatch]);
    return isLoaded ? !resource ? fallback : <ResourceView resourceConsumerPackage={resource} /> : null;
};
