import { FC, useCallback, useState } from 'react';
import { QuestionnaryResult } from '../Pages/Common/Questionnary/SubmitQuestionnary/QuestionnaryResult';
import { getDynVariableQuestionnary } from '../Pages/Common/Questionnary/getDynVariableChange';
import { patchScope } from '../Redux/Reducers/DynamiqueData/reducer';
import { setStorageEndpoints, useMessageSelector } from '../Redux/Reducers/System/reducer';
import { useAppDispatch } from '../Redux/hook';
import { IQuestionnary, IQuestionnaryResult } from '../Services/SakuraApiClient';
import { ErrorPage } from '../common/ErrorPage';
import { useApiCall } from '../common/Hooks/useApiCall';
import { InvalidLinkPage } from '../common/InvalidLinkPage';
import { LayoutPublic } from '../common/LayoutPublic/LayoutPublic';
import { LoadingScreen } from '../common/LoadingScreen';
import { ResourcePackageContextProvider } from '../common/components/ResourcePackage/ResourcePackageContextProvider';
import { MarketingOffer } from './ViewMarketingOffer';

interface ViewQuestionnaryResultState {
    result: IQuestionnaryResult;
    questionnary: IQuestionnary;
    offer: MarketingOffer;
}
export const ViewQuestionnaryResult: FC = () => {
    const [state, setState] = useState<ViewQuestionnaryResultState | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const error = useMessageSelector();
    const dispatch = useAppDispatch();

    useApiCall(
        useCallback(
            async (client, cancelToken) => {
                const values = await client.getProtectedValueOfQueryString(window.location.href, cancelToken);
                const questionnaryResultId = values['id'];
                const result = await client.getQuestionnaryResultForAnonymouseUser(questionnaryResultId.protected, cancelToken);

                dispatch(setStorageEndpoints({ storage: result.storageInformation }));

                dispatch(patchScope({ scope: 'quiz', content: getDynVariableQuestionnary(result.questionnary.questions, result.result.details ?? []) }));

                setState({
                    result: result.result,
                    questionnary: result.questionnary,
                    offer: {
                        showCurrentOffers: values['o'],
                        offerId1: values['o1'],
                        offerId2: values['o2'],
                    },
                });
                setIsLoading(false);
            },
            [dispatch],
        ),
        { anonymous: true },
    );
    return (
        <>
            {error && <ErrorPage />}
            {!error && (
                <>
                    {isLoading ? (
                        <LoadingScreen text='Bienvenue &#x1F603;' />
                    ) : (
                        <LayoutPublic>
                            {state?.result ? (
                                <>
                                    {/* <ViewMarketingOffer enabled={true} offer={state.offer} /> */}
                                    <div className='questionnaryRoot'>
                                        <div className='questionnaryUI'>
                                            <ResourcePackageContextProvider resourcePackages={state.questionnary.resourcePackage}>
                                                <QuestionnaryResult questionnary={state.questionnary} result={state.result} />
                                            </ResourcePackageContextProvider>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <InvalidLinkPage />
                            )}
                        </LayoutPublic>
                    )}
                </>
            )}
        </>
    );
};
