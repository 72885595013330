import { FC, useState } from 'react';
import { ResourceVideoGrid } from '../../../../Redux/Reducers/DataSet/ApiCall/Resource';
import { clearContext } from '../../../../Redux/Reducers/System/reducer';
import { useAppDispatch } from '../../../../Redux/hook';
import { IResource, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';
import { performApiCall } from '../../../../common/Hooks/useApiCall';
import { ConfirmationDialog } from '../../../../common/components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { GetResourceTypeIcon } from '../../../../common/components/Icons/ResourceTypeIcon';
import { Panel } from '../../../../common/components/Panel/Panel';
import { ButtonVideoViewer } from '../../../../common/components/VideoViewer/ButtonVideoViewer';
import { ResourceList } from '../ResourceList';
import { ResourceUXDataChange } from '../ResourceUXDataChange';
import { ResourceVideoDetails } from './ResourceVideoDetails';

export const ResourceVideoList: FC = () => {
    const [selectedResource, setSelectedResource] = useState<IResource>();
    const [showEditDialog, setShowEditDialog] = useState<'New' | 'Edit' | undefined>();
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [showEditUxData, setShowEditUxData] = useState<boolean>(false);

    const appDispatch = useAppDispatch();

    return (
        <Panel
            title='Liste des videos'
            icon={GetResourceTypeIcon(ResourceTypeEnum.Video)}
            commands={[
                {
                    icon: 'AddTo',
                    text: 'Ajouter',
                    onClick: () => {
                        setShowEditDialog('New');
                    },
                },
                {
                    icon: 'Edit',
                    text: 'Modifier',
                    disabled: !selectedResource,
                    onClick: () => {
                        if (selectedResource?.id) {
                            setShowEditDialog('Edit');
                        }
                    },
                },
                {
                    icon: 'PreviewLink',
                    text: 'Modifier la tuile',
                    disabled: !selectedResource,
                    onClick: () => {
                        if (selectedResource?.id) {
                            setShowEditUxData(true);
                        }
                    },
                },
                {
                    icon: 'Delete',
                    text: 'Supprimer',
                    disabled: !selectedResource,
                    onClick: () => {
                        setShowDeleteDialog(true);
                    },
                },
                {
                    icon: 'Refresh',
                    text: 'Actualiser',
                    onClick: () => {
                        ResourceVideoGrid.refresh();
                    },
                },
            ]}
        >
            <ResourceList
                datasetName={'ResourceVideoGrid'}
                onSelect={(selection) => setSelectedResource(selection)}
                hasEnabledColumn
                extraColumns={[
                    {
                        key: 'Video',
                        name: 'Image',
                        fieldName: 'blobPath',
                        minWidth: 500,
                        maxWidth: 500,
                        allowSorting: false,
                        isResizable: true,
                        onRender: (item: IResource) => {
                            if (item.data?.content?.video?.videoId) {
                                return (
                                    <ButtonVideoViewer
                                        videoId={item.data.content.video.videoId}
                                        provider={item.data.content.video.provider}
                                        videoName={item.name ?? ''}
                                        videoDescription={item.description}
                                        display='Action'
                                    />
                                );
                            }
                            return <span>{'pas de video'}</span>;
                        },
                    },
                ]}
                onRowDoubleClick={(item) => {
                    setSelectedResource(item);
                    setShowEditDialog('Edit');
                }}
            />
            <ConfirmationDialog
                id='ConfirmationDialog'
                icon={{ iconName: GetResourceTypeIcon(ResourceTypeEnum.Video) }}
                show={showDeleteDialog}
                title='Suppression de la video'
                subText='Etes-vous sur de vouloir supprimer la video?'
                onReply={(reply) => {
                    if (reply) {
                        performApiCall(
                            async (client) => {
                                if (selectedResource?.id) {
                                    await client.deleteResource(selectedResource.id);
                                    setShowDeleteDialog(false);
                                    ResourceVideoGrid.refresh();
                                }
                            },
                            appDispatch,
                            { contextId: 'ConfirmationDialog' },
                        );
                    } else {
                        setShowDeleteDialog(false);
                        appDispatch(
                            clearContext({
                                contextId: 'ConfirmationDialog',
                            }),
                        );
                    }
                }}
            />
            {showEditDialog ? (
                <ResourceVideoDetails
                    resource={showEditDialog === 'Edit' ? selectedResource : undefined}
                    show={showEditDialog !== undefined}
                    onClose={(res) => {
                        setShowEditDialog(undefined);
                        if (res) {
                            ResourceVideoGrid.refresh();
                        }
                    }}
                />
            ) : null}
            {showEditUxData && selectedResource ? (
                <ResourceUXDataChange
                    resource={selectedResource}
                    onClose={(res) => {
                        setShowEditUxData(false);
                        if (res) {
                            ResourceVideoGrid.refresh();
                        }
                    }}
                />
            ) : null}
        </Panel>
    );
};
