import { useEffect, useRef } from 'react';

export function useTimeout(callback: () => void, delay: number): void {
    const savedCallback = useRef<() => void>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        if (delay) {
            const tick = () => {
                savedCallback.current?.();
            };
            const id = setTimeout(tick, delay);

            return () => clearTimeout(id);
        } else {
            return () => {};
        }
    }, [delay]);
}

export function useInterval(callback?: () => void, delay?: number): void {
    const savedCallback = useRef<() => void>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current?.();
        }

        if (delay) {
            const id = setInterval(tick, delay);

            return () => clearInterval(id);
        } else {
            return () => {};
        }
    }, [delay]);
}
