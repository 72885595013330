import { CSSProperties } from 'react';
import { EnumItemsAlignement } from '../../../../Services/SakuraApiClient';

export interface IBaseStyle {
    opacity: number | undefined;
    border: string | undefined;
    borderRadius: string | undefined;
    padding: string | undefined;
    margin: string | undefined;
    boxShadow: string | undefined;
    alignSelf: EnumItemsAlignement;
    flexGrow: number | undefined;
}
export function baseStyleBuilder(style: IBaseStyle): CSSProperties {
    return {
        opacity: style?.opacity,
        border: style?.border,
        borderRadius: style?.borderRadius,
        padding: style?.padding,
        margin: style?.margin,
        boxShadow: style?.boxShadow,
        alignSelf: style?.alignSelf,
        flexGrow: style?.flexGrow,
    };
}
