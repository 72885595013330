import { FC, useState } from 'react';
import { ResourceAudioGrid } from '../../../../Redux/Reducers/DataSet/ApiCall/Resource';
import { clearContext } from '../../../../Redux/Reducers/System/reducer';
import { useAppDispatch } from '../../../../Redux/hook';
import { IResource, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';
import { performApiCall } from '../../../../common/Hooks/useApiCall';
import { AudioPlayer } from '../../../../common/components/AudioPlayer/AudioPlayer';
import { ConfirmationDialog } from '../../../../common/components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { GetResourceTypeIcon } from '../../../../common/components/Icons/ResourceTypeIcon';
import { Panel } from '../../../../common/components/Panel/Panel';
import { ResourceList } from '../ResourceList';
import { ResourceUXDataChange } from '../ResourceUXDataChange';
import { ResourceAudioDetails } from './ResourceAudioDetails';

export const ResourceAudioList: FC = () => {
    const [selectedResource, setSelectedResource] = useState<IResource>();
    const [showEditDialog, setShowEditDialog] = useState<'New' | 'Edit' | undefined>();
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [showEditUxData, setShowEditUxData] = useState<boolean>(false);

    const appDispatch = useAppDispatch();

    return (
        <Panel
            title='Liste des audios'
            icon={GetResourceTypeIcon(ResourceTypeEnum.Audio)}
            commands={[
                {
                    icon: 'AddTo',
                    text: 'Ajouter',
                    onClick: () => {
                        setShowEditDialog('New');
                    },
                },
                {
                    icon: 'Edit',
                    text: 'Modifier',
                    disabled: !selectedResource,
                    onClick: () => {
                        if (selectedResource?.id) {
                            setShowEditDialog('Edit');
                        }
                    },
                },
                {
                    icon: 'PreviewLink',
                    text: 'Modifier la tuile',
                    disabled: !selectedResource,
                    onClick: () => {
                        if (selectedResource?.id) {
                            setShowEditUxData(true);
                        }
                    },
                },
                {
                    icon: 'Delete',
                    text: 'Supprimer',
                    disabled: !selectedResource,
                    onClick: () => {
                        setShowDeleteDialog(true);
                    },
                },
                {
                    icon: 'Refresh',
                    text: 'Actualiser',
                    onClick: () => {
                        ResourceAudioGrid.refresh();
                    },
                },
            ]}
        >
            <ResourceList
                hasEnabledColumn
                datasetName={'ResourceAudioGrid'}
                onSelect={(selection) => setSelectedResource(selection)}
                extraColumns={[
                    {
                        key: 'Audio',
                        name: 'Audio',
                        fieldName: 'blobPath',
                        minWidth: 500,
                        maxWidth: 500,
                        allowSorting: false,
                        isResizable: true,
                        onRender: (item: IResource) => {
                            return item.blobPath ? <AudioPlayer blobPath={item.blobPath} blobPathKind='Value' display='Action' /> : <span>{"Pas d'audio"}</span>;
                        },
                    },
                ]}
                onRowDoubleClick={(item) => {
                    setSelectedResource(item);
                    setShowEditDialog('Edit');
                }}
            />
            <ConfirmationDialog
                id='ConfirmationDialog'
                icon={{ iconName: GetResourceTypeIcon(ResourceTypeEnum.Audio) }}
                show={showDeleteDialog}
                title='Suppression du fichier audio'
                subText='Etes-vous sur de vouloir supprimer le fichier audio?'
                onReply={(reply) => {
                    if (reply) {
                        performApiCall(
                            async (client) => {
                                if (selectedResource?.id) {
                                    await client.deleteResource(selectedResource.id);
                                    setShowDeleteDialog(false);
                                    ResourceAudioGrid.refresh();
                                }
                            },
                            appDispatch,
                            { contextId: 'ConfirmationDialog' },
                        );
                    } else {
                        setShowDeleteDialog(false);
                        appDispatch(
                            clearContext({
                                contextId: 'ConfirmationDialog',
                            }),
                        );
                    }
                }}
            />
            {showEditDialog && (
                <ResourceAudioDetails
                    resource={showEditDialog === 'Edit' ? selectedResource : undefined}
                    show={showEditDialog !== undefined}
                    onClose={(res) => {
                        setShowEditDialog(undefined);
                        if (res) {
                            ResourceAudioGrid.refresh();
                        }
                    }}
                />
            )}
            {showEditUxData && selectedResource ? (
                <ResourceUXDataChange
                    resource={selectedResource}
                    onClose={(res) => {
                        setShowEditUxData(false);
                        if (res) {
                            ResourceAudioGrid.refresh();
                        }
                    }}
                />
            ) : null}
        </Panel>
    );
};
