import clsx from 'clsx';
import { FC, useMemo } from 'react';
import { dynamicDataResolverOnData } from '../../../../Redux/Reducers/DynamiqueData/dynamicDataResolver';
import { useDynamicData } from '../../../../Redux/Reducers/DynamiqueData/reducer';
import { IImageEffect, IQuestionnaryQuestionAnswer } from '../../../../Services/SakuraApiClient';
import { Expression } from '../../../../common/components/ExpressionField/Expression';
import { DeepPartial } from '../../../../common/helpers/ObjectAndArray';
import { QuestionChoiceAnswer } from './QuestionChoiceAnswer';

export interface QuestionChoiceProps {
    questionId: string;
    multiple?: boolean;
    imageEffect?: DeepPartial<IImageEffect>;
    answer: string[] | undefined;
    answerList: IQuestionnaryQuestionAnswer[];
    onAnswer: (answer: string[] | undefined) => void;
}
const multipleChoice_checked = {
    content: '/images/icons/checkbox_true.svg',
    color: '#49c1c5',
};
const multipleChoice_unchecked = {
    content: '/images/icons/checkbox_false.svg',
    color: '#c1eced',
};
const singleChoice_checked = {
    content: '/images/icons/radio_true.svg',
    color: '#49c1c5',
};
const singleChoice_unchecked = {
    content: '/images/icons/radio_false.svg',
    color: '#c1eced',
};

function setAnswer(answerId: string, checked: boolean, answers: string[], multiple: boolean) {
    if (checked) {
        if (multiple && answers.findIndex((a) => a === answerId) === -1) {
            const newAnswers = [...answers];
            newAnswers.push(answerId);
            return newAnswers;
        } else {
            return [answerId];
        }
    } else {
        if (multiple) {
            return answers.filter((a) => a !== answerId);
        } else {
            return [];
        }
    }
}

export const QuestionChoice: FC<QuestionChoiceProps> = (props: QuestionChoiceProps) => {
    const { answer, onAnswer, answerList, multiple, questionId, imageEffect } = props;

    const checkedIcon = useMemo(() => (multiple ? multipleChoice_checked : singleChoice_checked), [multiple]);
    const uncheckedIcon = useMemo(() => (multiple ? multipleChoice_unchecked : singleChoice_unchecked), [multiple]);
    const hasAnswerPic = useMemo(() => {
        return answerList.findIndex((a) => a.imageResourceId !== undefined) !== -1;
    }, [answerList]);
    const dynData = useDynamicData();
    const answerAvailableList = useMemo(() => {
        return answerList.filter((a) => {
            if (a.visibleIf) {
                const exp = new Expression(a.visibleIf ?? '', (variableName) => dynamicDataResolverOnData(dynData, variableName));
                if (!exp.evalCondition()) {
                    return undefined;
                }
            }
            return a;
        });
    }, [answerList, dynData]);
    return (
        <div className={clsx(hasAnswerPic ? 'pic' : '', 'questionChoice')}>
            {answerAvailableList.map((a, index) => {
                const selected = (answer ?? []).findIndex((s) => s === a.id) !== -1;
                const onSelect = (isSelected: boolean) => {
                    onAnswer(setAnswer(a.id ?? '', isSelected, answer ?? [], multiple ?? false));
                };
                return (
                    <QuestionChoiceAnswer
                        key={`${questionId}_answer_${a.id}`}
                        selected={selected}
                        hasAnswerPic={hasAnswerPic}
                        imageEffect={imageEffect}
                        uncheckedIcon={uncheckedIcon}
                        checkedIcon={checkedIcon}
                        answerText={a.text}
                        imageResourceId={a.imageResourceId}
                        index={index}
                        onSelect={onSelect}
                    />
                );
            })}
        </div>
    );
};
