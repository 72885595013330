import { IDropdownOption } from '@fluentui/react';
import { FC, useEffect, useState } from 'react';
import { AccountActivityLogGrid } from '../../../Redux/Reducers/DataSet/ApiCall/Log';
import { IAccountActivityEventLog } from '../../../Services/SakuraApiClient';
import { INavigationState, useNav } from '../../../common/Hooks/useNav';
import { FilterType } from '../../../common/components/CustomDetailList/FilteredHeaderColumn';
import { SimpleDialog } from '../../../common/components/Dialog/SimpleDialog/SimpleDialog';
import { fullResourceTypeOptions } from '../../../common/components/Icons/ResourceTypeIcon';
import { Panel } from '../../../common/components/Panel/Panel';
import { DatasetGrid } from '../../Common/DatasetGrid';

interface NavState {
    refresh?: boolean;
}

const eventOptions: IDropdownOption[] = ['LOGIN', 'LOGOUT', 'RESET_PASSWORD', 'START_RESOURCE', 'COMPLETE_RESOURCE'].map((s) => ({ key: s, text: s }));

export const AccountActivityLogsList: FC = () => {
    const nav = useNav();

    const navState = nav.current.state as INavigationState<NavState>;
    const [messageView, setMessageView] = useState<string>();
    useEffect(() => {
        if (navState?.state?.refresh) {
            AccountActivityLogGrid.refresh();
        }
    }, [navState?.state?.refresh]);

    const columns = [
        {
            key: 'compte',
            name: 'Compte',
            minWidth: 250,
            maxWidth: 250,
            allowSorting: true,
            isResizable: true,
            fieldName: 'signInEmail',
            filterOption: {
                type: FilterType.Text,
                placeholder: 'email',
                fieldNames: [
                    {
                        fieldName: 'signInEmail',
                        displayName: 'email du compte',
                    },
                ],
            },
        },
        {
            key: 'firstname',
            name: 'Prénom',
            minWidth: 150,
            maxWidth: 150,
            allowSorting: true,
            isResizable: true,
            fieldName: 'firstname',
            filterOption: {
                type: FilterType.Text,
                placeholder: 'prénom',
                fieldNames: [
                    {
                        fieldName: 'firstname',
                        displayName: 'Prénom',
                    },
                ],
            },
        },
        {
            key: 'lastname',
            name: 'Nom',
            minWidth: 150,
            maxWidth: 150,
            allowSorting: true,
            isResizable: true,
            fieldName: 'lastname',
            filterOption: {
                type: FilterType.Text,
                placeholder: 'Nom',
                fieldNames: [
                    {
                        fieldName: 'lastname',
                        displayName: 'Nom',
                    },
                ],
            },
        },
        {
            key: 'eventName',
            name: 'Evenement',
            minWidth: 150,
            maxWidth: 150,
            allowSorting: true,
            isResizable: true,
            fieldName: 'eventName',
            filterOption: {
                type: FilterType.Enum,
                options: eventOptions,
                placeholder: 'Evenement',
                fieldNames: [
                    {
                        fieldName: 'eventName',
                        displayName: 'Evenement',
                    },
                ],
            },
        },

        {
            key: 'eventMessage',
            name: 'Message',
            fieldName: 'eventMessage',
            minWidth: 175,
            maxWidth: 175,
            allowSorting: true,
            isResizable: true,
            onRender: (item: IAccountActivityEventLog) => {
                return (
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setMessageView(item.eventMessage);
                        }}
                    >
                        <span>{item.eventMessage}</span>
                    </div>
                );
            },
        },
        {
            key: 'resourceId',
            name: 'Id de la resource',
            minWidth: 150,
            maxWidth: 150,
            allowSorting: true,
            isResizable: true,
            fieldName: 'resourceId',
        },
        {
            key: 'resourceType',
            name: 'Type de la resource',
            minWidth: 200,
            maxWidth: 200,
            allowSorting: true,
            isResizable: true,
            fieldName: 'resourceType',
            filterOption: {
                type: FilterType.Enum,
                options: fullResourceTypeOptions,
                placeholder: 'Type de resource',
                fieldNames: [
                    {
                        fieldName: 'resourceType',
                        displayName: 'Type de resource',
                    },
                ],
            },
        },
        {
            key: 'resourceName',
            name: 'Nom de la resource',
            minWidth: 200,
            maxWidth: 200,
            allowSorting: true,
            isResizable: true,
            fieldName: 'resourceName',
        },
        {
            key: 'timestamp',
            name: 'Créer le',
            fieldName: 'timestamp',
            minWidth: 150,
            maxWidth: 150,
            allowSorting: true,
            isResizable: true,
            filterOption: {
                type: FilterType.Date,
                placeholder: 'Date',
                fieldNames: [
                    {
                        fieldName: 'createdOn_0',
                        displayName: 'Depuis',
                    },
                    {
                        fieldName: 'createdOn_1',
                        displayName: "Jusqu'a",
                    },
                ],
            },
            onRender: (item: IAccountActivityEventLog) => {
                return <span>{item.timestamp.toLocaleString()}</span>;
            },
        },
    ];
    return (
        <Panel
            title="Log d'activité des comptes"
            icon='Table'
            commands={[
                {
                    icon: 'Refresh',
                    text: 'Actualiser',
                    onClick: () => {
                        AccountActivityLogGrid.refresh();
                    },
                },
            ]}
        >
            <DatasetGrid datasetName={'AccountActivityLogGrid'} columns={columns} />
            <SimpleDialog
                show={messageView !== undefined}
                onClose={() => {
                    setMessageView(undefined);
                }}
                id={'messageView'}
                title={'Détails'}
            >
                <div style={{ whiteSpace: 'pre-wrap' }}>{messageView}</div>
            </SimpleDialog>
        </Panel>
    );
};
