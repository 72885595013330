import { Pivot, PivotItem } from '@fluentui/react';
import { FC } from 'react';
import { IResourceDataPage } from '../../../../Services/SakuraApiClient';
import { ToolBoxPanel } from '../../../Admin/Resources/Page/ToolBoxPanel';
import { PageHierachyMap } from './PageHierachyMap';
import { ToolBoxContent } from './ToolBoxContent';

export interface PageEditorToolBoxProps {
    pageData: IResourceDataPage | undefined;
}
export const PageEditorToolBox: FC<PageEditorToolBoxProps> = (props: PageEditorToolBoxProps) => {
    const { pageData } = props;
    return (
        <ToolBoxPanel icon='Design' title='Conception' width={275}>
            <Pivot linkSize='large'>
                <PivotItem headerText='Elements' itemIcon='DeveloperTools'>
                    <ToolBoxContent />
                </PivotItem>
                {pageData ? (
                    <PivotItem headerText='Structure' itemIcon='BulletedTreeList'>
                        <PageHierachyMap data={pageData} />
                    </PivotItem>
                ) : (
                    <></>
                )}
            </Pivot>
        </ToolBoxPanel>
    );
};
