import { dynamicDataResolver } from '../../../../Redux/Reducers/DynamiqueData/dynamicDataResolver';
import { IQuestionnaryQuestion } from '../../../../Services/SakuraApiClient';
import { Expression } from '../../../../common/components/ExpressionField/Expression';
import { QuestionnaryStatus } from './state';

export function moveToPrevQuestion(currentIndex: number, questions: IQuestionnaryQuestion[]): { index: number; status: QuestionnaryStatus | undefined } {
    let index = currentIndex;
    do {
        index = index - 1;
        if (index < 0) {
            return { index: 0, status: 'NotStarted' };
        }
        if (questions[index].visibleIf) {
            const exp = new Expression(questions[index].visibleIf ?? '', dynamicDataResolver);
            if (!exp.evalCondition()) {
                continue;
            }
        }
        if (index !== currentIndex) {
            return { index, status: undefined };
        }
    } while (true);
}

export function moveToNextQuestion(currentIndex: number, questions: IQuestionnaryQuestion[]): { index: number; status: QuestionnaryStatus | undefined } {
    let index = currentIndex;
    const nbQuestions = questions?.length ?? 0;
    do {
        if (index >= nbQuestions - 1) {
            return { index: nbQuestions - 1, status: 'Completed' };
        }
        index = index + 1;

        if (questions[index].visibleIf) {
            const exp = new Expression(questions[index].visibleIf ?? '', dynamicDataResolver);

            if (!exp.evalCondition()) {
                continue;
            }
        }
        if (index !== currentIndex) {
            return { index, status: undefined };
        }
    } while (true);
}
