import { Label } from '@fluentui/react';
import { FC } from 'react';
import { QuestionnaryKind } from '../../../../Services/SakuraApiClient';
import { DeepPartial, FormError } from '../../../../common/Hooks/Form/useForm';
import { AnswerSettingContent, AnswerSettingData } from './AnswerSettingContent';

export interface AnswerSettingProps {
    className?: string;
    label?: string;
    data: DeepPartial<AnswerSettingData> | undefined;
    error: FormError<AnswerSettingData> | undefined;
    onUpdate: (data: DeepPartial<AnswerSettingData>) => void;
    questionnaryKind: QuestionnaryKind;
    hideIsCorrect?: boolean;
}

export const AnswerSetting: FC<AnswerSettingProps> = (props: AnswerSettingProps) => {
    const { label, data, error, onUpdate, className, questionnaryKind, hideIsCorrect } = props;

    return (
        <div className={className}>
            {label ? <Label>{label}</Label> : <></>}
            <div className='AnswerSettingContent'>
                <AnswerSettingContent data={data} error={error} onUpdate={onUpdate} questionnaryKind={questionnaryKind} hideIsCorrect={hideIsCorrect} />
            </div>
        </div>
    );
};
