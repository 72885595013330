import React, { FC } from 'react';
import { IProduct, IWorkflow, ProductTypeEnum, Workflow } from '../../../Services/SakuraApiClient';

import { Dropdown, Separator, TextField, Toggle } from '@fluentui/react';

import { ProductTypes } from './ProductType';
import { EntityForm } from '../../../common/Hooks/Form/useForm';
import { ResourceLookupField } from '../../../common/LookupFields/ResourceLookupField';
import { DatetimePicker } from '../../../common/components/DatetimePicker/DatetimePicker';
import { WorkflowEdit } from '../Workflow/WorkflowEdit';
import { FormGroup } from '../../../common/components/FormGroup/FormGroup';
import { NumberField } from '../../../common/components/NumberField/NumberField';

export interface ProductDetailFormProps {
    form: EntityForm<IProduct>;
}

export const ProductDetailForm: FC<ProductDetailFormProps> = (props: ProductDetailFormProps) => {
    const { form } = props;

    return (
        <>
            <div className='SakuraPanelsGrid2'>
                <TextField label='Nom' errorMessage={form.fieldErrors.name} required value={form.state.name ?? ''} onChange={(_, v) => form.update({ name: v })} />
                <TextField label='Description' errorMessage={form.fieldErrors.description} required value={form.state.description ?? ''} onChange={(_, v) => form.update({ description: v })} />
                <Dropdown
                    label='Type'
                    errorMessage={form.fieldErrors.productType}
                    required
                    options={ProductTypes}
                    selectedKey={form.state.productType}
                    onChange={(_, v) => form.update({ productType: v?.key as ProductTypeEnum })}
                />
                <TextField
                    label='Référence externe'
                    errorMessage={form.fieldErrors.externalSystemReference}
                    value={form.state.externalSystemReference ?? ''}
                    onChange={(_, v) => form.update({ externalSystemReference: v })}
                />
                <DatetimePicker label='Date de sortie' value={form.state.releaseDate} onSelectDate={(v) => form.update({ releaseDate: v })} />
                <ResourceLookupField
                    type='ResourceImageVignetteLookup'
                    label='Vignette'
                    selectedResourceId={form.state.thumbnailResourceId}
                    onSelectResource={(resource) => form.update({ thumbnailResourceId: resource?.id })}
                />
            </div>
            <Separator />
            <WorkflowEdit
                workflow={(form.state.data?.workflow ?? new Workflow()) as IWorkflow}
                onChange={(workflow) => {
                    const wf = new Workflow();
                    if (workflow) {
                        wf.init(workflow);
                    }
                    form.update({ data: { workflow: wf } });
                }}
            />
            <Separator />
            <FormGroup title='Magasin Digital' icon='ShoppingCart'>
                <Toggle label={'En vente'} onText='Oui' offText='Non' checked={form.state.sellInStore} onChange={(_, sellInStore) => form.update({ sellInStore })} />
                <NumberField disabled={form.state.sellInStore !== true} label={'Prix'} value={form.state.price} onChange={(price) => form.update({ price })} />
            </FormGroup>
        </>
    );
};
