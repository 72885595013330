import { GetMenuEntry } from '../../../common/components/Icons/ResourceTypeIcon';
import { screenPaths } from '../../../Routing/ScreenPaths';
import { ResourceTypeEnum } from '../../../Services/SakuraApiClient';

export const adminMenu = [
    {
        key: 'dataCollection',
        title: 'Données',
        children: [
            {
                key: 'questionnaryResult',
                title: 'Réponses aux questionnaires',
                icon: 'Questionnaire',
                path: screenPaths.QuestionnaryResponseList,
            },
        ],
    },
    {
        key: 'admin',
        title: 'Administration',
        children: [
            {
                key: 'Settings',
                title: 'Configuration',
                children: [
                    {
                        key: 'Offer',
                        title: 'Offres',
                        icon: 'ProductCatalog',
                        path: screenPaths.offerList,
                    },
                    {
                        key: 'Product',
                        title: 'Produits',
                        icon: 'Product',
                        path: screenPaths.productList,
                    },
                    {
                        key: 'Resources',
                        title: 'Ressources',
                        //path:'/admin/resources',
                        children: GetMenuEntry([
                            ResourceTypeEnum.Formation,
                            ResourceTypeEnum.Page,
                            ResourceTypeEnum.Questionnary,
                            ResourceTypeEnum.Document,
                            ResourceTypeEnum.Audio,
                            ResourceTypeEnum.Image,
                            ResourceTypeEnum.Video,
                            ResourceTypeEnum.GameContent,
                        ]),
                    },
                    {
                        key: 'Badges',
                        title: 'Badges',
                        icon: 'Badge',
                        path: screenPaths.badgeList,
                    },
                    {
                        key: 'Workflows',
                        title: 'Workflows',
                        icon: 'SetAction',
                        path: screenPaths.workflowList,
                    },
                    {
                        key: 'socialNetworkLinksList',
                        title: 'Liens réseaux sociaux',
                        icon: 'JoinOnlineMeeting',
                        path: screenPaths.socialNetworkLinksList,
                    },
                ],
            },
            {
                key: 'logs',
                title: 'Logs',
                children: [
                    {
                        key: 'externalSystem',
                        title: 'Systeme Externe',
                        icon: 'Table',
                        path: screenPaths.externalSystemLog,
                    },
                    {
                        key: 'account',
                        title: 'Activité des comptes',
                        icon: 'ContactList',
                        path: screenPaths.accountLog,
                    },
                    {
                        key: 'coinTransaction',
                        title: 'Transaction de pétales',
                        icon: 'Flower',
                        path: screenPaths.coinTransactionLog,
                    },
                    {
                        key: 'diamondTransaction',
                        title: 'Transaction de diamants',
                        icon: 'Diamond',
                        path: screenPaths.diamondTransactionLog,
                    },
                ],
            },
            {
                key: 'Accounts',
                title: 'Comptes',
                icon: 'People',
                path: screenPaths.accountList,
            },
            {
                key: 'System',
                title: 'Réglage systeme',
                icon: 'DataManagementSettings',
                path: screenPaths.system,
            },
        ],
    },
];
