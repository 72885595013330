import { DatePicker, IDatePickerStyleProps, IDatePickerStyles, IStyleFunctionOrObject, Label, MaskedTextField } from '@fluentui/react';
import { FC, useEffect, useState } from 'react';
import { dateAndTime, displayDate, displayHHMM } from '../../helpers/DateTimeHelper';

import './DatetimePicker.scss';
export interface DatetimePickerProps {
    label?: string;
    value?: Date;
    required?: boolean;
    errorMessage?: string;
    onSelectDate: (value: Date | undefined) => void;
    styles?: IStyleFunctionOrObject<IDatePickerStyleProps, IDatePickerStyles>;
}

const maskFormat: { [key: string]: RegExp } = {
    '*': /[0-9_]/,
};

export const DatetimePicker: FC<DatetimePickerProps> = (props: DatetimePickerProps) => {
    const { label, value, required, errorMessage, styles, onSelectDate } = props;
    const [timeStr, setTimeStr] = useState<string>();
    useEffect(() => {
        setTimeStr(value ? displayHHMM(value) : undefined);
    }, [value]);
    return (
        <div className='datetimePicker'>
            <Label>{label}</Label>
            <div className='content'>
                <DatePicker
                    styles={styles}
                    isRequired={required}
                    className='datePart'
                    value={value}
                    onSelectDate={(v) => onSelectDate(dateAndTime(v ?? undefined, timeStr ?? '00:00'))}
                    formatDate={displayDate}
                />
                <MaskedTextField
                    className='timePart'
                    mask='**:**'
                    maskFormat={maskFormat}
                    maskChar='_'
                    value={timeStr}
                    onBlur={() => {
                        const date = value ? dateAndTime(value, timeStr ?? '00:00') : undefined;
                        onSelectDate(date);
                    }}
                    onChange={(_, v) => {
                        setTimeStr(v);
                    }}
                />
            </div>
            {errorMessage ? <span>{errorMessage}</span> : null}
        </div>
    );
};
