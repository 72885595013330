import { FC, useMemo } from 'react';
import { SocialNetworkLinksBlock } from '../../Pages/Common/SocialNetworkLinks/SocialNetworkLinksBlock';
import { SocialNetworkShareBlock } from '../../Pages/Common/SocialNetworkLinks/SocialNetworkShareBlock';
import { IPublicResource } from '../../Services/SakuraApiClient';
import { ResourcePackageContextProvider } from '../../common/components/ResourcePackage/ResourcePackageContextProvider';
import { ResourceViewer } from '../../common/components/ResourcePackage/ResourceViewer';
import { getMetaContentByName } from '../../common/helpers/documentMetadata';
import { MarketingOffer } from '../ViewMarketingOffer';
import { ViewMarketingOfferBasic } from '../ViewMarketingOfferBasic';
import './thanksPage.scss';
export interface ThanksPageProps {
    offer: MarketingOffer;
    resource: IPublicResource;
}
export const ThanksPage: FC<ThanksPageProps> = (props: ThanksPageProps) => {
    const { offer, resource } = props;
    const url = useMemo(() => {
        const url = getMetaContentByName('og:url');
        if (url) {
            return url;
        }
        return window.location.toString();
    }, []);
    return (
        <ResourcePackageContextProvider resourcePackages={resource.questionnary.resourcePackage}>
            <div className='thanksPage'>
                <div>
                    <div className='blockHorizontalWrap'>
                        {resource.questionnary.thanksPage.imageResourceId ? (
                            <div className='pic'>
                                <ResourceViewer resourceId={resource.questionnary.thanksPage.imageResourceId} />
                            </div>
                        ) : (
                            <></>
                        )}
                        <div className='blockVertical'>
                            <h2>{'MERCI POUR TA CONFIANCE !'}</h2>
                            {resource.questionnary.thanksPage.thanksMessage ? (
                                <>
                                    <p>{resource.questionnary.thanksPage.thanksMessage}</p>
                                    <p>
                                        {"Pense à bien vérifier dans tes spams et tes indésirables. Et si tu ne l'as toujours pas reçu, d'ici 30 minutes, pas de panique ! Écris moi à : "}{' '}
                                        <a href='mailto:sakura@sakura-coaching.fr'>sakura@sakura-coaching.fr</a>
                                    </p>
                                </>
                            ) : null}
                        </div>
                    </div>
                    <h2>{'Restons en contact 😸'}</h2>
                    <div className='blockHorizontalWrap'>
                        <SocialNetworkLinksBlock />
                        {resource.questionnary.thanksPage.shareTitle && resource.questionnary.thanksPage.shareMessage ? (
                            <SocialNetworkShareBlock
                                title={resource.questionnary.thanksPage.shareTitle ?? ''}
                                message={resource.questionnary.thanksPage.shareMessage ?? ''}
                                share={resource.questionnary.thanksPage.share}
                                url={url}
                            />
                        ) : null}
                    </div>
                    {offer.offerId1 || offer.offerId2 || offer.showCurrentOffers ? (
                        <>
                            <h2>{'Et pour aller plus loin ✨'}</h2>
                            <div className='blockHorizontalWrap'>
                                <ViewMarketingOfferBasic offer={offer} />
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
        </ResourcePackageContextProvider>
    );
};
