import { CSSProperties } from 'react';
import { EnumItemsAlignement, IDimensions, IPageElementCustomComponent, PageElement, PageElementType } from '../../../Services/SakuraApiClient';

export function getEditorContainerStylePerElement(element: PageElement): CSSProperties {
    if (element.type === PageElementType.Separator || (element.type === PageElementType.CustomComponent && (element as unknown as IPageElementCustomComponent).name === 'ThumbnailsSection')) {
        return {
            alignSelf: 'stretch',
            width: '-webkit-fill-available',
        };
    }
    const result: Record<string, unknown> = {};

    const alignSelf = (element as unknown as { style: { alignSelf: EnumItemsAlignement } })?.style?.alignSelf;
    const dimensions = (element as unknown as { style: { dimensions: IDimensions } })?.style?.dimensions;
    if (dimensions?.width !== undefined || dimensions?.maxWidth !== undefined) {
        result['width'] = undefined;
    }
    if (alignSelf) {
        result['alignSelf'] = alignSelf;
    }
    return result;
}
