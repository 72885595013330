import { FC } from 'react';
import { FacebookMessengerShareButton } from 'react-share';
import { SocialNetworkLogo } from '../SocialNetworkLogo';
import { SharedButtonProps } from './type';

export const MessengerSB: FC<SharedButtonProps> = (props: SharedButtonProps) => {
    const { url } = props;
    return url ? (
        <FacebookMessengerShareButton url={url} appId=''>
            <div className='messenger_share_b' title='Partager via messenger'>
                <SocialNetworkLogo socialNetwork='messenger' size='small' />
                <span>{'Partager'}</span>
            </div>
        </FacebookMessengerShareButton>
    ) : null;
};
