import { FC } from 'react';
import * as SvgLibrary from '../../../../common/components/Svg/Library';
import { SvgButton } from '../../../../common/components/Svg/SvgButton';
export interface QuestionYesNoProps {
    answer: boolean | undefined;
    onAnswer: (answer: boolean | undefined) => void;
}
const yesVisualTrue = {
    content: SvgLibrary.yes,
    color: '#39b54a',
};
const yesVisualFalse = {
    content: SvgLibrary.yes,
    color: '#a6a6a6',
};
const noVisualTrue = {
    content: SvgLibrary.no,
    color: '#b6443b',
};
const noVisualFalse = {
    content: SvgLibrary.no,
    color: '#a6a6a6',
};
export const QuestionYesNo: FC<QuestionYesNoProps> = (props: QuestionYesNoProps) => {
    const { answer, onAnswer } = props;

    return (
        <div className='questionYesNo'>
            <SvgButton
                state={answer === true}
                visualTrue={yesVisualTrue}
                visualFalse={yesVisualFalse}
                width={100}
                height={100}
                onChangeState={(selected) => onAnswer(selected === true ? true : undefined)}
            />
            <SvgButton
                state={answer === false}
                visualTrue={noVisualTrue}
                visualFalse={noVisualFalse}
                width={100}
                height={100}
                onChangeState={(selected) => onAnswer(selected === true ? false : undefined)}
            />
        </div>
    );
};
