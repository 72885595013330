import React, { FC, PropsWithChildren, useLayoutEffect, useRef, useState } from 'react';

export interface ScalerProps {
    width?: number;
    height?: number;
    renderWidth?: number;
    renderheight?: number;
}
export const Scaler: FC<PropsWithChildren<ScalerProps>> = (props: PropsWithChildren<ScalerProps>) => {
    const { width, height, children, renderWidth, renderheight } = props;
    const [scale, setScale] = useState<{ x: number; y: number }>({ x: 1, y: 1 });
    const divRef = useRef<HTMLDivElement>(null);
    const contentDivRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        if (divRef.current && contentDivRef.current) {
            const resizeObserver = new ResizeObserver(() => {
                // Do what you want to do when the size of the element changes
                if (divRef.current && contentDivRef.current) {
                    const x = divRef.current.clientWidth / contentDivRef.current.clientWidth;
                    const y = divRef.current.clientHeight / contentDivRef.current.clientHeight;
                    setScale({
                        x: Math.max(x, y),
                        y: Math.max(x, y),
                    });
                }
            });
            resizeObserver.observe(contentDivRef.current);
            return () => resizeObserver.disconnect(); // clean up
        }
        return undefined;
    }, []);
    return (
        <div style={{ width, height, overflow: 'hidden' }} ref={divRef}>
            <div
                style={{ width: renderWidth, height: renderheight, transformOrigin: 'top left', transform: `scale(${scale.x},${scale.y})`, overflow: 'hidden', overflowY: 'auto' }}
                ref={contentDivRef}
            >
                {children}
            </div>
        </div>
    );
};
