import { BuildPagination, PageInfo, pagination, PaginationSetting } from '../CustomDetailList/ItemHelper';
import { NavigationAction } from '../CustomDetailList/Pagination';
import { FilterDefinition, SearchCriteria, SearchParameter } from './SearchParameters';

export interface SearchComboBoxState<TItem> {
    items?: TItem[];
    itemsView?: TItem[];
    pageInfo?: PageInfo;
    isSelected: boolean;
    searchText?: string;
    saveSearchText?: string;
    primaryText?: string;
    editCriterias?: SearchCriteria[];
    criterias?: SearchCriteria[];
    hasCriterias: boolean;
    filters?: FilterDefinition[];
    rtl: boolean;
    filterVisible?: boolean;
}

export type PageSettingChangedAction = {
    type: 'pageSettingChanged';
    paginationSetting?: PaginationSetting;
};
export type ReceivedNewItemsAction<TItem> = {
    type: 'receivedNewItems';
    payload: { items: TItem[] | undefined };
};
export type ChangeSearchsAction = {
    type: 'ChangeSearch';
    payload: { searchText?: string | undefined };
};
export type UpdateCriteriaAction = {
    type: 'UpdateCriteria';
    payload: { name: string; value: string | undefined };
};
export type ApplyCriteriasAction = { type: 'ApplyCriterias' };
export type CancelCriteriasAction = { type: 'CancelCriterias' };
export type ClearCriteriaAction = { type: 'Clear' };
export type SelectAction = { type: 'Select'; payload: { text: string } };
export type UnSelectAction = { type: 'unSelect' };
export type ChangeDirectionAction = { type: 'ChangeDirection'; payload: { rtl: boolean } };
export type DeclareFilterAction = {
    type: 'DeclareFilter';
    payload: { filters?: FilterDefinition[] };
};
export type FilterPanelToggleAction = { type: 'FilterPanelToggle'; payload: { visible?: boolean } };
export type SearchComboBoxAction<TItem> =
    | PageSettingChangedAction
    | ChangeSearchsAction
    | FilterPanelToggleAction
    | UpdateCriteriaAction
    | ApplyCriteriasAction
    | CancelCriteriasAction
    | ClearCriteriaAction
    | DeclareFilterAction
    | ChangeDirectionAction
    | SelectAction
    | UnSelectAction
    | ReceivedNewItemsAction<TItem>
    | NavigationAction;

export type SearchComboBoxReducer<TItem> = (state: SearchComboBoxState<TItem>, action: SearchComboBoxAction<TItem>) => SearchComboBoxState<TItem>;
export function searchComboBoxReducer<TItem>(state: SearchComboBoxState<TItem>, action: SearchComboBoxAction<TItem>) {
    let newPageInfo;

    switch (action.type) {
        case 'FilterPanelToggle':
            return {
                ...state,
                filterVisible: action.payload.visible ?? state.filterVisible ?? false,
            };
        case 'ChangeDirection':
            return { ...state, rtl: action.payload.rtl };
        case 'receivedNewItems':
            newPageInfo = BuildPagination(state.pageInfo?.paginationSetting?.initialPage ?? 0, action.payload.items, state.pageInfo?.paginationSetting);
            return {
                ...state,
                items: action.payload.items,
                itemsView: pagination(action.payload.items, newPageInfo),
                pageInfo: newPageInfo,
            };
        case 'Select':
            return {
                ...state,
                isSelected: true,
                searchText: action.payload.text,
                saveSearchText: state.searchText,
            };
        case 'unSelect':
            if (state.isSelected) {
                const param = new SearchParameter(state.filters ?? [], state.saveSearchText, state.rtl);
                return {
                    ...state,
                    searchText: state.saveSearchText ?? state.searchText,
                    isSelected: false,
                    saveSearchText: undefined,
                    criterias: param.criterias,
                    editCriterias: param.criterias,
                    primaryText: param.primaryText,
                    hasCriterias: param.hasCritieras,
                };
            }
            return state;
        case 'Clear': {
            const clearCtierias = state.criterias?.map((m) => {
                return { name: m.name, value: undefined, definition: m.definition };
            });
            return {
                ...state,
                // don't clear view because we perform a search
                // items: undefined,
                // itemsView: undefined,
                searchText: '',
                primaryText: '',
                criterias: clearCtierias,
                editCriterias: clearCtierias,
                hasCriterias: false,
            };
        }
        case 'DeclareFilter': {
            const param = new SearchParameter(action.payload.filters ?? [], state.searchText, state.rtl);
            return {
                ...state,
                filters: action.payload.filters,
                searchText: state.searchText,
                criterias: param.criterias,
                editCriterias: param.criterias,
                primaryText: param.primaryText,
                hasCriterias: param.hasCritieras,
            };
        }
        case 'ChangeSearch': {
            if (state.isSelected === false) {
                const param = new SearchParameter(state.filters ?? [], action.payload.searchText, state.rtl);
                return {
                    ...state,
                    searchText: action.payload.searchText,
                    criterias: param.criterias,
                    editCriterias: param.criterias,
                    primaryText: param.primaryText,
                    hasCriterias: param.hasCritieras,
                };
            }
            return state;
        }
        case 'UpdateCriteria': {
            const copy = state.editCriterias ? [...state.editCriterias] : [];
            const item = copy.find((c) => c.name === action.payload.name);
            if (item) {
                item.value = action.payload.value;
                return {
                    ...state,
                    editCriterias: copy,
                };
            }
            return state;
        }
        case 'ApplyCriterias': {
            const param = new SearchParameter(state.filters ?? [], state.searchText, state.rtl);
            param.criterias = state.editCriterias ?? [];
            return {
                ...state,
                criterias: state.editCriterias,
                hasCriterias: param.hasCritieras,
                searchText: param.toString(),
                filterVisible: false,
            };
        }
        case 'CancelCriterias': {
            return { ...state, editCriterias: state.criterias, filterVisible: false };
        }
        case 'pageSettingChanged':
            if (action.paginationSetting) {
                newPageInfo = BuildPagination(action.paginationSetting?.initialPage ?? 0, state.items, {
                    ...action.paginationSetting,
                    totalPage: action.paginationSetting.totalPage,
                    nbItemPerPage: action.paginationSetting?.nbItemPerPage,
                });
            } else {
                newPageInfo = undefined;
            }
            return {
                ...state,
                itemsView: pagination(state.items, newPageInfo),
                pageInfo: newPageInfo,
            };
        case 'beginNav':
        case 'endNav':
        case 'pageNav':
            if (state.pageInfo) {
                switch (action.type) {
                    case 'beginNav':
                        newPageInfo = { ...state.pageInfo, currentPage: 0 };
                        break;
                    case 'endNav':
                        newPageInfo = {
                            ...state.pageInfo,
                            currentPage: state.pageInfo.totalPage - 1,
                        };
                        break;
                    case 'pageNav':
                        newPageInfo = {
                            ...state.pageInfo,
                            currentPage: action.pageNumber,
                        };
                        break;
                    default:
                        throw new Error('navigation page action invalid');
                }
                return {
                    ...state,
                    itemsView: pagination(state.items, newPageInfo),
                    pageInfo: newPageInfo,
                };
            }
            return {
                ...state,
                itemsView: pagination(state.items),
            };
        default:
            break;
    }
    return state;
}
