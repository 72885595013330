import { ActionButton, Label, Pivot, PivotItem, PrimaryButton, Separator, TextField } from '@fluentui/react';
import { FC, useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../Redux/hook';
import { IColorPalette, IEmailTemplate, IFontStyles, MenuEntryType } from '../../../Services/SakuraApiClient';
import { NotIn, required, requiredIf, useForm, validLink } from '../../../common/Hooks/Form';
import { performApiCall } from '../../../common/Hooks/useApiCall';
import { NumberField } from '../../../common/components/NumberField/NumberField';
import { Panel } from '../../../common/components/Panel/Panel';
import { ArrayOperation, ObjectOperation } from '../../../common/helpers/ObjectAndArray';
import { capitalizeFirstLetter } from '../../../common/helpers/string';
import { EmailTemplateParameterSelector } from '../EmailTemplateSelector/EmailTemplateParameterSelector';
import { EmailTemplateSelector } from '../EmailTemplateSelector/EmailTemplateSelector';

import { InitializeMenu } from '../../../Redux/Reducers/Menu/reducer';
import { colorsPaletteSelector, setColorPalette, setFontStyles, setThumbnailSections } from '../../../Redux/Reducers/System/reducer';
import { ConfiguredLinks } from '../../../Redux/Reducers/System/state';
import { updateProfile } from '../../../Redux/Reducers/User/actionChain';
import { ReursiveValidation } from '../../../common/Hooks/Form/useForm';
import { ColorPaletteEditor } from '../../../common/components/Color/ColorPaletteEditor';
import { FontStylesEditor } from '../../../common/components/FontStyles/FontStylesEditor';
import { SocialNetworkLogo } from '../../Common/SocialNetworkLinks/SocialNetworkLogo';
import { MenuSettingEdit } from './MenuSetting/MenuSettingEdit';
import { IMenuSetting, listOfBuildinPageName } from './MenuSetting/menuSetting';
import { ISectionSetting } from './SectionSetting/ISectionSetting';
import { SectionSettingEdit } from './SectionSetting/SectionSettingEdit';
import './SystemEdit.scss';
import { UserVariablesEdit } from './UserVariablesEdit';

export interface SystemState {
    templateEmailResetPassword: {
        id: number;
        template: IEmailTemplate | undefined;
        paramName: string;
    };
    templateEmailInviteFirstConnection: {
        id: number;
        template: IEmailTemplate | undefined;
        paramName: string;
    };
    templateEmailCompleteFirstConnection: {
        id: number;
        template: IEmailTemplate | undefined;
        paramName: string;
    };
    experienceMap: {
        range: number[];
    };
    links: ConfiguredLinks;
    colorPalette: IColorPalette;
    fontStyles: IFontStyles;
    sectionSetting: ISectionSetting;
    userVariables: {
        vars: string[];
    };
    menuSetting: IMenuSetting;
}

export interface SystemEditProps {
    settings: SystemState;
}
export const SystemEdit: FC<SystemEditProps> = (props: SystemEditProps) => {
    const { settings } = props;
    const appDispatch = useAppDispatch();

    const form = useForm<SystemState>(
        {
            initialState: settings,
            validators: {
                fields: {
                    links: {
                        generalConditionOfSale: { displayName: 'Le lien', validators: [validLink] },
                        privacyPolicy: { displayName: 'Le lien', validators: [validLink] },
                        facebook: { displayName: 'Le lien', validators: [validLink] },
                        instagram: { displayName: 'Le lien', validators: [validLink] },
                        pinterest: { displayName: 'Le lien', validators: [validLink] },
                        tiktok: { displayName: 'Le lien', validators: [validLink] },
                        youtube: { displayName: 'Le lien', validators: [validLink] },
                        x: { displayName: 'Le lien', validators: [validLink] },
                    },
                    menuSetting: {
                        children: {
                            key: {
                                displayName: 'Le chemin',
                                validators: [
                                    requiredIf((m) => m?.type === MenuEntryType.Page || m?.type === MenuEntryType.ListOf || m?.type === MenuEntryType.ViewResource),
                                    NotIn((m) => m?.type === MenuEntryType.Page || m?.type === MenuEntryType.ListOf || m?.type === MenuEntryType.ViewResource, listOfBuildinPageName),
                                ],
                            },
                            title: { displayName: 'Le libellé', validators: [required] },
                            type: { displayName: 'Le type', validators: [requiredIf((m) => (m?.children?.length ?? 0) === 0)] },
                            externalUrl: { displayName: 'Le lien web', validators: [requiredIf((m) => m?.type === MenuEntryType.Url)] },
                            listname: { displayName: 'Une liste', validators: [requiredIf((m) => m?.type === MenuEntryType.ListOf)] },
                            buildinPageName: { displayName: 'Une page', validators: [requiredIf((m) => m?.type === MenuEntryType.BuildinPage)] },
                            children: ReursiveValidation,
                        },
                    },
                },
            },
        },
        true,
        'globalSetting',
        'globalSetting',
    );

    const onSave = useCallback(() => {
        const error = form.validate();
        if (!error) {
            performApiCall(async (client) => {
                const keys = Object.keys(form.state);

                const state = form.state as Record<string, any>;

                const existingState = form.initialState as Record<string, any>;
                for (let i = 0; i < keys.length; i++) {
                    const key = keys[i];
                    if (!ObjectOperation.jsonEquals(state[key], existingState[key])) {
                        await client.setSetting(capitalizeFirstLetter(key), { ...state[key], template: undefined });
                    }
                }
                form.commit();
                //Reload userProfile
                const profile = await client.getMyAccount();
                updateProfile(appDispatch, {
                    profile: profile?.account,
                    menu: profile.menu,
                    experienceRequiredToLevelUp: profile?.experienceRequiredToLevelUp ?? 0,
                    dynVariableNames: profile.dynVariableNames,
                });
                appDispatch(
                    InitializeMenu({
                        userAccount: profile.account,
                        userMenu: profile.menu ?? [],
                    }),
                );
                appDispatch(setColorPalette({ colors: profile.colors }));
                appDispatch(setFontStyles({ fontStyles: profile.fontStyles }));
                appDispatch(setThumbnailSections({ sections: profile.thumbnailSections }));
            }, appDispatch);
        }
    }, [appDispatch, form]);
    const colorPalette = useAppSelector(colorsPaletteSelector);
    useEffect(() => {
        form.update({ colorPalette });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [colorPalette, form.update]);
    return (
        <Panel
            title='Réglage systeme'
            icon={'DataManagementSettings'}
            isContentModified={form.isModified}
            revertContentModification={() => {
                form.rollback();
            }}
            commands={[
                {
                    text: 'Sauvegarder',
                    icon: 'Save',
                    onClick: () => {
                        onSave();
                    },
                },
            ]}
        >
            <Pivot aria-label='Réglage'>
                <PivotItem headerText='Menu' itemIcon='GlobalNavButton'>
                    <MenuSettingEdit
                        errors={form.fieldErrors.menuSetting}
                        data={form.state.menuSetting ?? { children: [] }}
                        onChange={(menuSetting) => {
                            form.update({ menuSetting });
                        }}
                    />
                </PivotItem>
                <PivotItem headerText="Modéles d'emails" itemIcon='MailOptions'>
                    <div className='templates'>
                        <EmailTemplateSelector
                            label={'Premiére connexion'}
                            emailTemplate={form.state.templateEmailInviteFirstConnection?.template as IEmailTemplate}
                            errorMessage={form.fieldErrors.templateEmailInviteFirstConnection?.id}
                            onSelectEmailTemplate={function (template: IEmailTemplate | undefined): void {
                                form.update({ templateEmailInviteFirstConnection: { id: template?.id, template, paramName: '' } });
                            }}
                        ></EmailTemplateSelector>
                        <EmailTemplateParameterSelector
                            label='Lien de connexion'
                            emailTemplate={form.state.templateEmailInviteFirstConnection?.template as IEmailTemplate}
                            errorMessage={form.fieldErrors.templateEmailInviteFirstConnection?.paramName}
                            value={form.state.templateEmailInviteFirstConnection?.paramName}
                            onChange={(v) => form.update({ templateEmailInviteFirstConnection: { paramName: v } })}
                        />

                        <Separator />

                        <EmailTemplateSelector
                            label={'Confirmation premiére connexion'}
                            emailTemplate={form.state.templateEmailCompleteFirstConnection?.template as IEmailTemplate}
                            errorMessage={form.fieldErrors.templateEmailCompleteFirstConnection?.id}
                            onSelectEmailTemplate={function (template: IEmailTemplate | undefined): void {
                                form.update({ templateEmailCompleteFirstConnection: { id: template?.id, template, paramName: '' } });
                            }}
                        ></EmailTemplateSelector>
                        <EmailTemplateParameterSelector
                            label='Lien vers la plateforme Sakura Coaching'
                            emailTemplate={form.state.templateEmailCompleteFirstConnection?.template as IEmailTemplate}
                            errorMessage={form.fieldErrors.templateEmailCompleteFirstConnection?.paramName}
                            value={form.state.templateEmailCompleteFirstConnection?.paramName}
                            onChange={(v) => form.update({ templateEmailCompleteFirstConnection: { paramName: v } })}
                        />

                        <Separator />

                        <EmailTemplateSelector
                            label={'Réinitialisation du mot de passe'}
                            emailTemplate={form.state.templateEmailResetPassword?.template as IEmailTemplate}
                            errorMessage={form.fieldErrors.templateEmailResetPassword?.id}
                            onSelectEmailTemplate={function (template: IEmailTemplate | undefined): void {
                                form.update({ templateEmailResetPassword: { id: template?.id, template, paramName: '' } });
                            }}
                        ></EmailTemplateSelector>
                        <EmailTemplateParameterSelector
                            label='Lien de reinitialisation'
                            emailTemplate={form.state.templateEmailResetPassword?.template as IEmailTemplate}
                            errorMessage={form.fieldErrors.templateEmailResetPassword?.paramName}
                            value={form.state.templateEmailResetPassword?.paramName}
                            onChange={(v) => form.update({ templateEmailResetPassword: { paramName: v } })}
                        />
                    </div>
                </PivotItem>
                <PivotItem headerText='Liens' itemIcon='Link'>
                    <h3>Legale</h3>
                    <TextField
                        label='Condition générale de vente'
                        errorMessage={form.fieldErrors.links?.generalConditionOfSale}
                        value={form.state.links?.generalConditionOfSale}
                        onChange={(_, generalConditionOfSale) => form.update({ links: { generalConditionOfSale } })}
                    />
                    <TextField
                        label='Politique de confidentialité'
                        errorMessage={form.fieldErrors.links?.privacyPolicy}
                        value={form.state.links?.privacyPolicy}
                        onChange={(_, privacyPolicy) => form.update({ links: { privacyPolicy } })}
                    />
                    <Separator />
                    <h3>Social Networks</h3>
                    <TextField
                        onRenderPrefix={() => {
                            return <SocialNetworkLogo socialNetwork='facebook' />;
                        }}
                        label='Facebook'
                        errorMessage={form.fieldErrors.links?.facebook}
                        value={form.state.links?.facebook}
                        onChange={(_, facebook) => form.update({ links: { facebook } })}
                    />
                    <TextField
                        label='Instagram'
                        onRenderPrefix={() => {
                            return <SocialNetworkLogo socialNetwork='instagram' />;
                        }}
                        errorMessage={form.fieldErrors.links?.instagram}
                        value={form.state.links?.instagram}
                        onChange={(_, instagram) => form.update({ links: { instagram } })}
                    />
                    <TextField
                        onRenderPrefix={() => {
                            return <SocialNetworkLogo socialNetwork='pinterest' />;
                        }}
                        label='Pinterest'
                        errorMessage={form.fieldErrors.links?.pinterest}
                        value={form.state.links?.pinterest}
                        onChange={(_, pinterest) => form.update({ links: { pinterest } })}
                    />
                    <TextField
                        onRenderPrefix={() => {
                            return <SocialNetworkLogo socialNetwork='threads' />;
                        }}
                        label='Threads'
                        errorMessage={form.fieldErrors.links?.threads}
                        value={form.state.links?.threads}
                        onChange={(_, threads) => form.update({ links: { threads } })}
                    />
                    <TextField
                        onRenderPrefix={() => {
                            return <SocialNetworkLogo socialNetwork='youtube' />;
                        }}
                        label='Youtube'
                        errorMessage={form.fieldErrors.links?.youtube}
                        value={form.state.links?.youtube}
                        onChange={(_, youtube) => form.update({ links: { youtube } })}
                    />
                    <TextField
                        onRenderPrefix={() => {
                            return <SocialNetworkLogo socialNetwork='tiktok' />;
                        }}
                        label='Tiktok'
                        errorMessage={form.fieldErrors.links?.tiktok}
                        value={form.state.links?.tiktok}
                        onChange={(_, tiktok) => form.update({ links: { tiktok } })}
                    />
                    <TextField
                        onRenderPrefix={() => {
                            return <SocialNetworkLogo socialNetwork='x' />;
                        }}
                        label='X'
                        errorMessage={form.fieldErrors.links?.x}
                        value={form.state.links?.tiktok}
                        onChange={(_, x) => form.update({ links: { x } })}
                    />
                </PivotItem>
                <PivotItem headerText="Table d'experience" itemIcon='Table'>
                    <div className='xpTable'>
                        {!form.state.experienceMap?.range?.length ? (
                            <PrimaryButton
                                text='Créer'
                                onClick={() => {
                                    form.update({ experienceMap: { range: [1, 1000] } });
                                }}
                            />
                        ) : (
                            <>
                                <Label>Level</Label>
                                <Label>{"Point d'experience requis"}</Label>
                            </>
                        )}

                        {form.state.experienceMap?.range?.map((r, index) => {
                            return (
                                <div className='xpTableRow' key={`xp_map_${index}`}>
                                    <NumberField
                                        value={r}
                                        onChange={(v) => form.update({ experienceMap: { range: ArrayOperation.updateValueByIndex<number>(form.state.experienceMap?.range ?? [], index, v ?? 0) } })}
                                    />
                                    {index % 2 ? (
                                        <>
                                            <ActionButton
                                                iconProps={{ iconName: 'CircleAddition' }}
                                                onClick={() => {
                                                    const newArray = [...(form.state.experienceMap?.range ?? [])];
                                                    const prevLevel = index - 1 >= 0 ? newArray[index - 1] : 1;
                                                    const prevXp = index >= 0 ? newArray[index] : 1000;
                                                    newArray.splice(index + 1, 0, prevLevel + 1, prevXp);
                                                    form.update({ experienceMap: { range: newArray } });
                                                }}
                                            />
                                            <ActionButton
                                                iconProps={{ iconName: 'Delete' }}
                                                style={{ color: 'red' }}
                                                onClick={() => {
                                                    form.update({ experienceMap: { range: (form.state.experienceMap?.range ?? []).filter((_, i) => !(i === index || i === index - 1)) } });
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </PivotItem>
                <PivotItem headerText='Palettes de couleur' itemIcon='Color'>
                    <ColorPaletteEditor palette={form.state.colorPalette} onChange={(colorPalette) => form.update({ colorPalette })} />
                </PivotItem>
                <PivotItem headerText='Style de policy' itemIcon='Font'>
                    <FontStylesEditor fontStyles={form.state.fontStyles ?? { entries: [] }} onChange={(fontStyles) => form.update({ fontStyles })} />
                </PivotItem>
                <PivotItem headerText='Variables utilisateur' itemIcon='Variable2'>
                    <UserVariablesEdit
                        variables={form.state.userVariables?.vars ?? []}
                        onChange={(vars) => {
                            form.update({ userVariables: { vars } });
                        }}
                    />
                </PivotItem>
                <PivotItem headerText='Sections de vignette' itemIcon='ThumbnailView'>
                    <SectionSettingEdit
                        sections={form.state.sectionSetting?.sections ?? []}
                        onChange={(sections) => {
                            form.update({ sectionSetting: { sections } });
                        }}
                    />
                </PivotItem>
            </Pivot>
        </Panel>
    );
};
