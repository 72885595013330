import { FC, useMemo } from 'react';
import { ColumnInfo, FilterType } from '../../../common/components/CustomDetailList/FilteredHeaderColumn';
import { IconEnabled } from '../../../common/components/Icons/IconEnabled';
import { DataSetName } from '../../../Redux/Reducers/DataSet/DataSetName';
import { IResource } from '../../../Services/SakuraApiClient';

import { IDropdownOption } from '@fluentui/react';
import { DatasetGrid } from '../../Common/DatasetGrid';

export interface ResourceListProps {
    datasetName: DataSetName;
    categoryOptions?: IDropdownOption[];
    extraColumns: ColumnInfo[];
    onSelect?: (resource: IResource | undefined) => void;
    hasEnabledColumn?: boolean;
    onRowClick?: (item: IResource) => void;
    onRowDoubleClick?: (item: IResource) => void;
}

export const ResourceList: FC<ResourceListProps> = (props: ResourceListProps) => {
    const { onSelect, extraColumns, datasetName, hasEnabledColumn, categoryOptions, onRowClick, onRowDoubleClick } = props;

    const columns = useMemo<ColumnInfo[]>(() => {
        const col: ColumnInfo[] = [];
        if (hasEnabledColumn) {
            col.push({
                key: 'Actif',
                name: 'Actif',
                minWidth: 40,
                maxWidth: 40,
                allowSorting: true,
                isResizable: true,
                onRender: (item: IResource) => {
                    return <IconEnabled enabled={item.enabled} />;
                },
            });
        }
        col.push(
            {
                key: 'Category',
                name: 'Category',
                fieldName: 'category',
                minWidth: 150,
                maxWidth: 150,
                allowSorting: true,
                isResizable: true,
                filterOption: categoryOptions
                    ? {
                          type: FilterType.Enum,
                          placeholder: 'Choisir une category',
                          options: categoryOptions,
                          fieldNames: [
                              {
                                  fieldName: 'category',
                                  displayName: 'Category',
                              },
                          ],
                      }
                    : {
                          type: FilterType.Text,
                          placeholder: 'entrer une category',
                          fieldNames: [
                              {
                                  fieldName: 'category',
                                  displayName: 'Category',
                              },
                          ],
                      },
            },
            {
                key: 'Name',
                name: 'Nom',
                fieldName: 'name',
                minWidth: 350,
                maxWidth: 350,
                allowSorting: true,
                isResizable: true,
                filterOption: {
                    type: FilterType.Text,
                    placeholder: 'entrer un nom',
                    fieldNames: [
                        {
                            fieldName: 'name',
                            displayName: 'Nom',
                        },
                    ],
                },
            },
            {
                key: 'Description',
                name: 'Description',
                fieldName: 'description',
                isResizable: true,
                minWidth: 450,
                maxWidth: 500,
                allowSorting: true,
            },
            ...extraColumns,
            {
                key: 'CreatedOn',
                name: 'Créer le',
                fieldName: 'createdOn',
                minWidth: 100,
                maxWidth: 100,
                allowSorting: true,
                isResizable: true,
                onRender: (item: IResource) => {
                    return <span>{item.createdOn.toLocaleDateString()}</span>;
                },
            },
            {
                key: 'updatedOn',
                name: 'Mis à jour le',
                fieldName: 'updatedOn',
                minWidth: 100,
                maxWidth: 100,
                allowSorting: true,
                isResizable: true,
                onRender: (item: IResource) => {
                    return <span>{item.updatedOn?.toLocaleDateString() ?? ''}</span>;
                },
            },
        );
        return col;
    }, [extraColumns, hasEnabledColumn, categoryOptions]);

    return <DatasetGrid datasetName={datasetName} columns={columns} getKey={(item) => item.id ?? ''} onRowDoubleClick={onRowDoubleClick} onRowClick={onRowClick} onSelect={onSelect} />;
};
