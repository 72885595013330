import clsx from 'clsx';
import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FormGroup } from '../../../common/components/FormGroup/FormGroup';
import { ArrayOperation, ObjectOperation } from '../../../common/helpers/ObjectAndArray';
import { IWorkflow, WorkflowAction, WorkflowActionType } from '../../../Services/SakuraApiClient';
import { WorkflowActionEdit } from './WorkflowActionEdit';
import './WorkflowEdit.scss';

export interface WorkflowEditProps {
    direction?: 'horizontal' | 'vertical';
    defaultOpenState?: boolean;
    workflow: IWorkflow | undefined;
    onChange: (workflow: IWorkflow | undefined) => void;
}
export const WorkflowEdit: FC<WorkflowEditProps> = (props: WorkflowEditProps) => {
    const { workflow, onChange, direction, defaultOpenState } = props;
    const actions = workflow?.actions ?? [];
    return (
        <FormGroup
            isCollapsable
            defaultOpenState={defaultOpenState ?? false}
            title={`Actions (${actions.length})`}
            commands={[
                {
                    icon: 'AddTo',
                    text: 'Ajouter',
                    onClick: () => {
                        const newItem = new WorkflowAction();
                        newItem.init({ type: WorkflowActionType.CreditSakuraCoin, name: '', value: undefined });
                        onChange({ id: workflow?.id ?? uuidv4(), actions: [...(workflow?.actions ?? []), newItem] });
                    },
                },
            ]}
        >
            <div className={clsx('WorkflowEdit', direction === 'vertical' ? 'vertical' : 'horizontal')}>
                {actions.map((action, index) => {
                    return (
                        <WorkflowActionEdit
                            action={action}
                            key={`action${index}`}
                            onChange={(action, mode) => {
                                if (action) {
                                    onChange(ObjectOperation.merge(workflow, { actions: ArrayOperation.updateByIndex(actions, index, action, mode) }));
                                } else {
                                    onChange(ObjectOperation.merge(workflow, { actions: ArrayOperation.removeByIndex(actions, index) }));
                                }
                            }}
                        ></WorkflowActionEdit>
                    );
                })}
            </div>
        </FormGroup>
    );
};
