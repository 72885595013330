import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingScreen } from '../LoadingScreen';

interface RouteParameters extends Record<string, string | undefined> {
    organization: string | undefined;
}

export const LogoutPage: FC = () => {
    const { organization } = useParams<RouteParameters>();
    useEffect(() => {
        window.location.replace('/' + (organization ?? ''));
    }, [organization]);
    return <LoadingScreen text='Déconnexion en cours &#x1F44B;' />;
};
