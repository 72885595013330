import { FC, useMemo } from 'react';
import { useDynamicData } from '../../../../../Redux/Reducers/DynamiqueData/reducer';
import { IPageElementLabel } from '../../../../../Services/SakuraApiClient';
import { useBracketResolver } from '../../../../../common/components/ExpressionField/Hook/useBracketResolver';
import { textStyleBuilder } from './textStyleBuilder';

export interface ElementLabelViewProps {
    data: IPageElementLabel;
}

export const ElementLabelView: FC<ElementLabelViewProps> = (props: ElementLabelViewProps) => {
    const { data } = props;
    const style = useMemo(() => {
        return textStyleBuilder(data.style);
    }, [data.style]);
    const dynData = useDynamicData();
    const valueResolved = useBracketResolver(data.value, dynData);
    return (
        <span title={data.toolTip} style={style}>
            {valueResolved}
        </span>
    );
};
