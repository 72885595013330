import { IconButton, INavLink, INavLinkGroup, INavStyles, IPanelHeaderRenderer, IPanelProps, Nav, Panel, PanelType } from '@fluentui/react';
import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNav } from '../Hooks/useNav';
import { ApplicationLogo } from './ApplicationLogo';
import './LayoutNavigation.scss';

export interface MenuEntry {
    key: string;
    title: string;
    icon?: string;
    path?: string;
    svgPath?: string;
    externalUrl?: string;
    children?: MenuEntry[];
    disabled?: boolean;
    disabledBreadCrumb?: boolean;
}

interface LayoutNavigationProps {
    side: 'left' | 'right';
    menu: MenuEntry[];
    rootPath: string;
}

const navStyles: Partial<INavStyles> = {
    root: {
        overflowY: 'auto',
        overflowX: 'hidden',
    },

    // these link styles override the default truncation behavior
    link: {
        whiteSpace: 'normal',
        lineHeight: 'inherit',
    },
};
function searchSelectedItem(data: MenuEntry[], path: string): MenuEntry | undefined {
    if (data) {
        let selectedItem: MenuEntry | undefined;
        data.find((m) => {
            if (m.children) {
                const result = searchSelectedItem(m.children, path);
                if (result) {
                    selectedItem = result;
                    return true;
                }
            }
            if (path === (m.path ?? '')) {
                selectedItem = m;
                return true;
            }
            return null;
        });
        return selectedItem;
    }
    return undefined;
}

export const LayoutNavigation: FC<LayoutNavigationProps> = (props: LayoutNavigationProps) => {
    const { side, menu, rootPath } = props;

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const location = useLocation();
    const nav = useNav();
    const removeStartBy = (path: string, start: string) => {
        if (path.startsWith(start.toLowerCase())) {
            return path.substring(start.length);
        }
        return path;
    };
    const menuToGroups = useCallback(
        (menuData: MenuEntry[], currentPath: string): INavLink[] | undefined => {
            const path = currentPath.replace(rootPath, '').toLowerCase();

            const data = menuData.map<INavLink & INavLinkGroup>((md) => {
                return {
                    key: md.key,
                    name: md.title,
                    title: md.title,
                    links: menuToGroups(md.children ?? [], removeStartBy(path, '/' + md.key)) as INavLink[],
                    icon: (md?.children?.length ?? 0) > 0 ? undefined : md.icon,
                    groupData: md,
                    isExpanded: path.startsWith('/' + md.key.toLowerCase()),
                    disabled: md.disabled,
                    url: md.externalUrl ?? '',
                    target: md.externalUrl ? '_blank' : undefined,
                    onClick: (ev) => {
                        if (md.externalUrl === undefined) {
                            if (md.disabled && ev) {
                                ev.preventDefault();
                            } else {
                                if (md.path !== undefined) {
                                    nav.navigate(md.path);
                                    setIsMenuOpen(false);
                                }
                            }
                        }
                    },
                };
            });
            if (data.length === 0) {
                return undefined;
            }
            return data;
        },
        [nav, rootPath],
    );

    const [selectedKey, setSelectedKey] = useState<string>();
    const [groups, setGroups] = useState(menuToGroups(menu, location.pathname));

    useEffect(() => {
        setGroups(menuToGroups(menu, location.pathname));
    }, [menu, nav, menuToGroups, location.pathname]);

    useEffect(() => {
        const key = searchSelectedItem(menu, location.pathname.replace(rootPath, ''))?.key;
        setSelectedKey(key);
    }, [menu, rootPath, location.pathname]);

    return (
        <div className='sakura-layout-navigation'>
            <IconButton iconProps={{ iconName: 'GlobalNavButton' }} title='Navigation' onClick={() => setIsMenuOpen(true)} />
            <Panel
                isLightDismiss
                className='sakura-layout-navigation-header'
                isOpen={isMenuOpen}
                onDismiss={() => setIsMenuOpen(false)}
                closeButtonAriaLabel='Close'
                type={side === 'left' ? PanelType.customNear : PanelType.custom}
                customWidth={'500px'}
                onRenderHeader={(props?: IPanelProps, defaultRender?: IPanelHeaderRenderer, headerTextId?: string | undefined): JSX.Element | null => {
                    return (
                        <>
                            <div />
                            <ApplicationLogo />
                        </>
                    );
                }}
                onRenderFooter={(props?: IPanelProps, defaultRender?: IPanelHeaderRenderer, headerTextId?: string | undefined): JSX.Element | null => {
                    return (
                        <div className='sakura-layout-navigation-footer'>
                            <img id='footer-pic' src='/images/sakuraflower.jpg' alt='Sakura coaching logo' tabIndex={-1} height={100} />
                            <img src='/logo_white.svg' alt='Sakura coaching logo' tabIndex={-1} height={64} />
                        </div>
                    );
                }}
            >
                {/* <NavMenu selectedKey={selectedKey} items={groups} /> */}
                <Nav
                    className='sakura-layout-navigation-panel'
                    styles={(props) => ({ ...navStyles, chevronIcon: { transform: props.isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)' } })}
                    selectedKey={selectedKey}
                    groups={[{ links: groups ?? [] }]}
                />
            </Panel>
        </div>
    );
};
