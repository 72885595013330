import { IDropdownOption } from '@fluentui/react';
import { IImageEffect } from '../../../Services/SakuraApiClient';
import { DeepPartial } from '../../helpers/ObjectAndArray';

export type EffectKind = 'scaleOutShadow' | 'scaleOut' | 'shake';
export const imageEffectPredefined: Record<EffectKind | string, DeepPartial<IImageEffect>> = {
    scaleOutShadow: {
        onHover: {
            transform: 'scale(1.05)',
            backgroundColor: '#f1dee1',
            boxShadow: '0px 0px 5px 5px rgba(0, 0, 0, 0.25)',
        },
        onPressed: {
            transform: 'scale(1.05)',
            backgroundColor: '#f8d3d8',
            boxShadow: '0px 0px 5px 5px rgba(0, 0, 0, 0.5)',
        },
        onSelected: {
            transform: 'scale(1.05)',
            backgroundColor: '#f8d3d8',
            boxShadow: '0px 0px 5px 5px rgba(0, 0, 0, 0.5)',
        },
    },
    scaleOut: {
        onHover: {
            transform: 'scale(1.05)',
            backgroundColor: '#f1dee1',
        },
        onPressed: {
            transform: 'scale(1.05)',
            backgroundColor: '#f8d3d8',
        },
        onSelected: {
            transform: 'scale(1.05)',
            backgroundColor: '#f8d3d8',
        },
    },
    shake: {
        onHover: {
            animation: 'move-shaking 150ms normal 3',

            backgroundColor: '#f1dee1',
        },
        onPressed: {
            backgroundColor: '#f8d3d8',
        },
        onSelected: {
            backgroundColor: '#f8d3d8',
        },
    },
};

export const imageEffectPredefinedDropDown: IDropdownOption[] = [
    {
        key: '',
        text: 'Aucun effet',
    },
    {
        key: 'scaleOutShadow',
        text: 'Effet de zoom avec ombrage',
    },
    {
        key: 'scaleOut',
        text: 'Effet de zoom seul',
    },
    {
        key: 'shake',
        text: 'Effet de secousse',
    },
];
export const imageEffectShapeDropDown: IDropdownOption[] = [
    {
        key: '',
        text: 'Carré',
    },
    {
        key: 'Rounded',
        text: 'Carré arrondi',
    },
    {
        key: 'Circle',
        text: 'Cercle',
    },
    {
        key: 'Triangle',
        text: 'Triangle',
    },
];
