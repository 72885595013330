import { configureStore } from '@reduxjs/toolkit';
import systemReducer from './Reducers/System/reducer';
import userReducer from './Reducers/User/reducer';
import menuReducer from './Reducers/Menu/reducer';
import datasetReducer from './Reducers/DataSet/reducer';
import dynamicDataReducer from './Reducers/DynamiqueData/reducer';

export const store = configureStore({
    reducer: {
        system: systemReducer,
        user: userReducer,
        menu: menuReducer,
        dataset: datasetReducer,
        dynamicData: dynamicDataReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
