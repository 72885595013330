import { Label } from '@fluentui/react';
import { FC } from 'react';
import { useAppSelector } from '../../../Redux/hook';
import { userProfileSelector } from '../../../Redux/Reducers/User/reducer';
import './PetalCounter.scss';

interface PetalCounterProps {
    noTitle?: boolean;
}
export const PetalCounter: FC<PetalCounterProps> = (props: PetalCounterProps) => {
    const { noTitle } = props;
    const userProfile = useAppSelector(userProfileSelector);
    return (
        <div className='sakuraPetalCounter'>
            {!noTitle && <Label>Mes pétales</Label>}
            <div className='content'>
                <span>{userProfile?.sakuraCoin ?? 0}</span>
                <img src='/images/sakura_badge.svg' alt='Mes pétales' height={64} />
            </div>
        </div>
    );
};
