import { FC } from 'react';

import { IResultDescription } from '../../../../../Services/SakuraApiClient';
import { ShowResultDescription } from './ShowResultDescription';

export interface ShowCategoryProps {
    category: IResultDescription | undefined;
    immediate?: boolean;
}

export const ShowCategory: FC<ShowCategoryProps> = (props: ShowCategoryProps) => {
    const { category, immediate } = props;

    return category ? <ShowResultDescription description={category} immediate={immediate} /> : <div className='description' />;
};
