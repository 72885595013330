import { EntityForm } from '../../../common/Hooks/Form/useForm';
import { IProduct, ProductUpdateModel, ProductCreationModel, IResourceOfProduct, RelatedResource } from '../../../Services/SakuraApiClient';
import { performApiCall } from '../../../common/Hooks/useApiCall';
import { AppDispatch } from '../../../Redux/store';

export function saveProduct(
    form: EntityForm<IProduct>,
    product: IProduct | undefined,
    resources: IResourceOfProduct[] | undefined,
    appDispatch: AppDispatch,
    close: (product: IProduct) => void,
    contextId?: string,
) {
    const error = form.validate();
    if (!error) {
        performApiCall(
            async (client) => {
                if (form.state) {
                    let savedProduct;
                    if (product) {
                        const data = new ProductUpdateModel();
                        data.init(form.state);
                        savedProduct = await client.updateProduct(product.id, data);
                    } else {
                        const data = new ProductCreationModel();
                        data.init(form.state);
                        data.associatedResources = resources
                            ? resources.map((r) => {
                                  const rr = new RelatedResource();
                                  rr.init(r);
                                  return rr;
                              })
                            : undefined;
                        savedProduct = await client.createProduct(data);
                    }
                    form.commit();
                    close(savedProduct);
                }
            },
            appDispatch,
            { contextId },
        );
    }
}
