export type SocialNetworkName = 'facebook' | 'x' | 'instagram' | 'youtube' | 'pinterest' | 'tiktok' | 'threads' | 'whatsapp' | 'messenger' | 'email';
export type SocialNetworkNameShareable = 'facebook' | 'pinterest' | 'whatsapp' | 'x' | 'messenger' | 'email';

export interface SupportedProps {
    hashTag?: boolean;
    content?: boolean;
    subject?: boolean;
    media?: boolean;
}
export function supportedPropsForShare(name: SocialNetworkNameShareable | undefined): SupportedProps {
    switch (name) {
        case 'email':
            return { content: true, subject: true };
        case 'pinterest':
            return { media: true };
        case 'facebook':
            return { hashTag: true };
    }
    return {};
}
