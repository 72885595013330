import { IDropdownOption } from '@fluentui/react';
import { QuestionnaryKind } from '../../../../Services/SakuraApiClient';

export function getQuestionnaryKind(kind: QuestionnaryKind): string {
    switch (kind) {
        case QuestionnaryKind.Exam:
            return 'Test de connaissance';
        case QuestionnaryKind.Survey:
            return 'Sondage';
        case QuestionnaryKind.Interview:
            return 'Interview';
    }
    return '<TBD>';
}
export const QuestionnaryKinds: IDropdownOption[] = [
    {
        key: QuestionnaryKind.Exam,
        text: getQuestionnaryKind(QuestionnaryKind.Exam),
    },
    {
        key: QuestionnaryKind.Survey,
        text: getQuestionnaryKind(QuestionnaryKind.Survey),
    },
    {
        key: QuestionnaryKind.Interview,
        text: getQuestionnaryKind(QuestionnaryKind.Interview),
    },
];
