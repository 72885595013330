import { PrimaryButton } from '@fluentui/react';
import { FC } from 'react';
import { IResultDescription, IWorkflow } from '../../../../Services/SakuraApiClient';
import { DeepPartial } from '../../../../common/Hooks/Form/useForm';
import { ResourceLookupField } from '../../../../common/LookupFields/ResourceLookupField';
import { ExpressionField, ExpressionKind } from '../../../../common/components/ExpressionField/ExpressionField';
import { NumberField } from '../../../../common/components/NumberField/NumberField';
import { WorkflowConfigurator } from './WorkflowConfigurator';

export interface ResultDescriptionProps {
    item: DeepPartial<IResultDescription> | undefined;
    onUpdate: (item: DeepPartial<IResultDescription>) => void;
    onDelete: () => void;
    disableDelete: boolean;
}

export const ResultDescription: FC<ResultDescriptionProps> = (props: ResultDescriptionProps) => {
    const { item, onUpdate, onDelete, disableDelete } = props;
    return (
        <div className='resultDescription'>
            <PrimaryButton
                className='button'
                disabled={disableDelete}
                title='Effacer cette description'
                iconProps={{ iconName: 'Delete' }}
                onClick={() => {
                    onDelete();
                }}
            />
            <div className='resultDescriptionInfo'>
                <NumberField
                    label='Palier de score'
                    value={item?.threshold}
                    onChange={(threshold) => {
                        onUpdate({ threshold });
                    }}
                ></NumberField>
                <ExpressionField
                    className='title'
                    kind={ExpressionKind.TextWithExpression}
                    label='Titre'
                    value={item?.title}
                    onChange={(title) => {
                        onUpdate({ title });
                    }}
                ></ExpressionField>
                <ResourceLookupField
                    label='Image'
                    type='ResourceImageContentLookup'
                    selectedResourceId={item?.imageResourceId}
                    onSelectResource={(resource) => {
                        if (resource) {
                            onUpdate({ imageResourceId: resource.id });
                        } else {
                            onUpdate({ imageResourceId: undefined });
                        }
                    }}
                />
                <WorkflowConfigurator workflow={item?.workflow as IWorkflow} onChangeWorkflow={(workflow) => onUpdate({ workflow })} />
            </div>
            <ExpressionField
                label='Description'
                kind={ExpressionKind.TextWithExpression}
                multilines
                minVisibleLines={5}
                maxVisibleLines={10}
                value={item?.description}
                onChange={(description) => {
                    onUpdate({ description });
                }}
            ></ExpressionField>
        </div>
    );
};
