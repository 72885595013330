import { IFunction } from '../IFunction';
import { Value } from '../../Value';
import { IntelisenseInfo } from '../../../../../../Redux/Reducers/DynamiqueData/state';

export class ReplaceFunction implements IFunction {
    public get name(): string {
        return 'remplacer';
    }
    public get intelisenseInfo(): IntelisenseInfo {
        return {
            type: 'func',
            fullName: this.name,
            description: 'Replace toutes les occurences de la chaine B dans la chaine A par la chaine C.',
            parameters: [
                {
                    name: 'chaine A',
                    type: 'Chaine',
                    description: 'la chaine de caractere source',
                },
                {
                    name: 'chaine B',
                    type: 'Chaine',
                    description: 'la chaine de caractere a remplacer par la chaine C',
                },
                {
                    name: 'chaine C',
                    type: 'Chaine',
                    description: 'la chaine de caractere qui remplacera la chaine B',
                },
            ],
        };
    }
    public checkArguments(parameters: Value[]): string | null {
        if (parameters.length !== 1) {
            return 'Cette fonction prend un seul argument';
        }
        return null;
    }

    public invoke(parameters: Value[]): Value {
        let value = parameters[0];
        const replace = parameters[1];
        const replaceBy = parameters[2];

        value = Value.Transtype(value, 'string');
        const replaceStr = Value.Transtype(replace, 'string').getValue<string>() ?? '';
        const replaceByStr = Value.Transtype(replaceBy, 'string').getValue<string>() ?? '';

        if (typeof value.valueObject === 'string' && value.valueObject !== null) {
            value.valueObject = value.getValue<string>().replace(replaceStr, replaceByStr);
        }
        return value;
    }
}
