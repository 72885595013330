import { FC } from 'react';
import { IPublicResource } from '../../Services/SakuraApiClient';
import { DownloadLink } from '../../common/components/DownloadLink/DownloadLink';
import { ResourceImageViewer } from '../../common/components/ImageViewer/ResourceImageViewer';

export interface ResourceDocumentPageProps {
    resource: IPublicResource;
}

export const ResourceDocumentPage: FC<ResourceDocumentPageProps> = (props: ResourceDocumentPageProps) => {
    const { resource } = props;

    return (
        <>
            {resource.thumbnailBlobPath ? <ResourceImageViewer style={{ maxHeight: '40vh' }} blobPath={resource.thumbnailBlobPath}></ResourceImageViewer> : null}
            <DownloadLink
                display='Button'
                disabled={!resource.enabled}
                iconProps={{ iconName: 'Download' }}
                blobPath={resource.blob?.blobReferenceProtected ?? ''}
                downloadName={resource.blob?.metadata?.filename}
                blobPathKind='ProtectedValue'
                text='Télécharger mon fichier'
            />
        </>
    );
};
