import { FC } from 'react';
import { ApplicationLogo } from './ApplicationLogo';
import { LayoutNavigation, MenuEntry } from './LayoutNavigation';

import { useAppSelector } from '../../Redux/hook';
import { mobileViewSelector } from '../../Redux/Reducers/System/reducer';
import './LayoutHeader.scss';
import { LayoutUserProfile } from './LayoutUserProfile';

interface LayoutHeaderProps {
    side: 'left' | 'right';
    menu: MenuEntry[];
    rootPath: string;
}
export const LayoutHeader: FC<LayoutHeaderProps> = (props: LayoutHeaderProps) => {
    const { side, menu, rootPath } = props;
    const isMobileView = useAppSelector(mobileViewSelector);
    return (
        <>
            <div className='sakura-layout-logo'>{!isMobileView && <ApplicationLogo />}</div>
            <div className='sakura-layout-header'>
                {side === 'left' && (
                    <>
                        <LayoutNavigation side='left' menu={menu} rootPath={rootPath} />
                        {isMobileView && <ApplicationLogo />}
                        <LayoutUserProfile />
                    </>
                )}
                {side === 'right' && (
                    <>
                        <LayoutUserProfile />
                        {isMobileView && <ApplicationLogo />}
                        <LayoutNavigation side='right' menu={menu} rootPath={rootPath} />
                    </>
                )}
            </div>
        </>
    );
};
