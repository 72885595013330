import { DefaultButton, Pivot, PivotItem, PrimaryButton, TextField } from '@fluentui/react';
import { FC, useCallback, useEffect, useState } from 'react';
import { useLoadingSelector } from '../../Redux/Reducers/System/reducer';
import { updateProfile } from '../../Redux/Reducers/User/actionChain';
import { userProfileSelector } from '../../Redux/Reducers/User/reducer';
import { useAppDispatch, useAppSelector } from '../../Redux/hook';
import { Account, AccountData, AccountUpdatePayload, Avatar, IAccount } from '../../Services/SakuraApiClient';
import { performApiCall } from '../../common/Hooks/useApiCall';
import { BadgeCounter } from '../../common/components/BageCounter/BadgeCounter';
import { Panel } from '../../common/components/Panel/Panel';
import { PetalCounter } from '../../common/components/PetalCounter/PetalCounter';
import { ObjectOperation } from '../../common/helpers/ObjectAndArray';
import { SocialNetworkLogo } from '../Common/SocialNetworkLinks/SocialNetworkLogo';
import { AvatarEditor } from './AvatarEditor/AvatarEditor';
import { BadgeCollection } from './Badge/BadgeCollection';
import { PushNotificationSetting } from './PushNotificationSetting';

const DefaultAvatar = new Avatar({
    topType: 'LongHairBigHair',
    mouthType: 'Smile',
    eyeType: 'Happy',
    eyebrowType: 'Default',
    facialHairType: 'Blank',
    clotheType: 'Hoodie',
    accessoriesType: 'Blank',
    skinColor: 'Light',
    hairColor: 'Auburn',
    clotheColor: 'PastelBlue',
    facialHairColor: 'Black',
    graphicType: '',
});

let iosZoom10Timer: unknown = null;
let iosZoom1Timer: unknown = null;

function handleZoomOnIOS(e: any) {
    if (e.type === 'blur') {
        iosZoom1Timer = setTimeout(function () {
            document.querySelector('meta[name="viewport"]')?.setAttribute('content', 'width=device-width,initial-scale=1,maximum-scale=1');

            iosZoom10Timer = setTimeout(function () {
                document.querySelector('meta[name="viewport"]')?.setAttribute('content', 'width=device-width,initial-scale=1,maximum-scale=10');
            }, 100);
        }, 100);
    } else if (e.type === 'focus') {
        clearTimeout(iosZoom1Timer as number);
        clearTimeout(iosZoom10Timer as number);
    }
}

export const AccountPage: FC = () => {
    const dispatch = useAppDispatch();
    const loading = useLoadingSelector();
    const userProfile = useAppSelector(userProfileSelector);
    const [editProfile, setEditProfile] = useState<IAccount>({ ...(userProfile ?? new Account()) });

    useEffect(() => {
        setEditProfile({ ...(userProfile ?? new Account()) });
    }, [userProfile]);
    const onSave = useCallback(() => {
        performApiCall(async (client) => {
            const payload = new AccountUpdatePayload();
            payload.init({
                contactEmail: editProfile.contactEmail,
                faceBookId: editProfile.facebookId,
                firstName: editProfile.firstname,
                lastName: editProfile.lastname,
                pseudo: editProfile.pseudo,
                displayName: `${editProfile.firstname} ${editProfile.lastname}`,
            });
            const result = await client.updateMyAccount(payload);
            updateProfile(dispatch, { profile: result });
        }, dispatch);
    }, [dispatch, editProfile.contactEmail, editProfile.facebookId, editProfile.firstname, editProfile.lastname, editProfile.pseudo]);
    const onSaveAvatar = useCallback(() => {
        performApiCall(async (client) => {
            const payload = new AccountUpdatePayload();
            payload.init({
                avatar: editProfile.accountData?.avatar,
            });
            const result = await client.updateMyAccount(payload);
            updateProfile(dispatch, { profile: result });
        }, dispatch);
    }, [dispatch, editProfile.accountData?.avatar]);
    const onCancel = useCallback(() => {
        setEditProfile({ ...(userProfile ?? new Account()), accountData: editProfile.accountData });
    }, [editProfile.accountData, userProfile]);
    const onCancelAvatar = useCallback(() => {
        setEditProfile({
            ...editProfile,
            accountData: userProfile?.accountData ?? new AccountData(),
        });
    }, [editProfile, userProfile?.accountData]);
    const noChanged = ObjectOperation.equals(editProfile, userProfile, ['accountData', 'createdOn']);
    const noChangedAvatar = ObjectOperation.equals(editProfile.accountData?.avatar, userProfile?.accountData?.avatar);
    return (
        <div className='SakuraPanels'>
            <Panel
                title='Mes informations'
                icon='Contact'
                footer={
                    <>
                        <PrimaryButton text='Sauvegarder' disabled={noChanged || loading?.isLoading} onClick={() => onSave()} />
                        <DefaultButton text='Annuler' disabled={noChanged || loading?.isLoading} onClick={() => onCancel()} />
                    </>
                }
            >
                <div className='block'>
                    <TextField
                        disabled={loading?.isLoading}
                        onBlur={handleZoomOnIOS}
                        onFocus={handleZoomOnIOS}
                        required
                        label='Nom'
                        value={editProfile.lastname}
                        onChange={(ev, value) => setEditProfile({ ...editProfile, lastname: value ?? '' })}
                    />
                    <TextField
                        disabled={loading?.isLoading}
                        required
                        label='Prénom'
                        value={editProfile.firstname}
                        onChange={(ev, value) => setEditProfile({ ...editProfile, firstname: value ?? '' })}
                    />
                </div>
                <TextField disabled={loading?.isLoading} required iconProps={{ iconName: 'Permissions' }} label='Email de connexion' readOnly value={editProfile.signInEmail ?? ''} />
                <TextField disabled={loading?.isLoading} required iconProps={{ iconName: 'Mail' }} label='Email de contact' value={editProfile.contactEmail ?? ''} />
                <TextField
                    disabled={loading?.isLoading}
                    onRenderSuffix={(_, df) => {
                        return <SocialNetworkLogo socialNetwork='facebook' />;
                    }}
                    styles={{ suffix: { padding: '0 5px', background: 'none' } }}
                    label='Compte FaceBook'
                    value={editProfile.facebookId ?? ''}
                    onChange={(ev, value) => setEditProfile({ ...editProfile, facebookId: value })}
                />
                <TextField disabled={loading?.isLoading} label='Mon Pseudo' value={editProfile.pseudo ?? ''} onChange={(ev, value) => setEditProfile({ ...editProfile, pseudo: value })} />
            </Panel>
            <Panel
                title='Mon Avatar'
                icon='Color'
                removeBottomPadding
                footer={
                    <>
                        <PrimaryButton text='Sauvegarder' disabled={noChangedAvatar || loading?.isLoading} onClick={() => onSaveAvatar()} />
                        <DefaultButton text='Annuler' disabled={noChangedAvatar || loading?.isLoading} onClick={() => onCancelAvatar()} />
                    </>
                }
            >
                <AvatarEditor
                    avatar={editProfile?.accountData?.avatar ?? DefaultAvatar}
                    onChangeAvatar={(avatar) => {
                        const newAccountData = new AccountData();
                        const newAvatar = new Avatar();
                        newAvatar.init(avatar);
                        newAccountData.init({ ...editProfile.accountData, avatar: newAvatar });
                        setEditProfile({ ...editProfile, accountData: newAccountData });
                    }}
                />
            </Panel>
            <Panel title='Notifications' icon='Megaphone'>
                <PushNotificationSetting />
            </Panel>
            <Panel title='Progressions' icon='Trackers'>
                <div className='block wrap'>
                    <PetalCounter />
                    <BadgeCounter />
                </div>
            </Panel>

            <Panel title='Mon Abonnement' icon='Trackers'></Panel>

            <Panel title='Mes produits' icon='ProductCatalog'>
                <Pivot>
                    <PivotItem headerText='Formations' />
                    <PivotItem headerText='Jeux' />
                    <PivotItem headerText='Autres' />
                </Pivot>
            </Panel>
            <Panel title='Mes Badges' icon='Badge'>
                <BadgeCollection />
            </Panel>
        </div>
    );
};
