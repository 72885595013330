import { EnumBlockDirection, EnumItemsAlignement, PageElement, PageElementContainerStyle } from '../../../Services/SakuraApiClient';

export function getEditorFlexStylePerElement(element: PageElement, parentContainerStyle: PageElementContainerStyle | undefined): string | undefined {
    if (parentContainerStyle) {
        if (parentContainerStyle.direction === EnumBlockDirection.Column && parentContainerStyle.alignItems === EnumItemsAlignement.Stretch) {
            return '1';
        }
    }
    return undefined;
}
