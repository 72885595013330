import { Icon } from '@fluentui/react';
import clsx from 'clsx';
import { FC, PropsWithChildren, ReactNode, useState } from 'react';
import './FormGroup.scss';

import { OverflowCommands } from '../OverflowCommands/OverflowCommands';

export interface FormGroupCommand {
    text?: string;
    icon?: string;
    disabled?: boolean;
    onClick: () => void;
}
export interface FormGroupProps {
    icon?: string;
    title: string;
    footer?: ReactNode | undefined;
    removeBottomPadding?: boolean;
    noPadding?: boolean;
    disabled?: boolean;
    commands?: FormGroupCommand[];
    isCollapsable?: boolean;
    defaultOpenState?: boolean;
}

export const FormGroup: FC<PropsWithChildren<FormGroupProps>> = (props: PropsWithChildren<FormGroupProps>) => {
    const { title, icon, footer, children, removeBottomPadding, noPadding, disabled, commands, isCollapsable, defaultOpenState } = props;
    const [isOpenState, setIsOpenState] = useState<boolean>(defaultOpenState ?? true);
    return (
        <div className='SakuraFormGroup'>
            <div
                className={clsx('SakuraFormGroupHeader', disabled ? 'disabled' : '', isOpenState ? '' : 'collapsed', isCollapsable ? 'isCollapsable' : '')}
                role='button'
                onClick={() => {
                    setIsOpenState(!isOpenState);
                }}
            >
                <div className='content'>
                    {isCollapsable && <Icon className='accordion-indicator' iconName={isOpenState ? 'ChevronDown' : 'ChevronUp'} />}
                    {icon && <Icon iconName={icon} />}
                    <span>{title}</span>
                    <div className='SakuraFormGroupHeaderCommand'>
                        {commands && !disabled && (
                            <OverflowCommands
                                commandButtons={(commands ?? []).map((c) => ({
                                    ...c,
                                    key: `${c.icon ?? ''}${c.text ?? ''}`,
                                    onClick: () => {
                                        setIsOpenState(true);
                                        c.onClick();
                                    },
                                }))}
                            />
                        )}
                    </div>
                </div>
            </div>
            {isOpenState ? (
                <>
                    <div className={clsx(noPadding ? 'noPadding' : '', removeBottomPadding ? 'noBottomPadding' : '', 'SakuraFormGroupContent', disabled ? 'disabled' : '')}>{children}</div>
                    {footer && <div className='SakuraFormGroupFooter'>{footer}</div>}
                </>
            ) : (
                <></>
            )}
        </div>
    );
};
