import { CommandBarButton, IButtonStyles, IOverflowSetItemProps, Icon, Label, OverflowSet } from '@fluentui/react';
import clsx from 'clsx';
import { FC, useMemo, useState } from 'react';
import { MenuEntryType } from '../../../../Services/SakuraApiClient';
import { DeepPartial, FormError } from '../../../../common/Hooks/Form/useForm';
import { ConfirmationDialog } from '../../../../common/components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { generateUniqueId } from '../../../../common/helpers/uniqueId';
import { MenuHierarchyItemDnd } from './MenuHierarchyItemDnd';
import { MenuHierarchyAction } from './formMenuHierarchySlicer';
import { MenuSettingEntry } from './menuSetting';

const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
    if (item.onRender) {
        return item.onRender(item);
    }
    return <CommandBarButton iconProps={{ iconName: item.icon }} menuProps={item.subMenuProps} text={item.name} />;
};

const onRenderOverflowButton = (overflowItems: IOverflowSetItemProps[] | undefined): JSX.Element => {
    const buttonStyles: Partial<IButtonStyles> = {
        root: {
            minWidth: 0,
            padding: '0 4px',
            alignSelf: 'stretch',
            height: 'auto',
        },
    };
    return <CommandBarButton ariaLabel='More items' styles={buttonStyles} menuIconProps={{ iconName: 'MoreVertical' }} menuProps={{ items: overflowItems ?? [] }} />;
};

export interface MenuHierarchyItemProps {
    root: boolean;
    errors: FormError<MenuSettingEntry> | undefined;
    item: DeepPartial<MenuSettingEntry>;
    updateForm: (action: MenuHierarchyAction) => void;
    selectedId: string | undefined;
    onSelect: (selectedId: string | undefined) => void;
}

export const MenuHierarchyItem: FC<MenuHierarchyItemProps> = (props: MenuHierarchyItemProps) => {
    const { item, updateForm, selectedId, onSelect, errors, root } = props;
    const [showDeleteItemDialog, setShowDeleteItemDialog] = useState<boolean>(false);

    const isSelected = useMemo(() => {
        return selectedId === item.id;
    }, [selectedId, item]);
    const menuItems = [
        {
            key: 'AddChild',
            text: 'Ajouter un sous-menu',
            onClick: () => {
                const id = generateUniqueId(`${item.id}/`);
                updateForm({
                    type: 'addItem',
                    payload: {
                        idPath: item.id ?? '',
                        item: {
                            id,
                            children: [],
                        },
                    },
                });
            },
        },
        {
            key: 'Del',
            text: 'Supprimer cet élément',
            iconProps: { iconName: 'Delete' },
            onClick: () => {
                setShowDeleteItemDialog(true);
            },
        },
    ];
    const hasError = useMemo(() => {
        if (errors) {
            const keys = Object.keys(errors);
            for (let i = 0; i < keys.length; i++) {
                if (keys[i] !== 'children') {
                    const val = (errors as Record<string, unknown>)[keys[i]];
                    if (val && (typeof val === 'string' || val instanceof String)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }, [errors]);
    return (
        <MenuHierarchyItemDnd
            entry={item}
            orientation='Vertical'
            onDropItem={(item, destination, position) => {
                updateForm({
                    type: 'MoveItem',
                    payload: {
                        item,
                        destination,
                        position: position === 'Start' ? 'before' : position === 'End' ? 'after' : 'inside',
                    },
                });
            }}
        >
            <div
                className={clsx('menuHierarchyItem', root ? '' : 'shift')}
                onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    onSelect(item.id);
                }}
            >
                <div className={clsx('header', isSelected ? 'selected' : null)}>
                    <div>
                        <Icon iconName={(item?.children?.length ?? 0) > 0 || item.type === MenuEntryType.ListOf ? 'ChevronDown' : item.icon} />
                        <Label color={hasError ? 'red' : undefined}>{item.title ?? '[Sans nom]'}</Label>
                        {hasError ? (
                            <div className='errorFlag'>
                                <Icon iconName='StatusErrorFull' style={{ color: 'red' }} title='il y a des erreurs pour cet element' />
                            </div>
                        ) : null}
                    </div>
                    <div className='menu'>
                        <OverflowSet overflowItems={menuItems} onRenderItem={onRenderItem} onRenderOverflowButton={onRenderOverflowButton} />

                        <ConfirmationDialog
                            id='ConfirmationDialogDeleteItem'
                            show={showDeleteItemDialog}
                            icon={{ iconName: 'GlobalNavButton' }}
                            title='Suppression'
                            subText={`Etes-vous sur de vouloir supprimer cette entrée dans le menu?`}
                            onReply={(reply) => {
                                if (reply && item.id) {
                                    updateForm({ type: 'deleteItem', payload: { idPath: item.id } });
                                }
                                setShowDeleteItemDialog(false);
                            }}
                        />
                    </div>
                </div>

                {(item.children ?? []).map((child, index) => {
                    return (
                        <MenuHierarchyItem
                            root={false}
                            selectedId={selectedId}
                            onSelect={onSelect}
                            errors={errors?.children ? errors.children[index] : undefined}
                            key={child.id}
                            item={child}
                            updateForm={updateForm}
                        />
                    );
                })}
            </div>
        </MenuHierarchyItemDnd>
    );
};
