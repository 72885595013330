export function copyClipboard(text: string) {
    navigator.clipboard.writeText(text).then(
        () => {
            /* clipboard successfully set */
        },
        () => {
            /* clipboard write failed */
        },
    );
}
