import { Separator } from '@fluentui/react';

import { FC } from 'react';
import { EnumItemsAlignement } from '../../../../../Services/SakuraApiClient';
import { CssBorderInput } from '../../../../../common/components/Css/CssBorderInput';
import { CssBoxShadow } from '../../../../../common/components/Css/CssBoxShadow';
import { CssRectInput } from '../../../../../common/components/Css/CssRectInput';
import { Alignement } from './Alignment';

export interface StyleCommon {
    border: string | undefined;
    padding: string | undefined;
    margin: string | undefined;
    borderRadius: string | undefined;
    boxShadow: string | undefined;
    alignSelf: EnumItemsAlignement | undefined;
}
export interface CssCommonFieldProps {
    data: StyleCommon | undefined;
    onChange: (data: Partial<StyleCommon>) => void;
}

export const CssCommonField: FC<CssCommonFieldProps> = (props: CssCommonFieldProps) => {
    const { data, onChange } = props;
    return (
        <>
            <Separator />
            <Alignement
                alignSelf={data?.alignSelf}
                onChangeData={(alignSelf) => {
                    onChange({ alignSelf });
                }}
            />
            <CssRectInput label='Marge' title='Marge exterieure' value={data?.margin} onChange={(val) => onChange({ margin: val })} />
            <CssRectInput label='Padding' title='Marge interieure' value={data?.padding} onChange={(val) => onChange({ padding: val })} />
            <CssBorderInput label='Bordure' title='Bordure du conteneur' value={data?.border} onChange={(val) => onChange({ border: val })}></CssBorderInput>
            <CssRectInput label='Bordure arrondi' cornerMode title='Bordure arrondi' value={data?.borderRadius} onChange={(val) => onChange({ borderRadius: val })} />
            <CssBoxShadow label='Box Shadow' value={data?.boxShadow} onChangeValue={(val) => onChange({ boxShadow: val })} />
        </>
    );
};
