import { DatePicker } from '@fluentui/react';
import { FC, useEffect } from 'react';
import { displayDate } from '../../../../../common/helpers/DateTimeHelper';
import { performApiCall } from '../../../../../common/Hooks/useApiCall';
import { useState2 } from '../../../../../common/Hooks/useState2';
import { ProductLookupField } from '../../../../../common/LookupFields/ProductLookupField';
import { useAppDispatch } from '../../../../../Redux/hook';
import { IProduct, IResourceRef } from '../../../../../Services/SakuraApiClient';

export interface ProductReferencePickerProps {
    label: string;
    resourceRef: IResourceRef | undefined;
    onChange: (resourceRef: Partial<IResourceRef> | undefined) => void;
}
export const ProductReferencePicker: FC<ProductReferencePickerProps> = (props: ProductReferencePickerProps) => {
    const { label, resourceRef, onChange } = props;
    const [product, setProduct, productRef] = useState2<IProduct>();
    const appDispatch = useAppDispatch();
    useEffect(() => {
        if ((resourceRef?.productId && productRef.current === undefined) || productRef.current?.id !== resourceRef?.productId) {
            performApiCall(async (client, cancelToken) => {
                if (resourceRef?.productId) {
                    const p = await client.getProduct(resourceRef?.productId, cancelToken);
                    setProduct(p);
                }
            }, appDispatch);
        }
    }, [appDispatch, productRef, resourceRef?.productId, setProduct]);
    return (
        <>
            <ProductLookupField
                label={label}
                selectedProduct={product}
                onSelectProduct={(p) => {
                    setProduct(p);
                    onChange({ ...resourceRef, productId: p?.id });
                }}
            />
            <DatePicker label='expire le' formatDate={displayDate} value={resourceRef?.expiredOn} onSelectDate={(v) => onChange({ ...resourceRef, expiredOn: v === null ? undefined : v })} />
        </>
    );
};
