import { Icon } from '@fluentui/react';
import { FC } from 'react';

export interface IconStatusProps {
    status: boolean | undefined;
}

export const IconStatus: FC<IconStatusProps> = (props: IconStatusProps) => {
    const { status } = props;
    return (
        <Icon
            iconName={status !== undefined ? (status ? 'StatusCircleCheckmark' : 'StatusCircleErrorX') : 'StatusCircleQuestionMark'}
            style={{ color: status !== undefined ? (status ? 'green' : 'red') : 'gray', fontSize: 'xx-large' }}
        />
    );
};
