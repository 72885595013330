import { Label } from '@fluentui/react';
import { FC } from 'react';
import './BadgeCounter.scss';

interface BadgeCounterProps {
    noTitle?: boolean;
}
export const BadgeCounter: FC<BadgeCounterProps> = (props: BadgeCounterProps) => {
    const { noTitle } = props;
    return (
        <div className='sakuraBadgeCounter'>
            {!noTitle && <Label>Mes Badges</Label>}
            <div className='content'>
                <span></span>
                <img src='/images/medaille.svg' alt='Mes badges' height={55} />
            </div>
        </div>
    );
};
