import { IDropdownOption } from '@fluentui/react';
import { FC, useEffect, useState } from 'react';
import { ActivityLogExternalServiceGrid } from '../../../Redux/Reducers/DataSet/ApiCall/Log';
import { EnumSystem, IExternalSystemLog } from '../../../Services/SakuraApiClient';
import { INavigationState, useNav } from '../../../common/Hooks/useNav';
import { FilterType } from '../../../common/components/CustomDetailList/FilteredHeaderColumn';
import { SimpleDialog } from '../../../common/components/Dialog/SimpleDialog/SimpleDialog';
import { IconStatus } from '../../../common/components/Icons/IconStatus';
import { Panel } from '../../../common/components/Panel/Panel';
import { DatasetGrid } from '../../Common/DatasetGrid';

interface NavState {
    refresh?: boolean;
}

export const systemLabels: Record<string, string> = {
    Sakura: 'Sakura',
    Brevo: 'Brevo',
    SystemIO: 'SystemIO',
    Stripe: 'Stripe',
};
const systemOptions: IDropdownOption[] = [
    {
        key: EnumSystem.Sakura,
        text: systemLabels[EnumSystem.Sakura.toString()],
    },
    {
        key: EnumSystem.Brevo,
        text: systemLabels[EnumSystem.Brevo.toString()],
    },
    {
        key: EnumSystem.SystemIO,
        text: systemLabels[EnumSystem.SystemIO.toString()],
    },
    {
        key: EnumSystem.Stripe,
        text: systemLabels[EnumSystem.Stripe.toString()],
    },
];

const statusOptions: IDropdownOption[] = [
    {
        key: 'S',
        text: 'Succés',
    },
    {
        key: 'F',
        text: 'Echéc',
    },
];
export const ExternalSystemLogsList: FC = () => {
    const nav = useNav();

    const navState = nav.current.state as INavigationState<NavState>;
    const [messageView, setMessageView] = useState<string>();
    useEffect(() => {
        if (navState?.state?.refresh) {
            ActivityLogExternalServiceGrid.refresh();
        }
    }, [navState?.state?.refresh]);

    const columns = [
        {
            key: 'SystemSrc',
            name: 'System source',
            minWidth: 150,
            maxWidth: 150,
            allowSorting: true,
            isResizable: true,
            fieldName: 'systemSrc',
            filterOption: {
                type: FilterType.Enum,
                placeholder: 'System source',
                options: systemOptions,
                fieldNames: [
                    {
                        fieldName: 'systemSrc',
                        displayName: 'System source',
                    },
                ],
            },
            onRender: (item: IExternalSystemLog) => {
                return <span>{item.systemSrc ? systemLabels[item.systemSrc] : ''}</span>;
            },
        },
        {
            key: 'SystemDst',
            name: 'System cible',
            minWidth: 150,
            maxWidth: 150,
            allowSorting: true,
            isResizable: true,
            fieldName: 'systemDst',
            filterOption: {
                type: FilterType.Enum,
                placeholder: 'System cible',
                options: systemOptions,
                fieldNames: [
                    {
                        fieldName: 'systemDst',
                        displayName: 'System cible',
                    },
                ],
            },
            onRender: (item: IExternalSystemLog) => {
                return <span>{item.systemDst ? systemLabels[item.systemDst] : ''}</span>;
            },
        },
        {
            key: 'eventType',
            name: "Type d'evenement",
            fieldName: 'eventType',
            minWidth: 175,
            maxWidth: 175,
            allowSorting: true,
            isResizable: true,
            filterOption: {
                type: FilterType.Text,
                placeholder: "Type d'evenement",
                fieldNames: [
                    {
                        fieldName: 'eventType',
                        displayName: "Type d'evenement",
                    },
                ],
            },
        },
        {
            key: 'status',
            name: 'Statut',
            fieldName: 'status',
            minWidth: 120,
            maxWidth: 120,
            allowSorting: true,
            isResizable: true,
            filterOption: {
                type: FilterType.Enum,
                placeholder: 'Status',
                options: statusOptions,
                fieldNames: [
                    {
                        fieldName: 'status',
                        displayName: 'Status',
                    },
                ],
            },
            onRender: (item: IExternalSystemLog) => {
                let status = undefined;
                let label = '';
                switch (item.status) {
                    case 'S':
                        status = true;
                        label = 'Succés';
                        break;
                    case 'F':
                        status = false;
                        label = 'Echéc';
                        break;
                }
                return (
                    <>
                        <IconStatus status={status} />
                        <span>{label}</span>
                    </>
                );
            },
        },
        {
            key: 'error',
            name: 'Message',
            fieldName: 'error',
            minWidth: 175,
            maxWidth: 175,
            allowSorting: true,
            isResizable: true,
            onRender: (item: IExternalSystemLog) => {
                return (
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setMessageView(item.error);
                        }}
                    >
                        <span>{item.error}</span>
                    </div>
                );
            },
        },
        {
            key: 'urlData',
            name: 'url',
            fieldName: 'urlData',
            minWidth: 250,
            maxWidth: 350,
            allowSorting: true,
            isResizable: true,
            filterOption: {
                type: FilterType.Text,
                placeholder: 'Url',
                fieldNames: [
                    {
                        fieldName: 'urlData',
                        displayName: 'Url contient',
                    },
                ],
            },
            onRender: (item: IExternalSystemLog) => {
                return (
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setMessageView(item.urlData);
                        }}
                    >
                        <span>{item.urlData}</span>
                    </div>
                );
            },
        },
        {
            key: 'jsonData',
            name: 'json data',
            fieldName: 'jsonData',
            minWidth: 250,
            maxWidth: 350,
            allowSorting: true,
            isResizable: true,
            filterOption: {
                type: FilterType.Text,
                placeholder: 'Json',
                fieldNames: [
                    {
                        fieldName: 'jsonData',
                        displayName: 'Json Data contient',
                    },
                ],
            },
            onRender: (item: IExternalSystemLog) => {
                let json: string | undefined = undefined;
                if (item.jsonData) {
                    try {
                        json = JSON.stringify(JSON.parse(item.jsonData), null, '\t');
                    } catch {}
                }
                return (
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setMessageView(json);
                        }}
                    >
                        <span>{item.jsonData}</span>
                    </div>
                );
            },
        },
        {
            key: 'CreatedOn',
            name: 'Créer le',
            fieldName: 'createdOn',
            minWidth: 150,
            maxWidth: 150,
            allowSorting: true,
            isResizable: true,
            filterOption: {
                type: FilterType.Date,
                placeholder: 'Date',
                fieldNames: [
                    {
                        fieldName: 'createdOn_0',
                        displayName: 'Depuis',
                    },
                    {
                        fieldName: 'createdOn_1',
                        displayName: "Jusqu'a",
                    },
                ],
            },
            onRender: (item: IExternalSystemLog) => {
                return <span>{item.createdOn.toLocaleString()}</span>;
            },
        },
        {
            key: 'updatedOn',
            name: 'Modifié le',
            fieldName: 'updatedOn',
            minWidth: 150,
            maxWidth: 150,
            allowSorting: true,
            isResizable: true,

            onRender: (item: IExternalSystemLog) => {
                return <span>{item.updatedOn?.toLocaleString()}</span>;
            },
        },
    ];
    return (
        <Panel
            title="Log d'échange avec les systems externes"
            icon='Table'
            commands={[
                {
                    icon: 'Refresh',
                    text: 'Actualiser',
                    onClick: () => {
                        ActivityLogExternalServiceGrid.refresh();
                    },
                },
            ]}
        >
            <DatasetGrid datasetName={'ActivityLogExternalServiceGrid'} columns={columns} />
            <SimpleDialog
                show={messageView !== undefined}
                onClose={() => {
                    setMessageView(undefined);
                }}
                id={'messageView'}
                title={'Détails'}
            >
                <div style={{ whiteSpace: 'pre-wrap' }}>{messageView}</div>
            </SimpleDialog>
        </Panel>
    );
};
