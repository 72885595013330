import { ExpressionCombiner } from './ExpressionCombiner';
import { CombinerContext, ExpressionItem } from './ExpressionItem';
import { Value } from './Value';

export enum LogicalEnum {
    And = 'And',
    Or = 'Or',
}

export class ExpressionLogic extends ExpressionCombiner {
    logical: LogicalEnum;

    constructor(logic: string) {
        super(logic);
        this.logical = logic === 'et' || logic === 'and' || logic === '&&' ? LogicalEnum.And : LogicalEnum.Or;
    }

    public resolve(): ExpressionItem {
        return this.combine((previousValue, nextValue) => {
            const left = previousValue.asBoolean();
            const right = nextValue.asBoolean();
            let result = false;

            if (this.logical === LogicalEnum.Or) {
                result = left || right;
            }

            if (this.logical === LogicalEnum.And) {
                result = left && right;
            }

            return new Value({
                type: 'boolean',
                valueObject: result,
            });
        }, CombinerContext.Logical);
    }

    public canReturnValue(): boolean {
        return false;
    }

    public getValue(): Value {
        throw new Error(`Cannot call GetValue of the Logical operator ${this.logical.toString()}`);
    }

    public isGreedy(context: string): boolean {
        return false;
    }
}
