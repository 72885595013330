import {
    BaseStyle,
    CheckFieldType,
    EnumBlockDirection,
    EnumDisplayMode,
    EnumItemsAlignement,
    EnumJustifyContent,
    IPageElement,
    NumberFieldType,
    PageElement,
    PageElementAudio,
    PageElementButton,
    PageElementButtonStyle,
    PageElementCheckField,
    PageElementContainer,
    PageElementContainerStyle,
    PageElementCustomComponent,
    PageElementDatetimeField,
    PageElementImage,
    PageElementImageStyle,
    PageElementLabel,
    PageElementLink,
    PageElementNumberField,
    PageElementPDF,
    PageElementPDFStyle,
    PageElementQuestionnary,
    PageElementRichText,
    PageElementSeparator,
    PageElementTextField,
    PageElementTextStyle,
    PageElementType,
    PageElementVideo,
    PageElementVideoStyle,
} from '../../../../../Services/SakuraApiClient';
import { CustomComponentName } from '../CustomComponent/type';

export function createNewElement(type: PageElementType, subType?: CustomComponentName | undefined, existingData?: IPageElement | undefined) {
    let newElement: PageElement | undefined = undefined;
    switch (type) {
        case PageElementType.Audio:
            newElement = new PageElementAudio();
            newElement.type = PageElementType.Audio;
            newElement.init({ type });
            break;
        case PageElementType.Button:
            newElement = new PageElementButton();
            newElement.type = PageElementType.Button;
            newElement.init({ type, style: new PageElementButtonStyle(), value: '' });
            break;
        case PageElementType.Container:
            newElement = new PageElementContainer();
            newElement.type = PageElementType.Container;
            const style = new PageElementContainerStyle();
            style.init({
                display: EnumDisplayMode.Flex,
                direction: EnumBlockDirection.Column,
                alignItems: EnumItemsAlignement.Start,
                alignContent: EnumJustifyContent.Start,
                justifyContent: EnumJustifyContent.Start,
                gridTemplates: '',
            });

            newElement.init({
                type,
                items: [],
                style,
            });
            break;
        case PageElementType.Image:
            newElement = new PageElementImage();
            newElement.init({ type, style: new PageElementImageStyle(), toolTip: '' });
            break;
        case PageElementType.TextField:
            newElement = new PageElementTextField();
            newElement.init({ type, assignValueTo: '', label: '', style: new PageElementTextStyle(), multiline: false });
            break;
        case PageElementType.NumberField:
            newElement = new PageElementNumberField();
            newElement.init({ type, assignValueTo: '', label: '', style: new PageElementTextStyle(), fieldType: NumberFieldType.Decimal });
            break;
        case PageElementType.DatetimeField:
            newElement = new PageElementDatetimeField();
            newElement.init({ type, assignValueTo: '', label: '', style: new PageElementTextStyle(), dateOnly: true });
            break;
        case PageElementType.CheckField:
            newElement = new PageElementCheckField();
            newElement.init({ type, assignValueTo: '', label: '', style: new PageElementTextStyle(), fieldType: CheckFieldType.CheckBox });
            break;
        case PageElementType.Label:
            newElement = new PageElementLabel();
            newElement.init({ type, value: '[text]', style: new PageElementTextStyle(), toolTip: '' });
            break;
        case PageElementType.RichText:
            newElement = new PageElementRichText();
            newElement.init({ type });
            break;
        case PageElementType.Link:
            newElement = new PageElementLink();
            newElement.init({ type, style: new PageElementTextStyle(), href: '', toolTip: '', value: '[link]' });
            break;
        case PageElementType.Video:
            newElement = new PageElementVideo();
            newElement.init({ type, style: new PageElementVideoStyle() });
            break;
        case PageElementType.Questionnary:
            newElement = new PageElementQuestionnary();
            newElement.init({ type, style: new BaseStyle() });
            break;
        case PageElementType.PDF:
            newElement = new PageElementPDF();
            newElement.init({ type, style: new PageElementPDFStyle() });
            break;
        case PageElementType.Separator:
            newElement = new PageElementSeparator();
            newElement.type = PageElementType.Separator;
            break;
        case PageElementType.CustomComponent:
            newElement = new PageElementCustomComponent();
            newElement.init({ name: subType, type: PageElementType.CustomComponent });
            break;
        default:
            alert(`type not supported, createNewElement is missing for ${type}`);
            break;
    }
    if (existingData && newElement) {
        newElement.init(existingData);
    }
    return newElement;
}
