import { FC, useMemo } from 'react';
import { fontStylesSelector } from '../../../../../Redux/Reducers/System/reducer';
import { useAppSelector } from '../../../../../Redux/hook';
import { IFontStyle } from '../../../../../Services/SakuraApiClient';
import { FontConfiguration, FontStyleData } from '../../../../../common/components/FontStyles/FontConfiguration';
import { FontStylePicker } from '../../../../../common/components/FontStyles/FontStylePicker';

import './common.scss';

export interface FontConfigurationProps {
    font: IFontStyle | undefined;
    onChangeFont: (data: Partial<IFontStyle>) => void;
}

export const FontStyle: FC<FontConfigurationProps> = (props: FontConfigurationProps) => {
    const { onChangeFont, font } = props;
    const fontStyles = useAppSelector(fontStylesSelector);

    const fontStyleData = useMemo((): FontStyleData => {
        if (font?.presetName) {
            const entry = fontStyles.entries?.filter((e) => e.entryName === font.presetName)[0];
            if (entry) {
                return {
                    color: entry?.color,
                    font: entry.font,
                    strike: entry.strike ?? false,
                    underline: entry.underline ?? false,
                };
            }
        }
        return {
            color: font?.color,
            font: font?.font,
            strike: font?.strike ?? false,
            underline: font?.underline ?? false,
        };
    }, [font, fontStyles]);
    return (
        <div className='panelfontstyle'>
            <FontStylePicker
                label='Style de police'
                selectedFontStyleName={font?.presetName ?? ''}
                onSelect={(presetName) => {
                    onChangeFont({ presetName: presetName === '' ? undefined : presetName, color: undefined, font: undefined, strike: undefined, underline: undefined });
                }}
            />
            <FontConfiguration
                font={fontStyleData}
                onChangeFont={function (data: Partial<FontStyleData>): void {
                    onChangeFont({ presetName: undefined, ...data });
                }}
            />
        </div>
    );
};
