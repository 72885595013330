import { Label } from '@fluentui/react';
import { FC } from 'react';
import { SelectableIcon } from '../../../../../common/components/Icons/SelectableIcon';
import { EnumTextAlignement } from '../../../../../Services/SakuraApiClient';

export interface TextAlignProps {
    label: string;
    value: EnumTextAlignement;
    onChange: (value: EnumTextAlignement) => void;
}

export const TextAlign: FC<TextAlignProps> = (props: TextAlignProps) => {
    const { value, onChange, label } = props;
    return (
        <>
            <Label>{label}</Label>
            <div className='DivFlexHorizontalCenter'>
                <SelectableIcon
                    iconName='AlignLeft'
                    selected={value === EnumTextAlignement.Start}
                    onChange={(selected) => {
                        if (selected) {
                            onChange(EnumTextAlignement.Start);
                        }
                    }}
                />
                <SelectableIcon
                    iconName='AlignCenter'
                    selected={value === EnumTextAlignement.Center}
                    onChange={(selected) => {
                        if (selected) {
                            onChange(EnumTextAlignement.Center);
                        }
                    }}
                />
                <SelectableIcon
                    iconName='AlignRight'
                    selected={value === EnumTextAlignement.End}
                    onChange={(selected) => {
                        if (selected) {
                            onChange(EnumTextAlignement.End);
                        }
                    }}
                />
                <SelectableIcon
                    iconName='AlignJustify'
                    selected={value === EnumTextAlignement.Justify}
                    onChange={(selected) => {
                        if (selected) {
                            onChange(EnumTextAlignement.Justify);
                        }
                    }}
                />
            </div>
        </>
    );
};
