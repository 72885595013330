import { IObjectWithKey, Selection } from '@fluentui/react';
import { FC, useRef, useState } from 'react';
import { addBadgeToDisplay } from '../../../Redux/Reducers/User/reducer';
import { useAppDispatch, useAppSelector } from '../../../Redux/hook';
import { Badge, IBadge } from '../../../Services/SakuraApiClient';
import { ST } from '../../../common/Hooks/StorageResolver';
import { performApiCall } from '../../../common/Hooks/useApiCall';
import { BadgeViewer } from '../../../common/components/Badge/BadgeViewer';
import { Panel } from '../../../common/components/Panel/Panel';
import { BadgeDetails, badgeTypes } from './BadgesDetail';

import { BadgeGrid } from '../../../Redux/Reducers/DataSet/ApiCall/Badge';
import { storageEndpointsSelector } from '../../../Redux/Reducers/System/reducer';
import { getBadgeTypeLabel } from '../../../common/components/Badge/helper';
import { ColumnInfo, FilterType } from '../../../common/components/CustomDetailList/FilteredHeaderColumn';
import { DatasetGrid } from '../../Common/DatasetGrid';
import './BadgesList.scss';

export const BadgesList: FC = () => {
    const dispatch = useAppDispatch();
    const storage = useAppSelector(storageEndpointsSelector);

    const [selectedBadge, setSelectedBadge] = useState<IBadge>();
    const [createBadge, setCreateBadge] = useState<boolean>();
    const [updateBadge, setUpdateBadge] = useState<IBadge>();

    const createSelection = () => {
        return new Selection({
            onSelectionChanged: () => {
                const selected = selection.current.getSelection();
                if (selected.length > 0) {
                    setSelectedBadge(selected[0] as Badge);
                } else {
                    setSelectedBadge(undefined);
                }
            },
            getKey: (item: IObjectWithKey) => (item as Badge).id,
        });
    };
    const onDeleteBadge = (badge: IBadge) => {
        performApiCall(async (client) => {
            await client.deleteBadge(badge.id);
            BadgeGrid.refresh();
            //setBadges(badges.filter((b) => b.id !== badge.id));
        }, dispatch);
    };
    const selection = useRef(createSelection());

    const onSelect = (badge: IBadge | undefined) => {
        setSelectedBadge(badge);
    };

    const columns: ColumnInfo[] = [
        {
            key: 'Visual',
            name: 'Visuel',
            fieldName: 'id',
            minWidth: 50,
            maxWidth: 50,
            allowSorting: false,
            isResizable: true,
            onRender: (item: IBadge) => {
                return (
                    <div className='badgeInGrid'>
                        <BadgeViewer width={40} height={40} url={ST(storage, item.imageBlobPath)} type={item.badgeType} graphics={item.badgeSetting.graphics} />
                    </div>
                );
            },
        },
        {
            key: 'type',
            name: 'Type',
            fieldName: 'badgeType',
            minWidth: 100,
            maxWidth: 100,
            allowSorting: true,
            isResizable: true,
            filterOption: {
                type: FilterType.Enum,
                placeholder: 'Choisir un type de badge',
                options: badgeTypes,
                fieldNames: [
                    {
                        fieldName: 'badgeType',
                        displayName: 'Type de page',
                    },
                ],
            },
            onRender: (item: IBadge) => {
                return <span>{getBadgeTypeLabel(item.badgeType)}</span>;
            },
        },
        {
            key: 'name',
            name: 'Nom',
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 300,
            allowSorting: true,
            isResizable: true,
            filterOption: {
                type: FilterType.Text,
                placeholder: 'entrer un nom',
                fieldNames: [
                    {
                        fieldName: 'name',
                        displayName: 'Nom',
                    },
                ],
            },
        },

        {
            key: 'description',
            name: 'Description',
            fieldName: 'description',
            minWidth: 200,
            maxWidth: 400,
            allowSorting: true,
            isResizable: true,
        },
    ];
    return (
        <div className='SakuraPanelsGrid'>
            <Panel
                title='Liste des badges'
                icon='Badge'
                commands={[
                    {
                        icon: 'AddTo',
                        text: 'Ajouter',
                        onClick: () => {
                            setCreateBadge(true);
                        },
                    },
                    {
                        icon: 'Edit',
                        text: 'Modifier',
                        disabled: !selectedBadge,
                        onClick: () => {
                            if (selectedBadge) {
                                setUpdateBadge(selectedBadge);
                            }
                        },
                    },
                    {
                        icon: 'Play',
                        text: 'Tester',
                        disabled: !selectedBadge,
                        onClick: () => {
                            if (selectedBadge) {
                                dispatch(addBadgeToDisplay({ badge: selectedBadge }));
                            }
                        },
                    },
                    {
                        icon: 'Delete',
                        text: 'Supprimer',
                        disabled: !selectedBadge,
                        onClick: () => {
                            if (selectedBadge) {
                                onDeleteBadge(selectedBadge);
                            }
                        },
                    },
                    {
                        icon: 'Refresh',
                        text: 'Actualiser',
                        onClick: () => {
                            BadgeGrid.refresh();
                        },
                    },
                ]}
            >
                <DatasetGrid
                    datasetName={'BadgeGrid'}
                    columns={columns}
                    getKey={(item) => item.id ?? ''}
                    onSelect={onSelect}
                    onRowDoubleClick={(item) => {
                        setSelectedBadge(item);
                        setUpdateBadge(item);
                    }}
                />
            </Panel>

            {createBadge && (
                <BadgeDetails
                    title='Créer un Badge'
                    onClose={(newBadge) => {
                        if (newBadge) {
                            BadgeGrid.refresh();
                        }
                        setCreateBadge(false);
                    }}
                />
            )}
            {updateBadge && (
                <BadgeDetails
                    title='Modification du Badge'
                    badge={updateBadge}
                    onClose={(updatedBadge) => {
                        if (updatedBadge) {
                            BadgeGrid.PatchData((item: IBadge) => {
                                if (item.id === updatedBadge.id) {
                                    return updatedBadge;
                                }
                                return item;
                            });
                        }
                        setUpdateBadge(undefined);
                    }}
                />
            )}
        </div>
    );
};
