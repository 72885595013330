import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector } from '../../hook';
import type { RootState } from '../../store';
import { ClearDataAction, SetDataAction } from './action';
import { DataSetName } from './DataSetName';
import { defaultDataSetState, initialState } from './state';

const dataSetSlice = createSlice({
    name: 'dataset',
    initialState,
    reducers: {
        SetData: (state, action: PayloadAction<SetDataAction>) => {
            const dataSetState = state.data[action.payload.dataSetName] ?? defaultDataSetState;
            state.data[action.payload.dataSetName] = { ...dataSetState, ...action.payload.data };
        },
        ClearData: (state, action: PayloadAction<ClearDataAction>) => {
            const dataSetState = state.data[action.payload.dataSetName] ?? defaultDataSetState;
            state.data[action.payload.dataSetName] = dataSetState;
        },
    },
});

//Export Actions
export const { SetData, ClearData } = dataSetSlice.actions;
//Export Selector
export const useDataSetSelector = (dataSet: DataSetName) => {
    return useAppSelector((state: RootState) => state.dataset.data[dataSet]);
};
export const useDataSetsSelector = () => {
    return useAppSelector((state: RootState) => state.dataset.data);
};

//export Reducer
export default dataSetSlice.reducer;
