import { Icon, IPersonaSharedProps, Persona, PersonaSize, Spinner } from '@fluentui/react';

import { FC, lazy, Suspense } from 'react';
import { IAccount } from '../../../Services/SakuraApiClient';
import { isNullOrEmpty } from '../../helpers/string';

import './SakuraPersona.scss';
import { SakuraPersonaStatus } from './SakuraPersonaStatus';

const Avatar = lazy(() => import('avataaars'));

interface SakuraPersonaProps {
    userProfile: IAccount | undefined;
    isMobileView: boolean;
    onclick: () => void;
}
export const SakuraPersona: FC<SakuraPersonaProps> = (props: SakuraPersonaProps) => {
    const { userProfile, isMobileView, onclick } = props;

    const avatar = userProfile?.accountData.avatar;
    return (
        <>
            {avatar ? (
                <div style={{ display: 'flex', flexDirection: 'row' }} onClick={() => onclick()}>
                    {!isMobileView && (
                        <div className='SakuraPersona-Details'>
                            <div className='SakuraPersona-PrimaryText'>
                                <div role='none'>
                                    {!isNullOrEmpty(userProfile.pseudo)
                                        ? userProfile.pseudo
                                        : !isNullOrEmpty(userProfile.displayName)
                                          ? userProfile.displayName
                                          : `${userProfile?.firstname ?? ''} ${userProfile?.lastname ?? ''}`}
                                </div>
                            </div>
                            <div className='SakuraPersona-SecondaryText'>
                                <div role='none'>{userProfile?.signInEmail}</div>
                            </div>
                        </div>
                    )}
                    <Suspense fallback={<Spinner label='Chargement...' />}>
                        <div id='personaButton'>
                            <Avatar
                                style={{ width: 50, height: 50 }}
                                avatarStyle='Circle'
                                topType={avatar?.topType}
                                accessoriesType={avatar?.accessoriesType}
                                hairColor={avatar?.hairColor}
                                facialHairType={avatar?.facialHairType}
                                facialHairColor={avatar?.facialHairColor}
                                clotheType={avatar?.clotheType}
                                clotheColor={avatar?.clotheColor}
                                eyeType={avatar?.eyeType}
                                eyebrowType={avatar?.eyebrowType}
                                mouthType={avatar?.mouthType}
                                skinColor={avatar?.skinColor}
                            ></Avatar>
                        </div>
                    </Suspense>
                    <SakuraPersonaStatus userProfile={userProfile} />
                </div>
            ) : (
                <Persona
                    hidePersonaDetails={isMobileView}
                    size={PersonaSize.size40}
                    id='personaButton'
                    onClick={() => onclick()}
                    onRenderInitials={(props?: IPersonaSharedProps, defaultRender?: (props?: IPersonaSharedProps) => JSX.Element | null): JSX.Element | null => {
                        return <Icon iconName='ContactHeart' className='sakura-editProfile' />;
                    }}
                    text={`${userProfile?.firstname ?? ''} ${userProfile?.lastname ?? ''}`}
                    secondaryText={userProfile?.signInEmail}
                    initialsColor={'#49c1c5'}
                />
            )}
        </>
    );
};
