import { FC, useEffect } from 'react';
import { IResource, Resource, ResourceTypeEnum } from '../../../Services/SakuraApiClient';

import { Dropdown, IDropdownOption, MessageBarType, TextField, Toggle } from '@fluentui/react';
import { setMessage } from '../../../Redux/Reducers/System/reducer';
import { useAppDispatch } from '../../../Redux/hook';
import { useForm } from '../../../common/Hooks/Form';
import * as validators from '../../../common/Hooks/Form/validators';
import { Container, blobPathToString, getAlternativePath, parseBlobPath } from '../../../common/Hooks/StorageResolver';
import { performApiCall } from '../../../common/Hooks/useApiCall';
import { ResourceLookupField } from '../../../common/LookupFields/ResourceLookupField';
import { EditDialog } from '../../../common/components/Dialog/EditDialog/EditDialog';
import { FilePicker } from '../../../common/components/FilePicker';
import { GetResourceTypeEntityTitle, GetResourceTypeFileExtensions, GetResourceTypeIcon } from '../../../common/components/Icons/ResourceTypeIcon';

export interface ResourceFileDetailsProps {
    resource?: IResource;
    type: ResourceTypeEnum;
    show: boolean;
    container: Container;
    contentDisposition: 'attachment' | 'inline';
    showOptionEnabled?: boolean;
    showThumbnail?: boolean;
    categoryOptions?: IDropdownOption[];
    category?: string;
    blobnameResolver: (resource: IResource, filename: string) => string;
    onClose: (resource: IResource | undefined) => void;
    allowAlternativesFiles?: Alternative[];
}
export interface Alternative {
    suffix: string;
    label: string;
}
const getNewResource = (category?: string) => {
    const newResource = new Resource();
    newResource.init({ enabled: true, category });
    return newResource;
};

interface FormModel {
    currentFile: File;
    alternativeFiles: Record<string, File>;
    resource: IResource;
}

export const ResourceFileDetails: FC<ResourceFileDetailsProps> = (props: ResourceFileDetailsProps) => {
    const { resource, show, onClose, blobnameResolver, container, contentDisposition, type, showOptionEnabled, showThumbnail, categoryOptions, category, allowAlternativesFiles } = props;
    const contextId = `Edit${type.toString()}`;

    const form = useForm<FormModel>(
        {
            initialState: { currentFile: undefined, resource: resource ?? getNewResource(category) },
            validators: {
                fields: {
                    currentFile: {
                        name: { displayName: 'Un fichier', validators: resource === undefined ? [validators.required] : [] },
                    },
                    resource: {
                        name: { displayName: 'Le nom', validators: [validators.required] },
                    },
                },
                global: (model) => {
                    const errors = [];
                    let fileExt = '';
                    if (model.currentFile?.name) {
                        fileExt = model.currentFile?.name.substring(model.currentFile?.name.lastIndexOf('.'));
                    }
                    if (model.resource?.blobPath) {
                        const blob = parseBlobPath(model.resource?.blobPath);
                        if (blob?.path) {
                            fileExt = blob.path.substring(blob.path.lastIndexOf('.'));
                        }
                    }

                    if (model.alternativeFiles) {
                        const keys = Object.keys(model.alternativeFiles);
                        for (let i = 0; i < keys.length; i++) {
                            const file = model.alternativeFiles[keys[i]];
                            if (file?.name) {
                                const fileExtension = file.name.substring(file.name.lastIndexOf('.'));
                                if (fileExtension !== fileExt) {
                                    errors.push(`Le fichier '${file.name}' alternative doit avoir la meme extensions que le fichier principal. Extension attendu: ${fileExt}`);
                                }
                            }
                        }
                    }
                    return errors;
                },
            },
        },
        resource !== undefined,
    );

    const appDispatch = useAppDispatch();

    const close = (res: IResource | undefined) => {
        form.clearErrors();
        onClose(res);
    };
    useEffect(() => {
        const message = form.globalErrors ? form.globalErrors.join('\n') : undefined;
        appDispatch(setMessage({ contextId, message: message ? { text: message, Severity: MessageBarType.blocked } : undefined }));
    }, [form.globalErrors, appDispatch, contextId]);

    const onSubmit = () => {
        const error = form.validate();
        if (!error) {
            performApiCall(
                async (client) => {
                    if (form.state.resource) {
                        const data = new Resource();
                        data.init(form.state.resource);
                        if (form.state.currentFile) {
                            const blobName = blobnameResolver(data, form.state.currentFile.name ?? '');
                            let blobref = null;
                            blobref = await client.uploadBlob(container.toString(), blobName, form.state.currentFile.type, contentDisposition, undefined, {
                                data: form.state.currentFile,
                                fileName: form.state.currentFile?.name ?? '',
                            });
                            data.resourceType = type;
                            data.blobPath = blobref.name;
                        }
                        if (form.state.alternativeFiles) {
                            const blob = parseBlobPath(data.blobPath);
                            if (blob) {
                                const existingPath = blob.path;
                                const existingFilename = existingPath.substring(existingPath.indexOf('/') + 1);
                                const extension = existingFilename.substring(existingFilename.lastIndexOf('.'));

                                const keys = Object.keys(form.state.alternativeFiles);
                                for (let i = 0; i < keys.length; i++) {
                                    const blobName = getAlternativePath(existingFilename, keys[i]);
                                    const file = form.state.alternativeFiles[keys[i]];
                                    if (file?.name) {
                                        const fileExtension = file.name.substring(file.name.lastIndexOf('.'));

                                        if (extension !== fileExtension) {
                                            return;
                                        }
                                        await client.uploadBlob(container.toString(), blobName, file?.type, contentDisposition, undefined, {
                                            data: file,
                                            fileName: file?.name ?? '',
                                        });
                                        if (!blob.alternatives) {
                                            blob.alternatives = [];
                                        }
                                        if (blob.alternatives.findIndex((s) => s === keys[i]) === -1) {
                                            blob.alternatives.push(keys[i]);
                                        }
                                    }
                                }

                                data.blobPath = blobPathToString(blob);
                            }
                        }
                        let savedResource;
                        if (resource) {
                            savedResource = await client.updateResource(resource.id, data);
                        } else {
                            data.enabled = true;
                            data.reusable = false;
                            savedResource = await client.createResource(data);
                        }
                        form.commit();
                        close(savedResource);
                    }
                },
                appDispatch,
                { contextId },
            );
        }
    };
    const onSelectFile = (file: File | null | undefined) => {
        if (!form.state.resource?.name && file) {
            form.update({ resource: { name: file?.name }, currentFile: file });
        } else {
            form.update({ currentFile: file ?? undefined });
        }
    };
    const onSelectFileAlternative = (alt: Alternative, file: File | null | undefined) => {
        form.update({ alternativeFiles: { [alt.suffix]: file ?? undefined } });
    };

    return (
        <EditDialog
            id={contextId}
            icon={{ iconName: GetResourceTypeIcon(type) }}
            mode={resource ? 'Edit' : 'Create'}
            title={GetResourceTypeEntityTitle(type, resource ? 'edit' : 'new')}
            subText='Selectionner un fichier depuis votre ordinateur'
            show={show}
            onSubmit={onSubmit}
            onClose={() => close(undefined)}
        >
            <div>
                <FilePicker
                    label='Fichier'
                    errorMessage={form.fieldErrors.currentFile?.name}
                    required={resource === undefined}
                    extension={GetResourceTypeFileExtensions(type)}
                    onChange={(file) => onSelectFile(file)}
                />
                {allowAlternativesFiles?.map((alt) => {
                    return <FilePicker key={`Alternative_${alt.suffix}`} label={alt.label} extension={GetResourceTypeFileExtensions(type)} onChange={(file) => onSelectFileAlternative(alt, file)} />;
                })}
                <TextField label='Nom' errorMessage={form.fieldErrors.resource?.name} required value={form.state.resource?.name ?? ''} onChange={(_, v) => form.update({ resource: { name: v } })} />
                {categoryOptions ? (
                    <Dropdown
                        label='Category'
                        options={categoryOptions}
                        selectedKey={form.state.resource?.category}
                        onChange={(_, v) => form.update({ resource: { category: v?.key?.toString() } })}
                    ></Dropdown>
                ) : (
                    <TextField label='Category' value={form.state.resource?.category ?? ''} onChange={(_, v) => form.update({ resource: { category: v } })} />
                )}
                <TextField label='Description' value={form.state.resource?.description ?? ''} onChange={(_, v) => form.update({ resource: { description: v } })} />
                {showThumbnail && (
                    <ResourceLookupField
                        type='ResourceImageVignetteLookup'
                        label='Vignette'
                        selectedResourceId={form.state.resource?.thumbnailResourceId}
                        onSelectResource={(resource) => form.update({ resource: { thumbnailResourceId: resource?.id } })}
                    />
                )}
                {resource && showOptionEnabled && (
                    <Toggle label='Actif' offText='Non' onText='Oui' checked={form.state.resource?.enabled} onChange={(_, val) => form.update({ resource: { enabled: val } })} />
                )}
            </div>
        </EditDialog>
    );
};
