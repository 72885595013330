import { FC, useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { IAccountDataResource, IResource, IResourceDataPage } from '../../../../Services/SakuraApiClient';
import { ExecutionDataContextProvider } from '../../Page/ExecutionContext/ExecutionDataContextProvider';
import { IResourceInfo } from '../../Page/ExecutionContext/IExecutionContextState';
import { EditorDataContextProvider } from '../EditorContext/EditorDataContextProvider';
import { PageElementEdition } from '../PageElementEdition';

export interface PreviewTemplateProps {
    resource: IResource | undefined;
    parentResourcePathId: string | undefined;
    innerResources: Record<number, IResourceInfo>;
    resourceAccountData: IAccountDataResource | undefined;
}

export const PreviewTemplate: FC<PreviewTemplateProps> = (props: PreviewTemplateProps) => {
    const { resource, innerResources, parentResourcePathId } = props;

    const pageData = resource?.data.content.page as IResourceDataPage;
    const noEdtiPreview = useCallback(() => {
        // no edit in preview
    }, []);
    return (
        <div style={{ pointerEvents: 'none' }} className='pageEditorStudio'>
            <DndProvider backend={HTML5Backend}>
                <ExecutionDataContextProvider
                    key={parentResourcePathId ?? resource?.id}
                    pageResourceId={resource?.id}
                    parentResourcePathId={parentResourcePathId}
                    resources={innerResources}
                    serializationIdentifier={undefined}
                >
                    <EditorDataContextProvider>
                        <PageElementEdition index={0} data={pageData.page} onChangeData={noEdtiPreview} />
                    </EditorDataContextProvider>
                </ExecutionDataContextProvider>
            </DndProvider>
        </div>
    );
};
