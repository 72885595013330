import { FC, useMemo } from 'react';

import './Amount.scss';

export interface AmountProps {
    amount: number | undefined;
    variation?: boolean;
}
export const Amount: FC<AmountProps> = (props: AmountProps) => {
    const { amount, variation } = props;
    const value = amount ?? 0;

    const color = useMemo(() => {
        if (amount) {
            if (amount > 0) {
                return 'green';
            }
            if (amount < 0) {
                return 'red';
            }
        }
        return undefined;
    }, [amount]);

    return (
        <div className='amount'>
            <span style={{ color }}>{`${variation ? (value > 0 ? '+' : '') : ''}${amount}`}</span>
        </div>
    );
};
