import { Dropdown } from '@fluentui/react';
import { FC } from 'react';
import { DeepPartial } from '../../../../../common/helpers/ObjectAndArray';
import { IPageElementImage } from '../../../../../Services/SakuraApiClient';

import { imageEffectPredefinedDropDown, imageEffectShapeDropDown } from '../../../../../common/components/ImageViewer/ImageEffectPreDefined';

export interface ImageEffectPanelProps {
    data: IPageElementImage;
    onChangeData: (data: DeepPartial<IPageElementImage>) => void;
}

export const ImageEffectPanel: FC<ImageEffectPanelProps> = (props: ImageEffectPanelProps) => {
    const { onChangeData, data } = props;

    return (
        <>
            <Dropdown
                label='Effet Visuel'
                title="Effet visuel utiliser pour les questions avec réponse a base d'image"
                options={imageEffectPredefinedDropDown}
                selectedKey={data.style?.effect?.preDefined ?? ''}
                onChange={(_, option) => {
                    onChangeData({
                        style: {
                            effect: {
                                ...data.style?.effect,
                                preDefined: option?.key === undefined || option?.key === '' ? undefined : (option.key as string),
                            },
                        },
                    });
                }}
            />
            <Dropdown
                label='Forme'
                title="Forme de l'image"
                options={imageEffectShapeDropDown}
                selectedKey={data.style?.effect?.shape ?? ''}
                onChange={(_, option) => {
                    onChangeData({
                        style: {
                            effect: {
                                ...data.style?.effect,
                                shape: option?.key === undefined || option?.key === '' ? undefined : (option.key as string),
                            },
                        },
                    });
                }}
            />
        </>
    );
};
