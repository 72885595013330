import { Label } from '@fluentui/react';
import React, { FC, useState } from 'react';
import { ImageViewer2 } from './ImageViewer2';

import './ButtonImageViewer.scss';

export interface ImageViewerContentProps {
    url: string | undefined;
}

interface ImageInfo {
    width: number;
    height: number;
}
export const ImageViewerContent: FC<ImageViewerContentProps> = (props: ImageViewerContentProps) => {
    const { url } = props;

    const [imageInfo, setImageInfo] = useState<ImageInfo>();

    const onImgLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        setImageInfo({ width: event.currentTarget.naturalWidth, height: event.currentTarget.naturalHeight });
    };

    return (
        <>
            {' '}
            {imageInfo ? (
                <>
                    <Label>Dimensions:</Label>
                    <span>{`Largeur: ${imageInfo?.width} px, Hauteur: ${imageInfo?.height} px`}</span>
                </>
            ) : (
                <></>
            )}
            <ImageViewer2 url={url} width={`${imageInfo?.width ?? 0}px`} height={`${imageInfo?.height ?? 0}px`} className={'imageView'} onLoad={onImgLoad} />
        </>
    );
};
