import { ProgressIndicator } from '@fluentui/react';
import { FC } from 'react';

interface LoadingScreenProps {
    text: string;
}
const logoWidth = 370;
const loadingWidth = 350;
export const LoadingScreen: FC<LoadingScreenProps> = (props: LoadingScreenProps) => {
    const { text } = props;
    return (
        <div className='sakuraFullScreen'>
            <img src='/Logo_application.svg' alt='Sakura coaching logo' className='applicationLogo entrance' tabIndex={-1} width={logoWidth} />
            <ProgressIndicator
                className='entranceLoader'
                styles={{
                    root: { width: loadingWidth, marginTop: 15 },
                    progressBar: { borderRadius: 10 },
                    itemProgress: { padding: '0', margin: '8px 0px', borderRadius: 10 },
                    progressTrack: { borderRadius: 10 },
                }}
                barHeight={10}
            />
            <span className='entranceLoader' style={{ color: 'gray' }}>
                {text}
            </span>
        </div>
    );
};
