import { ProgressIndicator } from '@fluentui/react';
import { FC } from 'react';
import { acquiredCoinSelector, acquiredDiamondSelector, commitCoin, commitDiamond, xpRequiredSelector } from '../../../Redux/Reducers/User/reducer';
import { useAppDispatch, useAppSelector } from '../../../Redux/hook';
import { IAccount } from '../../../Services/SakuraApiClient';
import { AnimatedCounter } from '../AnimateCounter';

interface SakuraPersonaStatusProps {
    userProfile: IAccount | undefined;
}
export const SakuraPersonaStatus: FC<SakuraPersonaStatusProps> = (props: SakuraPersonaStatusProps) => {
    const { userProfile } = props;

    const dispatch = useAppDispatch();
    const xpRequired = useAppSelector(xpRequiredSelector);
    const acquiredCoin = useAppSelector(acquiredCoinSelector);
    const acquiredDiamond = useAppSelector(acquiredDiamondSelector);
    return (
        <div className='SakuraPersonaStatus'>
            <div className='xpBlock'>
                <span>{'Lv'}</span>
                <span>{userProfile?.level ?? 0}</span>

                <ProgressIndicator barHeight={16} ariaValueText={`Point d'experience acquis ${userProfile?.experience ?? 0}`} percentComplete={(userProfile?.experience ?? 0) / xpRequired} />
            </div>
            <div className='block'>
                <div className='subBlock'>
                    <AnimatedCounter
                        charMax={6}
                        value={userProfile?.sakuraCoin ?? 0}
                        valueChange={acquiredCoin}
                        duration={2000}
                        onChangeValue={(newTotal) => {
                            dispatch(commitCoin(newTotal));
                        }}
                    />
                    <img src='/images/sakura-flower.svg' title='Pétale de Sakura' alt='Sakura Pétale' tabIndex={-1} height={25} />
                </div>
                <div className='subBlock'>
                    <AnimatedCounter
                        charMax={4}
                        value={userProfile?.sakuraDiamond ?? 0}
                        valueChange={acquiredDiamond}
                        duration={2000}
                        onChangeValue={(newTotal) => {
                            dispatch(commitDiamond(newTotal));
                        }}
                    />
                    <img src='/images/blue-diamond.svg' title='Diamant' alt='Sakura Diamond' tabIndex={-1} height={22} />
                </div>
            </div>
        </div>
    );
};
