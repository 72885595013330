import { EntityState, IResourceHierarchy } from '../../../../Services/SakuraApiClient';
import { DeepPartialWithArrayFunc } from '../../../../common/Hooks/Form/useForm';
import { DeepPartial, ObjectOperation } from '../../../../common/helpers/ObjectAndArray';
import { IResourceInfo } from '../../../Common/Page/ExecutionContext/IExecutionContextState';

export function buildInnerResourcePackages(item: IResourceHierarchy) {
    const innerResourcePackages = {};
    internalBuildInnerResourcePackages(item, innerResourcePackages);
    return innerResourcePackages;
}

function internalBuildInnerResourcePackages(item: IResourceHierarchy, innerResourcePackages: Record<number, IResourceInfo>) {
    if (item.innerResourcePackages) {
        for (const prop in item.innerResourcePackages) {
            const id = Number.parseInt(prop, 10);
            if (!innerResourcePackages[id]) {
                innerResourcePackages[id] = item.innerResourcePackages[id];
            }
        }
    }
    if (item.children) {
        item.children.forEach((child) => {
            internalBuildInnerResourcePackages(child, innerResourcePackages);
        });
    }
}
export function getParentPath(path: string | undefined) {
    if (path) {
        const index = path.lastIndexOf('/');
        const firstPart = index !== -1 ? path.substring(0, index) : path;
        return firstPart;
    }
    return undefined;
}
export function getIdPart(path: string | undefined) {
    if (path) {
        const index = path.lastIndexOf('/');
        const lastPart = path.substring(index + 1);
        return lastPart;
    }
    return undefined;
}
export function getItemByPath(path: string, item: IResourceHierarchy): IResourceHierarchy | undefined {
    const index = path.indexOf('/');

    const firstPart = index !== -1 ? path.substring(0, index) : path;
    const remainPart = index !== -1 ? path.substring(index + 1) : undefined;
    if (item.resourcePath?.endsWith(firstPart)) {
        if (remainPart) {
            return getItemByPath(remainPart, item);
        }
        return item;
    }
    const child = item.children?.find((c) => c.resourcePath && c.resourcePath.endsWith(firstPart));
    if (child && remainPart) {
        return getItemByPath(remainPart, child);
    }
    return child;
}
export function checkModuleContainsResource(pathModule: string, itemId: number, root: IResourceHierarchy) {
    const parentItem = getItemByPath(pathModule, root);
    if (parentItem?.children) {
        return parentItem.children.findIndex((i) => i.resource.id === itemId) !== -1;
    }
    return false;
}

export function updateAtResourcePath(resourcePath: string, value: DeepPartialWithArrayFunc<IResourceHierarchy>): DeepPartialWithArrayFunc<IResourceHierarchy> {
    const parts = resourcePath.split('/');
    if (parts.length === 1) {
        return value;
    }
    return updateArrayAtResourcePath(resourcePath, (currentArray, index) => {
        currentArray[index] = ObjectOperation.merge(currentArray[index], value);
        return undefined;
    });
}

export function updateArrayAtResourcePath(
    resourcePath: string,
    callback: (array: DeepPartialWithArrayFunc<IResourceHierarchy>[], index: number) => DeepPartialWithArrayFunc<IResourceHierarchy>[] | undefined,
): DeepPartialWithArrayFunc<IResourceHierarchy> {
    let updater: DeepPartialWithArrayFunc<IResourceHierarchy> = {};
    const parts = resourcePath.split('/');
    if (parts.length === 1) {
        return {};
    }

    updater = {
        children: (array) => {
            let newArray = [...array];
            newArray = updateArrayAtResourcePathInternal(newArray, parts, callback);

            return newArray;
        },
    };
    return updater;
}

export function updateArrayAtMultipleResourcePath(
    updates: {
        resourcePath: string;
        callback: (array: DeepPartialWithArrayFunc<IResourceHierarchy>[], index: number) => DeepPartialWithArrayFunc<IResourceHierarchy>[] | undefined;
    }[],
): DeepPartialWithArrayFunc<IResourceHierarchy> {
    let updater: DeepPartialWithArrayFunc<IResourceHierarchy> = {};

    updater = {
        children: (array) => {
            let newArray = [...array];
            for (let i = 0; i < updates.length; i++) {
                const parts = updates[i].resourcePath.split('/');
                if (parts.length === 1) {
                    continue;
                }
                newArray = updateArrayAtResourcePathInternal(newArray, parts, updates[i].callback);
            }
            return newArray;
        },
    };
    return updater;
}

function updateArrayAtResourcePathInternal(
    array: DeepPartialWithArrayFunc<IResourceHierarchy>[],
    parts: string[],
    callback: (array: DeepPartialWithArrayFunc<IResourceHierarchy>[], index: number) => DeepPartialWithArrayFunc<IResourceHierarchy>[] | undefined,
) {
    let rootArray = array;
    let currentArray = rootArray;
    let previousArray = undefined;
    let previousIndex = undefined;
    for (let i = 1; i < parts.length; i++) {
        const index = currentArray.findIndex((res) => res.resourcePath?.endsWith(parts[i]));
        if (index === -1) {
            break;
        }
        if (i === parts.length - 1) {
            const updatedArray = callback(currentArray, index);
            if (updatedArray && previousArray && previousIndex !== undefined) {
                previousArray[previousIndex] = ObjectOperation.merge(previousArray[previousIndex], {
                    children: updatedArray,
                });
            } else if (updatedArray) {
                rootArray = updatedArray as DeepPartialWithArrayFunc<IResourceHierarchy>[];
            }
            break;
        } else {
            if (currentArray[index].children) {
                currentArray[index] = ObjectOperation.merge(currentArray[index], {
                    children: [...(currentArray[index].children as unknown as IResourceHierarchy[])],
                });
                previousArray = currentArray;
                previousIndex = index;
                currentArray = currentArray[index].children as unknown as IResourceHierarchy[];
            }
        }
    }
    return rootArray;
}

export function updateSharedResource(resourcePath: string, value: DeepPartialWithArrayFunc<IResourceHierarchy>): DeepPartialWithArrayFunc<IResourceHierarchy> {
    let updater: DeepPartialWithArrayFunc<IResourceHierarchy> = {};
    const parts = resourcePath.split('/');
    const resourceId = parts[parts.length - 1];
    updater = {
        children: (array) => {
            return updateSharedResourceInternal(array, resourceId, value) ?? array;
        },
    };
    return updater;
}
function updateSharedResourceInternal(array: DeepPartialWithArrayFunc<IResourceHierarchy>[], resourceId: string, value: DeepPartialWithArrayFunc<IResourceHierarchy>) {
    let newArray: DeepPartialWithArrayFunc<IResourceHierarchy>[] | undefined = undefined;
    for (let i = 0; i < array.length; i++) {
        if (array[i].resourcePath?.endsWith(resourceId)) {
            if (!newArray) {
                newArray = [...array];
            }
            newArray[i] = ObjectOperation.merge(array[i], value);
        }
        if (array[i]?.children !== undefined) {
            const newChildArray = updateSharedResourceInternal(array[i].children as DeepPartialWithArrayFunc<IResourceHierarchy>[], resourceId, value);
            if (newChildArray) {
                if (!newArray) {
                    newArray = [...array];
                }
                newArray[i] = ObjectOperation.merge(array[i], {
                    children: newChildArray,
                });
            }
        }
    }
    return newArray;
}

export function getItemById(root: DeepPartial<IResourceHierarchy>, resourceId: string): DeepPartial<IResourceHierarchy> | undefined {
    if (root.resourcePath?.endsWith(resourceId)) {
        return root;
    }
    if (root.children !== undefined) {
        const foundItem = internalGetItemById(root.children as DeepPartial<IResourceHierarchy>[], resourceId);
        if (foundItem) {
            return foundItem;
        }
    }
    return undefined;
}

function internalGetItemById(array: DeepPartial<IResourceHierarchy>[], resourceId: string): DeepPartial<IResourceHierarchy> | undefined {
    for (let i = 0; i < array.length; i++) {
        if (array[i].resourcePath?.endsWith(resourceId)) {
            return array[i];
        }
        if (array[i]?.children !== undefined) {
            const foundItem = internalGetItemById(array[i].children as DeepPartial<IResourceHierarchy>[], resourceId);
            if (foundItem) {
                return foundItem;
            }
        }
    }
    return undefined;
}

export function reAssignOrderIndex(array: DeepPartialWithArrayFunc<IResourceHierarchy>[]) {
    return array.map((item, index) => {
        if (item.orderIndex !== index) {
            let linkState = item.linkState;
            if (item.linkState === EntityState.Unchange) {
                linkState = EntityState.Update;
            }
            return { ...item, orderIndex: index, linkState };
        }
        return item;
    });
}

export function updateResourcePathOnChildren(children: IResourceHierarchy[] | undefined, parentPath: string | undefined) {
    if (children) {
        const copyChildren = [...children];
        for (let i = 0; i < copyChildren.length; i++) {
            const id = getIdPart(copyChildren[i].resourcePath);
            copyChildren[i].resourcePath = `${parentPath}/${id}`;
            const newChildren = updateResourcePathOnChildren(copyChildren[i].children, copyChildren[i].resourcePath);
            if (newChildren) {
                copyChildren[i].children = newChildren;
            }
        }
        return copyChildren;
    }
    return undefined;
}

export function deleteCascade(children: IResourceHierarchy[] | undefined) {
    if (children && children.length > 0) {
        const childrenCopy = [...children];
        for (let i = 0; i < childrenCopy.length; i++) {
            if (childrenCopy[i].resourceState !== EntityState.Create) {
                childrenCopy[i].linkState = EntityState.Delete;
                if (!childrenCopy[i].resource?.reusable) {
                    childrenCopy[i].resourceState = EntityState.Delete;
                    childrenCopy[i].children = deleteCascade(childrenCopy[i].children);
                }
            }
        }
    }
    return children;
}
