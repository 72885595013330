import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RoleEnum } from '../../../Services/SakuraApiClient';
import type { RootState } from '../../store';
import { InitializeMenuAction } from './action';
import { buildUserMenu } from './buildUserMenu';
import { adminMenu } from './MenuDeclaration';
import { initialState } from './state';

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        InitializeMenu: (state, action: PayloadAction<InitializeMenuAction>) => {
            const isAdmin = action.payload.userAccount.userRole === RoleEnum.Admin;
            state.applicationMenu = [...buildUserMenu(action.payload.userMenu), ...(isAdmin ? adminMenu : [])];
        },
    },
});

//Export Actions
export const { InitializeMenu } = menuSlice.actions;
//Export Selector
export const applicationMenuSelector = (state: RootState) => state.menu.applicationMenu;
//export Reducer
export default menuSlice.reducer;
