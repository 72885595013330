import { FC } from 'react';
import { OfferLookup } from '../../Redux/Reducers/DataSet/ApiCall/Offer';
import { IOffer } from '../../Services/SakuraApiClient';
import { DatasetLookupField } from './DatasetLookupField';

export interface OfferLookupFieldProps {
    selectedOffer: IOffer | undefined;
    onSelectOffer: (offer: IOffer | undefined) => void;
    label?: string;
    readOnly?: boolean;
    required?: boolean;
    contextId?: string;
}
export const OfferLookupField: FC<OfferLookupFieldProps> = (props: OfferLookupFieldProps) => {
    const { label, selectedOffer, onSelectOffer, readOnly, required, contextId } = props;

    return (
        <DatasetLookupField
            label={label}
            apiCall={OfferLookup}
            accessKeyProp={(i) => i.id.toString()}
            accessTextProp={(i) => i.name ?? ''}
            selectedItem={selectedOffer}
            onSelectItem={(item) => onSelectOffer(item)}
            readOnly={readOnly}
            required={required}
            contextId={contextId}
        />
    );
};
