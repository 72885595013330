import { DataScope } from '../../../../../Redux/Reducers/DynamiqueData/state';
import { IAccountDataResource, IResourceData } from '../../../../../Services/SakuraApiClient';
import { ValueTyping } from '../../../../../common/components/ExpressionField/Expression';
import { buildPageScope } from './buildPageScope';
import { buildQuizScope } from './buildQuizScope';

export const scopeBuilder: Record<DataScope, (resourceData: IResourceData, resourceAccountData: IAccountDataResource | undefined, loadDefinition: boolean) => Record<string, ValueTyping>> = {
    quiz: buildQuizScope,
    page: buildPageScope,
    data: function (resourceData: IResourceData, resourceAccountData: IAccountDataResource | undefined, loadDefinition: boolean): Record<string, ValueTyping> {
        throw new Error('Function not implemented.');
    },
    user: function (resourceData: IResourceData, resourceAccountData: IAccountDataResource | undefined, loadDefinition: boolean): Record<string, ValueTyping> {
        throw new Error('Function not implemented.');
    },
    module: function (resourceData: IResourceData, resourceAccountData: IAccountDataResource | undefined, loadDefinition: boolean): Record<string, ValueTyping> {
        throw new Error('Function not implemented.');
    },
    formation: function (resourceData: IResourceData, resourceAccountData: IAccountDataResource | undefined, loadDefinition: boolean): Record<string, ValueTyping> {
        throw new Error('Function not implemented.');
    },
};
