import { Icon } from '@fluentui/react';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { performApiCall } from '../../../../../common/Hooks/useApiCall';
import { IPageElementQuestionnary, ResourceConsumerPackage } from '../../../../../Services/SakuraApiClient';
import { QuestionnaryUI } from '../../../Questionnary/QuestionnaryUI';

export interface ElementQuestionnaryViewProps {
    data: IPageElementQuestionnary;
}

export const ElementQuestionnaryView: FC<ElementQuestionnaryViewProps> = (props: ElementQuestionnaryViewProps) => {
    const { data } = props;

    const [resourcePackage, setResourcePackage] = useState<ResourceConsumerPackage | undefined>();
    const dispatch = useDispatch();
    useEffect(() => {
        performApiCall(async (apiCall, c) => {
            if (data.resource?.id && (data.resource?.expiredOn === undefined || data.resource?.expiredOn > new Date(Date.now()))) {
                const resPackage = await apiCall.getResourceConsumerPackage(data.resource.id, c);
                setResourcePackage(resPackage);
            } else {
                setResourcePackage(undefined);
            }
        }, dispatch);
    }, [data.resource?.id, data.resource?.expiredOn, dispatch]);

    return (
        <>
            {resourcePackage?.questionnary && data.resource?.id ? (
                <QuestionnaryUI resourceId={data.resource.id} questionnary={resourcePackage.questionnary} accountDataResource={resourcePackage.accountResourceData} enablePersitance />
            ) : (
                <div className='DivFlexHorizontalCenter' style={{ justifyContent: 'center' }}>
                    <Icon iconName='Warning' />
                    <span>Choisi un questionnaire</span>
                </div>
            )}
        </>
    );
};
