import { ActionButton, IStyle } from '@fluentui/react';
import { CSSProperties, FC, useContext, useMemo } from 'react';
import { IPageElementLink, IPageElementTextStyle } from '../../../../../Services/SakuraApiClient';

import { Property } from 'csstype';
import { dynamicDataResolver } from '../../../../../Redux/Reducers/DynamiqueData/dynamicDataResolver';
import { useDynamicData } from '../../../../../Redux/Reducers/DynamiqueData/reducer';
import { useAppDispatch } from '../../../../../Redux/hook';
import { useBracketResolver } from '../../../../../common/components/ExpressionField/Hook/useBracketResolver';
import { fontStyleToCss } from '../../../../../common/styles/FontStyles';
import { useFormationContextActions } from '../../../Formation/FormationContext/FormationContextSelector';
import { ExecutionDataContext } from '../../ExecutionContext/ExecutionDataContextProvider';
import { clickAction } from '../Button/clickAction';
import { buttonStyleBuilderContainerOnly, buttonStyleBuilderFontOnly, toIStyleContainer, toIStyleLabel } from '../Label/textStyleBuilder';
import { baseStyleBuilder } from '../baseStyleBuilder';

export interface ElementButtonViewProps {
    data: IPageElementLink;
    clickDisabled?: boolean;
}

function styleBuilder(style: IPageElementTextStyle): CSSProperties {
    return {
        ...fontStyleToCss(style?.font),
        textAlign: style?.textAlignement?.toString().toLowerCase() as Property.TextAlign | undefined,
        ...baseStyleBuilder(style),
        flex: 1,
    };
}
export const ElementLinkView: FC<ElementButtonViewProps> = (props: ElementButtonViewProps) => {
    const { data, clickDisabled } = props;

    const formationContextActions = useFormationContextActions();
    const style = useMemo(() => {
        return styleBuilder(data.style);
    }, [data.style]);
    const appDispatch = useAppDispatch();
    const dynData = useDynamicData();
    const [state] = useContext(ExecutionDataContext);
    const valueResolved = useBracketResolver(data.value, dynData);
    const labelStyle = useMemo(() => {
        return buttonStyleBuilderFontOnly(data.style);
    }, [data.style]);
    const containerStyle = useMemo(() => {
        return buttonStyleBuilderContainerOnly(data.style);
    }, [data.style]);
    const labelStyleFU = useMemo<IStyle>(() => {
        return toIStyleLabel(labelStyle);
    }, [labelStyle]);
    const containerStyleFU = useMemo<IStyle>(() => {
        return toIStyleContainer(containerStyle);
    }, [containerStyle]);
    return (
        <div>
            <ActionButton
                style={style}
                styles={{
                    root: containerStyleFU,
                    label: labelStyleFU,
                    icon: labelStyleFU,
                    menuIcon: labelStyleFU,
                    labelHovered: labelStyleFU,
                    flexContainer: {
                        borderRadius: style?.borderRadius as string,
                    },
                }}
                text={data.value && data.value !== '' ? valueResolved : '[taper un texte]'}
                onClick={
                    clickDisabled
                        ? undefined
                        : () => {
                              clickAction(state.pageResourceId, state.parentResourcePathId, appDispatch, data.onClick, dynamicDataResolver, formationContextActions);
                          }
                }
            />
        </div>
    );
};
