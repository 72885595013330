import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';

import './colorContrast.scss';
import './global.scss';
import { store } from './Redux/store';
import * as serviceWorker from './serviceWorker';
export const useStrictMode = false;

const container = document.getElementById('root');

if (container) {
    const root = createRoot(container); // createRoot(container!) if you use TypeScript
    if (useStrictMode) {
        root.render(
            <React.StrictMode>
                <Provider store={store}>
                    <App />
                </Provider>
            </React.StrictMode>,
        );
    } else {
        root.render(
            <Provider store={store}>
                <App />
            </Provider>,
        );
    }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
serviceWorker.register();
