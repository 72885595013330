import { createScopeDataForDefinition } from '../../../../../Redux/Reducers/DynamiqueData/helpers';
import { IAccountDataResource, IResourceData, QuestionTypeEnum } from '../../../../../Services/SakuraApiClient';

export function buildQuizScope(resourceData: IResourceData, resourceAccountData: IAccountDataResource | undefined, loadDefinition: boolean) {
    return {
        data: loadDefinition ? createScopeDataForDefinition(resourceAccountData?.data?.variables, resourceData.variableNames ?? []) : resourceAccountData?.data?.variables ?? {},
        ...buildQuizScopeReadOnlyField(resourceData),
    };
}
export function buildQuizScopeReadOnlyField(resourceData: IResourceData) {
    const quiz = resourceData?.content?.questionnary;
    if (quiz) {
        const result: Record<string, Record<string, unknown>> = {};
        quiz.questions?.forEach((q) => {
            if (q.id) {
                if (q.type === QuestionTypeEnum.FreeText || q.type === QuestionTypeEnum.YesNo) {
                    result[q.id] = {
                        reponse: '',
                    };
                }
                if (q.type === QuestionTypeEnum.Choice || q.type === QuestionTypeEnum.MultiChoice) {
                    result[q.id] = {
                        choix: '',
                        choixTexte: '',
                    };
                }

                result[q.id].resultat = {
                    isCorrect: false,
                    category: '',
                    score: 0,
                };
                result[q.id].texte = q.text;
                if (q.answers && q.answers.length > 0) {
                    q.answers.forEach((a) => {
                        if (q.id && a.id) {
                            result[q.id][a.id] = {
                                text: a.text,
                                selectionner: false,
                            };
                        }
                    });
                }
            }
        });
        return result;
    }
    return {};
}
