import { DatePicker, DetailsList, PrimaryButton } from '@fluentui/react';
import { FC, useMemo } from 'react';
import { ResourceLookupField } from '../../../../common/LookupFields/ResourceLookupField';
import { displayDate } from '../../../../common/helpers/DateTimeHelper';
import { DeepPartial } from '../../../../common/helpers/ObjectAndArray';
import { ConfiguredPage } from './menuSetting';

export interface PageSettingEditVariationProps {
    data: DeepPartial<ConfiguredPage>[];
    onChange: (data: DeepPartial<ConfiguredPage>[]) => void;
}

export const PageSettingEditVariation: FC<PageSettingEditVariationProps> = (props: PageSettingEditVariationProps) => {
    const { data, onChange } = props;

    const columns = useMemo(() => {
        return [
            {
                key: 'From',
                name: 'Du',
                fieldName: 'from',
                minWidth: 200,
                maxWidth: 200,
                allowSorting: true,
                isResizable: true,
                onRender: (item: ConfiguredPage) => {
                    return (
                        <DatePicker
                            formatDate={displayDate}
                            value={item.criteriaDateFrom}
                            onSelectDate={(date) => {
                                const index = data.indexOf(item);
                                const newData = [...data];
                                newData[index].criteriaDateFrom = date ?? undefined;
                                onChange(newData);
                            }}
                        />
                    );
                },
            },
            {
                key: 'To',
                name: 'Au',
                fieldName: 'to',
                minWidth: 200,
                maxWidth: 200,
                allowSorting: true,
                isResizable: true,
                onRender: (item: ConfiguredPage) => {
                    return (
                        <DatePicker
                            formatDate={displayDate}
                            value={item.criteriaDateTo}
                            onSelectDate={(date) => {
                                const index = data.indexOf(item);
                                const newData = [...data];
                                newData[index].criteriaDateTo = date ?? undefined;
                                onChange(newData);
                            }}
                        />
                    );
                },
            },
            {
                key: 'Page',
                name: 'Page',
                fieldName: 'Page',
                minWidth: 300,
                maxWidth: 300,
                allowSorting: true,
                isResizable: true,
                onRender: (item: ConfiguredPage) => {
                    return (
                        <ResourceLookupField
                            selectedResourceId={item.pageId}
                            onSelectResource={(resource) => {
                                const index = data.indexOf(item);
                                const newData = [...data];
                                newData[index].pageId = resource?.id;
                                onChange(newData);
                            }}
                            removeItemCreate
                            type={'ResourcePageAutreLookup'}
                        />
                    );
                },
            },
        ];
    }, [onChange, data]);
    return (
        <div className='PageSettingEditVariation'>
            <PrimaryButton
                onClick={() => {
                    const newData = [...data];
                    newData.push({ criteriaDateFrom: new Date(Date.now()), criteriaDateTo: undefined, pageId: undefined });
                    onChange(newData);
                }}
            >
                {'Ajouter une variation'}
            </PrimaryButton>
            <DetailsList columns={columns} items={data} />
        </div>
    );
};
