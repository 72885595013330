import axios from 'axios';
import axiosRetry from 'axios-retry';
import { interceptRequestHeader_ClientTime, interceptRequestHeader_Security } from './InterceptRequestHeader';
import { interceptResponseHeader } from './InterceptResponseHeader';
import { SakuraApiClient } from './SakuraApiClient';

export class ServiceCreator {
    public static SakuraApiClient(anonymous = false): SakuraApiClient {
        const instance = axios.create();
        axiosRetry(instance, {
            retries: 4,
            retryDelay(retryCount, error) {
                if (retryCount === 1) {
                    return 100;
                }
                return retryCount * 1000;
            },
        });
        if (!anonymous) {
            instance.interceptors.request.use(async (request) => {
                interceptRequestHeader_ClientTime(request);
                await interceptRequestHeader_Security(request);
                return request;
            });
        } else {
            instance.interceptors.request.use((request) => {
                interceptRequestHeader_ClientTime(request);
                return request;
            });
        }
        instance.interceptors.response.use(
            async (response) => {
                await interceptResponseHeader(response.headers);
                return response;
            },
            (error) => {
                return Promise.reject(error);
            },
        );
        return new SakuraApiClient({}, undefined, instance);
    }
}
