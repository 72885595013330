import { MessageBarType } from '@fluentui/react';
import { IColorPalette, IFontStyles, IStorageInformation } from '../../../Services/SakuraApiClient';

export const CstWidthMobile = 500;
export const CstWidthTablette = 1000;

export interface SystemState {
    mobileDisplay: boolean;
    tabletteDisplay: boolean;
    contextualMobileDisplay: boolean;
    windowWidth: number;
    loading: Record<string | 'default', LoadingState | undefined>;
    message: Record<string | 'default', MessageState | undefined>;
    preventLostData: (() => void) | undefined;
    popupMessage: PopupMessage | undefined;
    dirtyForm: Record<string | 'default', boolean>;
    hasTokenExpired: boolean;
    notification: INotification;
    notificationPublicKey?: string;
    serviceWorkerRegistration: ServiceWorkerRegistration | undefined;
    links: ConfiguredLinks | undefined;
    thumbnail: ThumbnailInfo;
    storage?: IStorageInformation;
    colors: IColorPalette;
    fontStyles: IFontStyles;
    thumbnailSections: string[] | undefined;
}

export interface INotification {
    permission: NotificationPermission;
    endpoint: string | undefined;
    p256dh: string | undefined;
    auth: string | undefined;
    id: number | undefined;
}
export interface LoadingState {
    isLoading: boolean;
    loadingText?: string;
}
export interface MessageState {
    text: string;
    Severity: MessageBarType;
}
export interface PopupMessage {
    title: string;
    subTitle?: string;
    buttonText?: string;
    icon?: string;
    timeout?: number;
}
export type SelectionStatus = 'Selected' | 'UnSelecting' | 'UnSelected';
export interface ThumbnailInfo {
    selected?: {
        blockId: string;
        id: number;
        status: SelectionStatus;
        origin: 'MouseOver' | 'Click';
    };
}
export interface ConfiguredLinks {
    generalConditionOfSale: string | undefined;
    privacyPolicy: string | undefined;
    facebook: string | undefined;
    instagram: string | undefined;
    pinterest: string | undefined;
    threads: string | undefined;
    tiktok: string | undefined;
    youtube: string | undefined;
    x: string | undefined;
}
export const initialState: SystemState = {
    mobileDisplay: window.innerWidth < CstWidthMobile,
    tabletteDisplay: window.innerWidth < CstWidthTablette,
    contextualMobileDisplay: false,
    windowWidth: window.innerWidth,
    loading: {},
    message: {},
    popupMessage: undefined,
    preventLostData: undefined,
    dirtyForm: {},
    hasTokenExpired: false,
    notification: {
        permission: 'default',
        auth: undefined,
        endpoint: undefined,
        p256dh: undefined,
        id: undefined,
    },
    serviceWorkerRegistration: undefined,
    links: undefined,
    thumbnail: {
        selected: undefined,
    },
    storage: {
        endpoints: {
            Public: '/public/',
        },
    },
    colors: { entries: [] },
    fontStyles: { entries: [] },
    thumbnailSections: undefined,
};
