import React, { FC, useContext } from 'react';
import { DeepPartial } from '../../../../../common/helpers/ObjectAndArray';
import { ResourceLookupField } from '../../../../../common/LookupFields/ResourceLookupField';
import { EnumPDFDisplayType, IPageElementPDF } from '../../../../../Services/SakuraApiClient';
import { ExecutionDataContext } from '../../../Page/ExecutionContext/ExecutionDataContextProvider';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import { DimensionsInput } from '../../../../../common/components/Css/DimensionsInput';

export interface PdfViewerElementPanelProps {
    data: IPageElementPDF;
    onChangeData: (data: DeepPartial<IPageElementPDF>) => void;
}

const displayTypeOptions: IDropdownOption[] = [
    {
        key: EnumPDFDisplayType.Scroll,
        text: 'Défilement vertical',
    },
    {
        key: EnumPDFDisplayType.Page,
        text: 'Par page',
    },
    {
        key: EnumPDFDisplayType.Book,
        text: 'Livre',
    },
];

export const PdfViewerElementPanel: FC<PdfViewerElementPanelProps> = (props: PdfViewerElementPanelProps) => {
    const { onChangeData, data } = props;
    const [, dispatch] = useContext(ExecutionDataContext);
    return (
        <>
            <ResourceLookupField
                label='Fichier PDF'
                type='ResourceDocumentLookup'
                selectedResourceId={data.resource?.id}
                onSelectResource={(resource) => {
                    if (resource) {
                        onChangeData({ resource: { id: resource.id } });
                        dispatch({
                            type: 'addResource',
                            payload: {
                                resourceId: resource.id,
                                data: {
                                    id: resource.id,
                                    blobPath: resource.blobPath,
                                    data: resource.data,
                                    type: resource.resourceType,
                                },
                            },
                        });
                    } else {
                        onChangeData({ resource: undefined });
                    }
                }}
            />
            <Dropdown
                label="Type d'affichage"
                title="Type d'affichage"
                options={displayTypeOptions}
                selectedKey={data.displayType}
                onChange={(_, option) => {
                    onChangeData({ displayType: option?.key as EnumPDFDisplayType });
                }}
            ></Dropdown>
            <DimensionsInput
                dimension={data.style?.dimensions}
                label='Dimensions'
                onChange={(dimensions) => {
                    onChangeData({ style: { dimensions } });
                }}
            />
        </>
    );
};
