import { FC, useMemo } from 'react';
import { useDynamicData } from '../../../../Redux/Reducers/DynamiqueData/reducer';
import { IQuestionnary, IQuestionnaryResult, QuestionnaryKind } from '../../../../Services/SakuraApiClient';
import { useBracketResolver } from '../../../../common/components/ExpressionField/Hook/useBracketResolver';
import { ShowAnswersDetails } from './ResultVisualization/ShowAnswersDetails';
import { ShowCategoriesGraphic } from './ResultVisualization/ShowCategoriesGraphic';
import { ShowCategory } from './ResultVisualization/ShowCategory';
import { ShowExamResult } from './ResultVisualization/ShowExamResult';
import { ShowResultDescription } from './ResultVisualization/ShowResultDescription';

export interface QuestionnaryResultProps {
    result: IQuestionnaryResult;
    questionnary: IQuestionnary;
}

export const QuestionnaryResult: FC<QuestionnaryResultProps> = (props: QuestionnaryResultProps) => {
    const { result, questionnary } = props;

    const firstCategory = useMemo(() => {
        if (result.firstCategory && result.categoryDesc) {
            return result.categoryDesc[result.firstCategory];
        }
        return undefined;
    }, [result.firstCategory, result.categoryDesc]);

    const dynData = useDynamicData();
    const resolvedTitle = useBracketResolver(questionnary.title, dynData);

    const examMode = useMemo(() => result.score !== undefined && result.examPassed !== undefined, [result]);
    return (
        <div className='questionnaryResultPage'>
            <div className='header'>
                <span>{resolvedTitle}</span>
            </div>
            <div className='content'>
                {questionnary.kind === QuestionnaryKind.Interview ? (
                    <>{result.details && result.details.length > 0 ? <ShowAnswersDetails result={result} questionnary={questionnary} /> : null}</>
                ) : (
                    <>
                        {questionnary.kind === QuestionnaryKind.Exam ? <ShowExamResult score={result.score} scoreDesc={result.scoreDesc} /> : null}
                        {result.resultDescription ? <ShowResultDescription description={result.resultDescription} /> : <></>}
                        {result.showScoringDetails ? (
                            <>{examMode ? <></> : <ShowCategoriesGraphic categoryDesc={result.categoryDesc} scoringByCategory={result.scoringByCategory} />}</>
                        ) : (
                            <>{examMode ? <></> : <ShowCategory category={firstCategory} />}</>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
