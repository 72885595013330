export type ContentSourceType =
    | 'FormationActive'
    | 'ResourceTileOwnByAccount'
    | 'ProductTile'
    | 'ResourceTileOwnByAccountAndProductTile'
    | 'FavoriteResource'
    | 'WhishlistProduct'
    | 'FavResAndWhishPrd';

export const contentSourceLabels: Record<ContentSourceType, string> = {
    FormationActive: 'Formations en cours',
    ResourceTileOwnByAccount: 'Resources acquises',
    ProductTile: 'Produits à vendre',
    ResourceTileOwnByAccountAndProductTile: 'Res. acquises et prod. à vendre',
    FavoriteResource: 'Resources Favorites',
    WhishlistProduct: 'Produits souhaités',
    FavResAndWhishPrd: 'Res. Fav et prod. souhaités',
};
export type DataType = 'Product' | 'Resource';
