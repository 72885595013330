import { Dropdown, IDropdownOption } from '@fluentui/react';
import { FC } from 'react';
import { EnumItemsAlignement } from '../../../../../Services/SakuraApiClient';

const enumItemsAlignement: IDropdownOption[] = [
    {
        key: -1,
        text: 'Non défini',
    },
    {
        key: EnumItemsAlignement.Start,
        text: 'Début',
    },
    {
        key: EnumItemsAlignement.End,
        text: 'Fin',
    },
    {
        key: EnumItemsAlignement.Center,
        text: 'Centré',
    },
    {
        key: EnumItemsAlignement.Stretch,
        text: 'Etiré',
    },
    // {
    //     key: EnumItemsAlignement.BaseLine,
    //     text: 'Ligne de base',
    // },
];
export interface AlignementProps {
    alignSelf: EnumItemsAlignement | undefined;
    onChangeData: (alignSelf: EnumItemsAlignement | undefined) => void;
}
export const Alignement: FC<AlignementProps> = (props: AlignementProps) => {
    const { alignSelf, onChangeData } = props;
    return (
        <Dropdown
            label='Alignment dans le conteneur parent'
            title='AlignSelf'
            options={enumItemsAlignement}
            selectedKey={alignSelf ?? -1}
            onChange={(_, option) => {
                const val = option?.key;
                onChangeData(val === -1 ? undefined : (val as EnumItemsAlignement));
            }}
        ></Dropdown>
    );
};
