import { ValueTyping } from '../../../common/components/ExpressionField/Expression';
import { FunctionLibrary } from '../../../common/components/ExpressionField/Expression/Function/FunctionLibrary';
import { DynamicData, Intelisense, IntelisenseInfo, readonlyScopes } from './state';

export function getVar(name: string[], data: Record<string, unknown>) {
    let ref = data;
    let result = undefined;
    name.forEach((subPart, index) => {
        if (index === name.length - 1) {
            result = ref[subPart];
        } else {
            if (!ref[subPart]) {
                ref[subPart] = {};
            }
            ref = ref[subPart] as Record<string, unknown>;
        }
    });
    return result;
}
export function setVar(name: string[], data: Record<string, unknown>, value: ValueTyping) {
    let ref = data;
    name.forEach((subPart, index) => {
        if (index === name.length - 1) {
            ref[subPart] = value;
        } else {
            if (!ref[subPart]) {
                ref[subPart] = {};
            }
            ref = ref[subPart] as Record<string, unknown>;
        }
    });
}
export function updateIntelisense(name: string[], intelisense: Intelisense, action: 'Add' | 'Remove') {
    let ref: IntelisenseInfo;
    let containData = false;
    name.forEach((subPart, index) => {
        if (subPart === 'data') {
            containData = true;
        }
        if (index === 0) {
            ref = intelisense[subPart];
            if (!ref) {
                //wrong scope, so exit
                return;
            }
        } else {
            if (index === name.length - 1) {
                if (containData) {
                    if (action === 'Add') {
                        if (!ref?.items) {
                            ref.items = {};
                        }
                        if (ref?.items) {
                            if (ref.items[subPart] === undefined) {
                                ref.items[subPart] = {
                                    fullName: ref.fullName + '.' + subPart,
                                    type: ref.type,
                                };
                            }
                        }
                    } else if (action === 'Remove') {
                        if (ref?.items) {
                            delete ref.items[subPart];
                        }
                    }
                }
            } else {
                if (!ref?.items) {
                    ref.items = {};
                }
                if (ref?.items) {
                    if (!ref.items[subPart]) {
                        ref.items[subPart] = {
                            fullName: ref.fullName + '.' + subPart,
                            type: ref.type,
                        };
                    }
                    ref = ref.items[subPart];
                }
            }
        }
    });
}
function isReadonlyVariable(fullName: string, isScope: boolean) {
    if (isScope) {
        return readonlyScopes.indexOf(fullName) !== -1;
    }
    if (fullName && fullName.indexOf('data') !== -1) {
        return false;
    }
    return true;
}
export function buildDynamicDataIntelisense(data: DynamicData) {
    const result: Intelisense = {};
    const properties = Object.entries(data).sort();

    properties.forEach((e) => {
        const scope = e[0];
        const scopeData = e[1];

        if (scopeData !== undefined) {
            result[scope] = buildIntelisense(scope, scopeData, undefined);
        }
    });
    const functions = FunctionLibrary.getIntelisense();
    functions.forEach((func) => {
        result[func.fullName] = func;
    });
    return result;
}
function buildIntelisense(name: string, data: Record<string, unknown>, parent?: IntelisenseInfo): IntelisenseInfo {
    const properties = typeof data === 'object' && data ? Object.entries(data).sort() : [];
    const fullName = parent ? parent.fullName + '.' + name : name;
    const newIntelisense: IntelisenseInfo = { fullName, type: isReadonlyVariable(fullName, parent === undefined) ? 'rovar' : 'var' };
    if (properties.length > 0) {
        newIntelisense.items = {};
        properties.forEach((e) => {
            const name = e[0];
            const entryValue = e[1] as Record<string, unknown>;
            if (newIntelisense.items) {
                newIntelisense.items[name] = buildIntelisense(name, entryValue, newIntelisense);
            }
        });
    }
    return newIntelisense;
}
export function createScopeDataForDefinition(existingData: Record<string, ValueTyping> | undefined, properties: string[]): Record<string, ValueTyping> {
    const data: Record<string, unknown> = {};
    properties.forEach((propertyName) => {
        const parts = propertyName.split('.');
        let ref = data;
        parts.forEach((subPart, index) => {
            if (index === parts.length - 1) {
                ref[subPart] = null;
            } else {
                if (!ref[subPart]) {
                    ref[subPart] = {};
                }
                ref = ref[subPart] as Record<string, unknown>;
            }
        });
    });
    if (existingData) {
        return { ...data, ...existingData } as Record<string, ValueTyping>;
    }
    return data as Record<string, ValueTyping>;
}
export function getVariablesNames(intelisense: Intelisense, prefixRemoval: string) {
    const variables: string[] = [];
    Object.entries(intelisense).forEach((i) => {
        if (i[1].items) {
            const subVariables = getVariablesNames(i[1].items, prefixRemoval);
            variables.push(...subVariables);
        } else {
            variables.push(i[1].fullName.substring(prefixRemoval.length));
        }
    });
    return variables;
}
export function hasChildProperty(info: IntelisenseInfo): boolean {
    if (info.items) {
        return Object.keys(info.items).length > 0;
    }
    return false;
}
