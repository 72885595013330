import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react';

export function useState2<T>(initialState?: T | (() => T)): [T | undefined, Dispatch<SetStateAction<T | undefined>>, MutableRefObject<T | undefined>] {
    const [v, setV] = useState<T | undefined>(initialState);
    const refV = useRef<T>();
    useEffect(() => {
        refV.current = v;
    }, [v]);
    return [v, setV, refV];
}
