import { Icon } from '@fluentui/react';
import clsx from 'clsx';
import { FC } from 'react';
import './SelectableIcon.scss';

export interface SelectableIconProps {
    title?: string;
    iconName: string;
    selected: boolean | undefined;
    onChange: (selected: boolean) => void;
}

export const SelectableIcon: FC<SelectableIconProps> = (props: SelectableIconProps) => {
    const { iconName, title, selected, onChange } = props;
    return (
        <Icon
            title={title}
            iconName={iconName}
            className={clsx('SelectedIcon', selected ? 'selected' : '')}
            onClick={() => {
                onChange(!selected);
            }}
        />
    );
};
