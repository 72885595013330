import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { InitializeMenu } from '../../Redux/Reducers/Menu/reducer';
import { addBadgeToDisplay, userOrganizationSelector, userProfileSelector } from '../../Redux/Reducers/User/reducer';
import { useAppDispatch, useAppSelector } from '../../Redux/hook';
import { ServiceCreator } from '../ServiceCreator';

import { setColorPalette, setConfiguredLinks, setFontStyles, setPushNotificationPublicKey, setStorageEndpoints, setThumbnailSections } from '../../Redux/Reducers/System/reducer';
import { ConfiguredLinks } from '../../Redux/Reducers/System/state';
import { updateProfile } from '../../Redux/Reducers/User/actionChain';
import { AuthenticationService } from './AuthService';
import { DisconnectedPopup } from './DisconnectedPopup';

interface RouteParam extends Record<string, string | undefined> {
    organization: string | undefined;
}

export interface IAuthenticationBarrierProps {
    authService: AuthenticationService;
    guestCode?: string;
    renderLoadingScreen: () => React.ReactNode | undefined;
}

export const AuthenticationBarrier: FC<PropsWithChildren<IAuthenticationBarrierProps>> = (props: PropsWithChildren<IAuthenticationBarrierProps>) => {
    const userProfile = useAppSelector(userProfileSelector);
    const userOrga = useAppSelector(userOrganizationSelector);
    const dispatch = useAppDispatch();
    const { authService, children, renderLoadingScreen, guestCode } = props;
    const location = useLocation();
    const navigate = useNavigate();

    const { organization } = useParams<RouteParam>();

    const org = useMemo(() => {
        return organization && organization.startsWith('app') ? '' : organization ?? '';
    }, [organization]);

    useEffect(() => {
        if (!userProfile || org !== userOrga) {
            if (userProfile) {
                authService.logout(guestCode);
                return;
            }
            authService
                .ensureLogin(org ?? '', guestCode ? '' : location.pathname + location.search, guestCode)
                .then(async (authenticated: boolean) => {
                    if (authenticated) {
                        const client = ServiceCreator.SakuraApiClient();
                        const storage = await client.getStorageInformation();
                        let profile;
                        try {
                            profile = await client.getMyAccount();
                        } catch (error) {
                            await authService.clearStaleState();
                            navigate('/' + userOrga);
                            return;
                        }

                        dispatch(setStorageEndpoints({ storage }));

                        updateProfile(dispatch, {
                            profile: profile?.account,
                            menu: profile.menu,
                            experienceRequiredToLevelUp: profile?.experienceRequiredToLevelUp ?? 0,
                            organization: org,
                            dynVariableNames: profile?.dynVariableNames,
                        });
                        if (profile?.colors) {
                            dispatch(setColorPalette({ colors: profile.colors }));
                        }
                        if (profile?.fontStyles) {
                            dispatch(setFontStyles({ fontStyles: profile.fontStyles }));
                        }
                        if (profile?.thumbnailSections) {
                            dispatch(setThumbnailSections({ sections: profile.thumbnailSections }));
                        }
                        if (profile?.links) {
                            dispatch(setConfiguredLinks({ links: profile.links as unknown as ConfiguredLinks }));
                        }
                        if (profile?.pushNotificationPublicKey) {
                            dispatch(setPushNotificationPublicKey({ publicKey: profile.pushNotificationPublicKey }));
                        }
                        if (profile) {
                            dispatch(
                                InitializeMenu({
                                    userAccount: profile.account,
                                    userMenu: profile.menu ?? [],
                                }),
                            );
                        }
                        let badgeNotSeen = undefined;
                        try {
                            badgeNotSeen = await client.getMyBadgesNotSeen();
                        } catch (error) {
                            console.log('badge not seen error - ' + error);
                        }
                        if (badgeNotSeen) {
                            for (let i = 0; i < badgeNotSeen.length; i++) {
                                dispatch(
                                    addBadgeToDisplay({
                                        badge: {
                                            id: badgeNotSeen[i].badgeId,
                                            description: badgeNotSeen[i].description,
                                            badgeType: badgeNotSeen[i].type,
                                            imageBlobPath: badgeNotSeen[i].imageBlobPath,
                                            name: badgeNotSeen[i].name,
                                            badgeSetting: { graphics: badgeNotSeen[i].graphics, workflow: { id: undefined, actions: [] } },
                                            createdBy: 0, //Not used
                                            createdOn: new Date(), //Not used
                                            orgId: 0, //Not used
                                            updatedBy: undefined, //Not used
                                            updatedOn: undefined, //Not used
                                        },
                                    }),
                                );
                            }
                        }
                    }
                })
                .catch((exception) => {
                    console.log(exception);
                    navigate('/error');
                });
        }
    }, [authService, dispatch, guestCode, location.pathname, location.search, navigate, org, userOrga, userProfile]);

    return (
        <>
            {userProfile ? (
                <>
                    <DisconnectedPopup authService={authService} />
                    {children}
                </>
            ) : (
                renderLoadingScreen()
            )}
        </>
    );
};
