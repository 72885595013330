import { FC, useMemo, useState } from 'react';
import { IResource, ISocialNetworkLink, SocialNetworkLink } from '../../../Services/SakuraApiClient';

import { MessageBarType, PrimaryButton, Separator, TextField } from '@fluentui/react';
import { mobileViewSelector, setMessage, storageEndpointsSelector } from '../../../Redux/Reducers/System/reducer';
import { useAppDispatch, useAppSelector } from '../../../Redux/hook';
import { useForm } from '../../../common/Hooks/Form';
import { FormFieldValue } from '../../../common/Hooks/Form/useForm';
import * as validators from '../../../common/Hooks/Form/validators';
import { ST } from '../../../common/Hooks/StorageResolver';
import { performApiCall } from '../../../common/Hooks/useApiCall';
import { ResourceLookupField } from '../../../common/LookupFields/ResourceLookupField';
import { EditDialog } from '../../../common/components/Dialog/EditDialog/EditDialog';
import { SimpleDialog } from '../../../common/components/Dialog/SimpleDialog/SimpleDialog';

export interface SocialNetworkLinkDetailsProps {
    entity?: ISocialNetworkLink;
    linkDefaultValue?: string;
    commentDefaultValue?: string;
    show: boolean;
    onClose: (entity: ISocialNetworkLink | undefined) => void;
}
function getNewSNL(linkDefaultValue: string | undefined, commentDefaultValue: string | undefined) {
    const entity = new SocialNetworkLink();
    entity.init({ link: linkDefaultValue, comment: commentDefaultValue, metadataLink: { sitename: 'sakura-coaching.fr' } });
    return entity;
}
function validImageLink<TModel>(model: TModel, value: FormFieldValue, displayName: string): string | undefined {
    return validators.evalRegEx((value ?? '').toString(), /^(((http|https):\/\/)|(Public:)).*\/.*$/, displayName);
}
function validShortner<TModel>(model: TModel, value: FormFieldValue, displayName: string): string | undefined {
    return validators.evalRegEx((value ?? '').toString(), /^[a-z0-9\\-]+$/, displayName);
}

export const SocialNetworkLinkDetails: FC<SocialNetworkLinkDetailsProps> = (props: SocialNetworkLinkDetailsProps) => {
    const { entity, show, onClose, linkDefaultValue, commentDefaultValue } = props;
    const [selectImage, setSelectImage] = useState<boolean>(false);
    const [imageError, setImageError] = useState<boolean>(false);
    const [imgResource, setImgResource] = useState<IResource>();
    const isMobile = useAppSelector(mobileViewSelector);
    const storage = useAppSelector(storageEndpointsSelector);
    const contextId = `EditSLNLink`;
    const form = useForm<ISocialNetworkLink>(
        {
            initialState: entity ?? getNewSNL(linkDefaultValue, commentDefaultValue),
            validators: {
                fields: {
                    name: { displayName: 'Nom', validators: [validators.required] },
                    shortner: { displayName: 'Nom court', validators: [validators.required, validShortner] },
                    link: { displayName: 'Lien', validators: [validators.required, validators.validLink] },
                    metadataLink: {
                        title: { displayName: 'Titre', validators: [validators.required] },
                        description: { displayName: 'Description', validators: [validators.required] },
                        imageUrl: { displayName: "Lien d'image", validators: [validators.required, validImageLink] },
                    },
                },
            },
        },
        entity !== undefined,
    );
    const onImgLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        form.update({ metadataLink: { imageHeight: event.currentTarget.naturalHeight.toString(), imageWidth: event.currentTarget.naturalWidth.toString() } });
        setImageError(false);
    };
    const appDispatch = useAppDispatch();

    const close = (res: ISocialNetworkLink | undefined) => {
        form.clearErrors();
        onClose(res);
    };

    const onSubmit = () => {
        const error = form.validate();
        if (!error) {
            performApiCall(
                async (client) => {
                    if (form.state) {
                        const data = new SocialNetworkLink();
                        data.init(form.state);

                        let savedSNLink;
                        try {
                            if (entity) {
                                savedSNLink = await client.updateSocialNetworkLink(entity.id, data);
                            } else {
                                savedSNLink = await client.createSocialNetworkLink(data);
                            }
                        } catch (ex) {
                            const err = ex as { title: string; status: number };
                            if (err.title === 'Conflict' && err.status === 409) {
                                appDispatch(setMessage({ contextId, message: { Severity: MessageBarType.error, text: 'Ce nom court (SLUG) est déjà utilisé.' } }));
                            }
                            return;
                        }
                        appDispatch(setMessage({ contextId, message: undefined }));
                        form.commit();
                        close(savedSNLink);
                    }
                },
                appDispatch,
                { contextId },
            );
        }
    };
    const imageLink = useMemo(() => {
        if (form.state.metadataLink?.imageUrl) {
            if (form.state.metadataLink?.imageUrl.startsWith('http')) {
                return form.state.metadataLink?.imageUrl;
            }
            return ST(storage, form.state.metadataLink?.imageUrl, isMobile);
        }
        return null;
    }, [form.state.metadataLink?.imageUrl, isMobile, storage]);

    return (
        <EditDialog
            id={contextId}
            icon={{ iconName: 'JoinOnlineMeeting' }}
            mode={entity ? 'Edit' : 'Create'}
            title={entity ? 'Modifier le lien' : 'Créer un lien'}
            show={show}
            onSubmit={onSubmit}
            onClose={() => close(undefined)}
        >
            <div>
                <TextField label='Nom du link' errorMessage={form.fieldErrors.name} required value={form.state.name ?? ''} onChange={(_, v) => form.update({ name: v })} />
                <TextField label='Commentaire' multiline errorMessage={form.fieldErrors.comment} value={form.state.comment ?? ''} onChange={(_, v) => form.update({ comment: v })} />
                <Separator />
                <TextField label='Nom court (SLUG)' errorMessage={form.fieldErrors.shortner} required value={form.state.shortner ?? ''} onChange={(_, v) => form.update({ shortner: v })} />
                <TextField label='Lien' errorMessage={form.fieldErrors.link} required value={form.state.link ?? ''} onChange={(_, v) => form.update({ link: v })} />
                <TextField
                    label='Titre'
                    placeholder='(og:title)'
                    errorMessage={form.fieldErrors.metadataLink?.title}
                    required
                    value={form.state.metadataLink?.title ?? ''}
                    onChange={(_, v) => form.update({ metadataLink: { title: v } })}
                />
                <TextField
                    label='Description'
                    placeholder='(og:description)'
                    multiline
                    errorMessage={form.fieldErrors.metadataLink?.description}
                    required
                    value={form.state.metadataLink?.description ?? ''}
                    onChange={(_, v) => form.update({ metadataLink: { description: v } })}
                />
                <TextField
                    label='Nom du site'
                    placeholder='(og:sitename)'
                    errorMessage={form.fieldErrors.metadataLink?.sitename}
                    required
                    value={form.state.metadataLink?.sitename ?? ''}
                    onChange={(_, v) => form.update({ metadataLink: { sitename: v } })}
                />
                <div className='DivFlexHorizontal' style={{ alignItems: 'flex-end' }}>
                    <div style={{ flex: 1 }}>
                        <TextField
                            label='Lien vers une image'
                            placeholder='(og:image)'
                            errorMessage={form.fieldErrors.metadataLink?.imageUrl}
                            required
                            value={form.state.metadataLink?.imageUrl ?? ''}
                            onChange={(_, v) => form.update({ metadataLink: { imageUrl: v, imageHeight: undefined, imageWidth: undefined } })}
                        />
                    </div>
                    <PrimaryButton
                        title='Choisir une resource image'
                        iconProps={{ iconName: 'PictureLibrary' }}
                        onClick={() => {
                            setSelectImage(true);
                        }}
                    />
                </div>
                {form.state.metadataLink?.imageWidth && form.state.metadataLink?.imageHeight ? (
                    <span>{`Image size ${form.state.metadataLink?.imageWidth} x ${form.state.metadataLink?.imageHeight}`}</span>
                ) : null}
                {imageError ? <span className='errorspan'>{"Lien d'image invalide."}</span> : null}
                <SimpleDialog
                    id={'picSelection'}
                    title={''}
                    show={selectImage}
                    onClose={() => {
                        if (imgResource) {
                            form.update({ metadataLink: { imageUrl: imgResource.blobPath, imageHeight: undefined, imageWidth: undefined } });
                        }
                        setSelectImage(false);
                    }}
                >
                    <ResourceLookupField type='ResourceImagePubliciteLookup' label='Choisi un image' selectedResourceId={imgResource?.id} onSelectResource={(resource) => setImgResource(resource)} />
                </SimpleDialog>

                {imageLink ? (
                    <div style={{ visibility: 'hidden', overflow: 'hidden', width: 0, height: 0 }}>
                        <img alt='link' onLoad={onImgLoad} onError={() => setImageError(true)} src={imageLink} />
                    </div>
                ) : null}
            </div>
        </EditDialog>
    );
};
