import { TextField } from '@fluentui/react';
import { FC, useContext } from 'react';
import {
    IPageElement,
    IPageElementAudio,
    IPageElementButton,
    IPageElementCheckField,
    IPageElementContainer,
    IPageElementCustomComponent,
    IPageElementDatetimeField,
    IPageElementImage,
    IPageElementLabel,
    IPageElementLink,
    IPageElementNumberField,
    IPageElementPDF,
    IPageElementQuestionnary,
    IPageElementRichText,
    IPageElementSeparator,
    IPageElementTextField,
    IPageElementVideo,
    PageElementType,
} from '../../../../Services/SakuraApiClient';
import { DeepPartial, ObjectOperation } from '../../../../common/helpers/ObjectAndArray';
import { ToolBoxPanel } from '../../../Admin/Resources/Page/ToolBoxPanel';
import { EditorDataContext } from '../EditorContext/EditorDataContextProvider';
import { getVisualOfElementType } from '../ToolBox/ElementToolBox';
import { AudioElementPanel } from './Audio/AudioElementPanel';
import { BoutonElementPanel } from './Bouton/BoutonElementPanel';
import { ContainerElementPanel } from './Container/ContainerElementPanel';
import { CustomComponentElementPanel } from './CustomComponent/CustomComponentElementPanel';
import { ImageElementPanel } from './Image/ImageElementPanel';
import { CheckFieldElementPanel } from './Input/CheckFieldElementPanel';
import { DatetimeFieldElementPanel } from './Input/DatetimeFieldElementPanel';
import { NumberFieldElementPanel } from './Input/NumberFieldElementPanel';
import { TextFieldElementPanel } from './Input/TextFieldElementPanel';
import { LabelElementPanel } from './Label/LabelElementPanel';
import { LinkElementPanel } from './Link/LinkElementPanel';
import { PdfViewerElementPanel } from './PdfViewer/PdfViewerElementPanel';
import { QuestionnaryElementPanel } from './Questionnary/QuestionnaryElementPanel';
import { RichTextElementPanel } from './RichText/RichTextElementPanel';
import { SeparatorElementPanel } from './Separator/SeparatorElementPanel';
import { VideoElementPanel } from './Video/VideoElementPanel';
import { VisibilityComponent } from './VisibilityComponent';

export interface PageEditorElementPanelProps {
    later?: string;
}

export const PageEditorElementPanel: FC<PageEditorElementPanelProps> = (props: PageEditorElementPanelProps) => {
    const editorContext = useContext(EditorDataContext);

    const changeData = (data: DeepPartial<IPageElement>) => {
        editorContext.updateSelected(ObjectOperation.merge(editorContext.selectedElement, data));
    };
    const visual = getVisualOfElementType(editorContext.selectedElement?.type);
    return (
        <ToolBoxPanel icon='PageList' title='Propriétes' width={320}>
            {editorContext.selectedElement === undefined ? null : (
                <div className='editorElementPanelContent'>
                    <div className='DivFlexHorizontalCenter'>
                        <TextField label='Type' readOnly underlined value={visual[1]}></TextField>
                        <VisibilityComponent visibleIf={editorContext.selectedElement.visibleIf} onChange={(visibleIf) => changeData({ visibleIf })} />
                    </div>
                    {editorContext.selectedElement.type === PageElementType.Container ? (
                        <ContainerElementPanel data={editorContext.selectedElement as IPageElement as IPageElementContainer} onChangeData={changeData} />
                    ) : null}
                    {editorContext.selectedElement.type === PageElementType.Label ? (
                        <LabelElementPanel data={editorContext.selectedElement as IPageElement as IPageElementLabel} onChangeData={changeData} />
                    ) : null}
                    {editorContext.selectedElement.type === PageElementType.CheckField ? (
                        <CheckFieldElementPanel data={editorContext.selectedElement as IPageElement as IPageElementCheckField} onChangeData={changeData} />
                    ) : null}
                    {editorContext.selectedElement.type === PageElementType.TextField ? (
                        <TextFieldElementPanel data={editorContext.selectedElement as IPageElement as IPageElementTextField} onChangeData={changeData} />
                    ) : null}
                    {editorContext.selectedElement.type === PageElementType.NumberField ? (
                        <NumberFieldElementPanel data={editorContext.selectedElement as IPageElement as IPageElementNumberField} onChangeData={changeData} />
                    ) : null}
                    {editorContext.selectedElement.type === PageElementType.DatetimeField ? (
                        <DatetimeFieldElementPanel data={editorContext.selectedElement as IPageElement as IPageElementDatetimeField} onChangeData={changeData} />
                    ) : null}
                    {editorContext.selectedElement.type === PageElementType.RichText ? (
                        <RichTextElementPanel data={editorContext.selectedElement as IPageElement as IPageElementRichText} onChangeData={changeData} />
                    ) : null}
                    {editorContext.selectedElement.type === PageElementType.Image ? (
                        <ImageElementPanel data={editorContext.selectedElement as IPageElement as IPageElementImage} onChangeData={changeData} />
                    ) : null}
                    {editorContext.selectedElement.type === PageElementType.Video ? (
                        <VideoElementPanel data={editorContext.selectedElement as IPageElement as IPageElementVideo} onChangeData={changeData} />
                    ) : null}
                    {editorContext.selectedElement.type === PageElementType.Audio ? (
                        <AudioElementPanel data={editorContext.selectedElement as IPageElement as IPageElementAudio} onChangeData={changeData} />
                    ) : null}
                    {editorContext.selectedElement.type === PageElementType.Questionnary ? (
                        <QuestionnaryElementPanel data={editorContext.selectedElement as IPageElement as IPageElementQuestionnary} onChangeData={changeData} />
                    ) : null}
                    {editorContext.selectedElement.type === PageElementType.Button ? (
                        <BoutonElementPanel data={editorContext.selectedElement as IPageElement as IPageElementButton} onChangeData={changeData} />
                    ) : null}
                    {editorContext.selectedElement.type === PageElementType.Link ? (
                        <LinkElementPanel data={editorContext.selectedElement as IPageElement as IPageElementLink} onChangeData={changeData} />
                    ) : null}

                    {editorContext.selectedElement.type === PageElementType.PDF ? (
                        <PdfViewerElementPanel data={editorContext.selectedElement as IPageElement as IPageElementPDF} onChangeData={changeData} />
                    ) : null}
                    {editorContext.selectedElement.type === PageElementType.Separator ? (
                        <SeparatorElementPanel data={editorContext.selectedElement as IPageElement as IPageElementSeparator} onChangeData={changeData} />
                    ) : null}
                    {editorContext.selectedElement.type === PageElementType.CustomComponent ? (
                        <CustomComponentElementPanel data={editorContext.selectedElement as IPageElement as IPageElementCustomComponent} onChangeData={changeData} />
                    ) : null}
                </div>
            )}
        </ToolBoxPanel>
    );
};
