import { CancelToken } from 'axios';
import { BadgeTypeEnum, SakuraApiClient } from '../../../../Services/SakuraApiClient';
import { DataSetInfo } from '../state';
import { ApiCall } from './apiCall';

const query = async (_: ApiCall, client: SakuraApiClient, newState: DataSetInfo, cancelToken?: CancelToken) => {
    const type = newState.filters['badgeType'] as BadgeTypeEnum;
    return await client.listBadges(newState.pageIndex, type, newState.pageSize, newState.filters['name'] as string, cancelToken);
};
export const BadgeGrid = new ApiCall('BadgeGrid', query);
export const BadgeLookup = new ApiCall('BadgeLookup', query);
