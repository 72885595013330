import { IColorPalette } from '../../Services/SakuraApiClient';

export function duplicatePalette(palette: IColorPalette) {
    let entries = palette.entries ? [...palette.entries] : undefined;
    if (entries) {
        entries = entries.map((e) => {
            return {
                colors: e.colors ? [...e.colors] : undefined,
                entryName: e.entryName,
            };
        });
    }
    return { entries };
}
export function loadPalette(palette: IColorPalette) {
    // Get the root element
    let entryIndex = 0;
    (palette.entries ?? []).forEach((entry) => {
        let colorIndex = 0;
        (entry.colors ?? []).forEach((color) => {
            const value = color.split('|')[0];
            document.body.style.setProperty(`--C${entryIndex}-${colorIndex}`, value);
            colorIndex++;
        });
        entryIndex++;
    });
}
