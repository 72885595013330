import { FC } from 'react';
import { useDynamicData } from '../../../Redux/Reducers/DynamiqueData/reducer';
import { IPageElement, PageElementContainer, PageElementContainerStyle, PageElementType } from '../../../Services/SakuraApiClient';
import { useExpressionCondition } from '../../../common/components/ExpressionField/Hook/useExpression';
import { ElementContainerView } from './Elements/Container/ElementContainerView';
import { PageElementBase } from './PageElementBase';

export interface PageElementProps {
    data: IPageElement;
    parentContainerStyle?: PageElementContainerStyle;
    rootContainer?: boolean;
}

export const PageElementView: FC<PageElementProps> = (props: PageElementProps) => {
    const { data, parentContainerStyle, rootContainer } = props;
    const dynData = useDynamicData();
    const isVisible = useExpressionCondition(data.visibleIf, dynData);
    return isVisible ? (
        <>
            {data.type === PageElementType.Container ? (
                <ElementContainerView rootContainer={rootContainer} data={data as PageElementContainer} parentContainerStyle={parentContainerStyle} />
            ) : (
                <PageElementBase data={data} type={data.type} parentContainerStyle={parentContainerStyle} />
            )}
        </>
    ) : (
        <></>
    );
};
