import { Checkbox, IStyle, Label, Toggle } from '@fluentui/react';
import { FC, useMemo, useState } from 'react';
import { setVariable, useDynamicData, useDynamicDataVariable } from '../../../../../Redux/Reducers/DynamiqueData/reducer';
import { useAppDispatch } from '../../../../../Redux/hook';
import { CheckFieldType, IPageElementCheckField } from '../../../../../Services/SakuraApiClient';
import { useBracketResolver } from '../../../../../common/components/ExpressionField/Hook/useBracketResolver';
import { useVariableName } from '../../../../../common/components/ExpressionField/Hook/useVariableName';
import { SvgButton, Visual } from '../../../../../common/components/Svg/SvgButton';
import { textStyleBuilderContainerOnly, textStyleBuilderFontOnly, toIStyleContainer, toIStyleLabel } from '../Label/textStyleBuilder';

export interface ElementCheckFieldProps {
    data: IPageElementCheckField;
}

function toVisual(data: IPageElementCheckField, checked: string) {
    if (data.fieldType === CheckFieldType.Art) {
        return {
            content: `/images/icons/${data.artStyleName}_${checked}.svg`,
            color: data.color,
        };
    }
    return { content: '' };
}
export const ElementCheckField: FC<ElementCheckFieldProps> = (props: ElementCheckFieldProps) => {
    const { data } = props;
    const [value, setValue] = useState<boolean>();
    const dispatch = useAppDispatch();
    const assignTo = useVariableName(data.assignValueTo);
    const visualTrue = useMemo((): Visual => {
        return toVisual(data, 'true');
    }, [data]);

    const visualFalse = useMemo((): Visual => {
        return toVisual(data, 'false');
    }, [data]);

    const dynData = useDynamicData();
    const labelResolved = useBracketResolver(data.label, dynData);

    const variableValue = useDynamicDataVariable(assignTo) as boolean | string | undefined;

    const checked = assignTo ? variableValue === true || variableValue === data.trueValue : value;
    // const style = useMemo(() => {
    //     return baseStyleBuilder(data.style);
    // }, [data.style]);

    const assignValue = (checked: boolean) => {
        let value: string | boolean | undefined = checked;
        if (assignTo) {
            if (data.trueValue) {
                value = checked ? data.trueValue : undefined;
            }
            dispatch(
                setVariable({
                    name: assignTo,
                    value,
                }),
            );
        } else {
            setValue(value);
        }
    };
    const labelStyle = useMemo(() => {
        return textStyleBuilderFontOnly(data.style);
    }, [data.style]);
    const containerStyle = useMemo(() => {
        return textStyleBuilderContainerOnly(data.style);
    }, [data.style]);
    const labelStyleFU = useMemo<IStyle>(() => {
        return toIStyleLabel(labelStyle);
    }, [labelStyle]);
    const containerStyleFU = useMemo<IStyle>(() => {
        return toIStyleContainer(containerStyle);
    }, [containerStyle]);
    return (
        <>
            {data.fieldType === CheckFieldType.CheckBox ? (
                <Checkbox
                    styles={{
                        text: labelStyleFU,
                        input: labelStyleFU,
                        label: labelStyleFU,
                        root: containerStyleFU,
                    }}
                    required={data.mandatory}
                    label={labelResolved}
                    checked={checked}
                    onChange={(_, checked) => assignValue(checked ?? false)}
                />
            ) : null}
            {data.fieldType === CheckFieldType.Toogle ? (
                <Toggle label={labelResolved} styles={{ label: labelStyleFU, root: containerStyleFU }} checked={checked} onChange={(_, checked) => assignValue(checked ?? false)} />
            ) : null}
            {data.fieldType === CheckFieldType.Art ? (
                <div className='DivFlexHorizontalCenter' style={containerStyle}>
                    <SvgButton
                        state={checked ?? false}
                        visualFalse={visualFalse}
                        visualTrue={visualTrue}
                        width={data.dimensions?.width ?? 32}
                        height={data.dimensions?.height ?? 32}
                        onChangeState={(checked) => assignValue(checked)}
                    />
                    <div>
                        <Label style={labelStyle}>{labelResolved}</Label>
                    </div>
                </div>
            ) : null}
        </>
    );
};
