import { Icon, IconButton, Label, SearchBox } from '@fluentui/react';
import clsx from 'clsx';
import { FC, useMemo, useState } from 'react';
import { SimpleDialog } from '../Dialog/SimpleDialog/SimpleDialog';
import './FluentIconSelectoDialog.scss';
import { DefaultEmptyValue, iconList } from './IconsList';

export interface FluentIconSelectorDialogProps {
    label: string;
    icon: string | undefined;
    onChange: (icon: string | undefined) => void;
}

export const FluentIconSelectorDialog: FC<FluentIconSelectorDialogProps> = (props: FluentIconSelectorDialogProps) => {
    const { label, icon, onChange } = props;
    const [searchText, setSearchText] = useState<string>();
    const [editIcon, setEditIcon] = useState<boolean>();
    const icons = useMemo(() => {
        if (searchText && searchText !== '') {
            return iconList.filter((i) => i.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) !== -1);
        }
        return iconList;
    }, [searchText]);
    return (
        <>
            <div className='DivFlexHVertical'>
                {label ? <Label>{label}</Label> : null}
                <div className={clsx('selectIcon', icon ? '' : 'empty')}>
                    <Icon iconName={icon} aria-hidden='true' title={icon} />
                    <IconButton
                        iconProps={{ iconName: 'edit' }}
                        onClick={() => {
                            setEditIcon(true);
                        }}
                    />
                </div>
            </div>
            {editIcon ? (
                <SimpleDialog id={'IconDialogSelector'} title={'Séléctionner une icone'} show={editIcon} onClose={() => setEditIcon(false)}>
                    <SearchBox
                        value={searchText}
                        onChange={(_, text) => {
                            setSearchText(text);
                        }}
                        onClear={() => {
                            setSearchText(undefined);
                        }}
                    />
                    <div className='iconLibrary'>
                        {icons.map((icon) => {
                            return (
                                <div
                                    key={icon}
                                    className='iconItem'
                                    onClick={() => {
                                        if (icon === DefaultEmptyValue) {
                                            onChange(undefined);
                                        } else {
                                            onChange(icon);
                                        }
                                        setEditIcon(false);
                                    }}
                                >
                                    {icon !== DefaultEmptyValue ? <Icon iconName={icon} aria-hidden='true' title={icon} /> : null}
                                    <span>{icon}</span>
                                </div>
                            );
                        })}
                    </div>
                </SimpleDialog>
            ) : null}
        </>
    );
};
