import Player from '@vimeo/player';
import { FC, useLayoutEffect, useRef } from 'react';
import { generateUniqueId } from '../../../helpers/uniqueId';
export interface VimeoVideoPlayerProps {
    videoUrl: string;
    width: number;
    height: number;
}
export const VimeoVideoPlayer: FC<VimeoVideoPlayerProps> = (props: VimeoVideoPlayerProps) => {
    const { videoUrl, height, width } = props;
    const videoPlayerId = useRef(generateUniqueId('video'));
    const player = useRef<Player>();
    useLayoutEffect(() => {
        if (!player.current) {
            player.current = new Player(videoPlayerId.current, {
                url: videoUrl,
                width,
                height,
                byline: false,
                portrait: false,
                title: false,
                // autoplay: true,
                controls: true,
            });
            player.current.getVideoWidth().then((val) => console.log('width  = ' + val));
            player.current.getVideoHeight().then((val) => console.log('height  = ' + val));
        }
    }, [height, width, videoUrl]);
    return (
        <div>
            <div style={{ height, width }} id={videoPlayerId.current}></div>
        </div>
    );
};
