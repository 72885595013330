import { ExpressionItem } from './ExpressionItem';
import { Value } from './Value';
import { FunctionLibrary } from './Function/FunctionLibrary';

export class ExpressionFunction extends ExpressionItem {
    public Name: string;
    public Parameters: ExpressionItem[];
    public Result: Value | null;

    constructor(text: string, name: string, parameters: ExpressionItem[]) {
        super(text);
        this.Name = name;
        this.Parameters = parameters;
        this.Result = null;
    }

    public resolve(): ExpressionItem {
        if (this.next) {
            return this.next.resolve();
        }
        return this;
    }

    public getValue(): Value {
        if (this.Result === null) {
            const args: Value[] = this.Parameters.map((param) => param.resolve().getValue());
            this.Result = FunctionLibrary.invoke(this.Name, args);
        }
        if (this.Result !== null) {
            return this.Result;
        } else {
            // Gérer le cas où this.Result est null
            throw new Error('Result is null');
        }
    }

    public canReturnValue(): boolean {
        return true;
    }
}
