import { Dropdown, Separator, Toggle } from '@fluentui/react';
import BigNumber from 'bignumber.js';
import { FC, useMemo } from 'react';
import { FormGroup } from '../../../../common/components/FormGroup/FormGroup';
import { NumberField } from '../../../../common/components/NumberField/NumberField';
import { PanelCommand } from '../../../../common/components/Panel/Panel';
import { ArrayOperation } from '../../../../common/helpers/ObjectAndArray';
import { DeepPartial, DeepPartialWithArrayFunc, EntityForm } from '../../../../common/Hooks/Form/useForm';
import { ExamModeEnum, IResource, IResourceDataQuestionnary } from '../../../../Services/SakuraApiClient';
import { ExamModes, getTotalPoints } from './ExamModes';
import { ResultDescription } from './ResultDescription';
import { ResultDescriptionBase } from './ResultDescriptionBase';

function setModel(data: DeepPartialWithArrayFunc<IResourceDataQuestionnary>) {
    return { data: { content: { questionnary: data } } };
}
function getModel(data: DeepPartial<IResource>) {
    return data?.data?.content?.questionnary;
}

export interface ScoreProps {
    form: EntityForm<IResource>;
    hasResponse: boolean;
}
export const Score: FC<ScoreProps> = (props: ScoreProps) => {
    const { form, hasResponse } = props;
    const model = useMemo(() => {
        return form.state?.data?.content?.questionnary;
    }, [form.state?.data?.content?.questionnary]);

    const totalPoints = useMemo(() => {
        return getTotalPoints(model);
    }, [model]);

    const examPassedMessage = useMemo(() => {
        const percentage = new BigNumber(model?.examPassedThreshold ?? 0).dividedBy(100);
        if (model?.examMode === ExamModeEnum.QuestionCount) {
            const nbQuestion = model.questions?.length ?? 0;

            const nbQuestionValid = percentage.multipliedBy(nbQuestion).toFixed(0); // Math.trunc(nbQuestion * ((model.examPassedThreshold ?? 0) / 100));
            return `${nbQuestionValid} questions correcte sur ${nbQuestion}`;
        } else if (model?.examMode === ExamModeEnum.Scoring) {
            const minimalPoints = percentage.multipliedBy(totalPoints).toFixed(0); //Math.trunc(totalPoints * ((model.examPassedThreshold ?? 0) / 100));
            return `${minimalPoints} points sur ${totalPoints}`;
        }
        return '';
    }, [model?.examPassedThreshold, model?.examMode, model?.questions?.length, totalPoints]);

    const scoreCommand = useMemo<PanelCommand[]>(() => {
        return [
            {
                text: 'Ajouter',
                icon: 'CircleAddition',
                onClick: () => {
                    form.update(setModel({ scoreDesc: (array) => ArrayOperation.add(array, {}) }));
                },
            },
        ];
    }, [form]);

    return (
        <div>
            <div className='scoring'>
                <Dropdown
                    label='Type de notation'
                    errorMessage={form.fieldErrors.data?.content?.questionnary?.examMode}
                    required
                    options={ExamModes}
                    selectedKey={form.state.data?.content?.questionnary?.examMode}
                    onChange={(_, v) => form.update(setModel({ examMode: v?.key as ExamModeEnum }))}
                />
                <NumberField
                    required
                    suffix='%'
                    label={`Pourcentage de reussite (${examPassedMessage})`}
                    errorMessage={form.fieldErrors.data?.content?.questionnary?.examPassedThreshold}
                    value={getModel(form.state)?.examPassedThreshold}
                    onChange={(examPassedThreshold) => form.update(setModel({ examPassedThreshold }))}
                />
            </div>
            <Toggle
                label={'Affichage des résultats'}
                onText='Afficher le score et son interprétation.'
                offText={"Afficher uniquement l'interprétation du score."}
                checked={getModel(form.state)?.showScoringDetails}
                onChange={(_, showScoringDetails) => form.update(setModel({ showScoringDetails }))}
            />
            <Separator />
            <ResultDescriptionBase item={form.state.data?.content?.questionnary?.resultDescription} onUpdate={(resultDescription) => form.update(setModel({ resultDescription }))} />
            <Separator />
            <FormGroup title='Description en fonction du score' commands={scoreCommand}>
                {(model?.scoreDesc ?? []).map((description, indexCategory: number) => {
                    return (
                        <ResultDescription
                            key={`category_${indexCategory}`}
                            item={description}
                            disableDelete={hasResponse}
                            onUpdate={(data) => {
                                form.update(
                                    setModel({
                                        scoreDesc: (array) => ArrayOperation.updateByIndex(array ?? [], indexCategory, data),
                                    }),
                                );
                            }}
                            onDelete={() => {
                                form.update(
                                    setModel({
                                        scoreDesc: (array) => ArrayOperation.removeByIndex(array ?? [], indexCategory),
                                    }),
                                );
                            }}
                        />
                    );
                })}
            </FormGroup>
        </div>
    );
};
