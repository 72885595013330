import { FC, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { IPageElement, IPageElementContainer, PageElement, PageElementContainerStyle, PageElementType } from '../../../Services/SakuraApiClient';
import { generateUniqueId } from '../../../common/helpers/uniqueId';
import { CustomComponentName } from '../Page/Elements/CustomComponent/type';
import { PageElementEditionBase } from '../Page/PageElementEditionBase';
import { EditorDataContext, IEditorDataContextProvider } from './EditorContext/EditorDataContextProvider';
import { DropPosition, ElementSelector } from './ElementSelector';

export interface PageElementProps {
    index: number;
    data: PageElement;
    parentContainerId?: string;
    orientation?: 'Vertical' | 'Horizontal';
    noDrag?: boolean;
    parentContainerStyle?: PageElementContainerStyle;
    onChangeData?: (data: PageElement, index: number) => void;
    onDropItem?: (position: DropPosition, elementType: PageElementType, elementSubType: CustomComponentName | undefined, index: number) => void;
    onDropLibraryItem?: (position: DropPosition, element: IPageElementContainer, index: number) => void;
    onMoveItem?: (position: DropPosition, element: PageElement, index: number) => void;
    onRemoveItem?: (immediate: boolean, index: number, position?: DropPosition) => void;
    onCopyPaste?: (itemCopied: IPageElement, to: IPageElement, index: number) => void;
}

export const PageElementEdition: FC<PageElementProps> = (props: PageElementProps) => {
    const { data, index, onChangeData, onDropItem, onDropLibraryItem, onMoveItem, onRemoveItem, orientation, parentContainerId, noDrag, parentContainerStyle, onCopyPaste } = props;
    const context = useContext(EditorDataContext);
    const uiComponentIdRef = useRef<string>(generateUniqueId('PI_'));
    const dataRef = useRef<PageElement>(data);
    const indexRef = useRef<number>(index);

    useEffect(() => {
        dataRef.current = data;
    }, [data]);
    useEffect(() => {
        indexRef.current = index;
    }, [index]);
    const onDataChange = useCallback(
        (data: PageElement) => {
            dataRef.current = data;
            if (onChangeData) {
                onChangeData(data, indexRef.current);
            }
        },
        [onChangeData],
    );

    const select = useCallback(
        (currentContext: IEditorDataContextProvider) => {
            currentContext.select(
                uiComponentIdRef.current,
                dataRef.current,
                (newData) => {
                    if (onChangeData) {
                        onChangeData(newData, indexRef.current);
                        return newData;
                    }
                    return dataRef.current;
                },
                () => {
                    if (onRemoveItem) {
                        onRemoveItem(true, indexRef.current);
                    }
                },
            );
        },
        [onChangeData, onRemoveItem],
    );

    useEffect(() => {
        if (context.planSelection) {
            if (data === context.planSelection) {
                select(context);
            }
        } else {
            if (uiComponentIdRef.current === context.selectedUIComponentId) {
                select(context);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, context.selectedUIComponentId, context.planSelection, select]);

    const isSelected = useMemo(() => {
        return context.selectedUIComponentId === uiComponentIdRef.current;
    }, [context.selectedUIComponentId]);
    const onSelect = useCallback(() => {
        select(context);
    }, [select, context]);
    return (
        <ElementSelector
            index={index}
            parentContainerId={parentContainerId}
            orientation={orientation}
            isSelected={isSelected}
            data={data}
            noDrag={noDrag}
            onSelect={onSelect}
            onDropItem={onDropItem}
            onDropLibraryItem={onDropLibraryItem}
            onMoveItem={onMoveItem}
            onRemoveItem={onRemoveItem}
            parentContainerStyle={parentContainerStyle}
            onCopyPaste={onCopyPaste}
        >
            <PageElementEditionBase data={data} type={data.type} isSelected={isSelected} onChangeData={onDataChange} parentContainerStyle={parentContainerStyle} />
        </ElementSelector>
    );
};
