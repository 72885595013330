import { FC } from 'react';
import { IResource, Resource } from '../../../Services/SakuraApiClient';

import { ActionButton, TextField } from '@fluentui/react';
import { refreshAllResourceTiles } from '../../../Redux/Reducers/DataSet/helper';
import { useAppDispatch } from '../../../Redux/hook';
import { useForm } from '../../../common/Hooks/Form';
import * as validators from '../../../common/Hooks/Form/validators';
import { performApiCall } from '../../../common/Hooks/useApiCall';
import { ResourceLookupField } from '../../../common/LookupFields/ResourceLookupField';
import { EditDialog } from '../../../common/components/Dialog/EditDialog/EditDialog';
import { SectionSettingLookup } from '../System/SectionSetting/SectionSettingLookup';

export interface ResourceUXDataChangeProps {
    resource: IResource;
    onClose: (resource: IResource | undefined) => void;
}

export const ResourceUXDataChange: FC<ResourceUXDataChangeProps> = (props: ResourceUXDataChangeProps) => {
    const { resource, onClose } = props;
    const contextId = `EditUxData`;

    const form = useForm<IResource>(
        {
            initialState: resource,
            validators: {
                fields: {
                    uxData: {
                        name: { displayName: 'Le nom', validators: [validators.required] },
                        section: { displayName: 'La section', validators: [validators.required] },
                    },
                },
            },
        },
        resource !== undefined,
    );

    const appDispatch = useAppDispatch();

    const close = (res: IResource | undefined) => {
        form.clearErrors();
        onClose(res);
    };

    const onSubmit = () => {
        const error = form.validate();
        if (!error) {
            performApiCall(
                async (client) => {
                    if (form.state) {
                        const data = new Resource();
                        data.init(form.state);
                        const savedResource = await client.updateResource(resource.id, data);
                        form.commit();
                        refreshAllResourceTiles();
                        close(savedResource);
                    }
                },
                appDispatch,
                { contextId },
            );
        }
    };

    return (
        <EditDialog
            id={contextId}
            icon={{ iconName: 'PreviewLink' }}
            mode={'Edit'}
            title={'Mettre à jour les informations de la tuile (NetFlix)'}
            show={true}
            onSubmit={onSubmit}
            onClose={() => close(undefined)}
        >
            <div>
                <TextField required label='Nom' value={form.state.uxData?.name ?? ''} onChange={(_, v) => form.update({ uxData: { name: v } })} />
                <SectionSettingLookup required label='Section' value={form.state.uxData?.section ?? ''} onChange={(v) => form.update({ uxData: { section: v } })} />
                <TextField label='Description' value={form.state.uxData?.description ?? ''} onChange={(_, v) => form.update({ uxData: { description: v } })} />
                <ResourceLookupField
                    type='ResourceImageVignetteLookup'
                    label='Vignette'
                    selectedResourceId={form.state.thumbnailResourceId}
                    onSelectResource={(resource) => form.update({ thumbnailResourceId: resource?.id })}
                />
                <ActionButton
                    title='En effacant ces informations, la tuile ne sera plus visible.'
                    text='Effacer ces informations'
                    iconProps={{ iconName: 'Delete' }}
                    onClick={() => {
                        performApiCall(
                            async (client) => {
                                await client.deleteResourceUxData(resource.id);
                                close(undefined);
                            },
                            appDispatch,
                            { contextId },
                        );
                    }}
                />
            </div>
        </EditDialog>
    );
};
