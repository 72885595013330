import { FC } from 'react';
import { useDynamicData } from '../../../../../Redux/Reducers/DynamiqueData/reducer';
import { IQuestionnaryQuestion, IQuestionnarySubmissionItem } from '../../../../../Services/SakuraApiClient';
import { useBracketResolver } from '../../../../../common/components/ExpressionField/Hook/useBracketResolver';

import './result.scss';

export interface QuestionResultProps {
    question: IQuestionnaryQuestion;
    answers: IQuestionnarySubmissionItem[];
}
export const QuestionResult: FC<QuestionResultProps> = (props: QuestionResultProps) => {
    const { question, answers } = props;
    const dynData = useDynamicData();

    const resolvedQuestionText = useBracketResolver(question.text, dynData);

    return (
        <div>
            <span>{resolvedQuestionText}</span>
            {answers.map((a, index) => {
                const answerLabels = a.answers?.map((answerId) => {
                    return question.answers?.find((qa) => qa.id === answerId)?.text;
                });
                return (
                    <div key={`${a.questionId}_${index}`}>
                        <>
                            {a.freeText !== null && a.freeText !== undefined ? <span>{a.freeText}</span> : null}
                            {a.answerYes !== null && a.answerYes !== undefined ? <span>{a.answerYes ? 'Oui' : 'Non'}</span> : null}
                            {answerLabels !== null && answerLabels !== undefined && answerLabels.length > 0 ? <span>{answerLabels?.join(', ')}</span> : null}
                        </>
                    </div>
                );
            })}
        </div>
    );
};
