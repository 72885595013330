import { Icon, IconButton, ITextFieldProps, Label, TextField } from '@fluentui/react';
import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { copyClipboard } from '../../helpers/Clipboard';

import './TextFieldExtended.scss';

export interface TextFieldExtendedProps {
    copyMessage?: string;
    onCopy?: () => void;
}
export type ITextFieldExtended = ITextFieldProps & TextFieldExtendedProps;

export const TextFieldExtended: FC<ITextFieldExtended> = (props: ITextFieldExtended) => {
    const { value, className, readOnly, disabled, copyMessage, onCopy } = props;

    const [copied, setCopied] = useState(false);

    const copyText = () => {
        copyClipboard(value || '');
        setCopied(true);
        if (onCopy) {
            onCopy();
        }
    };
    useEffect(() => {
        setCopied(false);
    }, [value]);
    const useClassName = clsx('copyText', className);
    const { label } = props;
    return (
        <div className='DivFlexHVertical'>
            {label && <Label>{label}</Label>}
            <div className='copyContainer'>
                <TextField {...props} readOnly={readOnly} label={undefined} disabled={disabled} className={useClassName} value={value ?? ''} />
                {copyMessage && <IconButton iconProps={{ iconName: 'Copy' }} onClick={() => copyText()} />}
            </div>
            {copied && (
                <div className='flex'>
                    <Icon className='copiedIcon' iconName='CompletedSolid' />
                    <span>{copyMessage}</span>
                </div>
            )}
        </div>
    );
};
