import { ChoiceGroup, IChoiceGroupOption, TextField, Toggle } from '@fluentui/react';
import { FC } from 'react';
import { useAppDispatch } from '../../../../Redux/hook';
import { EnumVideoProvider, IResource, Resource, ResourceContent, ResourceData, ResourceDataVideo, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';
import { useForm } from '../../../../common/Hooks/Form';
import * as validators from '../../../../common/Hooks/Form/validators';
import { performApiCall } from '../../../../common/Hooks/useApiCall';
import { ResourceLookupField } from '../../../../common/LookupFields/ResourceLookupField';
import { EditDialog } from '../../../../common/components/Dialog/EditDialog/EditDialog';
import { GetResourceTypeEntityTitle, GetResourceTypeIcon } from '../../../../common/components/Icons/ResourceTypeIcon';

export interface ResourceVideoDetailsProps {
    resource?: IResource;
    show: boolean;
    category?: string;
    onClose: (resource: IResource | undefined) => void;
}
const getNewResource = (category?: string) => {
    const newResource = new Resource();
    newResource.init({ enabled: true });
    newResource.data = new ResourceData();
    newResource.data.content = new ResourceContent();
    newResource.data.content.video = new ResourceDataVideo();
    newResource.data.content.video.provider = EnumVideoProvider.Vimeo;
    newResource.category = category;
    return newResource;
};

const imageSize = { width: 32, height: 32 };
const options: IChoiceGroupOption[] = [
    {
        key: EnumVideoProvider.Vimeo.toString(),
        imageSrc: '/images/logo/square/vimeo.svg',
        selectedImageSrc: '/images/logo/square/vimeo.svg',
        imageAlt: 'vimeo',
        imageSize,
        text: 'vimeo',
    },
    {
        key: EnumVideoProvider.Youtube.toString(),
        imageSrc: '/images/logo/square/youtube.svg',
        selectedImageSrc: '/images/logo/square/youtube.svg',
        imageAlt: 'youtube',
        imageSize,
        text: 'youtube',
    },
];

export const ResourceVideoDetails: FC<ResourceVideoDetailsProps> = (props: ResourceVideoDetailsProps) => {
    const { resource, show, onClose, category } = props;

    const contextId = `EditVideo`;
    const form = useForm<IResource>(
        {
            initialState: resource ?? getNewResource(category),
            validators: {
                fields: {
                    name: { displayName: 'Le nom', validators: [validators.required] },
                    data: {
                        content: {
                            video: {
                                videoId: { displayName: "L'identifiant", validators: [validators.required] },
                                provider: { displayName: "l'hébergeur", validators: [validators.required] },
                            },
                        },
                    },
                },
            },
        },
        resource !== undefined,
    );

    const appDispatch = useAppDispatch();

    const close = (res: IResource | undefined) => {
        form.clearErrors();
        if (res === undefined) {
            form.rollback();
        }
        onClose(res);
    };

    const onSubmit = () => {
        const error = form.validate();
        if (!error) {
            performApiCall(
                async (client) => {
                    if (form.state) {
                        const data = new Resource();
                        data.init(form.state);
                        data.resourceType = ResourceTypeEnum.Video;

                        let savedResource;
                        if (resource) {
                            savedResource = await client.updateResource(resource.id, data);
                        } else {
                            data.enabled = true;
                            data.reusable = false;
                            savedResource = await client.createResource(data);
                        }
                        form.commit();
                        close(savedResource);
                    }
                },
                appDispatch,
                { contextId },
            );
        }
    };

    return (
        <EditDialog
            id={contextId}
            icon={{ iconName: GetResourceTypeIcon(ResourceTypeEnum.Video) }}
            mode={resource ? 'Edit' : 'Create'}
            title={GetResourceTypeEntityTitle(ResourceTypeEnum.Video, resource ? 'edit' : 'new')}
            subText='Référencer une video youtube ou vimeo'
            show={show}
            onSubmit={onSubmit}
            onClose={() => close(undefined)}
        >
            <div>
                <ChoiceGroup
                    label='Hebergeur video'
                    defaultSelectedKey='vimeo'
                    options={options}
                    required
                    selectedKey={form.state.data?.content?.video?.provider}
                    onChange={(_, opt) => form.update({ data: { content: { video: { provider: opt?.key as EnumVideoProvider } } } })}
                />
                <TextField
                    label='Identifiant de la video'
                    errorMessage={form.fieldErrors.data?.content?.video?.videoId}
                    required
                    value={form.state.data?.content?.video?.videoId ?? ''}
                    onChange={(_, v) => form.update({ data: { content: { video: { videoId: v } } } })}
                />
                <TextField label='Nom' errorMessage={form.fieldErrors.name} required value={form.state.name ?? ''} onChange={(_, v) => form.update({ name: v })} />
                <TextField label='Category' value={form.state.category ?? ''} onChange={(_, v) => form.update({ category: v })} />
                <TextField label='Description' value={form.state.description ?? ''} onChange={(_, v) => form.update({ description: v })} />
                <ResourceLookupField
                    type='ResourceImageVignetteLookup'
                    label='Vignette'
                    selectedResourceId={form.state.thumbnailResourceId}
                    onSelectResource={(resource) => form.update({ thumbnailResourceId: resource?.id })}
                />
                {resource && <Toggle label='Actif' offText='Non' onText='Oui' checked={form.state.enabled} onChange={(_, val) => form.update({ enabled: val })} />}
            </div>
        </EditDialog>
    );
};
