import { IDropdownOption } from '@fluentui/react';
import { screenPaths } from '../../../Routing/ScreenPaths';
import { ResourceTypeEnum } from '../../../Services/SakuraApiClient';

export function GetResourceTypeIcon(resourceType: ResourceTypeEnum): string {
    switch (resourceType) {
        case ResourceTypeEnum.Document:
            return 'TextDocument';
        case ResourceTypeEnum.Audio:
            return 'Speakers';
        case ResourceTypeEnum.Image:
            return 'FileImage';
        case ResourceTypeEnum.Video:
            return 'Video';
        case ResourceTypeEnum.Formation:
            return 'ReadingMode';
        case ResourceTypeEnum.Questionnary:
            return 'Questionnaire';
        case ResourceTypeEnum.Page:
            return 'Page';
        case ResourceTypeEnum.PageBlock:
            return 'WebComponents';
        case ResourceTypeEnum.PageTemplate:
            return 'PageHeader';
        case ResourceTypeEnum.Module:
            return 'Dictionary';
        case ResourceTypeEnum.GameContent:
            return 'Game';
        case ResourceTypeEnum.Workflow:
            return 'SetAction';
    }
    return 'Document';
}
export function GetResourceTypeText(resourceType: ResourceTypeEnum): string {
    switch (resourceType) {
        case ResourceTypeEnum.Document:
            return 'Documents';
        case ResourceTypeEnum.Audio:
            return 'Audios';
        case ResourceTypeEnum.Image:
            return 'Images';
        case ResourceTypeEnum.Video:
            return 'Videos';
        case ResourceTypeEnum.Formation:
            return 'Formations';
        case ResourceTypeEnum.Questionnary:
            return 'Questionnaires';
        case ResourceTypeEnum.Page:
            return 'Pages';
        case ResourceTypeEnum.PageBlock:
            return 'Blocks de page';
        case ResourceTypeEnum.PageTemplate:
            return 'Modéles de page';
        case ResourceTypeEnum.Module:
            return 'Modules';
        case ResourceTypeEnum.GameContent:
            return 'Jeux';
        case ResourceTypeEnum.Workflow:
            return 'Workflow';
    }
    return '<TBD>';
}
export function GetResourceTypeTextOneSpecific(resourceType: ResourceTypeEnum): string {
    switch (resourceType) {
        case ResourceTypeEnum.Document:
            return 'au document';
        case ResourceTypeEnum.Audio:
            return 'au fichier audio';
        case ResourceTypeEnum.Image:
            return "à l'image";
        case ResourceTypeEnum.Video:
            return 'à la video';
        case ResourceTypeEnum.Formation:
            return 'à la formation';
        case ResourceTypeEnum.Questionnary:
            return 'au questionnaire';
        case ResourceTypeEnum.Page:
            return 'à la page';
        case ResourceTypeEnum.PageBlock:
            return 'au Block de page';
        case ResourceTypeEnum.PageTemplate:
            return 'au Modéle de page';
        case ResourceTypeEnum.Module:
            return 'au module';
        case ResourceTypeEnum.GameContent:
            return 'au jeu';
        case ResourceTypeEnum.Workflow:
            return 'au workflow';
    }
    return '<TBD>';
}
export function GetResourceTypeTextOneSpecific2(resourceType: ResourceTypeEnum): string {
    switch (resourceType) {
        case ResourceTypeEnum.Document:
            return 'ce document';
        case ResourceTypeEnum.Audio:
            return 'ce fichier audio';
        case ResourceTypeEnum.Image:
            return 'cette image';
        case ResourceTypeEnum.Video:
            return 'cette video';
        case ResourceTypeEnum.Formation:
            return 'cette formation';
        case ResourceTypeEnum.Questionnary:
            return 'ce questionnaire';
        case ResourceTypeEnum.Page:
            return 'cette page';
        case ResourceTypeEnum.PageBlock:
            return 'ce block de page';
        case ResourceTypeEnum.PageTemplate:
            return 'ce modéle de page';
        case ResourceTypeEnum.Module:
            return 'ce module';
        case ResourceTypeEnum.GameContent:
            return 'ce jeu';
        case ResourceTypeEnum.Workflow:
            return 'ce workflow';
    }
    return '<TBD>';
}
export function GetResourceTypeTextOne(resourceType: ResourceTypeEnum): string {
    switch (resourceType) {
        case ResourceTypeEnum.Document:
            return 'un document';
        case ResourceTypeEnum.Audio:
            return 'un audio';
        case ResourceTypeEnum.Image:
            return 'une image';
        case ResourceTypeEnum.Video:
            return 'une video';
        case ResourceTypeEnum.Formation:
            return 'une formation';
        case ResourceTypeEnum.Questionnary:
            return 'un questionnaire';
        case ResourceTypeEnum.Page:
            return 'une page';
        case ResourceTypeEnum.PageBlock:
            return 'un block de page';
        case ResourceTypeEnum.PageTemplate:
            return 'un modéle de page';
        case ResourceTypeEnum.Module:
            return 'un module';
        case ResourceTypeEnum.GameContent:
            return 'un jeu';
        case ResourceTypeEnum.Workflow:
            return 'un workflow';
    }
    return '<TBD>';
}
export function GetResourceTypeEntityTitle(resourceType: ResourceTypeEnum, mode: 'edit' | 'new'): string {
    switch (resourceType) {
        case ResourceTypeEnum.Document:
            return mode === 'edit' ? 'Modifier le document' : 'Ajouter un document';
        case ResourceTypeEnum.Audio:
            return mode === 'edit' ? 'Modifier le fichier audio' : 'Ajouter un fichier audio';
        case ResourceTypeEnum.Image:
            return mode === 'edit' ? "Modifier l'image" : 'Ajouter une image';
        case ResourceTypeEnum.Video:
            return mode === 'edit' ? 'Modifier la video' : 'Ajouter une video';
        case ResourceTypeEnum.Formation:
            return mode === 'edit' ? 'Modifier la formation' : 'Ajouter une formation';
        case ResourceTypeEnum.Questionnary:
            return mode === 'edit' ? 'Modifier le questionaire' : 'Ajouter un questionaire';
        case ResourceTypeEnum.Page:
            return mode === 'edit' ? 'Modifier la page' : 'Ajouter une page';
        case ResourceTypeEnum.PageBlock:
            return mode === 'edit' ? 'Modifier le block de page' : 'Ajouter un block de page';
        case ResourceTypeEnum.PageTemplate:
            return mode === 'edit' ? 'Modifier le modéle de page' : 'Ajouter un modéle de page';
        case ResourceTypeEnum.GameContent:
            return mode === 'edit' ? 'Modifier le jeu' : 'Ajouter un jeu';
        case ResourceTypeEnum.Workflow:
            return mode === 'edit' ? 'Modifier le workflow' : 'Ajouter un workflow';
    }
    return '<TBD>';
}
export function GetResourceTypeFileExtensions(resourceType: ResourceTypeEnum): string {
    switch (resourceType) {
        case ResourceTypeEnum.Document:
            return '.pdf,.docx,.xlsx,.pptx,.zip';
        case ResourceTypeEnum.Audio:
            return '.mp3,.ogg,.wav';
        case ResourceTypeEnum.Image:
            return '.jpg,.gif,.png,.svg';
    }
    return '<TBD>';
}
export function GetMenuEntry(resourceTypes: ResourceTypeEnum[]) {
    return resourceTypes.map((resourceType) => {
        return {
            key: `resource_${resourceType}`,
            title: GetResourceTypeText(resourceType),
            icon: GetResourceTypeIcon(resourceType),
            path: screenPaths.resourceList(resourceType),
        };
    });
}

export function GetResourceTypeOption(resourceType: ResourceTypeEnum): IDropdownOption {
    return { key: resourceType, text: GetResourceTypeText(resourceType), data: { icon: GetResourceTypeIcon(resourceType) } };
}

export const fullResourceTypeOptions: IDropdownOption[] = [
    GetResourceTypeOption(ResourceTypeEnum.Formation),
    GetResourceTypeOption(ResourceTypeEnum.Module),
    GetResourceTypeOption(ResourceTypeEnum.Page),
    GetResourceTypeOption(ResourceTypeEnum.PageBlock),
    GetResourceTypeOption(ResourceTypeEnum.PageTemplate),
    GetResourceTypeOption(ResourceTypeEnum.Questionnary),
    GetResourceTypeOption(ResourceTypeEnum.Document),
    GetResourceTypeOption(ResourceTypeEnum.Audio),
    GetResourceTypeOption(ResourceTypeEnum.Video),
    GetResourceTypeOption(ResourceTypeEnum.Image),
    GetResourceTypeOption(ResourceTypeEnum.GameContent),
    GetResourceTypeOption(ResourceTypeEnum.Workflow),
    GetResourceTypeOption(ResourceTypeEnum.Character),
    GetResourceTypeOption(ResourceTypeEnum.CharacterSpeech),
];
