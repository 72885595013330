//note that we don't use export default here
//create context here, use context in others

import React, { createContext, FC, PropsWithChildren, useReducer } from 'react';
import { jsonParse } from '../../../../common/helpers/Json';
import { ExecutionDataContextAction, ExecutionDataContextReducer, ExecutionDataContextReducerType } from './ExecutionDataContextReducer';
import { IExecutionContextState, IResourceInfo } from './IExecutionContextState';

const emptyContext = { resources: {}, serializationIdentifier: undefined, pageResourceId: undefined, parentResourcePathId: undefined };
//this DataContext will be shared by all the components

export const ExecutionDataContext = createContext<[IExecutionContextState, React.Dispatch<ExecutionDataContextAction>]>([emptyContext, (_) => alert('notset')]);

export interface ExecutionDataContextProviderProps {
    pageResourceId: number | undefined;
    parentResourcePathId: string | undefined;
    resources?: Record<number, IResourceInfo>;
    serializationIdentifier: string | undefined;
}
export const ExecutionDataContextProvider: FC<PropsWithChildren<ExecutionDataContextProviderProps>> = (props: PropsWithChildren<ExecutionDataContextProviderProps>) => {
    const { children, resources, serializationIdentifier, pageResourceId, parentResourcePathId } = props;

    const [state, dispatch] = useReducer<ExecutionDataContextReducerType, IExecutionContextState>(
        ExecutionDataContextReducer,
        { ...emptyContext, serializationIdentifier, resources: resources ?? {}, pageResourceId, parentResourcePathId },
        (arg) => {
            if (serializationIdentifier) {
                const json = localStorage.getItem(`ExecData_${serializationIdentifier}`);
                if (json) {
                    const existingState = jsonParse(json) as IExecutionContextState;
                    return existingState;
                }
            }
            return { ...arg, serializationIdentifier };
        },
    );

    // useEffect(() => {
    //     if (resources) {
    //         dispatch({ type: 'setResources', payload: { resources } });
    //     }
    // }, [pageResourceId, parentResourcePathId, resources, serializationIdentifier]);
    console.log(`context : ${pageResourceId} , ${state.pageResourceId}`);
    return <ExecutionDataContext.Provider value={[state, dispatch]}>{children}</ExecutionDataContext.Provider>;
};

export function deletePersistantExecutionDataContext(identifier: string) {
    localStorage.removeItem(`ExecData_${identifier}`);
}
