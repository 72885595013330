import { FC, useEffect, useState } from 'react';

import { ProductGrid } from '../../../Redux/Reducers/DataSet/ApiCall/Product';
import { clearContext } from '../../../Redux/Reducers/System/reducer';
import { useAppDispatch } from '../../../Redux/hook';
import { screenPaths } from '../../../Routing/ScreenPaths';
import { IProduct } from '../../../Services/SakuraApiClient';
import { performApiCall } from '../../../common/Hooks/useApiCall';
import { INavigationState, useNav } from '../../../common/Hooks/useNav';
import { FilterType } from '../../../common/components/CustomDetailList/FilteredHeaderColumn';
import { ConfirmationDialog } from '../../../common/components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { IconEnabled } from '../../../common/components/Icons/IconEnabled';
import { Panel } from '../../../common/components/Panel/Panel';
import { DatasetGrid } from '../../Common/DatasetGrid';
import { getProductTypeText } from './ProductType';
import { ProductUXDataChange } from './ProductUXDataChange';

interface NavState {
    refresh?: boolean;
}

export const ProductsList: FC = () => {
    const [selectedProduct, setSelectedProduct] = useState<IProduct | undefined>();
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [showEditUxData, setShowEditUxData] = useState<boolean>(false);
    const appDispatch = useAppDispatch();
    const onSelect = (product: IProduct | undefined) => {
        setSelectedProduct(product);
    };
    const nav = useNav();
    const navState = nav.current.state as INavigationState<NavState>;
    useEffect(() => {
        if (navState?.state?.refresh) {
            ProductGrid.refresh();
        }
    }, [navState?.state?.refresh]);
    const columns = [
        {
            key: 'name',
            name: 'Nom',
            fieldName: 'name',
            minWidth: 200,
            maxWidth: 200,
            allowSorting: true,
            isResizable: true,
            filterOption: {
                type: FilterType.Text,
                placeholder: 'entrer un nom',
                fieldNames: [
                    {
                        fieldName: 'name',
                        displayName: 'Nom',
                    },
                ],
            },
        },
        {
            key: 'type',
            name: 'Type',

            minWidth: 200,
            maxWidth: 200,
            allowSorting: true,
            isResizable: true,
            onRender: (item: IProduct) => {
                return <span>{getProductTypeText(item.productType)}</span>;
            },
        },
        {
            key: 'description',
            name: 'Description',
            fieldName: 'description',
            minWidth: 300,
            maxWidth: 300,
            allowSorting: true,
            isResizable: true,
        },
        {
            key: 'sellInStore',
            name: 'Digital store',
            fieldName: 'sellInStore',
            minWidth: 200,
            maxWidth: 200,
            allowSorting: true,
            isResizable: true,
            onRender: (item: IProduct) => {
                return <IconEnabled enabled={item.sellInStore} />;
            },
        },
        {
            key: 'releaseDate',
            name: 'Date de sortie',
            fieldName: 'releaseDate',
            minWidth: 100,
            maxWidth: 100,
            allowSorting: true,
            isResizable: true,
            onRender: (item: IProduct) => {
                return <span>{item.releaseDate?.toLocaleDateString() ?? ''}</span>;
            },
        },
    ];

    return (
        <Panel
            title='Liste des produits'
            icon='Product'
            commands={[
                {
                    icon: 'AddTo',
                    text: 'Ajouter',
                    onClick: () => {
                        nav.navigate(screenPaths.productCreate);
                    },
                },
                {
                    icon: 'Edit',
                    text: 'Modifier',
                    disabled: !selectedProduct,
                    onClick: () => {
                        if (selectedProduct?.id) {
                            nav.navigate(screenPaths.productUpdate(selectedProduct.id), selectedProduct);
                        }
                    },
                },
                {
                    icon: 'PreviewLink',
                    text: 'Modifier la tuile',
                    disabled: !selectedProduct,
                    onClick: () => {
                        if (selectedProduct?.id) {
                            setShowEditUxData(true);
                        }
                    },
                },
                {
                    icon: 'Delete',
                    text: 'Supprimer',
                    disabled: !selectedProduct,
                    onClick: () => {
                        setShowDeleteDialog(true);
                    },
                },
                {
                    icon: 'Refresh',
                    text: 'Actualiser',
                    onClick: () => {
                        ProductGrid.refresh();
                    },
                },
            ]}
        >
            <DatasetGrid
                datasetName={'ProductGrid'}
                columns={columns}
                getKey={(item) => item.id ?? ''}
                onSelect={onSelect}
                onRowDoubleClick={(item) => {
                    nav.navigate(screenPaths.productUpdate(item.id), item);
                }}
            />
            <ConfirmationDialog
                id='ConfirmationDialog'
                icon={{ iconName: 'Product' }}
                show={showDeleteDialog}
                title='Suppression du produit'
                subText='Etes-vous sur de vouloir supprimer ce produit?'
                onReply={(reply) => {
                    if (reply) {
                        performApiCall(
                            async (client) => {
                                if (selectedProduct?.id) {
                                    await client.deleteProduct(selectedProduct.id);
                                    setShowDeleteDialog(false);
                                    ProductGrid.refresh();
                                }
                            },
                            appDispatch,
                            { contextId: 'ConfirmationDialog' },
                        );
                    } else {
                        setShowDeleteDialog(false);
                        appDispatch(
                            clearContext({
                                contextId: 'ConfirmationDialog',
                            }),
                        );
                    }
                }}
            />
            {showEditUxData && selectedProduct ? (
                <ProductUXDataChange
                    product={selectedProduct}
                    onClose={(prd) => {
                        setShowEditUxData(false);
                        if (prd) {
                            ProductGrid.refresh();
                        }
                    }}
                />
            ) : null}
        </Panel>
    );
};
