import { MessageBar } from '@fluentui/react';
import { FC } from 'react';
import { useAppDispatch } from '../../../Redux/hook';
import { setMessage, useMessageSelector } from '../../../Redux/Reducers/System/reducer';

export interface ErrorMessageBarProps {
    contextId: string;
    reserveSpace?: boolean;
}
export const ErrorMessageBar: FC<ErrorMessageBarProps> = (props: ErrorMessageBarProps) => {
    const { contextId, reserveSpace } = props;
    const errorMessage = useMessageSelector(contextId);
    const appDispatch = useAppDispatch();
    return (
        <>
            {errorMessage ? (
                <MessageBar
                    messageBarType={errorMessage.Severity}
                    isMultiline={false}
                    onDismiss={() => {
                        appDispatch(setMessage({ contextId, message: undefined }));
                    }}
                    dismissButtonAriaLabel='Close'
                    truncated={true}
                    overflowButtonAriaLabel='See more'
                >
                    {errorMessage.text}
                </MessageBar>
            ) : (
                reserveSpace && <div style={{ height: 30 }} />
            )}
        </>
    );
};
