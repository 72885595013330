import { Dropdown, IDropdownOption, PrimaryButton, Separator, TextField } from '@fluentui/react';
import { FC, useCallback, useEffect, useState } from 'react';
import { storageEndpointsSelector, useLoadingSelector } from '../../../Redux/Reducers/System/reducer';
import { addBadgeToDisplay } from '../../../Redux/Reducers/User/reducer';
import { useAppDispatch, useAppSelector } from '../../../Redux/hook';
import { Badge, BadgeInformation, BadgeSetting, BadgeTypeEnum, EnumAlignement, EnumUnit, Graphics, IBadge, Workflow } from '../../../Services/SakuraApiClient';
import { ST } from '../../../common/Hooks/StorageResolver';
import { performApiCall } from '../../../common/Hooks/useApiCall';
import { BadgeViewer } from '../../../common/components/Badge/BadgeViewer';
import { getBadgeTypeLabel } from '../../../common/components/Badge/helper';
import { EditDialog } from '../../../common/components/Dialog/EditDialog/EditDialog';
import { FilePicker } from '../../../common/components/FilePicker';
import { FormGroup } from '../../../common/components/FormGroup/FormGroup';
import { ObjectOperation } from '../../../common/helpers/ObjectAndArray';
import { WorkflowEdit } from '../Workflow/WorkflowEdit';

export const badgeTypes: IDropdownOption[] = [
    {
        key: BadgeTypeEnum.Normal,
        text: getBadgeTypeLabel(BadgeTypeEnum.Normal),
        title: 'Badge le plus commun a obtenir',
    },
    {
        key: BadgeTypeEnum.Rare,
        text: getBadgeTypeLabel(BadgeTypeEnum.Rare),
        title: 'Badge un peu plus rare a obtenir',
    },
    {
        key: BadgeTypeEnum.Epic,
        text: getBadgeTypeLabel(BadgeTypeEnum.Epic),
        title: 'Badge Epique trés rare a obtenir',
    },
    {
        key: BadgeTypeEnum.Legendary,
        text: getBadgeTypeLabel(BadgeTypeEnum.Legendary),
        title: 'Badge legendaire, tres difficile a obtenir',
    },
    {
        key: BadgeTypeEnum.Mythical,
        text: getBadgeTypeLabel(BadgeTypeEnum.Mythical),
        title: 'Badge Mythique, exttrément tres difficile a obtenir',
    },
];
export interface BadgeDetailProps {
    title: string;
    badge?: IBadge | undefined;
    onClose: (badge: IBadge | undefined) => void;
}
const newBadge = new Badge();
newBadge.init({
    badgeType: BadgeTypeEnum.Normal,
    badgeSetting: new BadgeSetting({
        graphics: new Graphics({
            height: 1,
            width: 1,
            positionX: 0,
            positionY: 0,
            horizontalAlignment: EnumAlignement.Center,
            verticalAlignment: EnumAlignement.Center,
            unit: EnumUnit.Percentage,
        }),
        workflow: new Workflow(),
    }),
});
const contextId = 'badgeDetails';
export const BadgeDetails: FC<BadgeDetailProps> = (props: BadgeDetailProps) => {
    const dispatch = useAppDispatch();
    const loading = useLoadingSelector(contextId);
    const storage = useAppSelector(storageEndpointsSelector);
    const { onClose, badge, title } = props;
    const [entity, setEntity] = useState<IBadge>(badge ?? newBadge);
    const [currentFile, setCurrentFile] = useState<File>();

    useEffect(() => {
        setEntity(badge ?? newBadge);
    }, [badge]);

    const onSave = useCallback(() => {
        const payload = new BadgeInformation();
        payload.init({
            name: entity.name,
            description: entity.description,
            type: entity.badgeType,
            badgeSetting: entity.badgeSetting,
        });

        if (entity.id === undefined) {
            performApiCall(
                async (client) => {
                    let response = await client.createBadge(payload);
                    setEntity(response);
                    if (currentFile) {
                        response = await client.updloadBadgePicture(response.id, {
                            data: currentFile,
                            fileName: currentFile.name,
                        });
                    }
                    onClose(response);
                },
                dispatch,
                { contextId },
            );
        } else {
            performApiCall(
                async (client) => {
                    let response = await client.updateBadge(entity.id, payload);
                    setEntity(response);
                    if (currentFile) {
                        response = await client.updloadBadgePicture(response.id, {
                            data: currentFile,
                            fileName: currentFile.name,
                        });
                    }
                    onClose(response);
                },
                dispatch,
                { contextId },
            );
        }
    }, [currentFile, dispatch, entity.badgeSetting, entity.badgeType, entity.description, entity.id, entity.name, onClose]);
    const onSelectFile = (file: File | null | undefined) => {
        setCurrentFile(file ?? undefined);
    };
    const onTest = useCallback(() => {
        dispatch(addBadgeToDisplay({ badge: entity }));
    }, [dispatch, entity]);
    return (
        <EditDialog id={contextId} icon={{ iconName: 'Badge' }} mode={badge ? 'Edit' : 'Create'} title={title} show={true} onSubmit={() => onSave()} onClose={() => onClose(undefined)}>
            <FormGroup isCollapsable defaultOpenState={true} title={'Information du badge'}>
                <TextField label='Nom' value={entity.name ?? ''} onChange={(_, v) => setEntity({ ...entity, name: v })} />
                <Dropdown label='Type' options={badgeTypes} selectedKey={entity.badgeType ?? ''} onChange={(_, opt) => setEntity({ ...entity, badgeType: opt?.key as BadgeTypeEnum })} />
                <div className='badgeSelector'>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                            justifyContent: 'center',
                        }}
                    >
                        <FilePicker label='Fichier SVG' extension='.svg' onChange={onSelectFile} />
                        <PrimaryButton iconProps={{ iconName: 'Play' }} disabled={loading?.isLoading} onClick={onTest}>
                            Tester
                        </PrimaryButton>
                    </div>
                    <div className='badgePicture'>
                        <BadgeViewer
                            editable
                            width={100}
                            height={100}
                            url={ST(storage, entity.imageBlobPath)}
                            file={currentFile}
                            type={entity.badgeType}
                            graphics={entity.badgeSetting.graphics}
                            onChangeGraphic={(g) =>
                                setEntity({
                                    ...entity,
                                    badgeSetting: new BadgeSetting({
                                        ...entity.badgeSetting,
                                        graphics: new Graphics(g),
                                    }),
                                })
                            }
                        />
                    </div>
                </div>

                <TextField label='Description' multiline value={entity.description ?? ''} onChange={(_, v) => setEntity({ ...entity, description: v })} />
            </FormGroup>
            <Separator />
            <WorkflowEdit
                workflow={entity.badgeSetting?.workflow}
                onChange={(workflow) => {
                    const wf = new Workflow();
                    if (workflow) {
                        wf.init(workflow);
                    }
                    setEntity(ObjectOperation.merge(entity, { badgeSetting: { workflow: wf } }));
                }}
            />
        </EditDialog>
    );
};
