import clsx from 'clsx';
import { FC, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { AccordionIcon } from '../AccordionIcon/AccordionIcon';
import './Accordion.scss';

export interface AccordionProps {
    title: string;
    isOpen?: boolean;
    defaultState?: boolean;
    onRenderTitle?: (title: string) => JSX.Element;
    alwaysVisibleChildren?: ReactNode | undefined;
    onStateChange?: (open: boolean) => void;
    lightMode?: boolean;
}

export const Accordion: FC<PropsWithChildren<AccordionProps>> = (props: PropsWithChildren<AccordionProps>) => {
    const { title, onRenderTitle, isOpen, onStateChange, children, alwaysVisibleChildren, lightMode, defaultState } = props;
    const [isOpenState, setIsOpenState] = useState<boolean>(isOpen ?? defaultState ?? false);

    useEffect(() => {
        setIsOpenState(isOpen ?? defaultState ?? false);
    }, [isOpen, defaultState]);

    const changeState = (newState: boolean) => {
        if (onStateChange) {
            onStateChange(newState);
        } else {
            setIsOpenState(newState);
        }
    };

    return (
        <div className='accordion'>
            <div
                className={clsx('accordion-header', lightMode ? '' : 'full')}
                role='button'
                tabIndex={0}
                onKeyUp={(key) => {
                    if (key.key === ' ') {
                        changeState(!isOpenState);
                    }
                }}
                onClick={() => {
                    changeState(!isOpenState);
                }}
            >
                <AccordionIcon open={isOpenState} />

                {onRenderTitle ? onRenderTitle(title) : <span>{title}</span>}
            </div>
            {alwaysVisibleChildren && (
                <div className={clsx('accordion-body', lightMode ? '' : 'full')}>
                    {alwaysVisibleChildren}
                    <div style={!isOpenState ? { display: 'none' } : undefined}>{children}</div>
                </div>
            )}
            {!alwaysVisibleChildren && (
                <div className={clsx('accordion-body', lightMode ? '' : 'full')} style={!isOpenState ? { display: 'none' } : undefined}>
                    {children}
                </div>
            )}
        </div>
    );
};
