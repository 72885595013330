export const DefaultEmptyValue = 'Aucune';
export const iconList = [
    DefaultEmptyValue,
    '12PointStar',
    '6PointStar',
    'AADLogo',
    'Accept',
    'AccessibiltyChecker',
    'AccessLogo',
    'AccessLogoFill',
    'AccountActivity',
    'AccountBrowser',
    'AccountManagement',
    'Accounts',
    'ActionCenter',
    'ActivateOrders',
    'ActivityFeed',
    'Add',
    'AddBookmark',
    'AddEvent',
    'AddFavorite',
    'AddFavoriteFill',
    'AddFriend',
    'AddGroup',
    'AddHome',
    'AddIn',
    'AddLink',
    'AddNotes',
    'AddOnlineMeeting',
    'AddPhone',
    'AddReaction',
    'AddTo',
    'AddToShoppingList',
    'AddWork',
    'Admin',
    'AdminALogo32',
    'AdminALogoFill32',
    'AdminALogoInverse32',
    'AdminCLogoInverse32',
    'AdminDLogoInverse32',
    'AdminELogoInverse32',
    'AdminLLogoInverse32',
    'AdminMLogoInverse32',
    'AdminOLogoInverse32',
    'AdminPLogoInverse32',
    'AdminSLogoInverse32',
    'AdminYLogoInverse32',
    'Airplane',
    'AirplaneSolid',
    'AirTickets',
    'AlarmClock',
    'Album',
    'AlbumRemove',
    'AlertSettings',
    'AlertSolid',
    'AlignCenter',
    'AlignHorizontalCenter',
    'AlignHorizontalLeft',
    'AlignHorizontalRight',
    'AlignJustify',
    'AlignLeft',
    'AlignRight',
    'AlignVerticalBottom',
    'AlignVerticalCenter',
    'AlignVerticalTop',
    'AllApps',
    'AllAppsMirrored',
    'AllCurrency',
    'AnalyticsLogo',
    'AnalyticsQuery',
    'AnalyticsReport',
    'AnalyticsView',
    'AnchorLock',
    'Annotation',
    'AppIconDefault',
    'AppIconDefaultAdd',
    'AppIconDefaultList',
    'Archive',
    'AreaChart',
    'ArrangeBringForward',
    'ArrangeBringToFront',
    'ArrangeByFrom',
    'ArrangeSendBackward',
    'ArrangeSendToBack',
    'Arrivals',
    'ArrowDownRight8',
    'ArrowDownRightMirrored8',
    'ArrowTallDownLeft',
    'ArrowTallDownRight',
    'ArrowTallUpLeft',
    'ArrowTallUpRight',
    'ArrowUpRight',
    'ArrowUpRight8',
    'ArrowUpRightMirrored8',
    'Articles',
    'Ascending',
    'AspectRatio',
    'AssessmentGroup',
    'AssessmentGroupTemplate',
    'AssetLibrary',
    'Assign',
    'Asterisk',
    'AsteriskSolid',
    'ATPLogo',
    'Attach',
    'AustralianRules',
    'AuthenticatorApp',
    'AutoEnhanceOff',
    'AutoEnhanceOn',
    'AutoFillTemplate',
    'AutoHeight',
    'AutoRacing',
    'AwayStatus',
    'AzureAPIManagement',
    'AzureKeyVault',
    'AzureLogo',
    'AzureServiceEndpoint',
    'Back',
    'BackgroundColor',
    'Backlog',
    'BacklogBoard',
    'BacklogList',
    'BackToWindow',
    'Badge',
    'Balloons',
    'Bank',
    'BankSolid',
    'BarChart4',
    'BarChartHorizontal',
    'BarChartVertical',
    'BarChartVerticalEdit',
    'BarChartVerticalFill',
    'BarChartVerticalFilter',
    'BarChartVerticalFilterSolid',
    'Baseball',
    'BeerMug',
    'BIDashboard',
    'BidiLtr',
    'BidiRtl',
    'BingLogo',
    'BirthdayCake',
    'BlockContact',
    'Blocked',
    'Blocked12',
    'Blocked2',
    'Blocked2Solid',
    'BlockedSite',
    'BlockedSiteSolid12',
    'BlockedSolid',
    'Blog',
    'BlowingSnow',
    'Blur',
    'Boards',
    'Bold',
    'BookAnswers',
    'BookingsLogo',
    'BookmarkReport',
    'Bookmarks',
    'BookmarksMirrored',
    'BorderDash',
    'BorderDot',
    'BoxAdditionSolid',
    'BoxCheckmarkSolid',
    'BoxMultiplySolid',
    'BoxPlaySolid',
    'BoxSubtractSolid',
    'BranchCommit',
    'BranchCompare',
    'BranchFork',
    'BranchFork2',
    'BranchLocked',
    'BranchMerge',
    'BranchPullRequest',
    'BranchSearch',
    'BranchShelveset',
    'Breadcrumb',
    'Breakfast',
    'Brightness',
    'Broom',
    'BrowserScreenShot',
    'BrowserTab',
    'BrowserTabScreenshot',
    'Brunch',
    'Brush',
    'BucketColor',
    'BucketColorFill',
    'BufferTimeAfter',
    'BufferTimeBefore',
    'BufferTimeBoth',
    'Bug',
    'BugSolid',
    'Build',
    'BuildDefinition',
    'BuildIssue',
    'BuildQueue',
    'BuildQueueNew',
    'BulkUpload',
    'BulletedList',
    'BulletedList2',
    'BulletedList2Mirrored',
    'BulletedListBullet',
    'BulletedListBulletMirrored',
    'BulletedListMirrored',
    'BulletedListText',
    'BulletedListTextMirrored',
    'BulletedTreeList',
    'Bullseye',
    'BullseyeTarget',
    'BullseyeTargetEdit',
    'Bus',
    'BusinessCenterLogo',
    'BusinessHoursSign',
    'BusSolid',
    'ButtonControl',
    'Cafe',
    'Cake',
    'Calculator',
    'CalculatorAddition',
    'CalculatorEqualTo',
    'CalculatorMultiply',
    'CalculatorNotEqualTo',
    'CalculatorPercentage',
    'CalculatorSubtract',
    'Calendar',
    'CalendarAgenda',
    'CalendarDay',
    'CalendarMirrored',
    'CalendarReply',
    'CalendarSettings',
    'CalendarSettingsMirrored',
    'CalendarWeek',
    'CalendarWorkWeek',
    'Calories',
    'CaloriesAdd',
    'Camera',
    'CampaignTemplate',
    'Cancel',
    'CannedChat',
    'Car',
    'CaretBottomLeftCenter8',
    'CaretBottomLeftSolid8',
    'CaretBottomRightCenter8',
    'CaretBottomRightSolid8',
    'CaretDown8',
    'CaretDownSolid8',
    'CaretHollow',
    'CaretHollowMirrored',
    'CaretLeft8',
    'CaretLeftSolid8',
    'CaretRight',
    'CaretRight8',
    'CaretRightSolid8',
    'CaretSolid',
    'CaretSolid16',
    'CaretSolidDown',
    'CaretSolidLeft',
    'CaretSolidMirrored',
    'CaretSolidRight',
    'CaretSolidUp',
    'CaretTopLeftCenter8',
    'CaretTopLeftSolid8',
    'CaretTopRightCenter8',
    'CaretTopRightSolid8',
    'CaretUp8',
    'CaretUpSolid8',
    'Cat',
    'CellPhone',
    'Certificate',
    'CertifiedDatabase',
    'ChangeEntitlements',
    'Chart',
    'ChartSeries',
    'ChartTemplate',
    'ChartXAngle',
    'ChartYAngle',
    'Chat',
    'ChatBot',
    'ChatInviteFriend',
    'ChatSolid',
    'Checkbox',
    'CheckboxComposite',
    'CheckboxCompositeReversed',
    'CheckboxFill',
    'CheckboxIndeterminate',
    'CheckedOutByOther12',
    'CheckedOutByYou12',
    'CheckList',
    'CheckListCheck',
    'CheckListCheckMirrored',
    'CheckListText',
    'CheckListTextMirrored',
    'CheckMark',
    'ChevronDown',
    'ChevronDownEnd6',
    'ChevronDownMed',
    'ChevronDownSmall',
    'ChevronFold10',
    'ChevronLeft',
    'ChevronLeftEnd6',
    'ChevronLeftMed',
    'ChevronLeftSmall',
    'ChevronRight',
    'ChevronRightEnd6',
    'ChevronRightMed',
    'ChevronRightSmall',
    'ChevronUnfold10',
    'ChevronUp',
    'ChevronUpEnd6',
    'ChevronUpMed',
    'ChevronUpSmall',
    'Childof',
    'Chopsticks',
    'ChromeBack',
    'ChromeBackMirrored',
    'ChromeClose',
    'ChromeFullScreen',
    'ChromeMinimize',
    'ChromeRestore',
    'CircleAddition',
    'CircleAdditionSolid',
    'CircleFill',
    'CircleHalfFull',
    'CirclePause',
    'CirclePauseSolid',
    'CirclePlus',
    'CircleRing',
    'CircleShapeSolid',
    'CircleStop',
    'CircleStopSolid',
    'CityNext',
    'CityNext2',
    'ClassNotebookLogo16',
    'ClassNotebookLogo32',
    'ClassNotebookLogoFill16',
    'ClassNotebookLogoFill32',
    'ClassNotebookLogoInverse',
    'ClassNotebookLogoInverse16',
    'ClassNotebookLogoInverse32',
    'ClassroomLogo',
    'Clear',
    'ClearFilter',
    'ClearFormatting',
    'ClearFormattingA',
    'ClearFormattingEraser',
    'ClearNight',
    'ClearSelection',
    'ClearSelectionMirrored',
    'ClipboardList',
    'ClipboardListMirrored',
    'ClipboardSolid',
    'Clock',
    'CloneToDesktop',
    'ClosedCaption',
    'ClosePane',
    'ClosePaneMirrored',
    'Cloud',
    'CloudAdd',
    'CloudDownload',
    'CloudImportExport',
    'CloudUpload',
    'CloudWeather',
    'Cloudy',
    'Cocktails',
    'Code',
    'CodeEdit',
    'Coffee',
    'CoffeeScript',
    'CollapseContent',
    'CollapseContentSingle',
    'CollapseMenu',
    'CollegeFootball',
    'CollegeHoops',
    'Color',
    'ColorSolid',
    'ColumnLeftTwoThirds',
    'ColumnLeftTwoThirdsEdit',
    'ColumnOptions',
    'ColumnRightTwoThirds',
    'ColumnRightTwoThirdsEdit',
    'ColumnVerticalSection',
    'ColumnVerticalSectionEdit',
    'Combine',
    'Combobox',
    'CommandPrompt',
    'Comment',
    'CommentActive',
    'CommentAdd',
    'CommentNext',
    'CommentPrevious',
    'CommentSolid',
    'CommentUrgent',
    'Commitments',
    'Communications',
    'CompanyDirectory',
    'CompanyDirectoryMirrored',
    'Compare',
    'CompassNW',
    'Completed',
    'CompletedSolid',
    'ComplianceAudit',
    'ConfigurationSolid',
    'ConnectContacts',
    'ConnectVirtualMachine',
    'ConstructionCone',
    'ConstructionConeSolid',
    'Contact',
    'ContactCard',
    'ContactCardSettings',
    'ContactCardSettingsMirrored',
    'ContactHeart',
    'ContactInfo',
    'ContactLink',
    'ContactList',
    'ContextMenu',
    'Contrast',
    'Copy',
    'Cotton',
    'Coupon',
    'CPlusPlus',
    'CPlusPlusLanguage',
    'CreateMailRule',
    'Cricket',
    'CRMCustomerInsightsApp',
    'CRMProcesses',
    'CRMReport',
    'CRMServices',
    'Crop',
    'Crown',
    'CrownSolid',
    'CSharp',
    'CSharpLanguage',
    'CSS',
    'CubeShape',
    'CustomizeToolbar',
    'CustomList',
    'CustomListMirrored',
    'Cut',
    'Cycling',
    'D365TalentHRCore',
    'D365TalentInsight',
    'D365TalentLearn',
    'DashboardAdd',
    'Database',
    'DatabaseSync',
    'DataConnectionLibrary',
    'Dataflows',
    'DataflowsLink',
    'DataManagementSettings',
    'DateTime',
    'DateTime2',
    'DateTimeMirrored',
    'DeactivateOrders',
    'Decimals',
    'DecisionSolid',
    'DeclineCall',
    'DecreaseIndentArrow',
    'DecreaseIndentArrowMirrored',
    'DecreaseIndentLegacy',
    'DecreaseIndentText',
    'DecreaseIndentTextMirrored',
    'DefaultRatio',
    'DefectSolid',
    'DefenderTVM',
    'Delete',
    'DeleteColumns',
    'DeleteRows',
    'DeleteRowsMirrored',
    'DeleteTable',
    'DeliveryTruck',
    'DelveAnalytics',
    'DelveAnalyticsLogo',
    'DelveLogo',
    'DelveLogoFill',
    'DelveLogoInverse',
    'DependencyAdd',
    'DependencyRemove',
    'Deploy',
    'Descending',
    'Design',
    'DesktopScreenshot',
    'DeveloperTools',
    'Devices2',
    'Devices3',
    'Devices4',
    'Diagnostic',
    'DiagnosticDataBarTooltip',
    'Dialpad',
    'Diamond',
    'DiamondSolid',
    'Dictionary',
    'DictionaryRemove',
    'DietPlanNotebook',
    'DiffInline',
    'DiffSideBySide',
    'DisableUpdates',
    'DisconnectVirtualMachine',
    'Dislike',
    'DislikeSolid',
    'DistributeDown',
    'DockLeft',
    'DockLeftMirrored',
    'DockRight',
    'DocLibrary',
    'DocsLogoInverse',
    'Document',
    'DocumentApproval',
    'Documentation',
    'DocumentManagement',
    'DocumentReply',
    'DocumentSearch',
    'DocumentSet',
    'DOM',
    'DonutChart',
    'Door',
    'DoubleBookmark',
    'DoubleChevronDown',
    'DoubleChevronDown12',
    'DoubleChevronDown8',
    'DoubleChevronLeft',
    'DoubleChevronLeft12',
    'DoubleChevronLeft8',
    'DoubleChevronLeftMed',
    'DoubleChevronLeftMedMirrored',
    'DoubleChevronRight',
    'DoubleChevronRight12',
    'DoubleChevronRight8',
    'DoubleChevronUp',
    'DoubleChevronUp12',
    'DoubleChevronUp8',
    'DoubleColumn',
    'DoubleColumnEdit',
    'DoubleDownArrow',
    'Down',
    'Download',
    'DownloadDocument',
    'DragObject',
    'DrillDown',
    'DrillDownSolid',
    'DrillExpand',
    'DrillShow',
    'DrillThrough',
    'DRM',
    'Drop',
    'Dropdown',
    'DropShapeSolid',
    'DuplicateRow',
    'Duststorm',
    'Dynamics365Logo',
    'DynamicSMBLogo',
    'EaseOfAccess',
    'EatDrink',
    'EdgeLogo',
    'Edit',
    'EditContact',
    'EditCreate',
    'EditMail',
    'EditMirrored',
    'EditNote',
    'EditPhoto',
    'EditSolid12',
    'EditSolidMirrored12',
    'EditStyle',
    'Education',
    'Ellipse',
    'Embed',
    'EMI',
    'Emoji',
    'Emoji2',
    'EmojiDisappointed',
    'EmojiNeutral',
    'EmojiTabSymbols',
    'EmptyRecycleBin',
    'Encryption',
    'EngineeringGroup',
    'EntitlementPolicy',
    'EntitlementRedemption',
    'EntryDecline',
    'EntryView',
    'Equalizer',
    'EraseTool',
    'Error',
    'ErrorBadge',
    'Event',
    'Event12',
    'EventAccepted',
    'EventDate',
    'EventDateMissed12',
    'EventDeclined',
    'EventInfo',
    'EventTentative',
    'EventTentativeMirrored',
    'EventToDoLogo',
    'ExcelDocument',
    'ExcelLogo',
    'ExcelLogo16',
    'ExcelLogoFill',
    'ExcelLogoFill16',
    'ExcelLogoInverse',
    'ExcelLogoInverse16',
    'ExchangeLogo',
    'ExchangeLogoFill',
    'ExchangeLogoInverse',
    'ExerciseTracker',
    'ExpandMenu',
    'ExploreContent',
    'ExploreContentSingle',
    'ExploreData',
    'Export',
    'ExportMirrored',
    'ExternalBuild',
    'ExternalTFVC',
    'ExternalXAML',
    'Eyedropper',
    'EyeShadow',
    'F12DevTools',
    'FabricAssetLibrary',
    'FabricDataConnectionLibrary',
    'FabricDocLibrary',
    'FabricFolder',
    'FabricFolderConfirm',
    'FabricFolderFill',
    'FabricFolderSearch',
    'FabricFormLibrary',
    'FabricFormLibraryMirrored',
    'FabricMovetoFolder',
    'FabricNetworkFolder',
    'FabricNewFolder',
    'FabricOpenFolderHorizontal',
    'FabricPictureLibrary',
    'FabricPublicFolder',
    'FabricReportLibrary',
    'FabricReportLibraryMirrored',
    'FabricSyncFolder',
    'FabricTextHighlight',
    'FabricTextHighlightComposite',
    'FabricUnsyncFolder',
    'FabricUserFolder',
    'Family',
    'FangBody',
    'FastForward',
    'FastMode',
    'Favicon',
    'FavoriteList',
    'FavoriteStar',
    'FavoriteStarFill',
    'Fax',
    'Feedback',
    'FeedbackRequestMirroredSolid',
    'FeedbackRequestSolid',
    'FeedbackResponseSolid',
    'Ferry',
    'FerrySolid',
    'FieldChanged',
    'FieldEmpty',
    'FieldFilled',
    'FieldNotChanged',
    'FieldReadOnly',
    'FieldRequired',
    'FileASPX',
    'FileBug',
    'FileCode',
    'FileComment',
    'FileCSS',
    'FileHTML',
    'FileImage',
    'FileJAVA',
    'FileLess',
    'FilePDB',
    'FileRequest',
    'FileSass',
    'FileSQL',
    'FileSymlink',
    'FileTemplate',
    'FileTypeSolution',
    'FileYML',
    'Filter',
    'Filters',
    'FilterSettings',
    'FilterSolid',
    'FiltersSolid',
    'Financial',
    'FinancialMirroredSolid',
    'FinancialSolid',
    'Fingerprint',
    'FitPage',
    'FitWidth',
    'FiveTileGrid',
    'Flag',
    'FlameSolid',
    'Flashlight',
    'FlickDown',
    'FlickLeft',
    'FlickRight',
    'FlickUp',
    'Flow',
    'FlowChart',
    'Flower',
    'FocalPoint',
    'Focus',
    'Fog',
    'Folder',
    'FolderFill',
    'FolderHorizontal',
    'FolderList',
    'FolderListMirrored',
    'FolderOpen',
    'FolderQuery',
    'FolderSearch',
    'FollowUser',
    'Font',
    'FontColor',
    'FontColorA',
    'FontColorSwatch',
    'FontDecrease',
    'FontIncrease',
    'FontSize',
    'Footer',
    'FormLibrary',
    'FormLibraryMirrored',
    'Forward',
    'ForwardEvent',
    'Freezing',
    'Frigid',
    'FrontCamera',
    'FSharp',
    'FSharpLanguage',
    'FullCircleMask',
    'FullHistory',
    'FullScreen',
    'FullWidth',
    'FullWidthEdit',
    'FunctionalManagerDashboard',
    'FunnelChart',
    'GallatinLogo',
    'Game',
    'Generate',
    'GenericScan',
    'GenericScanFilled',
    'GIF',
    'Giftbox',
    'GiftboxOpen',
    'GiftBoxSolid',
    'GiftCard',
    'GitGraph',
    'Glasses',
    'Glimmer',
    'GlobalNavButton',
    'GlobalNavButtonActive',
    'Globe',
    'Globe2',
    'GlobeFavorite',
    'Go',
    'Golf',
    'GoMirrored',
    'GoToDashboard',
    'GotoToday',
    'GreetingCard',
    'GridViewLarge',
    'GridViewMedium',
    'GridViewSmall',
    'GripperBarHorizontal',
    'GripperBarVertical',
    'GripperDotsVertical',
    'GripperTool',
    'Group',
    'GroupedAscending',
    'GroupedDescending',
    'GroupedList',
    'GroupList',
    'GroupObject',
    'GUID',
    'Guitar',
    'HailDay',
    'HailNight',
    'HalfAlpha',
    'HalfCircle',
    'HandsFree',
    'Handwriting',
    'HardDrive',
    'HardDriveGroup',
    'HardDriveLock',
    'HardDriveUnlock',
    'Header',
    'Header1',
    'Header2',
    'Header3',
    'Header4',
    'Headset',
    'HeadsetSolid',
    'Health',
    'HealthSolid',
    'Heart',
    'HeartBroken',
    'HeartFill',
    'Help',
    'HelpMirrored',
    'Hexagon',
    'Hide',
    'Hide2',
    'Hide3',
    'Highlight',
    'HighlightMappedShapes',
    'HintText',
    'HistoricalWeather',
    'History',
    'Home',
    'HomeGroup',
    'HomeSolid',
    'HomeVerify',
    'HorizontalDistributeCenter',
    'HorizontalTabKey',
    'Hospital',
    'Hotel',
    'HourGlass',
    'IconSetsFlag',
    'IDBadge',
    'ImageCrosshair',
    'ImageDiff',
    'ImagePixel',
    'ImageSearch',
    'Import',
    'ImportAllMirrored',
    'Important',
    'ImportMirrored',
    'Inbox',
    'InboxCheck',
    'IncidentTriangle',
    'IncomingCall',
    'IncreaseIndentArrow',
    'IncreaseIndentArrowMirrored',
    'IncreaseIndentLegacy',
    'IncreaseIndentText',
    'IncreaseIndentTextMirrored',
    'Info',
    'Info2',
    'InformationBarriers',
    'InfoSolid',
    'InkingTool',
    'Insert',
    'InsertColumnsLeft',
    'InsertColumnsRight',
    'InsertRowsAbove',
    'InsertRowsBelow',
    'InsertSignatureLine',
    'InsertTextBox',
    'Installation',
    'InstallToDrive',
    'InternalInvestigation',
    'InternetSharing',
    'IRMForward',
    'IRMForwardMirrored',
    'IRMReply',
    'IRMReplyMirrored',
    'IssueSolid',
    'IssueTracking',
    'IssueTrackingMirrored',
    'Italic',
    'JavaScriptLanguage',
    'JoinOnlineMeeting',
    'JS',
    'KaizalaLogo',
    'KeyboardClassic',
    'KnowledgeArticle',
    'Label',
    'LadybugSolid',
    'Lamp',
    'LandscapeOrientation',
    'LaptopSecure',
    'LaptopSelected',
    'LargeGrid',
    'Leave',
    'Library',
    'Lifesaver',
    'LifesaverLock',
    'Light',
    'Lightbulb',
    'LightningBolt',
    'LightWeight',
    'Like',
    'LikeSolid',
    'Line',
    'LineChart',
    'LineSpacing',
    'LineStyle',
    'LineThickness',
    'Link',
    'Link12',
    'LinkedDatabase',
    'LinkedInLogo',
    'List',
    'ListMirrored',
    'LocaleLanguage',
    'Location',
    'LocationCircle',
    'LocationDot',
    'LocationFill',
    'LocationOutline',
    'Lock',
    'Lock12',
    'LockSolid',
    'LogRemove',
    'LookupEntities',
    'LowerBrightness',
    'LyncLogo',
    'M365InvoicingLogo',
    'Mail',
    'MailAlert',
    'MailAttached',
    'MailCheck',
    'MailFill',
    'MailForward',
    'MailForwardMirrored',
    'MailLowImportance',
    'MailOptions',
    'MailPause',
    'MailReminder',
    'MailRepeat',
    'MailReply',
    'MailReplyAll',
    'MailReplyAllMirrored',
    'MailReplyMirrored',
    'MailSchedule',
    'MailSolid',
    'MailTentative',
    'MailTentativeMirrored',
    'MailUndelivered',
    'ManagerSelfService',
    'Manufacturing',
    'MapDirections',
    'MapLayers',
    'MapPin',
    'MapPinSolid',
    'MarkAsProtected',
    'MarkDownLanguage',
    'Market',
    'MarketDown',
    'MasterDatabase',
    'MaximumValue',
    'Medal',
    'MedalSolid',
    'Media',
    'MediaAdd',
    'Medical',
    'Megaphone',
    'MegaphoneSolid',
    'Memo',
    'Merge',
    'MergeDuplicate',
    'Message',
    'MessageFill',
    'MicOff',
    'MicOff2',
    'Microphone',
    'MicrosoftFlowLogo',
    'MicrosoftStaffhubLogo',
    'MicrosoftTranslatorLogo',
    'MicrosoftTranslatorLogoBlue',
    'MicrosoftTranslatorLogoGreen',
    'MiniContract',
    'MiniContractMirrored',
    'MiniExpand',
    'MiniExpandMirrored',
    'MiniLink',
    'MinimumValue',
    'MobileReport',
    'MobileSelected',
    'ModelingView',
    'Money',
    'More',
    'MoreSports',
    'MoreVertical',
    'MountainClimbing',
    'Move',
    'Movers',
    'MoveToFolder',
    'MSNLogo',
    'MSNVideos',
    'MSNVideosSolid',
    'MultiSelect',
    'MultiSelectMirrored',
    'MusicInCollection',
    'MusicInCollectionFill',
    'MusicNote',
    'MuteChat',
    'MyMoviesTV',
    'Nav2DMapView',
    'NavigateBack',
    'NavigateBackMirrored',
    'NavigateExternalInline',
    'NavigateForward',
    'NavigateForwardMirrored',
    'NavigationFlipper',
    'NetworkTower',
    'NewAnalyticsQuery',
    'NewFolder',
    'NewMail',
    'News',
    'NewsSearch',
    'NewTeamProject',
    'Next',
    'NormalWeight',
    'NoteForward',
    'NotePinned',
    'NoteReply',
    'NotExecuted',
    'NotImpactedSolid',
    'NugetLogo',
    'NumberedList',
    'NumberedListNumber',
    'NumberedListNumberMirrored',
    'NumberedListText',
    'NumberedListTextMirrored',
    'NumberField',
    'NumberSequence',
    'NumberSymbol',
    'Octagon',
    'OEM',
    'OfficeAddinsLogo',
    'OfficeAssistantLogo',
    'OfficeChat',
    'OfficeChatSolid',
    'OfficeFormsLogo',
    'OfficeFormsLogo16',
    'OfficeFormsLogo24',
    'OfficeFormsLogoFill',
    'OfficeFormsLogoFill16',
    'OfficeFormsLogoFill24',
    'OfficeFormsLogoInverse',
    'OfficeFormsLogoInverse16',
    'OfficeFormsLogoInverse24',
    'OfficeLogo',
    'OfficeStoreLogo',
    'OfficeVideoLogo',
    'OfficeVideoLogoFill',
    'OfficeVideoLogoInverse',
    'OfflineOneDriveParachute',
    'OfflineOneDriveParachuteDisabled',
    'OfflineStorage',
    'OfflineStorageSolid',
    'Onboarding',
    'OneDriveAdd',
    'OneDriveFolder16',
    'OneDriveLogo',
    'OneNoteEduLogoInverse',
    'OneNoteLogo',
    'OneNoteLogo16',
    'OneNoteLogoFill',
    'OneNoteLogoFill16',
    'OneNoteLogoInverse',
    'OneNoteLogoInverse16',
    'OpenEnrollment',
    'OpenFile',
    'OpenFolderHorizontal',
    'OpenInNewTab',
    'OpenInNewWindow',
    'OpenPane',
    'OpenPaneMirrored',
    'OpenSource',
    'Org',
    'Orientation',
    'Orientation2',
    'OutlookLogo',
    'OutlookLogo16',
    'OutlookLogoFill',
    'OutlookLogoFill16',
    'OutlookLogoInverse',
    'OutlookLogoInverse16',
    'OutOfOffice',
    'Package',
    'Packages',
    'Padding',
    'PaddingBottom',
    'PaddingLeft',
    'PaddingRight',
    'PaddingTop',
    'Page',
    'PageAdd',
    'PageArrowRight',
    'PageCheckedin',
    'PageCheckedOut',
    'PageData',
    'PageEdit',
    'PageHeader',
    'PageHeaderEdit',
    'PageLeft',
    'PageLink',
    'PageList',
    'PageListFilter',
    'PageListMirroredSolid',
    'PageListSolid',
    'PageLock',
    'PageRemove',
    'PageRight',
    'PageShared',
    'PageSolid',
    'PanoIndicator',
    'Parachute',
    'ParachuteSolid',
    'Parameter',
    'ParkingLocation',
    'ParkingLocationMirrored',
    'ParkingMirroredSolid',
    'ParkingSolid',
    'PartlyCloudyDay',
    'PartlyCloudyNight',
    'PartyLeader',
    'PassiveAuthentication',
    'PasswordField',
    'Paste',
    'PasteAsCode',
    'PasteAsText',
    'Pause',
    'PaymentCard',
    'PC1',
    'PDF',
    'PencilReply',
    'Pentagon',
    'PenWorkspace',
    'People',
    'PeopleAdd',
    'PeopleAlert',
    'PeopleBlock',
    'PeoplePause',
    'PeopleRepeat',
    'Permissions',
    'PermissionsSolid',
    'Personalize',
    'Phishing',
    'Phone',
    'Photo',
    'Photo2',
    'Photo2Add',
    'Photo2Fill',
    'Photo2Remove',
    'PhotoCollection',
    'PhotoVideoMedia',
    'Picture',
    'PictureCenter',
    'PictureFill',
    'PictureLibrary',
    'PicturePosition',
    'PictureStretch',
    'PictureTile',
    'PieDouble',
    'PieSingle',
    'PieSingleSolid',
    'Pill',
    'Pin',
    'Pinned',
    'PinnedFill',
    'PinnedSolid',
    'PinSolid12',
    'PinSolidOff12',
    'PivotChart',
    'PlainText',
    'PlannerLogo',
    'PlanView',
    'Play',
    'PlaybackRate1x',
    'PlayerSettings',
    'PlayResume',
    'PlayReverse',
    'PlayReverseResume',
    'Plug',
    'PlugConnected',
    'PlugDisconnected',
    'PlugSolid',
    'POI',
    'POISolid',
    'PollResults',
    'PostUpdate',
    'PowerApps',
    'PowerApps2Logo',
    'PowerAppsLogo',
    'PowerBILogo',
    'PowerBILogo16',
    'PowerBILogoBackplate16',
    'PowerButton',
    'PowerPointDocument',
    'PowerPointLogo',
    'PowerPointLogo16',
    'PowerPointLogoFill',
    'PowerPointLogoFill16',
    'PowerPointLogoInverse',
    'PowerPointLogoInverse16',
    'Precipitation',
    'PresenceChickletVideo',
    'Presentation',
    'Presentation12',
    'Preview',
    'PreviewLink',
    'Previous',
    'PrimaryCalendar',
    'Print',
    'PrintfaxPrinterFile',
    'Processing',
    'ProcessMetaTask',
    'Product',
    'ProductCatalog',
    'ProductionFloorManagement',
    'ProductList',
    'ProductRelease',
    'ProductVariant',
    'ProfileSearch',
    'ProFootball',
    'ProgressLoopInner',
    'ProgressLoopOuter',
    'ProgressRingDots',
    'ProHockey',
    'ProjectCollection',
    'ProjectDocument',
    'ProjectLogo16',
    'ProjectLogo32',
    'ProjectLogoFill16',
    'ProjectLogoFill32',
    'ProjectLogoInverse',
    'PromotedDatabase',
    'ProtectedDocument',
    'ProtectionCenterLogo32',
    'ProtectRestrict',
    'PublicCalendar',
    'PublicContactCard',
    'PublicContactCardMirrored',
    'PublicEmail',
    'PublicFolder',
    'PublishContent',
    'PublishCourse',
    'PublisherLogo',
    'PublisherLogo16',
    'PublisherLogoFill',
    'PublisherLogoFill16',
    'PublisherLogoInverse16',
    'Puzzle',
    'PY',
    'PythonLanguage',
    'QandA',
    'QandAMirror',
    'QRCode',
    'QuadColumn',
    'Quantity',
    'QuarterCircle',
    'QueryList',
    'Questionnaire',
    'QuestionnaireMirrored',
    'QuickNote',
    'QuickNoteSolid',
    'R',
    'RadioBtnOff',
    'RadioBtnOn',
    'RadioBullet',
    'Rain',
    'RainShowersDay',
    'RainShowersNight',
    'RainSnow',
    'RawSource',
    'Read',
    'ReadingMode',
    'ReadingModeSolid',
    'ReadOutLoud',
    'RealEstate',
    'ReceiptCheck',
    'ReceiptForward',
    'ReceiptReply',
    'ReceiptTentative',
    'ReceiptTentativeMirrored',
    'ReceiptUndelivered',
    'Recent',
    'Record2',
    'RecruitmentManagement',
    'RectangleShape',
    'RectangleShapeSolid',
    'RectangularClipping',
    'RecurringEvent',
    'RecurringTask',
    'RecycleBin',
    'Redeploy',
    'RedEye',
    'Redo',
    'Refresh',
    'Relationship',
    'ReleaseDefinition',
    'ReleaseGate',
    'ReleaseGateCheck',
    'ReleaseGateError',
    'ReminderGroup',
    'ReminderPerson',
    'ReminderTime',
    'Remote',
    'Remove',
    'RemoveEvent',
    'RemoveFilter',
    'RemoveFromShoppingList',
    'RemoveFromTrash',
    'RemoveLink',
    'RemoveLinkChain',
    'RemoveLinkX',
    'RemoveOccurrence',
    'Rename',
    'RenewalCurrent',
    'RenewalFuture',
    'ReopenPages',
    'Repair',
    'RepeatAll',
    'RepeatOne',
    'Reply',
    'ReplyAll',
    'ReplyAllAlt',
    'ReplyAllMirrored',
    'ReplyAlt',
    'ReplyMirrored',
    'Repo',
    'ReportAdd',
    'ReportDocument',
    'ReportHacked',
    'ReportLibrary',
    'ReportLibraryMirrored',
    'ReportLock',
    'ReportWarning',
    'RepoSolid',
    'Rerun',
    'ReservationOrders',
    'ResponsesMenu',
    'ReturnKey',
    'ReturnToSession',
    'ReviewRequestMirroredSolid',
    'ReviewRequestSolid',
    'ReviewResponseSolid',
    'ReviewSolid',
    'RevToggleKey',
    'Rewind',
    'Ribbon',
    'RibbonSolid',
    'RightDoubleQuote',
    'RightTriangle',
    'Ringer',
    'RingerOff',
    'RingerRemove',
    'RingerSolid',
    'Robot',
    'Rocket',
    'Room',
    'Rotate',
    'Rotate90Clockwise',
    'Rotate90CounterClockwise',
    'RowsChild',
    'RowsGroup',
    'Rugby',
    'Running',
    'Sad',
    'SadSolid',
    'Save',
    'SaveAll',
    'SaveAndClose',
    'SaveAs',
    'SaveTemplate',
    'SaveToMobile',
    'Savings',
    'ScaleUp',
    'ScaleVolume',
    'ScatterChart',
    'ScheduleEventAction',
    'SchoolDataSyncLogo',
    'ScopeTemplate',
    'ScreenCast',
    'Script',
    'ScrollUpDown',
    'Search',
    'SearchAndApps',
    'SearchBookmark',
    'SearchCalendar',
    'SearchData',
    'SearchIssue',
    'SearchIssueMirrored',
    'SearchNearby',
    'SecondaryNav',
    'Section',
    'Sections',
    'SecurityGroup',
    'SeeDo',
    'SelectAll',
    'Sell',
    'SemiboldWeight',
    'Send',
    'SendMirrored',
    'Separator',
    'Server',
    'ServerEnviroment',
    'ServerProcesses',
    'SetAction',
    'Settings',
    'ShakeDevice',
    'Shapes',
    'Share',
    'ShareiOS',
    'SharepointAppIcon16',
    'SharepointLogo',
    'SharepointLogoFill',
    'SharepointLogoInverse',
    'Shield',
    'ShieldAlert',
    'ShieldSolid',
    'Shirt',
    'Shop',
    'ShoppingCart',
    'ShoppingCartSolid',
    'ShopServer',
    'ShowGrid',
    'ShowResults',
    'ShowResultsMirrored',
    'ShowTimeAs',
    'SidePanel',
    'SidePanelMirrored',
    'Signin',
    'SignOut',
    'SingleBookmark',
    'SingleBookmarkSolid',
    'SingleColumn',
    'SingleColumnEdit',
    'SIPMove',
    'SiteScan',
    'SizeLegacy',
    'SkiResorts',
    'SkypeArrow',
    'SkypeCheck',
    'SkypeCircleArrow',
    'SkypeCircleCheck',
    'SkypeCircleClock',
    'SkypeCircleMinus',
    'SkypeCircleSlash',
    'SkypeClock',
    'SkypeForBusinessLogo',
    'SkypeForBusinessLogo16',
    'SkypeForBusinessLogoFill',
    'SkypeForBusinessLogoFill16',
    'SkypeLogo',
    'SkypeLogo16',
    'SkypeMessage',
    'SkypeMinus',
    'SkypeSlash',
    'Slider',
    'SliderHandleSize',
    'SliderThumb',
    'Slideshow',
    'SmartGlassRemote',
    'SnapToGrid',
    'Snooze',
    'Snow',
    'Snowflake',
    'SnowShowerDay',
    'SnowShowerNight',
    'Soccer',
    'SocialListeningLogo',
    'Sort',
    'SortDown',
    'SortLines',
    'SortUp',
    'Source',
    'Spacer',
    'Speakers',
    'SpecialEvent',
    'SpeedHigh',
    'Split',
    'SplitObject',
    'Sprint',
    'Squalls',
    'SquareShapeSolid',
    'Stack',
    'StackColumnChart',
    'StackedBarChart',
    'StackedColumnChart2',
    'StackedColumnChart2Fill',
    'StackedLineChart',
    'StackIndicator',
    'StaffNotebookLogo16',
    'StaffNotebookLogo32',
    'StaffNotebookLogoFill16',
    'StaffNotebookLogoFill32',
    'StaffNotebookLogoInverted16',
    'StaffNotebookLogoInverted32',
    'Starburst',
    'StarburstSolid',
    'StatusCircleBlock',
    'StatusCircleBlock2',
    'StatusCircleCheckmark',
    'StatusCircleErrorX',
    'StatusCircleExclamation',
    'StatusCircleInfo',
    'StatusCircleInner',
    'StatusCircleOuter',
    'StatusCircleQuestionMark',
    'StatusCircleRing',
    'StatusCircleSync',
    'StatusErrorFull',
    'StatusTriangle',
    'StatusTriangleExclamation',
    'StatusTriangleInner',
    'StatusTriangleOuter',
    'Step',
    'StepInsert',
    'StepShared',
    'StepSharedAdd',
    'StepSharedInsert',
    'StockDown',
    'StockUp',
    'Stop',
    'StopSolid',
    'Stopwatch',
    'StorageOptical',
    'StoreLogo16',
    'StoreLogoMed20',
    'Storyboard',
    'Streaming',
    'StreamingOff',
    'StreamLogo',
    'Street',
    'StreetsideSplitMinimize',
    'Strikethrough',
    'Subscribe',
    'Subscript',
    'SubstitutionsIn',
    'Suitcase',
    'SunAdd',
    'Sunny',
    'SunQuestionMark',
    'Superscript',
    'SurveyQuestions',
    'SwayLogo16',
    'SwayLogo32',
    'SwayLogoFill16',
    'SwayLogoFill32',
    'SwayLogoInverse',
    'Switch',
    'SwitcherStartEnd',
    'Sync',
    'SyncFolder',
    'SyncOccurence',
    'SyncStatus',
    'SyncStatusSolid',
    'SyncToPC',
    'System',
    'Tab',
    'TabCenter',
    'Table',
    'TableComputed',
    'TableGroup',
    'TableLink',
    'Tablet',
    'TabletMode',
    'TabletSelected',
    'TabOneColumn',
    'TabThreeColumn',
    'TabTwoColumn',
    'Tag',
    'TagSolid',
    'TagUnknown',
    'TagUnknown12',
    'TagUnknown12Mirror',
    'TagUnknownMirror',
    'Taskboard',
    'TaskGroup',
    'TaskGroupMirrored',
    'TaskLogo',
    'TaskManager',
    'TaskManagerMirrored',
    'TaskSolid',
    'Taxi',
    'TeamFavorite',
    'TeamsLogo',
    'TeamsLogo16',
    'TeamsLogoFill',
    'TeamsLogoFill16',
    'TeamsLogoInverse',
    'Teamwork',
    'Teeth',
    'Telemarketer',
    'TemporaryUser',
    'Tennis',
    'TestAutoSolid',
    'TestBeaker',
    'TestBeakerSolid',
    'TestCase',
    'TestExploreSolid',
    'TestImpactSolid',
    'TestParameter',
    'TestPlan',
    'TestStep',
    'TestSuite',
    'TestUserSolid',
    'TextBox',
    'TextCallout',
    'TextDocument',
    'TextDocumentShared',
    'TextField',
    'TextOverflow',
    'TFVCLogo',
    'ThisPC',
    'ThreeQuarterCircle',
    'ThumbnailView',
    'ThumbnailViewMirrored',
    'Thunderstorms',
    'Ticket',
    'Tiles',
    'Tiles2',
    'TimeEntry',
    'Timeline',
    'TimelineDelivery',
    'TimelineMatrixView',
    'TimelineProgress',
    'TimePicker',
    'Timer',
    'TimeSheet',
    'ToDoLogoBottom',
    'ToDoLogoInverse',
    'ToDoLogoOutline',
    'ToDoLogoTop',
    'ToggleBorder',
    'ToggleFilled',
    'ToggleLeft',
    'ToggleRight',
    'ToggleThumb',
    'Toll',
    'Toolbox',
    'Touch',
    'TouchPointer',
    'Trackers',
    'TrackersMirrored',
    'Train',
    'TrainSolid',
    'TransferCall',
    'Transition',
    'TransitionEffect',
    'TransitionPop',
    'TransitionPush',
    'Translate',
    'Trending12',
    'TriangleDown12',
    'TriangleLeft12',
    'TriangleRight12',
    'TriangleShape',
    'TriangleShapeSolid',
    'TriangleSolid',
    'TriangleSolidDown12',
    'TriangleSolidLeft12',
    'TriangleSolidRight12',
    'TriangleSolidUp12',
    'TriangleUp12',
    'TriggerApproval',
    'TriggerAuto',
    'TriggerUser',
    'Trim',
    'TrimEnd',
    'TrimStart',
    'TripleColumn',
    'TripleColumnEdit',
    'TripleColumnWide',
    'Trophy',
    'Trophy2',
    'Trophy2Solid',
    'TurnRight',
    'TVMonitor',
    'TVMonitorSelected',
    'TypeScriptLanguage',
    'Umbrella',
    'Underline',
    'Undo',
    'Uneditable',
    'Uneditable2',
    'Uneditable2Mirrored',
    'UneditableMirrored',
    'UneditableSolid12',
    'UneditableSolidMirrored12',
    'Unfavorite',
    'UngroupObject',
    'Unknown',
    'UnknownCall',
    'UnknownMirrored',
    'UnknownMirroredSolid',
    'UnknownSolid',
    'Unlock',
    'UnlockSolid',
    'Unpin',
    'UnpublishContent',
    'UnSetColor',
    'UnstackSelected',
    'Unsubscribe',
    'UnsyncFolder',
    'UnsyncOccurence',
    'Untag',
    'Up',
    'UpgradeAnalysis',
    'Upload',
    'USB',
    'UserEvent',
    'UserFollowed',
    'UserGauge',
    'UserOptional',
    'UserPause',
    'UserRemove',
    'UserSync',
    'UserWarning',
    'Vacation',
    'Variable',
    'Variable2',
    'VariableGroup',
    'VB',
    'VennDiagram',
    'VerifiedBrand',
    'VerifiedBrandSolid',
    'VersionControlPush',
    'VerticalDistributeCenter',
    'Video',
    'Video360Generic',
    'VideoLightOff',
    'VideoOff',
    'VideoSearch',
    'VideoSolid',
    'View',
    'ViewAll',
    'ViewAll2',
    'ViewDashboard',
    'ViewList',
    'ViewListGroup',
    'ViewListTree',
    'ViewOriginal',
    'VisioDiagram',
    'VisioDiagramSync',
    'VisioDocument',
    'VisioLogo',
    'VisioLogo16',
    'VisioLogoFill',
    'VisioLogoFill16',
    'VisioLogoInverse',
    'VisioLogoInverse16',
    'VisioOnlineLogo32',
    'VisioOnlineLogoCloud32',
    'VisioOnlineLogoFill32',
    'VisualBasicLanguage',
    'VisuallyImpaired',
    'VisualsFolder',
    'VisualsStore',
    'VisualStudioIDELogo32',
    'VisualStudioLogo',
    'VoicemailForward',
    'VoicemailIRM',
    'VoicemailReply',
    'Volume0',
    'Volume1',
    'Volume2',
    'Volume3',
    'VolumeDisabled',
    'VSTSAltLogo1',
    'VSTSAltLogo2',
    'VSTSLogo',
    'Waffle',
    'WaffleOffice365',
    'WaitlistConfirm',
    'WaitlistConfirmMirrored',
    'Warning',
    'Warning12',
    'WarningSolid',
    'WavingHand',
    'WebAppBuilderFragment',
    'WebAppBuilderFragmentCreate',
    'WebAppBuilderModule',
    'WebAppBuilderSlot',
    'Webcam2',
    'WebComponents',
    'WebPublish',
    'Website',
    'WebTemplate',
    'Weights',
    'Wheelchair',
    'WhiteBoardApp16',
    'WhiteBoardApp32',
    'WifiEthernet',
    'WifiWarning4',
    'WindDirection',
    'WindowEdit',
    'WindowsLogo',
    'Wines',
    'WipePhone',
    'WordDocument',
    'WordLogo',
    'WordLogo16',
    'WordLogoFill',
    'WordLogoFill16',
    'WordLogoInverse',
    'WordLogoInverse16',
    'Work',
    'WorkFlow',
    'WorkforceManagement',
    'WorkItem',
    'WorkItemAlert',
    'WorkItemBar',
    'WorkItemBarSolid',
    'WorkItemBug',
    'World',
    'WorldClock',
    'YammerLogo',
    'ZipFolder',
    'Zoom',
    'ZoomIn',
    'ZoomOut',
    'ZoomToFit',
];
