export function idToCssVar(id: string | undefined) {
    return id ? `var(${id})` : undefined;
}
export function isCssVar(id: string | undefined) {
    return id && id.startsWith('var(') && id.endsWith(')');
}
export function getIdFromCssVar(id: string | undefined) {
    if (id && isCssVar(id)) {
        return id.substring(4, id.length - 1);
    }
    return id;
}
