import { FC, useMemo } from 'react';
import { SocialNetworkName } from './type';

export interface SocialNetworkLogoProps {
    socialNetwork: SocialNetworkName;
    size?: 'small' | 'large' | 'extraLarge';
    white?: boolean;
}
export const SocialNetworkLogo: FC<SocialNetworkLogoProps> = (props: SocialNetworkLogoProps) => {
    const { socialNetwork, size, white } = props;
    const height = useMemo(() => {
        switch (size) {
            case 'small':
                return 24;
            case 'large':
                return 32;
            case 'extraLarge':
                return 48;
        }
        return 24;
    }, [size]);
    return <img alt='Social network logo' src={`/images/sn/${white ? 'w/' : ''}${socialNetwork}.svg`} height={height} />;
};
