export function FileSizeToString(bytes: number): string {
    const Kilo = 1000;

    if (Math.abs(bytes) < Kilo) {
        return `${bytes} Bytes`;
    }
    const units = ['kB', 'MB', 'GB', 'TB'];
    let u = -1;
    let byteValue = bytes;
    do {
        byteValue /= Kilo;
        u += 1;
    } while (Math.round(Math.abs(byteValue) * 10) / 10 >= Kilo && u < units.length - 1);

    return `${byteValue.toFixed(1)} ${units[u]}`;
}

export function getExtension(path: string) {
    const basename = path.split(/[\\/]/).pop(); // extract file name from full path ...
    // (supports `\\` and `/` separators)
    const pos = basename ? basename.lastIndexOf('.') : -1; // get last position of `.`

    if (basename === '' || pos < 1)
        // if file name is empty or ...
        return ''; //  `.` not found (-1) or comes first (0)

    return basename?.slice(pos + 1); // extract extension ignoring `.`
}
