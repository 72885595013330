import { ActionButton, IconButton, Label } from '@fluentui/react';
import { Fragment, useMemo } from 'react';
import { IColorPalette } from '../../../Services/SakuraApiClient';
import { SwatchColorPicker } from './SwatchColorPicker';
export interface IColorPaletteProps {
    palette: IColorPalette;
    selectedId: string | undefined;
    onSelected: (id: string | undefined) => void;
    onAddColor?: (id: string) => void;
    onRenameGroup?: (index: number) => void;
    disableGradiantColorSelection?: boolean;
}
export interface Palette {
    name: string | undefined;
    colors: ColorInfo[];
}
export interface ColorInfo {
    id: string;
    label: string;
    color: string;
}
export function ColorPaletteView(props: IColorPaletteProps) {
    const { palette, selectedId, onSelected, onAddColor, onRenameGroup, disableGradiantColorSelection } = props;

    const palettesData: Palette[] = useMemo(() => {
        let entryIndex = -1;
        return (palette?.entries ?? []).map((e) => {
            let colorIndex = -1;
            entryIndex++;
            return {
                name: e.entryName,
                colors: (e.colors ?? []).map((c, index) => {
                    const val = c.split('|');
                    colorIndex++;
                    return {
                        id: `--C${entryIndex}-${colorIndex}`,
                        label: val.length === 2 ? val[1] : '',
                        color: val[0],
                    };
                }),
            };
        });
    }, [palette]);

    return (
        <div className='colorPaletteView'>
            {palettesData.map((paletteData, index) => {
                const indexes = getIndexesForId(selectedId);
                const selectedIdOnIndex = selectedId !== undefined && indexes && indexes[0] === index ? selectedId : undefined;
                return (
                    <Fragment key={paletteData.name}>
                        <div className='colorPaletteEditorGroup'>
                            <div>
                                <Label>{paletteData.name}</Label>
                                {onRenameGroup ? (
                                    <IconButton
                                        className='rename'
                                        iconProps={{ iconName: 'Edit' }}
                                        text='Renommer ce groupe'
                                        title='Renommer ce groupe'
                                        onClick={() => {
                                            if (onRenameGroup) {
                                                onRenameGroup(index);
                                            }
                                        }}
                                    />
                                ) : null}
                            </div>
                            {onAddColor ? (
                                <IconButton
                                    iconProps={{ iconName: 'AddTo' }}
                                    text='Ajouter une couleur'
                                    title='Ajouter une couleur'
                                    onClick={() => {
                                        if (onAddColor) {
                                            onAddColor(`--C${index}-${paletteData.colors.length}`);
                                        }
                                    }}
                                />
                            ) : null}
                        </div>
                        <SwatchColorPicker
                            selectedId={selectedIdOnIndex}
                            onChange={(_, id) => {
                                onSelected(id);
                            }}
                            disableGradiantColorSelection={disableGradiantColorSelection}
                            colorCells={paletteData.colors}
                        />
                    </Fragment>
                );
            })}
            <ActionButton
                iconProps={{ iconName: 'UnSetColor' }}
                text='Sans couleur'
                title='Sans couleur'
                onClick={() => {
                    onSelected(undefined);
                }}
            />
        </div>
    );
}
export function getIndexesForId(id: string | undefined) {
    if (id) {
        const data = id.substring(3).split('-');
        return [Number.parseInt(data[0], 10), Number.parseInt(data[1], 10)];
    }
    return undefined;
}
