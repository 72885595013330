import { ExpressionItem } from './ExpressionItem';
import { Value } from './Value';

export class ExpressionValue extends ExpressionItem {
    private value: Value;

    constructor(text: string);
    constructor(value: Value);

    constructor(arg: string | Value) {
        super(typeof arg === 'string' ? arg : '', undefined);

        if (typeof arg === 'string') {
            this.value = Value.ParseValue(arg);
        } else {
            this.value = arg;
        }
    }

    public resolve(): ExpressionItem {
        if (this.next) {
            return this.next.resolve();
        }
        return this;
    }

    public getValue(): Value {
        return this.value;
    }

    public canReturnValue(): boolean {
        return true;
    }
}
