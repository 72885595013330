import { FC, useEffect, useState } from 'react';
import { IOffer, IResource, ResourceSharedAccessUrlRequest } from '../../../Services/SakuraApiClient';

import { IIconProps, Label, Position, PrimaryButton, SpinButton, TextField, Toggle } from '@fluentui/react';
import { performApiCall } from '../../../common/Hooks/useApiCall';
import { useAppDispatch } from '../../../Redux/hook';

import { SimpleDialog } from '../../../common/components/Dialog/SimpleDialog/SimpleDialog';
import { GetResourceTypeTextOne, GetResourceTypeTextOneSpecific } from '../../../common/components/Icons/ResourceTypeIcon';
import { TextFieldExtended } from '../../../common/components/TextFieldExtended/TextFieldExtended';
import { OfferLookupField } from '../../../common/LookupFields/OfferLookupField';

import { booleanAsString, parseBoolean } from '../../../common/helpers/boolean';
import { addDays, dateNow, displayDate } from '../../../common/helpers/DateTimeHelper';
import { useNav } from '../../../common/Hooks/useNav';
import { SocialNetworkLinksGrid } from '../../../Redux/Reducers/DataSet/ApiCall/SocialNetworkLinks';
import { screenPaths } from '../../../Routing/ScreenPaths';
import { SocialNetworkLinkDetails } from '../SocialNetworkLinks/SocialNetworkLinkDetails';
import './ResourceGenerateLink.scss';

export interface ExtraParameters {
    name: string;
    type: 'Boolean' | 'String';
    label: string;
}
export interface ResourceGenerateLinkProps {
    resource: IResource;
    show: boolean;
    extraParameters?: ExtraParameters[];
    onValidate?: (extraParamtersValues: string[]) => boolean;
    onClose: () => void;
}

export const contextId = 'ResourceGenerateLink';
const iconProps: IIconProps = { iconName: 'DateTime' };

export const ResourceGenerateLink: FC<ResourceGenerateLinkProps> = (props: ResourceGenerateLinkProps) => {
    const { resource, show, onClose, extraParameters, onValidate } = props;
    const appDispatch = useAppDispatch();
    const [offer1, setOffer1] = useState<IOffer>();
    const [offer2, setOffer2] = useState<IOffer>();
    const [nbDayExpire, setNbDayExpire] = useState<number>(0);
    const [snlinkDialog, setSnlinkDialog] = useState<boolean>(false);
    const [sharedUrlComment, setSharedUrlComment] = useState<string>('');
    const [expired, setExpired] = useState<boolean>(false);
    const [hasOffer, setHasOffer] = useState<boolean>(false);
    const [currentOffer, setCurrentOffer] = useState<boolean>(true);
    const [sharedUrl, setSharedUrl] = useState<string>();
    const [extraParameterValues, setExtraParameterValues] = useState<string[]>(extraParameters ? Array(extraParameters.length) : []);
    const nav = useNav();
    const onGenerateLink = () => {
        setSharedUrl('');
        if (onValidate && onValidate(extraParameterValues) === false) {
            return;
        }
        performApiCall(
            async (client) => {
                if (resource) {
                    const currentOfferParam = hasOffer ? currentOffer : undefined;
                    const request = new ResourceSharedAccessUrlRequest();
                    request.init({
                        currentOffers: currentOfferParam,
                        offerId1: hasOffer && !currentOfferParam ? offer1?.id : undefined,
                        offerId2: hasOffer && !currentOfferParam ? offer2?.id : undefined,
                        expireNbDay: expired ? nbDayExpire : undefined,
                        extraParameters: extraParameterValues && extraParameters ? extraParameters.map((e, i) => ({ name: e.name, value: extraParameterValues[i] ?? '' })) : undefined,
                    });
                    const result = await client.getResourceSharedAccessUrl(resource.id, request);
                    setSharedUrl(result.data);
                }
            },
            appDispatch,
            { contextId },
        );
    };
    useEffect(() => {
        setSharedUrl(undefined);
        setExpired(false);
        setNbDayExpire(0);
        setOffer1(undefined);
        setOffer2(undefined);
    }, [show]);
    return (
        <SimpleDialog
            id={contextId}
            icon={{ iconName: 'Link' }}
            title="Lien d'accés"
            subText={`Générer un lien d'accés ${GetResourceTypeTextOneSpecific(resource?.resourceType)} '${resource?.name ?? ''}'`}
            show={show}
            onClose={onClose}
        >
            <div className='ResourceGenerateLink'>
                <Toggle label='Durée de validité du lien' onText='Expire' offText="N'expire jamais" checked={expired} onChange={(_, checked) => setExpired(checked ?? false)}></Toggle>
                <SpinButton
                    disabled={!expired}
                    labelPosition={Position.top}
                    label='Jour avant expiration'
                    iconProps={iconProps}
                    defaultValue='0'
                    min={0}
                    max={365}
                    step={1}
                    incrementButtonAriaLabel='Augmenter la valeur de 1'
                    decrementButtonAriaLabel='Diminuer la valeur de 1'
                    value={nbDayExpire.toString()}
                    onChange={(_, val) => setNbDayExpire(Number.parseInt(val ?? '0', 10))}
                />
                <Label className='ResourceGenerateLink-span2'>Proposer des offres commercial sur la page d&apos;accés</Label>
                <Toggle onText='Oui' offText='Non' checked={hasOffer} onChange={(_, checked) => setHasOffer(checked ?? false)} />
                {hasOffer ? (
                    <>
                        <Toggle onText='Les offres actuelles' offText='Des offres spécifiques' checked={currentOffer} onChange={(_, checked) => setCurrentOffer(checked ?? false)} />
                        {!currentOffer ? (
                            <>
                                <OfferLookupField contextId={contextId} label='Premiére Offre' selectedOffer={offer1} onSelectOffer={(offer) => setOffer1(offer)} />
                                <OfferLookupField contextId={contextId} label='Seconde Offre' selectedOffer={offer2} onSelectOffer={(offer) => setOffer2(offer)} />
                            </>
                        ) : (
                            <></>
                        )}
                    </>
                ) : (
                    <></>
                )}

                {(extraParameters ?? []).map((e, index) => {
                    return (
                        <div key={`extraParam_${index}`} className='ResourceGenerateLink-span2'>
                            {e.type === 'Boolean' ? (
                                <Toggle
                                    onText='Oui'
                                    offText='Non'
                                    label={e.label}
                                    checked={parseBoolean(extraParameterValues[index])}
                                    onChange={(_, checked) => {
                                        const val = [...extraParameterValues];
                                        val[index] = booleanAsString(checked, true) ?? 'no';
                                        setExtraParameterValues(val);
                                    }}
                                />
                            ) : (
                                <></>
                            )}
                            {e.type === 'String' ? (
                                <TextField
                                    label={e.label}
                                    value={extraParameterValues[index]}
                                    onChange={(_, value) => {
                                        const val = [...extraParameterValues];
                                        val[index] = value ?? '';
                                        setExtraParameterValues(val);
                                    }}
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                    );
                })}
                <div className='ResourceGenerateLink-span2'>
                    <PrimaryButton style={{ marginBottom: '1rem' }} iconProps={{ iconName: 'AddLink' }} text='Générer le lien' onClick={onGenerateLink} />
                    <TextFieldExtended value={sharedUrl} copyMessage='Le lien a été copier dans le presse-papiers' />
                    {sharedUrl ? (
                        <PrimaryButton
                            text='Créer un lien réseau sociaux'
                            onClick={() => {
                                setSnlinkDialog(true);
                                const validity = expired ? `Ce lien expire le ${displayDate(addDays(dateNow(), nbDayExpire))}.\n` : "Ce lien n'expire pas.\n";
                                const offer = hasOffer ? 'Ce lien propose des offres commerciales.\n' : '';
                                const offerDetail = hasOffer ? (currentOffer ? '-Offre actuelle.' : `-Offre1: ${offer1?.name ?? 'aucune'}\n-Offre2: ${offer2?.name ?? 'aucune'}\n`) : '';
                                const extraParam = extraParameters ? extraParameters?.map((val, index) => `${val.label}: ${extraParameterValues[index] ?? 'non'}\n`) : '';
                                setSharedUrlComment(`Lien de la resource "${resource.name}" qui est ${GetResourceTypeTextOne(resource.resourceType)}.\n${validity}${offer}${offerDetail}${extraParam}`);
                            }}
                        />
                    ) : null}
                    {snlinkDialog ? (
                        <SocialNetworkLinkDetails
                            onClose={(entity) => {
                                setSnlinkDialog(false);
                                if (entity) {
                                    SocialNetworkLinksGrid.refresh();
                                    nav.navigate(screenPaths.socialNetworkLinksList);
                                }
                            }}
                            entity={undefined}
                            linkDefaultValue={sharedUrl}
                            commentDefaultValue={sharedUrlComment}
                            show={true}
                        />
                    ) : null}
                </div>
            </div>
        </SimpleDialog>
    );
};
