import { IAccountDataResource, IQuestionnary, IQuestionnaryQuestion, IQuestionnarySubmissionItem } from '../../../../Services/SakuraApiClient';
import { elapsedSecond } from '../../../../common/helpers/DateTimeHelper';
import { jsonParse } from '../../../../common/helpers/Json';
import { moveToNextQuestion, moveToPrevQuestion } from './helper';
import { IQuestionaryState } from './state';

export type QuestionnaryInitAction = { type: 'init'; payload: IQuestionaryState };
export type QuestionnaryStartAction = { type: 'start' };
export type QuestionnaryNextAction = { type: 'next' };
export type QuestionnaryPrevAction = { type: 'prev' };
export type QuestionnaryAnswerAction = { type: 'answer'; payload: { answer: Partial<IQuestionnarySubmissionItem> } };

export type QuestionnaryAction = QuestionnaryInitAction | QuestionnaryStartAction | QuestionnaryNextAction | QuestionnaryPrevAction | QuestionnaryAnswerAction;

function loadQuestionnaryState(resourceId: number): IQuestionaryState | undefined {
    const json = localStorage.getItem(`Questionnary_${resourceId}`);
    if (json) {
        return jsonParse(json);
    }
    return undefined;
}

export function questionnaryReducerInitializer(resourceId: number, questionnary: IQuestionnary, enablePersitance: boolean, accountDataResource: IAccountDataResource | undefined): IQuestionaryState {
    const nbQ = questionnary.questions?.length ?? 0;
    const existingState = enablePersitance ? loadQuestionnaryState(resourceId) : undefined;
    if (existingState) {
        if (JSON.stringify(existingState.questionnary) === JSON.stringify(questionnary)) {
            const now = new Date(Date.now());
            return { ...existingState, sessionStartTime: now, elapsedTimeInSec: existingState.elapsedTimeInSec + existingState.currentSessionElapsedTimeInSec, currentSessionElapsedTimeInSec: 0 };
        }
    }
    return {
        enablePersitance: enablePersitance,
        questionnary: questionnary,
        resourceId: resourceId,
        navigate: { current: 0, canGoPrev: false, canGoNext: nbQ > 1 },
        answers: (questionnary.questions ?? []).map<IQuestionnarySubmissionItem>((q) => ({ questionId: q.id, answers: undefined, answerYes: undefined, freeText: undefined })),
        startTime: new Date(Date.now()),
        sessionStartTime: new Date(Date.now()),
        endTime: new Date(Date.now()),
        elapsedTimeInSec: 0,
        currentSessionElapsedTimeInSec: 0,
        status: 'NotStarted',
    };
}
function hasAnswer(answer: IQuestionnarySubmissionItem | undefined, question: IQuestionnaryQuestion | undefined) {
    if (answer && question?.optional !== true) {
        if (answer.answerYes != null) return true;
        if (answer.freeText != null && answer.freeText !== '') return true;
        if (answer.answers != null && answer.answers.length > 0) return true;
    }
    return question?.optional === true;
}
export function questionnaryReducer(state: IQuestionaryState, action: QuestionnaryAction): IQuestionaryState {
    let newState = state;
    switch (action.type) {
        case 'init':
            newState = action.payload;
            break;
        case 'start':
            const index = 0;
            const answer = hasAnswer(state.answers[index], state.questionnary.questions ? state.questionnary.questions[index] : undefined);

            newState = {
                ...state,
                status: 'InProgress',
                navigate: {
                    current: index,
                    canGoPrev: index > 0,
                    canGoNext: answer,
                },
            };
            break;
        case 'prev':
            {
                // const index = state.navigate.current > 0 ? state.navigate.current - 1 : 0;
                // let newStatus = state.status;
                // if (state.navigate.current === 0) {
                //     newStatus = 'NotStarted';
                // }
                const { index, status } = moveToPrevQuestion(state.navigate.current, state.questionnary.questions ?? []);
                const newStatus = status ?? state.status;
                const answer = hasAnswer(state.answers[index], state.questionnary.questions ? state.questionnary.questions[index] : undefined);

                newState = {
                    ...state,
                    status: newStatus,
                    navigate: {
                        current: index,
                        canGoPrev: index > 0,
                        canGoNext: answer,
                    },
                };
            }
            break;
        case 'next':
            {
                // const index = state.navigate.current < nbQuestions - 1 ? state.navigate.current + 1 : nbQuestions - 1;
                // let newStatus = state.status;

                // if (state.navigate.current === nbQuestions - 1) {
                //     newStatus = 'Completed';
                // }
                const { index, status } = moveToNextQuestion(state.navigate.current, state.questionnary.questions ?? []);
                const newStatus = status ?? state.status;
                const answer = hasAnswer(state.answers[index], state.questionnary.questions ? state.questionnary.questions[index] : undefined);

                newState = {
                    ...state,
                    status: newStatus,
                    navigate: {
                        current: index,
                        canGoPrev: index > 0,
                        canGoNext: answer,
                    },
                };
            }
            break;
        case 'answer':
            {
                const answers = [...state.answers];

                answers[state.navigate.current] = { ...answers[state.navigate.current], ...action.payload.answer };
                const answer = hasAnswer(answers[state.navigate.current], state.questionnary.questions ? state.questionnary.questions[state.navigate.current] : undefined);

                newState = {
                    ...state,
                    answers,
                    navigate: {
                        ...state.navigate,
                        canGoNext: answer,
                    },
                };
            }
            break;
    }
    const now = new Date(Date.now());
    newState.endTime = now;
    newState.currentSessionElapsedTimeInSec = elapsedSecond(now, newState.sessionStartTime);
    if (state.enablePersitance) {
        localStorage.setItem(`Questionnary_${state.resourceId}`, JSON.stringify(newState));
    }

    return newState;
}

export function clearPersistanceState(resourceId: number) {
    return localStorage.removeItem(`Questionnary_${resourceId}`);
}
