import { FC } from 'react';
import { IResultDescriptionBase } from '../../../../Services/SakuraApiClient';
import { DeepPartial } from '../../../../common/Hooks/Form/useForm';
import { ResourceLookupField } from '../../../../common/LookupFields/ResourceLookupField';
import { ExpressionField, ExpressionKind } from '../../../../common/components/ExpressionField/ExpressionField';

export interface ResultDescriptionBaseProps {
    item: DeepPartial<IResultDescriptionBase> | undefined;
    onUpdate: (item: DeepPartial<IResultDescriptionBase>) => void;
}

export const ResultDescriptionBase: FC<ResultDescriptionBaseProps> = (props: ResultDescriptionBaseProps) => {
    const { item, onUpdate } = props;
    return (
        <div className='resultDescriptionBase'>
            <ExpressionField
                kind={ExpressionKind.TextWithExpression}
                className='title'
                label='Titre'
                value={item?.title}
                onChange={(title) => {
                    onUpdate({ title });
                }}
            ></ExpressionField>

            <ExpressionField
                kind={ExpressionKind.TextWithExpression}
                label='Description'
                multilines
                maxVisibleLines={10}
                value={item?.description}
                onChange={(description) => {
                    onUpdate({ description });
                }}
            ></ExpressionField>
            <ResourceLookupField
                label='Image'
                type='ResourceImageContentLookup'
                selectedResourceId={item?.imageResourceId}
                onSelectResource={(resource) => {
                    if (resource) {
                        onUpdate({ imageResourceId: resource.id });
                    } else {
                        onUpdate({ imageResourceId: undefined });
                    }
                }}
            />
        </div>
    );
};
