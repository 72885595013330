import { CombinerContext, ExpressionItem } from './ExpressionItem';
import { ExpressionValue } from './ExpressionValue';
import { Value } from './Value';

export class ExpressionCombiner extends ExpressionItem {
    protected combine(operation: (left: Value, right: Value) => Value, context: CombinerContext): ExpressionItem {
        if (this.previous === null) {
            throw new Error(`No value before ${this.text}`);
        }

        if (this.next === null) {
            throw new Error(`No value after ${this.text}`);
        }

        const previousValue = this.previous?.getValue();
        if (!previousValue) {
            throw new Error(`Previous value can't be undefined`);
        }
        let nextItemResolved: ExpressionItem | undefined = this.next;

        if (this.next?.next === undefined || this.next.next.isGreedy(context)) {
            nextItemResolved = this.next?.resolve();
        }

        if (nextItemResolved?.canReturnValue() === false) {
            // If we cannot get a value, trigger a resolve.
            nextItemResolved = nextItemResolved.resolve();
        }

        const nextValue = nextItemResolved?.getValue();
        if (!nextValue) {
            throw new Error(`Next value can't be undefined`);
        }
        const newValue = operation(previousValue, nextValue);
        const newItem = new ExpressionValue(newValue);

        newItem.previous = this.previous?.previous;

        if (this.previous?.previous) {
            this.previous.previous.next = newItem;
        }

        newItem.next = nextItemResolved?.next;

        if (nextItemResolved?.next) {
            nextItemResolved.next.previous = newItem;
        }

        return newItem.resolve();
    }
}
