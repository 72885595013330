import { setVariable } from '../../../../../Redux/Reducers/DynamiqueData/reducer';
import { AppDispatch } from '../../../../../Redux/store';
import { EnumClickAction, IClickable } from '../../../../../Services/SakuraApiClient';
import { performApiCall } from '../../../../../common/Hooks/useApiCall';
import { Expression, ValueTyping } from '../../../../../common/components/ExpressionField/Expression';
import { openNewTab } from '../../../../../common/helpers/uri';
import { FormationActions } from '../../../Formation/FormationContext/FormationContextActions';

export function clickAction(
    resourceId: number | undefined,
    parentResourcePathId: string | undefined,
    dispatch: AppDispatch,
    onClick: IClickable,
    dynVariableResolver: (variableName: string[]) => Record<string, ValueTyping> | undefined,
    formationContextActions: FormationActions | undefined,
) {
    switch (onClick.action) {
        case EnumClickAction.Link:
            if (onClick.href) {
                openNewTab(onClick.href);
            }
            break;
        case EnumClickAction.ExecuteWorkflow:
            performApiCall(async (client, cancelToken) => {
                if (onClick.workflow.id && resourceId) {
                    const val = await client.executeResourceWorkflow(resourceId, onClick.workflow.id, parentResourcePathId, cancelToken);
                    console.log('workflow execute return ' + val);
                }
            }, dispatch);
            break;
        case EnumClickAction.AssignVariable:
            if (onClick.assignExpression) {
                const assignExpression = new Expression(onClick.assignExpression, dynVariableResolver);
                const assignment = assignExpression.evalAssignment();
                dispatch(
                    setVariable({
                        name: assignment.variableName,
                        value: assignment.value.valueObject,
                    }),
                );
            }
            break;
        case EnumClickAction.DownloadDocument:
            alert('todo DownloadDocument');
            break;
        case EnumClickAction.NavigatePrev:
            if (formationContextActions) {
                formationContextActions.goBack();
            }
            break;
        case EnumClickAction.NavigateNext:
            if (formationContextActions) {
                formationContextActions.goNext();
            }
            break;
        case EnumClickAction.CompletePage:
            if (formationContextActions) {
                formationContextActions.completeCurrentItem();
            }
            break;
    }
}
