import BigNumber from 'bignumber.js';
import clsx from 'clsx';
import { FC, KeyboardEvent, useRef, useState } from 'react';
import { BadgeTypeEnum, EnumUnit, IGraphics } from '../../../Services/SakuraApiClient';
import { generateUniqueId } from '../../helpers/uniqueId';
import { useWheel, WheelDirection } from '../../Hooks/wheel';
import { ImageViewer } from '../ImageViewer/ImageViewer';

import './BadgeViewer.scss';

export interface BadgeViewerProps {
    width: number;
    height: number;
    type: BadgeTypeEnum;
    graphics: IGraphics;
    url?: string;
    file?: File;
    editable?: boolean;
    onChangeGraphic?: (graphics: IGraphics) => void;
}

export const BadgeViewer: FC<BadgeViewerProps> = (props: BadgeViewerProps) => {
    const { width, height, type, graphics, url, file, editable, onChangeGraphic } = props;
    const divId = useRef(generateUniqueId('bagde'));
    const imgWidth = graphics.unit === EnumUnit.Percentage ? width * graphics.width : graphics.width;
    const imgHeight = graphics.unit === EnumUnit.Percentage ? height * graphics.height : graphics.height;
    const imgPosX = graphics.unit === EnumUnit.Percentage ? width * graphics.positionX : graphics.positionX;
    const imgPosY = graphics.unit === EnumUnit.Percentage ? height * graphics.positionY : graphics.positionY;
    const [hasFocus, setFocus] = useState<boolean>(false);
    const onClickDiv = () => {
        document.getElementById(`#${divId.current}`)?.focus();
    };
    const onKeyUp = (e: KeyboardEvent<HTMLDivElement>) => {
        let positionX = undefined;
        let positionY = undefined;
        if (e.key === 'ArrowUp') {
            positionY = new BigNumber(graphics.positionY).minus(0.01).toNumber();
        } else if (e.key === 'ArrowDown') {
            positionY = new BigNumber(graphics.positionY).plus(0.01).toNumber();
        } else if (e.key === 'ArrowLeft') {
            positionX = new BigNumber(graphics.positionX).minus(0.01).toNumber();
        } else if (e.key === 'ArrowRight') {
            positionX = new BigNumber(graphics.positionX).plus(0.01).toNumber();
        }

        if ((positionX !== undefined || positionY !== undefined) && onChangeGraphic) {
            onChangeGraphic({
                ...graphics,
                positionX: positionX !== undefined ? positionX : graphics.positionX,
                positionY: positionY !== undefined ? positionY : graphics.positionY,
            });
        }
    };
    const onWheel = (direction: WheelDirection, delta: number) => {
        const val = new BigNumber(delta).dividedBy(100).dividedBy(100).toNumber();

        if (onChangeGraphic) {
            onChangeGraphic({
                ...graphics,
                height: new BigNumber(graphics.height).plus(val).toNumber(),
                width: new BigNumber(graphics.width).plus(val).toNumber(),
            });
        }
    };
    useWheel(editable && hasFocus ? onWheel : undefined);
    return (
        <div
            id={`#${divId.current}`}
            tabIndex={editable ? -1 : undefined}
            className={clsx('SakuraBadge', editable ? 'clickable' : '')}
            style={{ width, height }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            onClick={editable ? onClickDiv : undefined}
            onKeyUp={editable && hasFocus ? onKeyUp : undefined}
        >
            {(url || file) && <ImageViewer url={url} file={file} width={`${imgWidth}px`} height={`${imgHeight}px`} style={{ transform: `translate(${imgPosX}px, ${imgPosY}px)` }} />}
            <ImageViewer url={`/images/badge/${type.toString()}.svg`} width={`${width}px`} height={`${height}px`} />
        </div>
    );
};
