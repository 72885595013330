import { ActionButton, Icon, IconButton, Label } from '@fluentui/react';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { copyClipboard } from '../../helpers/Clipboard';

import './LinkExtended.scss';
export interface ILinkExtendedProps {
    label?: string;
    className?: string;
    value: string;
    text?: string | undefined;
    copyMessage?: string;
    onCopy?: () => void;
}

export const LinkExtended: FC<ILinkExtendedProps> = (props: ILinkExtendedProps) => {
    const { value, className, copyMessage, onCopy, text } = props;

    const [copied, setCopied] = useState(false);

    const copyText = () => {
        copyClipboard(value || '');
        setCopied(true);
        if (onCopy) {
            onCopy();
        }
    };
    useEffect(() => {
        setCopied(false);
    }, [value]);
    const useClassName = clsx('copyText', className);
    const { label } = props;
    return (
        <div className='DivFlexHVertical'>
            {label && <Label>{label}</Label>}
            <div className='copyContainerLink'>
                <div className='copyContainerLinkInside'>
                    <ActionButton className={useClassName} href={value}>
                        {text ?? value}
                    </ActionButton>
                    {copyMessage && <IconButton iconProps={{ iconName: 'Copy' }} onClick={() => copyText()} />}
                </div>
                {copied && (
                    <div className='flex'>
                        <Icon className='copiedIcon' iconName='CompletedSolid' />
                        <span>{copyMessage}</span>
                    </div>
                )}
            </div>
        </div>
    );
};
