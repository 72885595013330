import { FC, useState } from 'react';
import { ResourceGameContentGrid } from '../../../../Redux/Reducers/DataSet/ApiCall/Resource';
import { useAppDispatch } from '../../../../Redux/hook';
import { IResource, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';
import { performApiCall } from '../../../../common/Hooks/useApiCall';
import { ConfirmationDialog } from '../../../../common/components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { GetResourceTypeIcon } from '../../../../common/components/Icons/ResourceTypeIcon';
import { Panel } from '../../../../common/components/Panel/Panel';
import { ResourceList } from '../ResourceList';
import { ResourceUXDataChange } from '../ResourceUXDataChange';

export const ResourceGameContentList: FC = () => {
    const [selectedResource, setSelectedResource] = useState<IResource>();
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [showEditUxData, setShowEditUxData] = useState<boolean>(false);

    const appDispatch = useAppDispatch();

    return (
        <Panel
            title='Liste des Jeux'
            icon={GetResourceTypeIcon(ResourceTypeEnum.GameContent)}
            commands={[
                {
                    icon: 'AddTo',
                    text: 'Ajouter',
                    onClick: () => {
                        alert('todo');
                    },
                },
                {
                    icon: 'Edit',
                    text: 'Modifier',
                    disabled: !selectedResource,
                    onClick: () => {
                        if (selectedResource?.id) {
                            alert('todo');
                        }
                    },
                },
                {
                    icon: 'PreviewLink',
                    text: 'Modifier la tuile',
                    disabled: !selectedResource,
                    onClick: () => {
                        if (selectedResource?.id) {
                            setShowEditUxData(true);
                        }
                    },
                },
                {
                    icon: 'Delete',
                    text: 'Supprimer',
                    disabled: !selectedResource,
                    onClick: () => {
                        setShowDeleteDialog(true);
                    },
                },
                {
                    icon: 'Refresh',
                    text: 'Actualiser',
                    onClick: () => {
                        ResourceGameContentGrid.refresh();
                    },
                },
            ]}
        >
            <ResourceList
                datasetName={'ResourceGameContentGrid'}
                onSelect={(selection) => setSelectedResource(selection)}
                extraColumns={[]}
                onRowDoubleClick={(item) => {
                    setSelectedResource(item);
                    //TODO
                    //setShowEditDialog('Edit');
                }}
            />
            <ConfirmationDialog
                id='ConfirmationDialog'
                icon={{ iconName: GetResourceTypeIcon(ResourceTypeEnum.GameContent) }}
                show={showDeleteDialog}
                title='Suppression du jeu'
                subText='Etes-vous sur de vouloir supprimer le jeu?'
                onReply={(reply) => {
                    if (reply) {
                        performApiCall(
                            async (client) => {
                                if (selectedResource?.id) {
                                    await client.deleteResource(selectedResource.id);
                                    setShowDeleteDialog(false);
                                    ResourceGameContentGrid.refresh();
                                }
                            },
                            appDispatch,
                            { contextId: 'ConfirmationDialog' },
                        );
                    } else {
                        setShowDeleteDialog(false);
                    }
                }}
            />
            {showEditUxData && selectedResource ? (
                <ResourceUXDataChange
                    resource={selectedResource}
                    onClose={(res) => {
                        setShowEditUxData(false);
                        if (res) {
                            ResourceGameContentGrid.refresh();
                        }
                    }}
                />
            ) : null}
        </Panel>
    );
};
