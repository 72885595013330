import { DefaultButton, DialogFooter, PrimaryButton } from '@fluentui/react';
import { FC, useState } from 'react';
import { fontStylesSelector, setFontStyles } from '../../../Redux/Reducers/System/reducer';
import { useAppDispatch, useAppSelector } from '../../../Redux/hook';
import { IFontStyleEntry, IFontStyles } from '../../../Services/SakuraApiClient';
import { DeepPartial } from '../../Hooks/Form/useForm';
import { performApiCall } from '../../Hooks/useApiCall';
import { duplicateFontStyles } from '../../styles/FontStyles';
import { CustomDialog } from '../Dialog/CustomDialog';
import { FontStylesEditor } from './FontStylesEditor';

export interface FontStylesEditorDialogProps {
    show: boolean;
    onClose: () => void;
}
export const FontStylesEditorDialog: FC<FontStylesEditorDialogProps> = (props: FontStylesEditorDialogProps) => {
    const { show, onClose } = props;
    const fontStyles = useAppSelector(fontStylesSelector);
    const appDispatch = useAppDispatch();
    const [editFontStyles, setEditFontStyles] = useState<DeepPartial<IFontStyles> | undefined>(duplicateFontStyles(fontStyles));
    return (
        <CustomDialog id={'EditFontStyle'} icon={{ iconName: 'Font' }} title={'Modification des style police'} hidden={!show} onDismiss={() => onClose()}>
            <div style={{ minWidth: 1000 }}>
                <FontStylesEditor
                    fontStyles={editFontStyles ?? { entries: [] }}
                    onChange={(modifiedFontStyles) => {
                        setEditFontStyles(modifiedFontStyles);
                    }}
                />
            </div>
            <DialogFooter>
                <PrimaryButton
                    text='Sauvegarder'
                    onClick={() => {
                        if (editFontStyles) {
                            performApiCall(async (client, canceltoken) => {
                                await client.setSetting(
                                    'FontStyles',
                                    {
                                        entries: editFontStyles.entries,
                                    },
                                    canceltoken,
                                );
                                appDispatch(
                                    setFontStyles({
                                        fontStyles: {
                                            entries: editFontStyles.entries as IFontStyleEntry[],
                                        },
                                    }),
                                );
                            }, appDispatch);
                        }
                        onClose();
                    }}
                />
                <DefaultButton text='Annuler' onClick={() => onClose()} />
            </DialogFooter>
        </CustomDialog>
    );
};
