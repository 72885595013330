import { FC } from 'react';
import { EmailTemplateLookup } from '../../Redux/Reducers/DataSet/ApiCall/Brevo';
import { IEmailTemplate } from '../../Services/SakuraApiClient';
import { DatasetLookupField } from './DatasetLookupField';

export interface EmailTemplateLookupFieldProps {
    emailTemplate: IEmailTemplate | undefined;
    onSelectEmailTemplate: (badge: IEmailTemplate | undefined) => void;
    label?: string;
    readOnly?: boolean;
    required?: boolean;
    contextId?: string;
    errorMessage?: string;
}
export const EmailTemplateLookupField: FC<EmailTemplateLookupFieldProps> = (props: EmailTemplateLookupFieldProps) => {
    const { label, emailTemplate, onSelectEmailTemplate, readOnly, required, contextId, errorMessage } = props;

    return (
        <DatasetLookupField
            label={label}
            errorMessage={errorMessage}
            apiCall={EmailTemplateLookup}
            accessKeyProp={(i) => i.id.toString()}
            accessTextProp={(i) => i.name ?? ''}
            selectedItem={emailTemplate}
            onSelectItem={(item) => onSelectEmailTemplate(item)}
            readOnly={readOnly}
            required={required}
            contextId={contextId}
        />
    );
};
