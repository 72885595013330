import { DefaultButton, DialogFooter, PrimaryButton } from '@fluentui/react';
import { FC, useState } from 'react';
import { colorsPaletteSelector, setColorPalette } from '../../../Redux/Reducers/System/reducer';
import { useAppDispatch, useAppSelector } from '../../../Redux/hook';
import { IColorEntry, IColorPalette } from '../../../Services/SakuraApiClient';
import { DeepPartial } from '../../Hooks/Form/useForm';
import { performApiCall } from '../../Hooks/useApiCall';
import { duplicatePalette } from '../../styles/ColorPalette';
import { CustomDialog } from '../Dialog/CustomDialog';
import { ColorPaletteEditor } from './ColorPaletteEditor';

export interface ColorPaletteEditorDialogProps {
    show: boolean;
    onClose: () => void;
}
export const ColorPaletteEditorDialog: FC<ColorPaletteEditorDialogProps> = (props: ColorPaletteEditorDialogProps) => {
    const { show, onClose } = props;
    const colors = useAppSelector(colorsPaletteSelector);
    const appDispatch = useAppDispatch();
    const [editPalette, setEditPalette] = useState<DeepPartial<IColorPalette> | undefined>(duplicatePalette(colors));
    return (
        <CustomDialog id={'EditColorPalette'} icon={{ iconName: 'Color' }} title={'Modification de la palette de couleur'} hidden={!show} onDismiss={() => onClose()}>
            <ColorPaletteEditor
                palette={editPalette}
                onChange={(modifiedPalette) => {
                    setEditPalette(modifiedPalette);
                }}
            />
            <DialogFooter>
                <PrimaryButton
                    text='Sauvegarder'
                    onClick={() => {
                        if (editPalette) {
                            performApiCall(async (client, canceltoken) => {
                                await client.setSetting(
                                    'ColorPalette',
                                    {
                                        entries: editPalette.entries,
                                    },
                                    canceltoken,
                                );
                                appDispatch(
                                    setColorPalette({
                                        colors: {
                                            entries: editPalette.entries as IColorEntry[],
                                        },
                                    }),
                                );
                            }, appDispatch);
                        }
                        onClose();
                    }}
                />
                <DefaultButton text='Annuler' onClick={() => onClose()} />
            </DialogFooter>
        </CustomDialog>
    );
};
