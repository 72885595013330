import { Dropdown, DropdownMenuItemType, IDropdownOption, IDropdownStyles, Label } from '@fluentui/react';
import clsx from 'clsx';
import { FC, useMemo, useState } from 'react';

import { NumberField } from '../NumberField/NumberField';
import './cssStyles.scss';
export interface CssUnitBoxProps {
    label?: string;
    title?: string;
    value: string | undefined;
    disabled?: boolean;
    fullMode?: boolean;
    hideUnit?: boolean;
    small?: boolean;
    vertical?: boolean;
    hasExpandButton?: boolean;
    noBottomborderRadius?: boolean;
    onChange: (value: string | undefined) => void;
}

const units: IDropdownOption[] = [
    { key: 'Absolue', text: 'Absolue', itemType: DropdownMenuItemType.Header },
    {
        key: 'px',
        text: 'pixels',
        title: 'pixels (1px = 1/96th of 1in)',
    },
    {
        key: 'pt',
        text: 'points',
        title: 'points (1pt = 1/72 of 1in)',
    },
    {
        key: 'pc',
        text: 'picas',
        title: 'picas (1pc = 12 pt)',
    },
    {
        key: 'cm',
        text: 'centimetres',
        title: 'centimetres',
    },
    {
        key: 'mm',
        text: 'milimetres',
        title: 'milimetres',
    },
    {
        key: 'in',
        text: 'inches',
        title: 'inches (1in = 96px = 2.54cm)',
    },
    { key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider },
    { key: 'Relative', text: 'Relative', itemType: DropdownMenuItemType.Header },
    {
        key: 'em',
        text: 'em',
        title: 'Relative to the font-size of the element (2em means 2 times the size of the current font)',
    },
    {
        key: 'rem',
        text: 'rem',
        title: 'Relative to font-size of the root element',
    },
    {
        key: 'ex',
        text: 'ex',
        title: 'Relative to the x-height of the current font (rarely used)',
    },
    {
        key: 'ch',
        text: 'ch',
        title: 'Relative to the width of the "0" (zero)',
    },
    {
        key: 'vw',
        text: 'vw',
        title: 'Relative to 1% of the width of the viewport',
    },
    {
        key: 'vh',
        text: 'vh',
        title: 'Relative to 1% of the height of the viewport',
    },
    {
        key: 'vmin',
        text: 'vmin',
        title: "Relative to 1% of viewport's smaller dimension",
    },
    {
        key: 'vmax',
        text: 'vmax',
        title: "Relative to 1% of viewport's larger dimension",
    },
    {
        key: '%',
        text: '%',
        title: 'Pourcentage relative to the parent element',
    },
];

const unitsLight: IDropdownOption[] = [
    {
        key: 'px',
        text: 'px',
        title: 'pixels (1px = 1/96th of 1in)',
    },
    {
        key: 'em',
        text: 'em',
        title: 'Relative to the font-size of the element (2em means 2 times the size of the current font)',
    },
    {
        key: 'rem',
        text: 'rem',
        title: 'Relative to font-size of the root element',
    },
    {
        key: '%',
        text: '%',
        title: 'Pourcentage relative to the parent element',
    },
];
function convertToLitteral(val: string | undefined, unit: string | undefined) {
    if (val && val !== '') {
        return `${val ?? ''}${unit ?? ''}`;
    }
    return undefined;
}
function parseCssValue(val: string | undefined) {
    if (val) {
        const regexp = /(?<value>\d+\.?\d*)(?<unit>[a-zA-Z%]+)?/;
        const match = val.match(regexp);
        if (match?.groups) {
            const value = match.groups['value'];
            const unit = match.groups['unit'];
            return [value !== undefined ? Number.parseFloat(value.toString()) : undefined, unit?.toString()];
        }
    }
    return [undefined, undefined];
}
const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 100 },
};
const dropdownStylesLight: Partial<IDropdownStyles> = {
    dropdown: { width: 67 },
};
export const CssUnitBox: FC<CssUnitBoxProps> = (props: CssUnitBoxProps) => {
    const { value, onChange, label, title, fullMode, hideUnit, disabled, small, vertical, hasExpandButton, noBottomborderRadius } = props;

    const [unit, setUnit] = useState('px');
    const [cssValue, cssUnit] = useMemo(() => {
        return parseCssValue(value);
    }, [value]);

    return (
        <div className={clsx('cssUnitBoxContainer', vertical ? 'vertical' : '')}>
            {label && <Label title={title}>{label}</Label>}
            <div className={clsx('cssUnitBox', hideUnit ? '' : 'hasUnit', small ? 'small' : '', hasExpandButton ? 'hasExpandButton' : '')}>
                <NumberField
                    className='cssUnitBoxValue'
                    disabled={disabled}
                    title={title}
                    value={cssValue ? Number.parseFloat(cssValue.toString()) : undefined}
                    inputType='DecimalNumber'
                    onChange={(v) => {
                        const val = convertToLitteral(v?.toString(), cssUnit?.toString() ?? unit);
                        onChange(val);
                    }}
                />
                {hideUnit ? (
                    <></>
                ) : (
                    <Dropdown
                        disabled={disabled}
                        styles={fullMode ? dropdownStyles : dropdownStylesLight}
                        options={fullMode ? units : unitsLight}
                        className={noBottomborderRadius ? 'noBottomborderRadius' : ''}
                        selectedKey={cssUnit ?? unit}
                        onChange={(_, opt) => {
                            setUnit((opt?.key as string) ?? 'px');
                            onChange(convertToLitteral(cssValue?.toString(), (opt?.key as string) ?? unit));
                        }}
                    />
                )}
            </div>
        </div>
    );
};
