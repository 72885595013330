import { FC, useCallback, useMemo, useState } from 'react';
import { IProduct, IResourceOfProduct, Product } from '../../../Services/SakuraApiClient';

import { Separator } from '@fluentui/react';
import { useAppDispatch } from '../../../Redux/hook';

import { useForm } from '../../../common/Hooks/Form';

import { Panel, PanelCommand } from '../../../common/components/Panel/Panel';
import { useNav } from '../../../common/Hooks/useNav';
import { screenPaths } from '../../../Routing/ScreenPaths';

import * as validators from '../../../common/Hooks/Form/validators';
import { useNavigationEntity } from '../../../common/Hooks/useNavigationEntity';
import { ProductDetailForm } from './ProductDetailForm';
import { ProductDetailResourceList } from './ProductDetailResourceList';
import { saveProduct } from './saveProduct';

const getNewProduct = (): IProduct => {
    const newProduct = new Product();
    newProduct.init({ enabled: true });
    return newProduct;
};
export const ProductDetail: FC = () => {
    const [id, product] = useNavigationEntity((id, client) => client.getProduct(id as number));
    return id && !product ? <></> : <ProductDetailContent product={product}></ProductDetailContent>;
};
interface ProductDetailContentProps {
    product: IProduct | undefined;
}

export const ProductDetailContent: FC<ProductDetailContentProps> = (props: ProductDetailContentProps) => {
    const { product } = props;
    const nav = useNav();
    const appDispatch = useAppDispatch();

    const [resources, setResources] = useState<IResourceOfProduct[]>([]); //use in creation mode only
    const form = useForm<IProduct>(
        {
            initialState: product ?? getNewProduct(),
            validators: {
                fields: {
                    name: { displayName: 'Le nom', validators: [validators.required] },
                    productType: { displayName: 'Le type', validators: [validators.required] },
                },
            },
        },
        product !== undefined,
    );
    const goBack = useCallback(
        (refresh: boolean) => {
            if (nav.from?.pathname.endsWith(screenPaths.productList)) {
                nav.navigate(screenPaths.productList, refresh ? { refresh: true } : undefined);
            } else if (nav.from) {
                nav.goBack();
            }
        },
        [nav],
    );

    const productCommand = useMemo<PanelCommand[]>(() => {
        return [
            {
                text: product ? 'Sauvegarder' : 'Créer',
                icon: 'Save',
                onClick: () => {
                    saveProduct(form, product, resources, appDispatch, () => goBack(true));
                },
            },
        ];
    }, [product, form, resources, appDispatch, goBack]);

    const backButtonCommand = useCallback(() => (nav.from ? goBack(true) : nav.navigate(screenPaths.productList)), [goBack, nav]);
    return (
        <>
            <Panel isContentModified={form.isModified} revertContentModification={form.rollback} backButtonCommand={backButtonCommand} title='Produit' icon='Product' commands={productCommand}>
                <ProductDetailForm form={form} />
            </Panel>
            <Separator />
            <ProductDetailResourceList onResourcesChange={(r) => setResources(r)} resources={resources} createMode={product === undefined} product={product} />
        </>
    );
};
