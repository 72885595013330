import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ObjectOperation } from '../../../common/helpers/ObjectAndArray';
import { useAppSelector } from '../../hook';
import type { RootState } from '../../store';
import { ClearScopeAction, DeclareVariableAction, EnableAutoRefreshIntelisenseAction, LoadScopeAction, PatchScopeAction, SetVariableAction } from './action';
import { dynamicDataResolverOnData } from './dynamicDataResolver';
import { buildDynamicDataIntelisense, setVar, updateIntelisense } from './helpers';
import { DataScope, initialState } from './state';

export const dynamicDataSlice = createSlice({
    name: 'dynamicData',
    initialState,
    reducers: {
        loadScope: (state, action: PayloadAction<LoadScopeAction>) => {
            state.data[action.payload.scope] = { ...state.data[action.payload.scope], ...action.payload.content };

            if (state.autoRefreshIntelisense) {
                state.intelisense = buildDynamicDataIntelisense(state.data);
            }
        },

        clearScope: (state, action: PayloadAction<ClearScopeAction>) => {
            state.data[action.payload.scope] = undefined;
            if (state.autoRefreshIntelisense) {
                state.intelisense = buildDynamicDataIntelisense(state.data);
            }
        },
        patchScope: (state, action: PayloadAction<PatchScopeAction>) => {
            if (action.payload.content) {
                state.data[action.payload.scope] = action.payload.content;
            }
            if (action.payload.contentToMerge) {
                state.data[action.payload.scope] = ObjectOperation.merge(state.data[action.payload.scope], action.payload.contentToMerge);
            }
            if (state.autoRefreshIntelisense) {
                state.intelisense = buildDynamicDataIntelisense(state.data);
            }
        },
        setVariable: (state, action: PayloadAction<SetVariableAction>) => {
            if (action.payload.name.findIndex((s) => s === 'data') === -1) {
                return;
            }
            setVar(action.payload.name, state.data, action.payload.value);
            if (state.autoRefreshIntelisense) {
                updateIntelisense(action.payload.name, state.intelisense, 'Add');
            }
        },

        declareVariables: (state, action: PayloadAction<DeclareVariableAction>) => {
            action.payload.variables.forEach((v) => {
                updateIntelisense(v.variableName, state.intelisense, 'Add');
            });
        },
        refreshIntelisense: (state) => {
            state.intelisense = buildDynamicDataIntelisense(state.data);
        },
        enableAutoRefreshIntelisense: (state, action: PayloadAction<EnableAutoRefreshIntelisenseAction>) => {
            state.autoRefreshIntelisense = action.payload.enable;
        },
    },
});

//Export Actions
export const { loadScope, clearScope, patchScope, setVariable, refreshIntelisense, enableAutoRefreshIntelisense, declareVariables } = dynamicDataSlice.actions;
//Export Selector
export const dynamicDataSelector = (state: RootState) => state.dynamicData.data;

export const useDynamicData = () => {
    return useAppSelector(dynamicDataSelector);
};
export const useDynamicDataIntelisense = () => {
    return useAppSelector((state: RootState) => state.dynamicData.intelisense);
};
export const useIntelisenseWritableDataOfScope = (scope: DataScope) => {
    return useAppSelector((state: RootState) => {
        if (state.dynamicData.intelisense[scope]?.items) {
            const scopeItems = state.dynamicData.intelisense[scope].items;
            if (scopeItems) {
                return scopeItems['data'].items;
            }
        }
        return undefined;
    });
};
export const useDynamicDataVariable = (variableName: string[] | undefined) => {
    return useAppSelector((state: RootState) => {
        const dynData = state.dynamicData.data;
        if (variableName && variableName.length > 0) {
            return dynamicDataResolverOnData(dynData, variableName);
        }
        return undefined;
    });
};
// export const getDynamicDataResolver = () => {
//     return useAppSelector((state: RootState) => {
//         const dynData = state.dynamicData.data;
//         return dynamicDataResolver(dynData);
//     });
// };
//export Reducer
export default dynamicDataSlice.reducer;
