import clsx from 'clsx';
import { FC, useEffect, useRef, useState } from 'react';
import { storageEndpointsSelector } from '../../../Redux/Reducers/System/reducer';
import { removeLastDisplayBadge } from '../../../Redux/Reducers/User/reducer';
import { useAppDispatch, useAppSelector } from '../../../Redux/hook';
import { IBadge } from '../../../Services/SakuraApiClient';
import { ST } from '../../Hooks/StorageResolver';
import { performApiCall } from '../../Hooks/useApiCall';
import { gblSoundBadge } from '../../Sounds/GlobalSounds';
import './BadgeToast.scss';
import { BadgeViewer } from './BadgeViewer';
import { getBadgeTypeLabel } from './helper';

export interface BadgeToastProps {
    badge: IBadge;
}

export const BadgeToast: FC<BadgeToastProps> = (props: BadgeToastProps) => {
    const { badge } = props;
    const storage = useAppSelector(storageEndpointsSelector);
    const dispatch = useAppDispatch();
    const [name, setName] = useState<string>();
    const [isClosed, setIsClosed] = useState<boolean>(false);
    const [displayBadge, setDisplayBadge] = useState<IBadge>();
    const soundId = useRef<number>();
    useEffect(() => {
        setName(undefined);
        setIsClosed(false);
        setDisplayBadge(undefined);
        const timeOutIds: NodeJS.Timeout[] = [];
        const timeoutId = setTimeout(() => {
            setDisplayBadge(badge);
            performApiCall(async (client, cancelToken) => {
                await client.markBadgeSeen(badge.id, cancelToken);
            }, dispatch);
            if (soundId.current) {
                gblSoundBadge.seek(0, soundId.current);
            }
            soundId.current = gblSoundBadge.play();
            timeOutIds[0] = setTimeout(() => {
                setName(badge.name);

                timeOutIds[1] = setTimeout(() => {
                    setName(undefined);
                    setIsClosed(true);
                    timeOutIds[2] = setTimeout(() => {
                        gblSoundBadge.stop(soundId.current);
                        soundId.current = undefined;
                        dispatch(removeLastDisplayBadge());
                    }, 2000);
                }, 6000);
            }, 1000);
        }, 200);
        return () => {
            clearTimeout(timeoutId);
            timeOutIds.forEach((id) => clearTimeout(id));
        };
    }, [badge, dispatch]);

    return displayBadge ? (
        <div className={clsx('BadgeToast', displayBadge.badgeType, isClosed ? 'close' : 'open')}>
            <div className='effect'></div>
            <div className='cercle'>
                <BadgeViewer width={100} height={100} url={ST(storage, displayBadge.imageBlobPath)} type={displayBadge.badgeType} graphics={displayBadge.badgeSetting.graphics} />
            </div>

            <div className={clsx('text', name ? 'show' : '')}>
                <span>{name}</span>
                {name && <span className={clsx(displayBadge.badgeType, 'Type')}>{getBadgeTypeLabel(displayBadge.badgeType)}</span>}
                <span className='sub'>Vous avez acquis un badge.</span>
            </div>
        </div>
    ) : (
        <></>
    );
};
