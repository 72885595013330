import { PrimaryButton } from '@fluentui/react';
import { FC } from 'react';

export const ErrorPage: FC = () => {
    return (
        <div className='sakuraFullScreen'>
            <img src='/Logo_application.svg' alt='Sakura coaching logo' className='applicationLogo entrance' tabIndex={-1} width={370} />
            <span style={{ color: 'gray', margin: 20 }}>
                Le site est temporairement inaccessible.
                <br />
                Nous faisons notre maximum pour que tout rentre dans la normal dans les plus bref délais.
            </span>
            <PrimaryButton
                text='Ressayer'
                className='sakuraButton'
                iconProps={{ iconName: 'Refresh' }}
                onClick={() => {
                    if (window.location.pathname.startsWith('/login') || window.location.pathname.startsWith('/error')) {
                        window.location.replace('/');
                    } else {
                        window.location.reload();
                    }
                }}
            />
        </div>
    );
};
