import React, { FC } from 'react';
import { IResource, ResourceTypeEnum } from '../../../../Services/SakuraApiClient';
import { GetResourceTypeIcon } from '../../../../common/components/Icons/ResourceTypeIcon';
import { Icon, Label } from '@fluentui/react';
import clsx from 'clsx';

export interface PageTemplateItemProps {
    item: IResource;
    isSelected: boolean;
    onSelect: () => void;
}
export const PageTemplateItem: FC<PageTemplateItemProps> = (props: PageTemplateItemProps) => {
    const { item, isSelected, onSelect } = props;

    return (
        <div className={clsx('templateItem', isSelected ? 'selected' : '')} onClick={() => onSelect()}>
            <Icon iconName={GetResourceTypeIcon(ResourceTypeEnum.PageTemplate)} />
            <Label>{item.name}</Label>
        </div>
    );
};
