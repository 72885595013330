import { Icon } from '@fluentui/react';
import { FC } from 'react';

export interface IconEnabledProps {
    enabled: boolean | undefined;
}

export const IconEnabled: FC<IconEnabledProps> = (props: IconEnabledProps) => {
    const { enabled } = props;
    return <Icon iconName={enabled ? 'SkypeCircleCheck' : 'SkypeCircleMinus'} style={{ color: enabled ? 'green' : 'gray', fontSize: 'large' }} />;
};
