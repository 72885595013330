import { Pivot, PivotItem, Toggle } from '@fluentui/react';
import React, { FC } from 'react';
import { DeepPartial } from '../../../../../common/helpers/ObjectAndArray';
import { IPageElementNumberField, NumberFieldType } from '../../../../../Services/SakuraApiClient';

import { InputElementPanel } from './InputElementPanel';
import { FontStyle } from '../Common/FontStyle';
import { CssCommonField } from '../Common/CssCommonField';

export interface NumberFieldElementPanelProps {
    data: IPageElementNumberField;
    onChangeData: (data: DeepPartial<IPageElementNumberField>) => void;
}

export const NumberFieldElementPanel: FC<NumberFieldElementPanelProps> = (props: NumberFieldElementPanelProps) => {
    const { onChangeData, data } = props;

    return (
        <>
            <Pivot>
                <PivotItem headerText='Propriétés'>
                    <InputElementPanel data={data} onChangeData={onChangeData} />
                    <Toggle
                        label={'Type'}
                        checked={data.fieldType === NumberFieldType.Decimal}
                        offText={'entier'}
                        onText={'Décimal'}
                        onChange={(_, value) => onChangeData({ fieldType: value ? NumberFieldType.Decimal : NumberFieldType.Integer })}
                    />
                </PivotItem>
                <PivotItem headerText='Style'>
                    <FontStyle
                        font={data.style?.font}
                        onChangeFont={(font) => {
                            onChangeData({ style: { font } });
                        }}
                    />
                    <CssCommonField data={data.style} onChange={(style) => onChangeData({ style })} />
                </PivotItem>
            </Pivot>
        </>
    );
};
