import { FC, useMemo, useState } from 'react';
import { IFontStyleEntry } from '../../../Services/SakuraApiClient';

import { ActionButton, Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react';
import { fontStylesSelector } from '../../../Redux/Reducers/System/reducer';
import { useAppSelector } from '../../../Redux/hook';
import { FontStylesEditorDialog } from './FontStylesEditorDialog';

export interface FontStylePickerProps {
    label?: string;
    selectedFontStyleName: string;
    onSelect: (fontStyleName: string, data: IFontStyleEntry) => void;
    smallDropdown?: boolean;
}
const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { minWidth: 175 },
};

export const FontStylePicker: FC<FontStylePickerProps> = (props: FontStylePickerProps) => {
    const { selectedFontStyleName, onSelect, smallDropdown, label } = props;
    const fontStyles = useAppSelector(fontStylesSelector);
    const [showCreateStyle, setShowCreateStyle] = useState<boolean>(false);
    const options = useMemo<IDropdownOption[]>(() => {
        if (fontStyles.entries && fontStyles.entries.length > 0) {
            return [
                { key: '', text: 'Personnalisé' },
                ...fontStyles.entries.map((e) => ({
                    text: e.entryName ?? '',
                    key: e.entryName ?? '',
                    data: { entryName: e.entryName, font: e.font, color: e.color },
                })),
            ];
        }
        return [{ key: '', text: 'Personnalisé' }];
    }, [fontStyles.entries]);
    return (
        <>
            <Dropdown
                label={label}
                title='Style de police'
                className={smallDropdown ? 'ddlsmallsize' : undefined}
                styles={dropdownStyles}
                options={options}
                selectedKey={selectedFontStyleName}
                onChange={(_, option) => {
                    onSelect(option?.key as string, option?.data);
                }}
                onRenderList={(props, df) => {
                    if (df) {
                        return (
                            <div>
                                <ActionButton
                                    iconProps={{ iconName: 'AddTo' }}
                                    onClick={() => {
                                        setShowCreateStyle(true);
                                    }}
                                >
                                    {'Créer un nouveau style'}
                                </ActionButton>
                                {df(props)}
                            </div>
                        );
                    }
                    return <div></div>;
                }}
            />
            <FontStylesEditorDialog
                show={showCreateStyle}
                onClose={() => {
                    setShowCreateStyle(false);
                }}
            />
        </>
    );
};
