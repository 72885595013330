import { FC, useContext, useMemo } from 'react';
import { storageEndpointsSelector } from '../../../../../Redux/Reducers/System/reducer';
import { useAppSelector } from '../../../../../Redux/hook';
import { IPageElementAudio } from '../../../../../Services/SakuraApiClient';
import { ST } from '../../../../../common/Hooks/StorageResolver';
import { AudioPlayer } from '../../../../../common/components/AudioPlayer/AudioPlayer';
import { ExecutionDataContext } from '../../ExecutionContext/ExecutionDataContextProvider';

export interface ElementAudioViewProps {
    data: IPageElementAudio;
}

export const ElementAudioView: FC<ElementAudioViewProps> = (props: ElementAudioViewProps) => {
    const { data } = props;
    const storage = useAppSelector(storageEndpointsSelector);

    const [state] = useContext(ExecutionDataContext);
    const url = useMemo(() => {
        return ST(storage, state.resources[data.resource?.id]?.blobPath);
    }, [state.resources, data, storage]);

    return <AudioPlayer blobPath={url} blobPathKind='Value' display='Action' />;
};
