import { Dropdown, IDropdownOption, Label, Separator } from '@fluentui/react';
import { FC, useCallback, useEffect, useState } from 'react';
import { CstWidthMobile } from '../../../Redux/Reducers/System/state';
import { IPageElementContainer, IResourceDataPage } from '../../../Services/SakuraApiClient';
import { NumberField } from '../../../common/components/NumberField/NumberField';

import { setContextualMobileDisplay, setWindowWidth } from '../../../Redux/Reducers/System/reducer';
import { useAppDispatch } from '../../../Redux/hook';
import { PageElementView } from '../Page/PageElementView';
import { deviceResolutions } from './mobileResolution';

export interface PageViewProps {
    data: IResourceDataPage;
}

export const PagePreviewView: FC<PageViewProps> = (props: PageViewProps) => {
    const { data } = props;

    const [resolution, setResolution] = useState<IDropdownOption | undefined>(deviceResolutions.find((r) => r.key === 'PC 1600x700'));
    const dispatch = useAppDispatch();
    const isMobile = resolution?.data.width <= CstWidthMobile;
    useEffect(() => {
        dispatch(setWindowWidth({ width: resolution?.data.width ?? window.innerWidth }));
    }, [resolution, dispatch]);
    useEffect(() => {
        dispatch(setContextualMobileDisplay({ mobile: isMobile }));
    }, [isMobile, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(setWindowWidth({ width: window.innerWidth }));
        };
    }, [dispatch]);
    const onChangeDeviceResolution = useCallback(
        (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number) => {
            setResolution(option);
        },
        [setResolution],
    );
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div className='DivFlexHorizontalCenter'>
                <Dropdown style={{ width: 250 }} options={deviceResolutions} selectedKey={resolution?.key} onChange={onChangeDeviceResolution}></Dropdown>
                <NumberField value={resolution?.data.width} readOnly></NumberField>
                <Label>X</Label>
                <NumberField value={resolution?.data.height} readOnly></NumberField>
            </div>
            <Separator />
            <div style={{ width: resolution?.data.width, height: resolution?.data.height, overflowY: 'auto', border: '1px solid gray' }}>
                <PageElementView rootContainer data={isMobile && ((data.mobilePage as unknown as IPageElementContainer).items ?? []).length > 0 ? data.mobilePage : data.page} />
            </div>
        </div>
    );
};
