import { LexicalEditor } from 'lexical';
import { FC, useCallback } from 'react';
import { getIdFromCssVar, idToCssVar } from '../../../../styles/common';
import { ColorPalettePicker } from '../../../Color/ColorPalettePicker';
import { applyStyleOnSelection } from '../../Helpers/selection';
import { predefinedStylePropertyName } from './PredefinedStyleSelector';

export interface ColorSelectorProps {
    color?: string;
    editor: LexicalEditor;
    type: 'f' | 'b';
}

export const ColorSelector: FC<ColorSelectorProps> = (props: ColorSelectorProps) => {
    const { color, editor, type } = props;

    const onChangeHandle = useCallback(
        (colorId: string | undefined) => {
            const styles: Record<string, string | null> = {};
            if (colorId) {
                if (type === 'f') {
                    styles['color'] = idToCssVar(colorId)!;
                    styles[predefinedStylePropertyName] = null;
                } else {
                    styles['background'] = idToCssVar(colorId)!;
                }

                applyStyleOnSelection(editor, styles, true);
            }
        },
        [editor, type],
    );

    return <ColorPalettePicker type={type} colorId={getIdFromCssVar(color)} onChange={onChangeHandle} />;
};
