import { Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react';
import { FC, useMemo } from 'react';
import { IFontStyle } from '../../../Services/SakuraApiClient';
import { fontPropertyChange, parseFontProperty } from '../../styles/FontStyles';
import { ColorPalettePicker } from '../Color/ColorPalettePicker';
import { CssUnitBox } from '../Css/CssUnitBox';
import { SelectableIcon } from '../Icons/SelectableIcon';
import { FontFamilySelector } from './FontFamilySelector';

export const listVariant: IDropdownOption[] = [
    {
        key: `normal`,
        text: 'normal',
    },
    {
        key: `small-caps`,
        text: 'small-caps',
    },
];

export type FontStyleData = Omit<IFontStyle, 'presetName'>;

export interface FontConfigurationProps {
    font: FontStyleData | undefined;
    onChangeFont: (data: Partial<FontStyleData>) => void;
}

const variantDdlStyle: Partial<IDropdownStyles> = {
    dropdown: { width: 130 },
};
export const FontConfiguration: FC<FontConfigurationProps> = (props: FontConfigurationProps) => {
    const { onChangeFont, font } = props;

    const fontProperties = useMemo(() => {
        return parseFontProperty(font?.font);
    }, [font?.font]);
    return (
        <>
            <div className='DivFlexHorizontalCenter'>
                <SelectableIcon
                    iconName='Bold'
                    title='gras'
                    selected={fontProperties.weight === '700'}
                    onChange={(selected) => {
                        onChangeFont({ ...font, font: fontPropertyChange(font?.font, { weight: selected ? '700' : undefined }) });
                    }}
                />
                <SelectableIcon
                    iconName='Italic'
                    title='Italique'
                    selected={fontProperties.style === 'italic'}
                    onChange={(selected) => {
                        onChangeFont({ ...font, font: fontPropertyChange(font?.font, { style: selected ? 'italic' : undefined }) });
                    }}
                />
                <SelectableIcon
                    iconName='Underline'
                    title='Souligné'
                    selected={font?.underline}
                    onChange={(selected) => {
                        onChangeFont({ ...font, underline: selected ?? undefined, strike: undefined });
                    }}
                />
                <SelectableIcon
                    iconName='Strikethrough'
                    selected={font?.strike}
                    onChange={(selected) => {
                        onChangeFont({ ...font, strike: selected ?? undefined, underline: undefined });
                    }}
                />
                <ColorPalettePicker
                    type='f'
                    idAsCssVariable
                    colorId={font?.color}
                    onChange={(color) => {
                        onChangeFont({ ...font, color });
                    }}
                />
            </div>
            <div className='DivFlexHorizontalCenterWrap'>
                <FontFamilySelector
                    label='Policy'
                    family={fontProperties?.family}
                    onChange={(family) => {
                        onChangeFont({ ...font, font: fontPropertyChange(font?.font, { family }) });
                    }}
                />
                <CssUnitBox
                    label='taille'
                    value={fontProperties?.size}
                    title='Taille de la police'
                    small
                    vertical
                    onChange={(size) => {
                        onChangeFont({ ...font, font: fontPropertyChange(font?.font, { size }) });
                    }}
                />
                <CssUnitBox
                    label='Hauteur de ligne'
                    value={fontProperties?.height}
                    title='Hauteur de ligne'
                    small
                    vertical
                    onChange={(height) => {
                        onChangeFont({ ...font, font: fontPropertyChange(font?.font, { height }) });
                    }}
                />
                <Dropdown
                    label='Variant'
                    options={listVariant}
                    styles={variantDdlStyle}
                    selectedKey={fontProperties?.variant ?? 'normal'}
                    onChange={(_, option) => {
                        onChangeFont({ ...font, font: fontPropertyChange(font?.font, { variant: option?.key as string }) });
                    }}
                ></Dropdown>
            </div>
        </>
    );
};
