import { FC } from 'react';
import { IPageElementCustomComponent } from '../../../../../Services/SakuraApiClient';
import { DeepPartial } from '../../../../../common/helpers/ObjectAndArray';
import { CustomComponentName } from '../../../Page/Elements/CustomComponent/type';
import { ShareButtonPanel } from './ShareButton';
import { ThumbnailsSectionPanel } from './ThumbnailsSection';

export interface CustomComponentElementPanelProps {
    data: IPageElementCustomComponent;
    onChangeData: (data: DeepPartial<IPageElementCustomComponent>) => void;
}

export const CustomComponentElementPanel: FC<CustomComponentElementPanelProps> = (props: CustomComponentElementPanelProps) => {
    const { onChangeData, data } = props;

    switch (data.name as CustomComponentName) {
        case 'ShareButton':
            return <ShareButtonPanel data={data} onChangeData={onChangeData} />;
        case 'ThumbnailsSection':
            return <ThumbnailsSectionPanel data={data} onChangeData={onChangeData} />;
        default:
    }
    return (
        <>
            <span>TODO</span>
        </>
    );
};
