import { Label, Pivot, PivotItem } from '@fluentui/react';
import { FC } from 'react';
import { IPageElementButton } from '../../../../../Services/SakuraApiClient';
import { ColorPalettePicker } from '../../../../../common/components/Color/ColorPalettePicker';
import { ExpressionField, ExpressionKind } from '../../../../../common/components/ExpressionField/ExpressionField';
import { FluentIconSelectorDialog } from '../../../../../common/components/Icons/FluentIconSelectorDialog';
import { DeepPartial } from '../../../../../common/helpers/ObjectAndArray';
import { ConfigureClickAction } from '../Common/ConfigureClickAction';
import { CssCommonField } from '../Common/CssCommonField';
import { FontStyle } from '../Common/FontStyle';

export interface BoutonElementPanelProps {
    data: IPageElementButton;
    onChangeData: (data: DeepPartial<IPageElementButton>) => void;
}

export const BoutonElementPanel: FC<BoutonElementPanelProps> = (props: BoutonElementPanelProps) => {
    const { onChangeData, data } = props;

    return (
        <>
            <Pivot>
                <PivotItem headerText='Propriétés'>
                    <ExpressionField
                        kind={ExpressionKind.TextWithExpression}
                        showExpandButton
                        label='Texte du bouton'
                        value={data.value}
                        onChange={(val) => {
                            onChangeData({ value: val });
                        }}
                    ></ExpressionField>
                    <ConfigureClickAction label='Action a effectuée' data={data.onClick} onChange={(onClick) => onChangeData({ onClick })} />
                    <Label>{"Couleur d'arriere plan"}</Label>
                    <ColorPalettePicker
                        type='b'
                        border
                        idAsCssVariable
                        colorId={data.style?.background}
                        onChange={(colorId) => {
                            onChangeData({ style: { background: colorId } });
                        }}
                    />
                    <FluentIconSelectorDialog
                        label='Icon à gauche'
                        icon={data.style?.iconLeft}
                        onChange={(iconLeft) => {
                            onChangeData({ style: { iconLeft } });
                        }}
                    />
                    <FluentIconSelectorDialog
                        label='Icon à droite'
                        icon={data.style?.iconRight}
                        onChange={(iconRight) => {
                            onChangeData({ style: { iconRight } });
                        }}
                    />
                </PivotItem>
                <PivotItem headerText='Style'>
                    <FontStyle
                        font={data.style?.font}
                        onChangeFont={(font) => {
                            onChangeData({ style: { font } });
                        }}
                    />
                    <CssCommonField data={data.style} onChange={(style) => onChangeData({ style })} />
                </PivotItem>
            </Pivot>
        </>
    );
};
